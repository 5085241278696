import React from 'react'
import {
    Box,
    CardHeader,
    IconButton
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CloseIcon from '@mui/icons-material/Close';
import useStyles from './Styles'

interface Props {
    setRightPanelOpened: (a: boolean) => void
    rightPanelOpened: boolean
}

const ChatDetailsComponentHeader: React.FC<Props> = ({ setRightPanelOpened, rightPanelOpened }) => {
    // const [value, setValue] = useState(0);

    const handleChange = () => {
        // setValue(newValue);
        setRightPanelOpened(!rightPanelOpened);
    };
    const { classes } = useStyles();

    return (
        <CardHeader sx={{ height: 46, }} subheader={
            <Box sx={{ display: "flex" }}>
                <Box className={classes.icon} >
                    <IconButton disableRipple><PersonIcon className={classes.color} /></IconButton>
                </Box>
                <Box>
                    <IconButton disableRipple><NoteAddIcon /></IconButton>
                </Box>
            </Box>

        } action={<Box onClick={handleChange}>
            <CloseIcon className={classes.close} />
        </Box>}
        >
        </CardHeader>
    )
}

export default ChatDetailsComponentHeader