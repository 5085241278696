import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import clsx from "clsx";
import {
  setAdminPanelDrawerToggle,
  setAdminPanelChatBoxDrawerToggle,
} from "../../../store/actions/AdminpanelActions";
import { useLocation, } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useEffect } from "react";

import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import ForumIcon from '@mui/icons-material/Forum';
import HomeIcon from '@mui/icons-material/Home';
import useStyles from './Styles'
import SvgIcon from '@mui/material/SvgIcon';
import { Typography } from "@mui/material";
import DescriptionIcon from '@mui/icons-material/Description';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  // transition: theme.transitions.create('width', {
  //   easing: theme.transitions.easing.easeOut,
  //   duration: theme.transitions.duration.enteringScreen,
  // }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


const NavigationList = (props: { setAdminPanelChatBoxDrawerToggle: (arg0: boolean) => void; adminPanelDrawerToggle: any; adminPanelClosedDrawerToggle: any; setAdminPanelDrawerToggle: (arg0: boolean) => void; }) => {
  const { classes } = useStyles();
  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const handleMouseEnter = () => {
    setOpen(!open);
  };
  const handleMouseLeave = () => {
    setOpen(!open);
  };

  let uperItems = [
    {
      name: 'Dashboard', icon: <IconButton ><HomeIcon
        className={clsx(classes.icon, {
          [classes.linkSelectedtext]:
            location.pathname.includes(`/dashboard`)
        })}

      /></IconButton>
    },
    {
      name: 'Chats', icon: <IconButton><ForumIcon className={clsx(classes.icon, {
        [classes.linkSelectedtext]:
          location.pathname.includes(`/chats`)
      })} /></IconButton>
    },
    {
      name: 'Users', icon: <IconButton><PeopleIcon className={clsx(classes.icon, {
        [classes.linkSelectedtext]:
          location.pathname.includes(`/users`)
      })} /></IconButton>
    },
    {
      name: 'Traffic', icon: <IconButton><TrackChangesIcon className={clsx(classes.icon, {
        [classes.linkSelectedtext]:
          location.pathname.includes(`/traffic`)
      })} /></IconButton>
    },
    {
      name: 'Reports', icon: <IconButton><DescriptionIcon className={clsx(classes.icon, {
        [classes.linkSelectedtext]:
          location.pathname.includes(`/reports`)
      })} /></IconButton>
    },

    {
      name: 'Settings', icon: <IconButton><SettingsIcon className={clsx(classes.icon, {
        [classes.linkSelectedtext]:
          location.pathname.includes(`/settings`)
      })} /> </IconButton>
    },
  ]

  const activeUsers: string | number | null = localStorage.getItem("ActiveUserdetail");
  const user: object | null = activeUsers && JSON.parse(activeUsers)
  const [activeUserData] = React.useState<any>({ ...user })


  // removing user icon from manager and agent

  if (activeUserData && activeUserData.paneltype !== "SUPERADMIN") {
    uperItems = uperItems.filter(item => item.name !== "Traffic")
    if (activeUserData.paneltype === "AGENT") {
      uperItems = uperItems.filter(item => item.name !== "Users")
    }
  }



  // useEffect(() => {
  //   if (location.pathname === "/chats") {
  //     props.setAdminPanelChatBoxDrawerToggle(true);
  //   } else {
  //     props.setAdminPanelChatBoxDrawerToggle(false);
  //   }
  // }, []);

  const LogoutQuery = gql`
    mutation {
      logout {
        success
        error
      }
    }
  `;

  let [
    logout,
    {
      // loading: logoutQueryLoading,
      // error: logoutQueryError,
      data: logoutQueryResult,
    },
  ] = useMutation(LogoutQuery);
  useEffect(() => {
    if (logoutQueryResult && logoutQueryResult.logout) {
      window.location.href = "/login";
    }
  }, [logoutQueryResult]);

  return (

    <Box sx={{ display: 'flex' }} >
      <CssBaseline />
      <Drawer variant="permanent" open={open} onMouseEnter={handleMouseEnter} sx={{
        position: 'fixed',
        zIndex: 999,
        left: 0,
        top: 0,
        height: '100vh',
        width: "4.166%",
        backgroundColor: '#f5f5f5',
        transition: 'width 0.2s ease-in-out',
        '&:hover': {
          width: 200,
        },
      }}
        onMouseLeave={handleMouseLeave}>
        <DrawerHeader sx={{ height: 70 }}>

          <ListItem sx={{
            display: 'block', padding: 0,
            poPoppinsEvents: "none",
            '&:hover': {
              backgroundColor: "#FFFFFF"
            },
          }} >

            <ListItemButton disableRipple
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5, textDecoration: "none", '&:hover': {
                  backgroundColor: "#ffffff"
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <SvgIcon className={classes.logo} >
                  <svg width="25" height="27" viewBox="0 0 40 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Group 3386">
                      <g id="Group 289746">
                        <path id="Vector" d="M39.4564 0.034361L33.8689 2.39589L23.7983 12.8916L28.9979 15.453L11.6406 28.3353L18.2963 25.5552C19.348 25.1147 20.5674 25.2647 21.468 25.9456L23.6241 27.5731C23.9824 27.8448 24.5148 27.7043 24.6824 27.2951L26.4639 22.825C26.7399 22.1316 27.279 21.5662 27.9758 21.2351L32.758 18.9641C33.2773 18.7173 33.6717 18.2863 33.859 17.7646L39.979 0.4873C40.0677 0.237403 39.8606 0 39.6141 0C39.5615 0 39.5089 0.0093712 39.4564 0.034361Z" fill="url(#paint0_linear_1896_16984)" />
                        <path id="Vector_2" d="M7.12844 13.7039C6.55655 13.9444 6.11941 14.4067 5.92878 14.9752L0.0192097 32.518C-0.0826794 32.821 0.239422 33.0959 0.545089 32.9679L4.00932 31.5216L14.8589 20.2137L9.65924 17.6523L25.3797 5.98828L7.12844 13.7039Z" fill="url(#paint1_linear_1896_16984)" />
                      </g>
                    </g>
                    <defs>
                      <linearGradient id="paint0_linear_1896_16984" x1="42.0463" y1="2.74887" x2="13.626" y2="32.6524" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#6BE900" />
                        <stop offset="0.2" stop-color="#66E501" />
                        <stop offset="0.42" stop-color="#58DC07" />
                        <stop offset="0.66" stop-color="#41CE11" />
                        <stop offset="0.9" stop-color="#21B91E" />
                        <stop offset="1" stop-color="#13B024" />
                      </linearGradient>
                      <linearGradient id="paint1_linear_1896_16984" x1="34.2047" y1="-4.70421" x2="5.78451" y2="25.2025" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#6BE900" />
                        <stop offset="0.2" stop-color="#66E501" />
                        <stop offset="0.42" stop-color="#58DC07" />
                        <stop offset="0.66" stop-color="#41CE11" />
                        <stop offset="0.9" stop-color="#21B91E" />
                        <stop offset="1" stop-color="#13B024" />
                      </linearGradient>
                    </defs>
                  </svg>
                </SvgIcon>
              </ListItemIcon>

              <SvgIcon sx={{ marginRight: open ? 10 : 'auto', opacity: open ? 1 : 0, width: "100%", marginTop: -1, marginLeft: -9, display: open ? { xs: 'none', md: 'block', lg: 'block' } : "none" }}>
                <svg width="104" height="25" viewBox="0 0 104 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.63982 14.9188L14.3926 21.0687C15.075 21.9683 14.8696 23.1168 13.8792 23.79C13.5048 24.0446 13.0579 24.2087 12.6473 24.2087C12.0313 24.2087 11.4153 23.9201 11.0046 23.377L6.83166 17.9627L4.60929 20.3954V22.1267C4.60929 23.3091 3.64908 24.1747 2.35069 24.1747C1.05229 24.1747 0.164551 23.3091 0.164551 22.1267V3.29807C0.164551 2.11562 1.11872 1.25 2.42315 1.25C3.72759 1.25 4.60929 2.11562 4.60929 3.29807V14.4436L10.1471 8.36165C10.5578 7.88075 11.1375 7.65444 11.6871 7.65444C12.1641 7.65444 12.6775 7.81286 13.0881 8.16929C13.9759 8.8765 14.1148 10.025 13.3297 10.8906L9.63982 14.9245V14.9188Z" fill="#240B36" />
                  <path d="M18.5051 7.64844C19.8035 7.64844 20.7275 8.51405 20.7275 9.6965V16.7742C20.7275 19.1447 22.1949 20.3611 24.3146 20.3611C26.5733 20.3611 28.2461 18.9524 28.2461 16.4517V9.6965C28.2461 8.51405 29.17 7.64844 30.4684 7.64844C31.7668 7.64844 32.6908 8.51405 32.6908 9.6965V22.1207C32.6908 23.3031 31.7668 24.1687 30.4684 24.1687C29.17 24.1687 28.2461 23.3031 28.2461 22.1207V21.8661C27.2557 23.4332 25.0997 24.3328 22.9136 24.3328C19.6344 24.3328 16.2827 22.2508 16.2827 16.9666V9.6965C16.2827 8.51405 17.2067 7.64844 18.5051 7.64844V7.64844Z" fill="#240B36" />
                  <path d="M37.7393 0.414062C39.2068 0.414062 40.3059 1.40415 40.3059 2.8129C40.3059 4.22165 39.2128 5.2174 37.7393 5.2174C36.2657 5.2174 35.1787 4.22731 35.1787 2.8129C35.1787 1.39849 36.2718 0.414062 37.7393 0.414062V0.414062ZM35.5169 9.69825C35.5169 8.5158 36.4711 7.65018 37.7755 7.65018C39.0799 7.65018 39.9616 8.5158 39.9616 9.69825V22.1224C39.9616 23.3049 39.0075 24.1705 37.703 24.1705C36.3986 24.1705 35.5169 23.3049 35.5169 22.1224V9.69825Z" fill="#240B36" />
                  <path d="M52.2814 14.9188L57.0341 21.0687C57.7166 21.9683 57.5112 23.1168 56.5208 23.79C56.1464 24.0446 55.6995 24.2087 55.2889 24.2087C54.6729 24.2087 54.0569 23.9201 53.6462 23.377L49.4733 17.9627L47.2509 20.3954V22.1267C47.2509 23.3091 46.2967 24.1747 44.9923 24.1747C43.6879 24.1747 42.8062 23.3091 42.8062 22.1267V3.29807C42.8062 2.11562 43.7603 1.25 45.0648 1.25C46.3692 1.25 47.2509 2.11562 47.2509 3.29807V14.4436L52.7887 8.36165C53.1994 7.88075 53.7791 7.65444 54.3286 7.65444C54.8057 7.65444 55.3191 7.81286 55.7297 8.16929C56.6175 8.8765 56.7563 10.025 55.9713 10.8906L52.2814 14.9245V14.9188Z" fill="#240B36" />
                  <path d="M75.2116 17.5776L77.5004 9.15336C77.742 8.22551 78.5572 7.64844 79.5174 7.64844C79.759 7.64844 79.9945 7.68238 80.2361 7.74462C81.468 8.09539 82.084 9.18731 81.7036 10.3358L77.8084 22.6016C77.5366 23.5011 76.5462 24.1687 75.4834 24.1687C74.4205 24.1687 73.4663 23.5294 73.1221 22.6299L69.5349 13.6965L65.9477 22.6299C65.6035 23.5238 64.5829 24.1687 63.52 24.1687C62.4571 24.1687 61.5392 23.4955 61.2614 22.6016L57.3662 10.3358C56.9918 9.18166 57.6078 8.09539 58.8337 7.74462C59.0753 7.68238 59.3108 7.64844 59.5523 7.64844C60.5125 7.64844 61.3278 8.22551 61.5694 9.15336L63.8582 17.5776L67.24 9.12508C67.5843 8.25946 68.5022 7.65409 69.5288 7.65409C70.5555 7.65409 71.4795 8.26512 71.8176 9.12508L75.1995 17.5776H75.2116Z" fill="#240B36" />
                  <path d="M86.8247 0.414062C88.2922 0.414062 89.3913 1.40415 89.3913 2.8129C89.3913 4.22165 88.2982 5.2174 86.8247 5.2174C85.3512 5.2174 84.2642 4.22731 84.2642 2.8129C84.2642 1.39849 85.3572 0.414062 86.8247 0.414062V0.414062ZM84.6023 9.69825C84.6023 8.5158 85.5565 7.65018 86.861 7.65018C88.1654 7.65018 89.0471 8.5158 89.0471 9.69825V22.1224C89.0471 23.3049 88.0929 24.1705 86.7885 24.1705C85.484 24.1705 84.6023 23.3049 84.6023 22.1224V9.69825Z" fill="#240B36" />
                  <path d="M95.4486 7.9049V5.14963C95.4486 3.96719 96.4088 3.10156 97.7072 3.10156C99.0056 3.10156 99.8933 3.96719 99.8933 5.14963V7.9049H101.947C103.142 7.9049 104 8.70262 104 9.85678C104 11.0109 103.142 11.7804 101.947 11.7804H99.8933V22.1225C99.8933 23.305 98.9331 24.1706 97.6347 24.1706C96.3363 24.1706 95.4486 23.305 95.4486 22.1225V11.7804H93.9449C92.7491 11.7804 91.8916 10.9826 91.8916 9.82849C91.8916 8.67433 92.7491 7.9049 93.9449 7.9049H95.4486Z" fill="#240B36" />
                </svg>

              </SvgIcon>
            </ListItemButton>
          </ListItem>
        </DrawerHeader>
        <List >

          {uperItems.map(({ name, icon }) => (
            <ListItem key={name} sx={{
              display: 'block', padding: 0, height: "45px"

            }}>

              <Link
                to={`/${name.toLowerCase()}`}
                className={clsx(classes.listLink, {
                  [classes.linkSelected]:
                    open && location.pathname.includes(`/${name.toLowerCase()}`)

                })} sx={{ padding: 0, }}
              >
                <ListItemButton disableRipple
                  sx={{
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5, '&:hover': {
                      backgroundColor: "#ffffff"
                    },
                  }} className={clsx({
                    [classes.full]:
                      open && location.pathname.includes(`/${name.toLowerCase()}`)

                  })}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <Box className={clsx(classes.top, {
                      [classes.back]:
                        location.pathname.includes(`/${name.toLowerCase()}`)

                    })}>

                      {icon}
                    </Box>
                  </ListItemIcon>

                  <ListItemText className={clsx(classes.listLink, {
                    [classes.linkSelectedtext]:
                      open && location.pathname.includes(`/${name.toLowerCase()}`)
                  })} ><Typography sx={{
                    height: "30px",
                    alignContent: "center",
                    // marginTop: "2px",
                    padding: 0.5,
                    fontSize: '14px',
                    fontWeight: open ? 400 : 800, fontFamily: 'Poppins', marginLeft: 3,
                    opacity: open ? 1 : 0, textDecoration: "none", color: location.pathname.includes(`/${name.toLowerCase()}`) ? 'primary' : '#777777'
                  }}
                    className={clsx({
                      [classes.linkSelectedtext]:
                        open && location.pathname.includes(`/${name.toLowerCase()}`)
                    })}
                  >{name}</Typography></ListItemText>

                </ListItemButton>
              </Link>
            </ListItem>

          ))}
        </List>
        {/* 
        <Box sx={{
          position: 'fixed', display: "block", width: 240,
          bottom: 0,
          left: 0,
          right: 0,
        }}> */}
        <List sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          width: 0,

        }}>

          <ListItem sx={{ display: 'block', padding: 0, height: "45px" }}>
            <Link
              to='/help'
              className={clsx(classes.listLink, {
                [classes.linkSelected]:
                  location.pathname.includes(`/help`)
              })} sx={{ padding: 0, }}
            >

              <ListItemButton disableRipple
                sx={{
                  justifyContent: open ? 'initial' : 'center', width: 240,
                  px: 2.5, '&:hover': {
                    backgroundColor: "#ffffff"
                  },
                }} className={clsx({
                  [classes.full]:
                    open && location.pathname.includes(`/help`)

                })}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Box className={clsx(classes.top, {
                    [classes.back]:
                      location.pathname.includes(`/help`)

                  })}>

                    <HelpIcon />
                  </Box>

                </ListItemIcon>
                <ListItemText className={clsx(classes.listLink, {
                  [classes.linkSelectedtext]:
                    open && location.pathname.includes(`/help`)
                })} ><Typography sx={{
                  height: "30px",
                  alignContent: "center",
                  // marginTop: "2px",
                  padding: 0.5,
                  fontSize: '14px',
                  fontWeight: open ? 400 : 800, fontFamily: 'Poppins', marginLeft: 3,
                  opacity: open ? 1 : 0, textDecoration: "none", color: location.pathname.includes(`/help`) ? 'primary' : '#777777'
                }}
                  className={clsx({
                    [classes.linkSelectedtext]:
                      open && location.pathname.includes(`/help`)
                  })}
                >Help</Typography></ListItemText>
              </ListItemButton>

            </Link>
          </ListItem>

          <ListItem sx={{
            display: 'block', padding: 0, marginTop: 1
          }}>

            <Link
              to="#"
              onClick={() => logout()}
              className={clsx(classes.listLink, {
                [classes.linkSelected]:
                  location.pathname.includes(`/logout`)
              })} sx={{ padding: 0, }}
            >
              <ListItemButton disableRipple
                sx={{
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5, '&:hover': {
                    backgroundColor: "#ffffff"
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    ml: open ? 0 : 3,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <LogoutIcon color="error" />
                </ListItemIcon>
                <ListItemText className={clsx(classes.listLink, {


                })}><Typography sx={{
                  fontSize: '14px', fontWeight: 400, fontFamily: 'Poppins',
                  opacity: open ? 1 : 0, textDecoration: "none", color: 'red'
                }}> Logout</Typography></ListItemText>
              </ListItemButton>


            </Link>
          </ListItem>
        </List>
        {/* {/ </Box> /} */}
      </Drawer>
    </Box >

  );
};

const mapStateToProps = (state: { AdminPanelReducer: any; }) => {
  return { ...state.AdminPanelReducer };
};
export default connect(mapStateToProps, {
  setAdminPanelDrawerToggle,
  setAdminPanelChatBoxDrawerToggle,
})(NavigationList);