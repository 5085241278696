import { makeStyles } from 'tss-react/mui';

const useStyle = makeStyles()(()=>({
    dropDownMainText: {
        fontFamily: "poppins",
        fontSize: "15px",
    },
    styleCustom:{
        fontFamily : "Poppins",
        color: '#4F3065'
    },
    activeButton:{
        backgroundColor: '#4F3065',
        borderRadius: '3px',
        "&:hover": {
            backgroundColor: "#4F3065",
            borderRadius: "3px"
          },
    },
    activeTextColor:{
        color: '#fff',
        textDecoration: 'none',
    },
    itemStyle:{
        fontSize:"14px"
    }
}));

export default useStyle