import React, { useEffect } from "react";

import {
  Route,
  Switch,
  Redirect,

  withRouter,

} from "react-router-dom";
import { connect } from "react-redux";
import { setAdminPanelDrawerToggle } from "../../store/actions/AdminpanelActions";
import AddUsers from "./addUsers";
import AddDesignations from "./addDesignations";
import AddProfiles from "./addProfiles";
import AddPages from "./addPages";
import ChatBox from "../chatBox";
import Setting from "../setting";
import Traffic from "./traffic";
import NotificationMain from "../Notification/Notification";
import Report from "../Report/Report";
import TotalCustomer from "../Report/TotalCustomer/TotalCustomer";
import DailySummary from "../Report/DailySummary/DailySummary";
import ChatDetail from "../Report/ChatDetail/ChatDetail";
import MainLayout from "../layout";
import Dashboard from "../dashboard/Dashboard";

const MainContentContainer = (props: any) => {

  return (

    <MainLayout>

      <Switch>
        <Route exact path="/users">
          <AddUsers />
        </Route>
        <Route path="/adddesignations">
          <AddDesignations />
        </Route>
        <Route path="/addprofiles">
          <AddProfiles />
        </Route>
        <Route path="/addpages">
          <AddPages />
        </Route>

        <Route
          path={["/chats", "/admin"]}
          render={(props: any) => <ChatBox {...props} isSuperAdmin={true} />}
        />

        <Redirect exact from="/chat" to={`chats`} />
        <Redirect exact from='/settings' to={`settings/website`} />
        <Route exact
          path={[
            "/settings",
            "/settings/:pageName"
          ]}
          component={(props: any) => <Setting {...props} />}
        >
        </Route>
        <Route exact path="/dashboard">
          {/* <Dashboard /> */}
          {props.authUserWsSubscriptionReady && <Dashboard />}
        </Route>

        <Route exact path="/traffic">
          <Traffic />
        </Route>
        <Route exact path="/reports">
          <Report />
        </Route>
        <Route
          exact
          path="/reports/totalcustomerreport"
          render={(props: any) => <TotalCustomer {...props} />}
        ></Route>
        <Route
          exact
          path="/reports/dailysummary"
          render={(props: any) => <DailySummary {...props} />}
        ></Route>
        <Route
          exact
          path="/reports/totalcustomerreport/:id"
          render={(props: any) => <ChatDetail />}
        ></Route>
        <Route
          exact
          path="/notifications"
          render={withRouter(NotificationMain)}
        />
      </Switch>

    </MainLayout>
  );
};

const mapStateToProps = (state: any) => {
  return { ...state.AdminPanelReducer, ...state.AuthReducer };
};
export default connect(mapStateToProps, {
  setAdminPanelDrawerToggle,
})(MainContentContainer);
