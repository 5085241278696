// import { width } from '@mui/system'
import { makeStyles } from 'tss-react/mui'

import theme from '../style/theme'

const useStyles = makeStyles()(() => ({


    outlineName: {
        '& input::-webkit-input-placeholder': {
            fontWeight: '500',
            fontFamily: 'poppins',
            fontSize: '12px',
            lineHeight: '15px',
            color: '#272525',
        },
    },


    outlinedInput: {
        '& input::-webkit-input-placeholder': {
            fontWeight: '500',
            fontFamily: 'poppins',
            fontSize: '12px',
            lineHeight: '15px',
            color: '#272525',
        },
    },





  root: {
    //
    '&::-webkit-scrollbar': {
      width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      borderRadius: '3px',
  },
  '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
  },
    //
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    //
    // height: '100vh',
    //
    height:"auto",
    maxHeight: '100vh',
    overflowY: 'auto',
    //
    [theme.breakpoints.up("lg")]: {
      height:"100vh",
      // backgroundColor:"aqua"
    },
    [theme.breakpoints.down("lg")]: {
      height:"100vh",
      // backgroundColor:"orange"
    },
    //
    flexDirection: 'column',
    boxSizing: 'border-box',
    background: 'rgba(127, 63, 152, 0.05)',
    // padding: '35px',
    padding:"0px 35px 35px 35px",
    // backgroundColor:"pink"
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    flexDirection: 'column',
    //
    width: '100%',
  },

  label: {
    marginTop: 10,
    fontSize: '12px',
    //
    [theme.breakpoints.up('xl')]: {
      lineHeight: '25px',
      // fontSize: '1.375rem',
      marginTop: 2,
      fontSize: "12px",
      maxWidth: '100%',
      width: '19vw',
    },
    // width: '300px',
    maxWidth: '300px',
    display: 'flex',
    lineHeight: '15px',
    fontWeight: 450,
  },
  terms: {
    maxWidth: '300px',
  },
  party: {
    width: '300px',
    display: 'flex',
    alignItems: 'center',
    // justifyContent  : 'center',
    // flexDirection: 'column'  ,
    height: '40px',
    background: '#fff',
    boxShadow: '0px 1px 2px rgba(24, 24, 28, 0.1)',
    borderRadius: '5px',
  },
  divider: {
    width: '100%',
    alignItems: 'center',
    color: '#B7B7B7',
  },
  dividerText: {
    color: theme.palette.text.secondary,
  },
  footer: {
    fontSize: '12px',
    width:"100%",
    //
    //
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '100%',
    },
    //
    // [theme.breakpoints.up('xl')]: {
    //   fontSize: '1.375rem',
    // },
    //
    color: theme.palette.text.primary,
    fontWeight: 500,
    lineHeight: '18px',
  },
}))

export default useStyles
