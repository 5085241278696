import React, { useContext, useEffect, useRef, useState } from "react";
import { Container, Grid, useMediaQuery } from "@material-ui/core";

import { Redirect, Route } from "react-router-dom";
import { auth } from "../../auth/auth";
import { connect } from "react-redux";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import LoginForm from "./LoginForm";
import ForgetPasswordForm from "./ForgetPasswordForm";
import ResetPasswordForm from "./ResetPasswordForm";
import { setRedirectToPath } from "../../store/actions/RedirectToPathActions";
import { setForceLogoutUser } from "../../store/actions/LoginForgetPasswordActions";
import robot from "../../assets/LoginIcons/robot.gif";
import greenBackground from "../../assets/LoginIcons/image.png";
import SignupForm from "./SignupForm";
import EmailConfirmation from "./EmailConfirmation";

import {
  setDialogOpen,
  setDialogOkText,
  setDialogCancelText,
  setDialogOkClick,
  setDialogTitle,
  setDialogContent,
} from "../../store/actions/DialogActions";
import CustomDialogRedux from "../CustomDialogRedux";
import { Link } from "react-router-dom";
import { UserContext } from "../../router/userContext";
import InviteAgentSignup from "./InviteAgentSignup";
//
//
import LeftContainer from "./UpdatedFront/loginLeftContainer/LoginLeftContainer";
import SignUpRightContainer from "./UpdatedFront/signUpRightContainer/SignUpRightContainer";
import SignInRightContainer from "./UpdatedFront/signInRightContainer/SignInRightContainer";
import ForgotPasswordRightComponent from "./UpdatedFront/forgotPasswordRightComponent/ForgotPasswordRightComponent";
import EmailConfirmRightComponent from "./UpdatedFront/EmailConfirmRightComponent/EmailConfirmRightComponent";
import ResetPasswordRightComponent from "./UpdatedFront/ResetPasswordRightComponent/ResetPasswordRightComponent";
import InviteAgentRightComponent from "./UpdatedFront/InviteAgentRightComponent/InviteAgentRightComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  leftContainer: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    //laptop
    [theme.breakpoints.down("lg")]: {
      width: "50%",
    },
  },
  rightContainer: {
    //
    backgroundColor:"white",
    //
    width: "50%",
    [theme.breakpoints.up("sm")]: {
      width: "50% !important",
    },
    [theme.breakpoints.down("md")]: {
      width: "50% !important",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },

    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
    },
    //
  },
}));

const LoginForgetPassword = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.only("sm"));
  const isScreenExtraSmall = useMediaQuery(theme.breakpoints.only("xs"));


  useEffect(() => {
    if (props.loginForgetPasswordToggleFormName == "login")
      document.title = props.titleLogin;
    else document.title = props.titleForgetPassword;
  }, [props.loginForgetPasswordToggleFormName]);

  useEffect(() => {
    if (props.forceLogoutUser) {
      props.setDialogOpen(true);
      props.setDialogOkText("ok");
      props.setDialogCancelText(null);
      props.setDialogOkClick(() => {
        window.location = "/user";
      });
      props.setDialogTitle("Warning");
      props.setDialogContent("You are logout by " + props.forceLogoutUser);
    }
  }, [props.forceLogoutUser]);

  if (props.redirectToPath) {
    const path = props.redirectToPath;
    props.setRedirectToPath(null);
    return <Redirect to={{ pathname: path }} />;
  }


  const loginForgetPasswordToggleFormName =
    props.match.path === "/resetpassword/:token"
      ? "resetpassword"
      : props.match.path === "/signup"
      ? "signup"
      : props.match.path === "/confirm"
      ? "confirm"
      : props.match.path === "/join/:email/:designation/:superAdminId/:token"
      ? "inviteAgent"
      : props.match.path === "/join/:designation/:superAdminId/:token"
      ? "inviteAgent"
      : props.match.path === "/forgetpassword"
      ? "forgetpassword"
      : props.loginForgetPasswordToggleFormName;

      console.log("props.match.params.token",props.match.params.token)
  return (
    <div className={classes.root}>
      <div className={classes.leftContainer}>
        <LeftContainer />
      </div>
      <div className={classes.rightContainer}>
        {loginForgetPasswordToggleFormName === "login" ? (
          <SignInRightContainer />
        ) : loginForgetPasswordToggleFormName === "forgetpassword" ? (
          <ForgotPasswordRightComponent />
        ) : loginForgetPasswordToggleFormName === "signup" ? (
          <SignUpRightContainer />
        ) : loginForgetPasswordToggleFormName === "confirm" ? (
          <EmailConfirmRightComponent />
        ) : loginForgetPasswordToggleFormName === "inviteAgent" ? (
          // <InviteAgentSignup
          //   email={props.match.params.email}
          //   designation={props.match.params.designation}
          //   token={props.match.params.token}
          // />
          <InviteAgentRightComponent
          email={props.match.params.email}
          designation={props.match.params.designation}
          token={props.match.params.token}
        />
        ) : (
          <ResetPasswordRightComponent token={props.match.params.token}/>
          // <ResetPasswordForm
          //   token={props.match.params.token}
          //   key="resetpassword"
          // />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    ...state.LoginForgetPasswordReducer,
    ...state.RedirectToPathReducer,
  };
};
export default connect(mapStateToProps, {
  setRedirectToPath,
  setForceLogoutUser,
  setDialogOpen,
  setDialogOkText,
  setDialogCancelText,
  setDialogOkClick,
  setDialogTitle,
  setDialogContent,
})(LoginForgetPassword);