import React, { useEffect, useRef, useState } from "react";
import { CircularProgress, Container } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import {
  setAddEditUserModalToggle,
  setAddEditUserModalSelectedRowData,
} from "../../../store/actions/AddEditUserModalActions";
import { setAllUsersData } from "../../../store/actions/AdmindataActions";
import { useSnackbar } from "notistack";
import PanelType from "../../../auth/PanelType";
import _ from "lodash";
import "./style.css";

// user module imports

import DeleteConfirmation from "../../modals/deleteConfirmation";
import SingleTable from "../../tables/singleTable";
import InviteAgents from "../../modals/inviteAgents";
import CreateTeam from "../../createTeam";
import Button from "../../atoms/buttons/button";
import AddUser from "../../icons/addUser";
import Link from "../../icons/link";
import Team from "../../icons/team";
import { constants } from "../../../config/constant";
import EditProfileSideBar from "../../modals/editProfileSideBar";
import Back from "../../icons/back";
import { setUsersListDatas } from "../../../store/actions/UsersListActions";
import useSnackBar from "../../../hooks/useSnackBar";
import { useSelector } from "react-redux";
//
import Header from "../../tables/LeftPanelHeader/LeftPanelHeader";
import RightPanel from "../../tables/RightPanel/RightPanel";

import Store from "../../../store/index";
//

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    width: 54,
    height: 54,
    margin: "20px auto",
    display: "block",
  },
}));

interface Props {
  authUserId?: any;
  authPanelType?: any;
  setAllUsersData?: any;
}

const AddUsers: React.FC<Props> = ({
  authUserId,
  authPanelType,
  setAllUsersData,
}) => {
  //
  const [isEditMode, setIsEditMode] = useState(false);
  useEffect(() => {}, [isEditMode]);
  //
  const [searchNextPage, setSearchNextPage] = useState(null);
  const [hitSearchScrolling, setHitSearchScrolling] = useState(false);
  //
  const [searchLoader, setSearchLoader] = useState(false);

  useEffect(() => {}, [searchLoader]);

  const [searchText, setSearchText] = useState(null);
  useEffect(() => {
    if (searchText === null) {
    } else {
    }
  }, [searchText]);
  //
  const { getState } = Store;
  const usersListReducerState = getState().UsersListReducer;
  //

  type MyComponentType = {
    handleCloseActionModal: () => void;
  };

  const myRef = useRef<MyComponentType>(null);
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedScreen, setSelectedScreen] = useState("userstable");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditProfilePanelOpen, setIsEditProfilePanelOpen] = useState(false);
  const [hoverItem, setHoverItem] = useState("0");
  const [userSelected, setUserSelected] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState({});

  type UserData = {
    __typename: string;
    id: string;
    picture: null | any; // Update the type for 'picture' property as needed
    pseudonym: string;
    name: string;
    // Add other properties as needed
  };

  const [deletingUserData, setDeletingUserData] = useState<UserData | null>(
    null
  );

  const { success, error } = useSnackBar();
  const [pagination, setPagination] = useState({
    page: 0,
    hasNextPage: false,
    limit: 10,
  });

  const classes = useStyles();
  const [userdata, setUserData] = React.useState([]);
  const { enqueueSnackbar } = useSnackbar();
  //
  const GetUsers = gql`
    query getUsers($accessToken: String, $cursor: Int, $limit: Int) {
      getUsers(accessToken: $accessToken, cursor: $cursor, limit: $limit) {
        users {
          createdAt
          id
          picture
          pseudonym
          name
          jobTitle
          username
          email
          status
          managerId {
            id
          }
          agentlimitchatassign
          designation {
            id
            name
            paneltype
          }
          number
          pages
        }
        hasNextPage
      }
    }
  `;

  let [
    getUsers,
    {
      loading: getUsersQueryLoading,
      error: getUsersQueryError,
      data: getUsersQueryResult,
    },
  ] = useLazyQuery(GetUsers, {
    fetchPolicy: "network-only",
  });

  const loadingComponent = (
    <div className={classes.circularProgress}>
      <CircularProgress size={24} />
    </div>
  );
  const gridColumns = [
    {
      title: "",
      field: "picture",
      render: (rowData: any) => (
        <img
          src={rowData.picture}
          style={{
            width: 50,
            height: 50,
            marginTop: 3,
            marginBottom: 5,
            borderRadius: "50%",
          }}
        />
      ),
      export: false,
    },
    { title: "Name", field: "name" },
    { title: "Pseudonym", field: "pseudonym" },
    { title: "Username", field: "username" },
    { title: "Email", field: "email" },
    { title: "Mobile Number", field: "number" },
    { title: "Designation", field: "designation.name" },
    { title: "Manager", field: "managerId.name" },
    { title: "Status", field: "status" },
    { title: "comments", field: "comments" },
  ];
  const isEditableDeleteable = (rowData: any) => {
    return (
      authUserId === rowData.id ||
      !(
        authPanelType === PanelType.SUPERADMIN ||
        (authPanelType !== PanelType.SUPERADMIN &&
          rowData.designation.paneltype !== PanelType.SUPERADMIN)
      )
    );
  };
  const mainContainerRef = useRef(null);

  const userApiCallingHandle = async () => {
    const activeUserDetail = localStorage.getItem("ActiveUserdetail");
    const mainSuperAdminId = activeUserDetail
      ? JSON.parse(activeUserDetail).mainSuperAdminId
      : null;

    getUsers({
      variables: {
        // mainSuperAdminId: JSON.parse(localStorage.getItem("ActiveUserdetail")).mainSuperAdminId,
        mainSuperAdminId,

        cursor:
          pagination.page === 0
            ? pagination.limit * pagination.page
            : // Number(store.UsersListReducer.usersListDatas.at(-1).id),
              Number(usersListReducerState.usersListDatas.at(-1).id),
        limit: pagination.limit,
      },
    });
  };

  useEffect(() => {
    document.title = "Add/Edit Users";
    window.Object.freeze = function (obj: any) {
      return obj;
    }; //keep_an_eye
    if (localStorage.getItem("ActiveUserdetail") && searchText === null) {
      userApiCallingHandle();
    }
  }, [localStorage.getItem("ActiveUserdetail")]);

  const [updating, setUpdating] = useState(false);
  const [usedonce, setusedonce] = useState(false);

  const fetchUsers = (data: any) => {
    setAllUsersData(data.users);
    dispatch(setUsersListDatas(data.getUsers.users, pagination.page));

    // setPagination({
    //   page: pagination.page + 1,
    //   limit: 10,
    //   hasNextPage: data.getUsers.hasNextPage,
    // });

    if (updating === true && usedonce === false) {
      setusedonce(true);
      setPagination({
        page: 0,
        limit: 10,
        hasNextPage: false,
      });
      //
      setUpdating(false);
    } else {
      setPagination({
        page: pagination.page + 1,
        limit: 10,
        hasNextPage: data.getUsers.hasNextPage,
      });
      //
      setUpdating(false);
    }
  };

  // const fetchUsers = (data: any) => {
  //   setAllUsersData(data.users);
  //   dispatch(setUsersListDatas(data.getUsers.users, pagination.page));
  //     setPagination({
  //       page: pagination.page + 1,
  //       limit: 10,
  //       hasNextPage: data.getUsers.hasNextPage,
  //     });
  //     //
  //     setUpdating(false);
  // };

  useEffect(() => {
    if (getUsersQueryResult && getUsersQueryResult.getUsers.users) {
      fetchUsers(getUsersQueryResult);
    }
  }, [getUsersQueryResult]);

  const handleOnProfileUpdate = () => {
    const activeUserDetail = localStorage.getItem("ActiveUserdetail");
    const mainSuperAdminId = activeUserDetail
      ? JSON.parse(activeUserDetail).mainSuperAdminId
      : null;

    setPagination({ page: 0, hasNextPage: false, limit: 10 });
    //
    dispatch(setUsersListDatas([], 0));
    setUpdating(true);
    getUsers({
      variables: {
        mainSuperAdminId: mainSuperAdminId,
        cursor: 0,
        limit: 10,
      },
    });
    //
  };

  const handleOpenUserEditProfile = (id: number, flag: boolean) => {
    setIsEditProfilePanelOpen(flag);
  };

  const loadMoreDataHandle = (i: React.SyntheticEvent) => {
    //
    setusedonce(true);
    // let bottom = i.target.scrollHeight - i.target.clientHeight - i.target.scrollTop < 1;
    let bottom =
      (i.target as HTMLElement).scrollHeight -
        (i.target as HTMLElement).clientHeight -
        (i.target as HTMLElement).scrollTop <
      1;
    if (bottom && pagination.hasNextPage && searchText === null) {
      userApiCallingHandle();
    }
    if (bottom && searchNextPage && searchText !== null) {
      setHitSearchScrolling(true);
    }
  };

  interface User {
    __typename: string;
    id: string;
    picture: null | string;
    pseudonym: string;
    name: string;
    // Add other properties as needed
  }

  const handleIsUserRowSelected = (item: User) => {
    //
    setSelectedUserData(item);
    setUserSelected(true);
  };

  // delete user //
  const DeleteUserMutation = gql`
    mutation DeleteUser($id: ID!) {
      deleteuser(id: $id) {
        success
        error
      }
    }
  `;
  const [
    deleteUser,
    {
      loading: deleteMutationLoading,
      error: deleteMutationError,
      data: deleteMutationResult,
    },
  ] = useMutation(DeleteUserMutation);
  // delete user //

  //this one was showing error in this regards...
  useEffect(() => {
    if (deleteMutationError && deleteMutationError.graphQLErrors) {
      error("Something went wrong");
      setIsDeleteModalOpen(false);
      setIsAddModalOpen(false);
    }
  }, [deleteMutationError]);

  useEffect(() => {
    if (deleteMutationResult && deleteMutationResult.deleteuser) {
      success("Record delete successfully");
      setIsDeleteModalOpen(false);
      setIsAddModalOpen(false);
      //
      handleOnProfileUpdate();
    }
  }, [deleteMutationResult]);

  const handleSelectedDataForDelete = (data: User) => {
    setDeletingUserData(data);
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleDeleteUser = async () => {
    if (deletingUserData) {
      await deleteUser({
        variables: {
          id: deletingUserData.id,
        },
      });
    }
    // setPagination({ page: 0, hasNextPage: false, limit: 10 });
    // setSelectedUserData({});
    // setIsAddModalOpen(false);
    // setIsEditProfilePanelOpen(false);
    // await userApiCallingHandle();
  };

  useEffect(() => {}, [selectedScreen]);

  const [userIndex, setUserIndex] = React.useState<number>();
  useEffect(() => {}, [userIndex]);

  ////////////////

  return (
    <>
      <div
        onClick={() => {
          myRef.current && myRef.current.handleCloseActionModal();
          setIsAddModalOpen(false);
          //
        }}
        className="d-flex"
      >
        <div style={{ width: "100%" }}>
          <div>
            {selectedScreen === "createteam" && (
              <CreateTeamBox setSelectedScreen={setSelectedScreen} />
            )}
          </div>
          <div className="d-flex justify-content-between my-4 mx-5">
            {isEditProfilePanelOpen && (
              <EditProfileSideBar
                selectedUserData={selectedUserData}
                handleOnProfileUpdate={handleOnProfileUpdate}
                onClose={() => {
                  setUserSelected(false);
                  setSelectedUserData({});
                  setIsEditProfilePanelOpen(!isEditProfilePanelOpen);
                }}
              />
            )}

            {/* header of userspage */}
            {/* <h4 className="m-0 align-self-center">{selectedScreen === 'userstable' || selectedScreen === '' ? 'Users' : ''}</h4> */}
            <h4 className="m-0 align-self-center">
              {selectedScreen === "userstable" || selectedScreen === ""
                ? "Users"
                : ""}
            </h4>
            <div>
              <Button
                iconColor="white"
                isIcon={true}
                onClick={(event: React.MouseEvent) => {
                  event.stopPropagation();
                  setIsAddModalOpen(!isAddModalOpen);
                }}
                text="Add"
                isTransparent={false}
                class="px-4 py-1"
                color="white"
              />

              {isAddModalOpen && (
                <AddButtonModal
                  hoverItem={hoverItem}
                  setHoverItem={setHoverItem}
                  setSelectedScreen={setSelectedScreen}
                  setIsAddModalOpen={setIsAddModalOpen}
                  isAddModalOpen={isAddModalOpen}
                />
              )}
            </div>
          </div>
          {/* <Alerts /> */}
          {isDeleteModalOpen && (
            <DeleteConfirmation
              isLoading={deleteMutationLoading}
              handleDeleteUser={handleDeleteUser}
              onClose={() => {
                setIsAddModalOpen(false);
                setIsDeleteModalOpen(!isDeleteModalOpen);
              }}
            />
          )}

          {selectedScreen === "inviteagent" && (
            <InviteAgents onClose={() => setSelectedScreen("userstable")} />
          )}
          {selectedScreen === "createteam" && (
            <CreateTeam onClose={() => setSelectedScreen("userstable")} />
          )}
          {/* userstable */}
          {selectedScreen === "userstable" && (
            <div
              onScroll={loadMoreDataHandle}
              style={{
                // maxHeight: '450px',
                // overflow: 'scroll',
                // overflowX: 'hidden'
                backgroundColor: "#FFFFFF",
                // backgroundColor: "pink",
                //
                marginTop: "42px",
                borderRadius: "10px",
                // height: "calc(100vh - 120px)",
                height: "auto",
                paddingBottom:"20px",
                //
                marginLeft: "20px",
                marginRight: "20px",
              }}
            >
              <Header
                setSearchText={setSearchText}
                setSearchLoader={setSearchLoader}
                hitSearchScrolling={hitSearchScrolling}
                setHitSearchScrolling={setHitSearchScrolling}
                setSearchNextPage={setSearchNextPage}
              />
              <SingleTable
                isEditMode={isEditMode}
                userSelected={userSelected}
                setUserIndex={setUserIndex}
                loadMoreDataHandle={loadMoreDataHandle}
                ref={myRef}
                //used for turning add dropdown off and is used in every single 'td' of the table
                setIsAddModalOpen={() => setIsAddModalOpen(false)}
                isLoading={getUsersQueryLoading}
                //
                isSearchLoading={searchLoader}
                //
                handleSelectedDataForDelete={handleSelectedDataForDelete}
                handleOpenEditPanel={() =>
                  setIsEditProfilePanelOpen(!isEditProfilePanelOpen)
                }
                handleIsUserRowSelected={handleIsUserRowSelected}
                handleOpenUserEditProfile={handleOpenUserEditProfile}
                // data={usersListReducerState.usersListDatas}
                data={
                  searchText === null
                    ? usersListReducerState.usersListDatas
                    : usersListReducerState.searchListData
                }
                // data={usersListReducerState.usersListDatas}
                actinsItems={[
                  { title: "Edit", method: () => {} },
                  { title: "Suspended", method: () => {} },
                  {
                    title: "Delete",
                    method: () => {
                      setIsDeleteModalOpen(!isDeleteModalOpen);
                    },
                  },
                ]}
                headers={[
                  { title: "Name" },
                  { title: "Role" },
                  { title: "Status" },
                  { title: "Employed" },
                  { title: "" },
                ]}
              />
            </div>
          )}
        </div>
        {selectedScreen === "userstable" &&
          // userSelected && <RightPanel handleDelete={handleSelectedDataForDelete} setRightPanelOpened={setUserSelected} data={!userIndex ? null : usersListReducerState.usersListDatas[userIndex]} selectedUserData={selectedUserData} handleOnProfileUpdate={handleOnProfileUpdate} onClose={() => { setUserSelected(false); setSelectedUserData({}); setIsEditProfilePanelOpen(!isEditProfilePanelOpen) }} />}
          userSelected && (
            <RightPanel
              setIsEditMode={setIsEditMode}
              handleDelete={handleSelectedDataForDelete}
              setRightPanelOpened={setUserSelected}
              data={
                !userIndex
                  ? null
                  : usersListReducerState.usersListDatas[userIndex]
              }
              selectedUserData={selectedUserData}
              handleOnProfileUpdate={handleOnProfileUpdate}
              onClose={() => {
                setUserSelected(false);
                setSelectedUserData({});
                setIsEditProfilePanelOpen(!isEditProfilePanelOpen);
              }}
            />
          )}
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return { ...state.AuthReducer, ...state.AdminDataReducer };
};
export default connect(mapStateToProps, {
  setAddEditUserModalToggle,
  setAddEditUserModalSelectedRowData,
  setAllUsersData,
  setUsersListDatas,
})(AddUsers);

interface AddButtonModalProps {
  hoverItem: string;
  setHoverItem: (hoverItem: string) => void;
  setSelectedScreen: (selectedScreen: string) => void;
  setIsAddModalOpen: (isOpen: boolean) => void;
  isAddModalOpen: boolean;
}

const AddButtonModal: React.FC<AddButtonModalProps> = ({
  hoverItem,
  setHoverItem,
  setSelectedScreen,
  setIsAddModalOpen,
  isAddModalOpen,
}) => {
  return (
    <div
      className="border p-2 rounded bg-white zIndex-10000"
      style={{
        position: "absolute",
        width: "200px",
        margin: " 0px 0px 0px -123px",
        boxShadow: " 5px 5px 9px -6px grey",
      }}
    >
      <div
        onMouseEnter={() => setHoverItem("1")}
        onClick={() => {
          setSelectedScreen("inviteagent");
          setIsAddModalOpen(!isAddModalOpen);
        }}
        style={{
          backgroundColor: hoverItem === "1" ? constants.theme.hoverGray : "",
        }}
        className="d-flex align-self-center cursor-pointer my-1 p-2 rounded"
      >
        <AddUser
          height={15}
          width={20}
          color={
            hoverItem === "1" ? constants.theme.green : constants.theme.gray
          }
        />
        <p
          className="m-0 px-1 font-medium"
          style={{
            color:
              hoverItem === "1" ? constants.theme.green : constants.theme.gray,
          }}
        >
          Invite Agent(s)
        </p>
      </div>
      <div
        onMouseEnter={() => setHoverItem("2")}
        onClick={() => {
          setSelectedScreen("inviteagent");
          setIsAddModalOpen(!isAddModalOpen);
        }}
        style={{
          backgroundColor: hoverItem === "2" ? constants.theme.hoverGray : "",
        }}
        className="d-flex align-self-center cursor-pointer my-1 p-2 rounded"
      >
        <Link
          class="align-self-center"
          color={
            hoverItem === "2" ? constants.theme.green : constants.theme.gray
          }
        />
        <p
          className="m-0 px-1 font-medium"
          style={{
            color:
              hoverItem === "2" ? constants.theme.green : constants.theme.gray,
          }}
        >
          Share Invite Link(s)
        </p>
      </div>
      <div
        onMouseEnter={() => setHoverItem("3")}
        onClick={() => {
          setSelectedScreen("createteam");
          setIsAddModalOpen(!isAddModalOpen);
        }}
        style={{
          backgroundColor: hoverItem === "3" ? constants.theme.hoverGray : "",
        }}
        className="d-flex align-self-center cursor-pointer my-1 p-2 rounded"
      >
        <Team
          color={
            hoverItem === "3" ? constants.theme.green : constants.theme.gray
          }
        />
        <p
          className="m-0 px-1 font-medium"
          style={{
            color:
              hoverItem === "3" ? constants.theme.green : constants.theme.gray,
          }}
        >
          Create Team
        </p>
      </div>
    </div>
  );
};

interface CreateTeamBoxProps {
  setSelectedScreen: (selectedScreen: string) => void;
}

const CreateTeamBox: React.FC<CreateTeamBoxProps> = ({ setSelectedScreen }) => {
  return (
    <div className="d-flex border p-3 align-self-center">
      <div
        className="d-flex align-self-center cursor-pointer"
        onClick={() => setSelectedScreen("userstable")}
      >
        <Back
          height={12}
          width={12}
          class="align-self-cente"
          color={constants.theme.gray}
        />
        <p
          className="m-0 px-2 align-self-center font-small"
          style={{ color: constants.theme.gray }}
        >
          Back
        </p>
      </div>
      <div className="d-flex m-auto">
        <h5
          className="m-0 px-2 align-self-center"
          style={{ color: constants.theme.gray }}
        >
          Create Team
        </h5>
      </div>
    </div>
  );
};
