import * as React from 'react'
import { Card, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
//
import useStyles from './Styles'
import First from './First/FirstInfo'
import Second from './Second/SecondEdit'
//
const RightPanel = ({
  //
  setRightPanelOpened,
  //
  data,
  selectedUserData,
  handleOnProfileUpdate,
  onClose,
  handleDelete,
  setIsEditMode,
}) => {
  //
  const { classes } = useStyles()
  const [edit, setEdit] = React.useState(false)

React.useEffect(()=>{
  setIsEditMode(edit)
},[edit])

  return (
    <Box className={classes.outerBox}>
      <Card className={classes.cardStyle}>
        <Box className={classes.topPanel}>
          <Box className={classes.topPanelRow}>
            Details
          </Box>
          <span onClick={() => {setRightPanelOpened(false);setIsEditMode(false);}}>
            <CloseIcon className={classes.crossIcon} />
          </span>
        </Box>
        <Divider className={classes.divider} />
        <Box className={classes.textAndSwitchOuterBox}>
          <Typography variant="subtitle2" className={classes.textAndSwitchInnerBox}>
            {edit ? 'Edit info' : 'General info'}
          </Typography>
          <Box className={classes.switchButtonBox}>
            <Switch
              onClick={() => {
                setEdit(!edit)
              }}
            />
            <Typography
              variant="subtitle2" className={classes.switchButtonBoxText}>
              Edit
            </Typography>
          </Box>
        </Box>

        {edit ? <Second setIsEditMode={setIsEditMode} setRightPanelOpened={setRightPanelOpened} selectedUserData={selectedUserData} handleOnProfileUpdate={handleOnProfileUpdate} onClose={onClose} handleDelete={handleDelete} /> : <First data={selectedUserData} />}
      </Card>
    </Box>
  )
}

export default RightPanel
