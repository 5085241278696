import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  root: {
    // backgroundColor: theme.palette.primary.main,
    transition: "all .2s ease-in-out",
    background: theme.palette.primary.main,
    color: theme.palette.secondary.dark,
    '&[aria-controls="menu-list-grow"],&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
  },
  label: {
    color: "#fff",
  },
  top: {
    borderRadius: "5px",
    width: "40px",
    marginLeft: 25,
    marginTop: -15,
    height: "30px",
    display: "flex",
    alignItem: "center",
    position: "fixed",
    paddingTop: 1.5,
    justifyContent: "center",
    // background: url("src/")
  },
  icon: {
    fontSize: 25,
    position: "fixed",
  },
  back: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
  full: {
    // backgroundColor: theme.palette.primary.light,
    marginRight: -5
  },
  logo: {
    overflow: "visible",
    width: "42px",
    height: "40px",
    top: 13,
    left: 10,
    position: "fixed",
  },
  rectangle: {
    backgroundColor: theme.palette.primary.light,
  },
  navListItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    // padding: "4px",
    "&:hover": {
      navListText: {
        color: theme.palette.primary.main,
        fontWeight: "500",
        fontSize: "12px",
        background: "rgb(237,231,241)",
        transition: theme.transitions.create(["color"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.standard,
          delay: 100,
        }),
      },
    },
  },
  navListIcon: {
    margin: "auto",
    textAlign: "center",

    // color: "rgb(255 255 255 / 80%)",
    color: theme.palette.primary.main,
    fontSize: 28,
  },
  navListText: {
    marginTop: 0,
    textAlign: "left",
    color: theme.palette.primary.main,
  },
  listLink: {
    textDecoration: "none",
    // marginLeft: 5,
    display: "flex",
    color: "#777777",
  },
  linkSelected: {
    // background: "#E6D9EC",
    // borderLeft: "4px solid #4D1277",
    // marginLeft: 0,
    // backgroundColor: theme.palette.primary.light,
    color: "#4D1277",
    fontWeight: "bold",
    pointerEvents: "none",
    textDecoration: "none",
  },
  linkSelectedInner: {
    color: theme.palette.primary.main,
  },
  linkSelectedtext: {
    backgroundColor: theme.palette.primary.light,
    // height: "30px"
    borderRadius: 5,
    color: "#4D1277",
    fontWeight: 500,
    pointerEvents: "none",
    textDecoration: "none",
  },
}));

export default useStyles;
