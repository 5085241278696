import { Box, CardContent, Divider, Grid, Typography, Card, Tooltip } from '@mui/material';
import React, { useState, useMemo } from 'react'
import { useTheme } from '@mui/material/styles';
import useStyles from './Styles';
import Chart from 'react-apexcharts';

// assets
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { getCustomerCountAndCountsPerPageResultItem } from '../../../../../components/Report/Types';

interface Props {
    pagesBreakdownData: getCustomerCountAndCountsPerPageResultItem[]
}

const ReportsPagesBreakdown: React.FC<Props> = ({ pagesBreakdownData }) => {

    const theme = useTheme();
    const { classes } = useStyles();

    // const apexcharts = useMemo(() => <ReactApexChart
    //     height={300}
    //     // options={this.state.options}
    //     // options={chartOptions}
    //     // series={chartSeries}
    //     // series={[63, 15, 22]}
    //     // labels={['Desktop', 'Tablet', 'Phone']}
    //     // type="pie"
    //     width="100%"

    // />, [])

    const series = pagesBreakdownData.map(item => item.totalCustomerCount)
    const data: any = {
        // series: [44, 55, 13, 33],
        series,
        options: {
            chart: {
                type: 'donut',
            },
            series: [44, 55, 41, 17, 15],
            labels: ['A', 'B', 'C', 'D', 'E'],
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: false,
            },
            tooltip: {
                enabled: false,
            },
        },
    };
    return (
        <Card sx={{ height: "365px", borderRadius: "10px" }}>
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container alignContent="center" justifyContent="space-between">
                            <Grid item>
                                <Typography className={classes.heading}>Pages Breakdown</Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ pt: '16px !important' }}>
                        {series ? <Chart options={data.options} series={data.series} type="donut" height={100} /> : null
                        }
                    </Grid>

                    <Grid item xs={12}>
                        {(pagesBreakdownData && pagesBreakdownData.length !== 0) ? (pagesBreakdownData.map(({ name, totalCustomerCount }) => {
                            return (
                                <>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Grid container alignItems="center" justifyContent="space-between">
                                                <Grid item>
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item>
                                                            <Box
                                                                sx={{
                                                                    width: 12,
                                                                    height: 12,
                                                                    backgroundColor: theme.palette.success.light,
                                                                    color: theme.palette.success.dark,
                                                                }}
                                                            >
                                                            </Box>
                                                        </Grid>
                                                        <Grid item ml={1}>
                                                            <Tooltip title={name} placement='top-start'>
                                                                <Typography className={classes.text}>
                                                                    {name}
                                                                </Typography>
                                                            </Tooltip>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.figure} >
                                                        {totalCustomerCount}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ my: 1.5 }} />
                                </>
                            )
                        })) : (
                            <Typography ml={14} className={classes.text}>No Pages </Typography>
                        )}



                    </Grid>
                </Grid>
            </CardContent>
            {/* <CardActions sx={{ p: 1.25, pt: 0, justifyContent: 'center' }}>
                <Button size="small" disableElevation>
                    View All
                    <ChevronRightOutlinedIcon />
                </Button>
            </CardActions> */}
        </Card>
    )
}

export default ReportsPagesBreakdown