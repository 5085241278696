import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import moment from "moment";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import ErrorIcon from "@mui/icons-material/Error";
import { Tooltip, CircularProgress } from "@material-ui/core";
import { Box, Avatar } from "@mui/material";
import useStyles from './Styles'

const OutgoingMessageContainer = ({
  textCreated,
  searchCount,
  parseNameFromURL,
  index,
  messages,
  item,
  handleClickOpen,
  itemData,
  outgoingMessageSeenLastItem,
  outgoingMessageDeliveredLastItem,
  linkify,
}) => {
  const { classes } = useStyles();
  return (
    <div>
      <>
        {index != 0 &&
          messages.messages[index].timestamp - 7200000 >
          messages.messages[index - 1].timestamp && (
            <div className={classes.messageTimeStamp}>
              <span className={classes.messageTimeStampText}>
                {moment
                  .unix(item.timestamp / 1000)
                  .format("DD MMM YYYY hh:mm a")}
              </span>
            </div>
          )}
        {item.mediatype ? (
          item.mediatype.includes("image") ? (

            <div className={classes.outgoingMessageContainer}>
              {item.loading && (
                <CircularProgress
                  className={classes.chatMessageProgress}
                ></CircularProgress>
              )}
              {item.error && (
                <Tooltip
                  arrow={true}
                  placement={"top"}
                  title={item.error}
                  className={classes.errorMessageContainer}
                >
                  <ErrorIcon className={classes.errorMessage} />
                </Tooltip>
              )}
              {!item.loading && (
                <Tooltip
                  arrow={true}
                  placement={"top"}
                  title={moment
                    .unix(item.timestamp / 1000)
                    .format("DD MMM YYYY hh:mm a")}
                  className={classes.errorMessageContainer}
                >
                  <div >
                    <img
                      className={classes.imageoutgoing}
                      src={item.file}
                      alt=""
                      onClick={() => handleClickOpen(item)}
                      style={{
                        cursor: "pointer",
                        // height: "123px",
                        borderRadius: 1,
                        width: 194,
                        border: '1px solid #4D1277',
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                      }}
                    />
                  </div>


                </Tooltip>
              )}
              {!item.loading &&
                outgoingMessageSeenLastItem &&
                outgoingMessageSeenLastItem.messageId == item.messageId && (
                  <Tooltip
                    arrow={true}
                    placement={"top"}
                    title={
                      "Seen at " +
                      moment
                        .unix(item.receiptreadtimestamp / 1000)
                        .format("DD MMM YYYY hh:mm a")
                    }
                  >
                    <span className={classes.seenMessageIconContainer}>
                      {itemData && (
                        <>

                          <Avatar
                            key={item.customerId}
                            className={classes.seenMessageIcon} src={itemData.customerPicture || ""}
                          ></Avatar>
                        </>
                      )}
                    </span>
                  </Tooltip>
                )}
              {!item.loading &&
                !(
                  outgoingMessageSeenLastItem &&
                  outgoingMessageSeenLastItem.messageId == item.messageId
                ) &&
                outgoingMessageDeliveredLastItem &&
                outgoingMessageDeliveredLastItem.messageId ==
                item.messageId && (
                  <Tooltip
                    arrow={true}
                    placement={"top"}
                    title={
                      "Delivered at " +
                      moment
                        .unix(item.deliverytimestamp / 1000)
                        .format("DD MMM YYYY hh:mm a")
                    }
                  >
                    <span className={classes.deliveredMessageIconContainer}>
                      <CheckIcon className={classes.deliveredMessageIcon} />
                    </span>
                  </Tooltip>
                )}
            </div>
          ) : item.mediatype !== "image" ? (
            <div className={classes.outgoingMessageContainer}>
              {item.loading && (
                <CircularProgress
                  className={classes.chatMessageProgress}
                ></CircularProgress>
              )}
              {item.error && (
                <Tooltip
                  arrow={true}
                  placement={"top"}
                  title={item.error}
                  className={classes.errorMessageContainer}
                >
                  <ErrorIcon className={classes.errorMessage} />
                </Tooltip>
              )}

              <Tooltip
                arrow={true}
                placement={"top"}
                title={moment
                  .unix(item.timestamp / 1000)
                  .format("DD MMM YYYY hh:mm a")}
                className={classes.errorMessageContainer}
              >
                <a
                  style={{
                    width: "160px",
                    textDecoration: "none",
                    background: "lightgrey",
                    padding: "15px 20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "black",
                    borderRadius: "20px",
                    margin: "5px",
                  }}
                  href={item.file}
                >
                  <DescriptionRoundedIcon />
                  <span style={{ marginLeft: "10px" }}>
                    {parseNameFromURL(item.file)}
                  </span>
                </a>
              </Tooltip>
              {!item.loading &&
                outgoingMessageSeenLastItem &&
                outgoingMessageSeenLastItem.messageId == item.messageId && (
                  <Tooltip
                    arrow={true}
                    placement={"top"}
                    title={
                      "Seen at " +
                      moment
                        .unix(item.receiptreadtimestamp / 1000)
                        .format("DD MMM YYYY hh:mm a")
                    }
                  >
                    <span className={classes.seenMessageIconContainer}>
                      {itemData && (
                        <>
                          <Avatar
                            key={item.customerId}
                            className={classes.seenMessageIcon} src={itemData.customerPicture || ""}
                          ></Avatar>
                        </>
                      )}
                    </span>
                  </Tooltip>
                )}
              {!item.loading &&
                !(
                  outgoingMessageSeenLastItem &&
                  outgoingMessageSeenLastItem.messageId == item.messageId
                ) &&
                outgoingMessageDeliveredLastItem &&
                outgoingMessageDeliveredLastItem.messageId ==
                item.messageId && (
                  <Tooltip
                    arrow={true}
                    placement={"top"}
                    title={
                      "Delivered at " +
                      moment
                        .unix(item.deliverytimestamp / 1000)
                        .format("DD MMM YYYY hh:mm a")
                    }
                  >
                    <span className={classes.deliveredMessageIconContainer}>
                      <CheckIcon className={classes.deliveredMessageIcon} />
                    </span>
                  </Tooltip>
                )}
            </div>
          ) : item.mediatype == "audio" ? (
            <div>
              {item.loading && (
                <CircularProgress
                  className={classes.chatMessageProgress}
                ></CircularProgress>
              )}
              {item.error && (
                <Tooltip
                  arrow={true}
                  placement={"top"}
                  title={item.error}
                  className={classes.errorMessageContainer}
                >
                  <ErrorIcon className={classes.errorMessage} />
                </Tooltip>
              )}

              <Tooltip
                arrow={true}
                placement={"top"}
                title={moment
                  .unix(item.timestamp / 1000)
                  .format("DD MMM YYYY hh:mm a")}
                className={classes.errorMessageContainer}
              >
                <audio controls>
                  <source src={item.filePath} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </Tooltip>
              {!item.loading &&
                outgoingMessageSeenLastItem &&
                outgoingMessageSeenLastItem.messageId == item.messageId && (
                  <Tooltip
                    arrow={true}
                    placement={"top"}
                    title={
                      "Seen at " +
                      moment
                        .unix(item.receiptreadtimestamp / 1000)
                        .format("DD MMM YYYY hh:mm a")
                    }
                  >
                    <span className={classes.seenMessageIconContainer}>
                      {itemData && (
                        <>
                          <Avatar
                            key={item.customerId}
                            className={classes.seenMessageIcon} src={itemData.customerPicture || ""}
                          ></Avatar>
                        </>
                      )}
                    </span>
                  </Tooltip>
                )}
              {!item.loading &&
                !(
                  outgoingMessageSeenLastItem &&
                  outgoingMessageSeenLastItem.messageId == item.messageId
                ) &&
                outgoingMessageDeliveredLastItem &&
                outgoingMessageDeliveredLastItem.messageId ==
                item.messageId && (
                  <Tooltip
                    arrow={true}
                    placement={"top"}
                    title={
                      "Delivered at " +
                      moment
                        .unix(item.deliverytimestamp / 1000)
                        .format("DD MMM YYYY hh:mm a")
                    }
                  >
                    <span className={classes.deliveredMessageIconContainer}>
                      <CheckIcon className={classes.deliveredMessageIcon} />
                    </span>
                  </Tooltip>
                )}
            </div>
          ) : (
            ""
          )
        ) : (
          <div className={classes.outgoingMessageContainer}>
            {item.loading && (
              <CircularProgress
                className={classes.chatMessageProgress}
              ></CircularProgress>
            )}
            {item.error && (
              <Tooltip
                arrow={true}
                placement={"top"}
                title={item.error}
                className={classes.errorMessageContainer}
              >
                <ErrorIcon className={classes.errorMessage} />
              </Tooltip>
            )}
            {/* outgoing message */}
            {!item.loading && (
              <Tooltip
                arrow={true}
                placement={"top"}
                title={moment
                  .unix(item.timestamp / 1000)
                  .format("DD MMM YYYY hh:mm a")}
                className={`${classes.outgoingMessage} ${classes.outgoingMessageLink}`}
              >
                <span
                  data-search={
                    textCreated.containsSearch
                      ? "Search" + searchCount
                      : undefined
                  }
                  className={classes.outgoingMessage}
                  dangerouslySetInnerHTML={{
                    __html: linkify(textCreated.text),
                  }}
                ></span>
              </Tooltip>
            )}
            {!item.loading &&
              outgoingMessageSeenLastItem &&
              outgoingMessageSeenLastItem.messageId == item.messageId && (
                <Tooltip
                  arrow={true}
                  placement={"top"}
                  title={
                    "Seen at " +
                    moment
                      .unix(item.receiptreadtimestamp / 1000)
                      .format("DD MMM YYYY hh:mm a")
                  }
                >
                  <Box className={classes.seenMessageIconContainer}>
                    {itemData && (
                      <Box sx={{ width: "14px !important", }}>
                        <Avatar
                          key={item.customerId}
                          className={classes.seenMessageIcon} src={itemData.customerPicture || ""}
                        ></Avatar>
                      </Box>
                    )}
                  </Box>
                </Tooltip>
              )}
            {!item.loading &&
              !(
                outgoingMessageSeenLastItem &&
                outgoingMessageSeenLastItem.messageId == item.messageId
              ) &&
              outgoingMessageDeliveredLastItem &&
              outgoingMessageDeliveredLastItem.messageId == item.messageId && (
                <Tooltip
                  arrow={true}
                  placement={"top"}
                  title={
                    "Delivered at " +
                    moment
                      .unix(item.deliverytimestamp / 1000)
                      .format("DD MMM YYYY hh:mm a")
                  }
                >
                  <span className={classes.deliveredMessageIconContainer}>
                    <CheckIcon className={classes.deliveredMessageIcon} />
                  </span>
                </Tooltip>
              )}
          </div>
        )}
      </>
    </div >
  );
};

export default OutgoingMessageContainer;
