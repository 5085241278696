import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setChatBoxRecentChatListData } from "../../store/actions/ChatBoxActions";
import { BrowserRouter, Redirect } from "react-router-dom";

import {
  setAdminPanelDrawerToggle,
  setAdminPanelFullscreenToggle,
  setAdminPanelClosedDrawerToggle,
  setAdminPanelChatOnline,
  setAdminPanelChatBoxDrawerToggle,
} from "../../store/actions/AdminpanelActions";
import Fullscreen from "fullscreen-react";

import { setPropsObjectData } from "../../store/actions/PropsObjectActions";
import { setUserPanelChatOnline } from "../../store/actions/UserPanelActions";
import { setNotificationsListData } from "../../store/actions/NotificationListAction";
import {
  setAuthUserWsSubscriptionReady,
  setAuthMainAppBarHeight,
} from "../../store/actions/AuthActions";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import MainContentContainer from "./MainContentContainer";

import { setRedirectToPath } from "../../store/actions/RedirectToPathActions";
import { Facebook } from "../../auth/Facebook";

import includes from "../chatBox/includes";

const AdminPanel = (props) => {
  //
  useEffect(() => {
    new includes().setSubscriptionReadyIfUserIdIsAvailable(
      props.authUserId,
      props.wsLink,
      props.setAuthUserWsSubscriptionReady
    );
  }, [props.authUserId]);

  const MeQuery = gql`
    query Me($accessToken: String) {
      me(accessToken: $accessToken) {
        id
        name
        email
        picture
        pseudonym
        designation {
          name
        }
      }
    }
  `;

  let [
    meQuery,
    { loading: meQueryLoading, error: meQueryQueryError, data: meQueryResult },
  ] = useLazyQuery(MeQuery, {
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    Facebook.fbInt();
    meQuery();
  }, []);

  useEffect(() => {
    if (props.adminPanelChatBoxDrawerToggle)
      document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
  }, [props.adminPanelChatBoxDrawerToggle]);


  if (props.redirectToPath) {
    const paths = props.redirectToPath;
    props.setRedirectToPath(null);
    return <Redirect to={{ pathname: paths }} />;
  }
  return (
    <BrowserRouter>
        <MainContentContainer props={props}/>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.AdminPanelReducer,
    ...state.RedirectToPathReducer,
    ...state.AuthReducer,
    ...state.ChatBoxReducer,
    ...state.UserPanelReducer,
    // ...state.NotificationsListReducer,
    ...state.PropsObjectDataReducer,
  };
};
export default connect(mapStateToProps, {
  setChatBoxRecentChatListData,
  setPropsObjectData,
  setNotificationsListData,
  setRedirectToPath,
  setAdminPanelDrawerToggle,
  setAdminPanelFullscreenToggle,
  setAuthMainAppBarHeight,
  setUserPanelChatOnline,
  setAdminPanelClosedDrawerToggle,
  setAdminPanelChatOnline,
  setAuthUserWsSubscriptionReady,
  setAdminPanelChatBoxDrawerToggle,
})(React.memo(AdminPanel));
