import React,{useEffect} from 'react'
import { Stack, Snackbar, SnackbarOrigin } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export interface State extends SnackbarOrigin {
  open: boolean;
}
interface Props {
  text: string,
  coolor: string,
  snackbarStatus: boolean
}
const SnackBar: React.FC<Props> = ({
  text,
  coolor,
  snackbarStatus
}) => {

  const [state, setState] = React.useState({
      open: false,
      vertical: 'top', 
      horizontal: 'center'
    });
  
  const { open } = state;
  // const handleClick = (newState: SnackbarOrigin) => {
  //   setState({
  //     open : true,
  //     ...newState
  //   });
  // };
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({ ...state, open: false });
  };
  useEffect(()=>{
    setState({ ...state, open: snackbarStatus });
  },[snackbarStatus])
  return (
    <Stack>
      {/* <Button variant="outlined" onClick={()=>handleClick({vertical: 'top',horizontal: 'center'})}>
        Click here
      </Button> */}
      <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{vertical:'top', horizontal:'center'}} onClose={handleClose}>
        <Alert onClose={handleClose} sx={{ width: '100%', background:`${coolor}` }}>
          {
            text
          }
        </Alert>
      </Snackbar>
    </Stack>
  )
}

export default SnackBar
