import React, { useState } from 'react';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { Skeleton } from '@mui/material';
import { Pagination, PaginationItem, PaginationRenderItemParams } from '@mui/material';
import { CustomerData, TabularData } from '../../../../../components/Report/Types';
import remToPixels from '../../../../../style/functions/remToPixels';
import { Link } from 'react-router-dom';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: Number(remToPixels(2.75)), editable: true, },
    {
        field: 'timeStamp',
        headerName: 'TimeStamp',
        width: Number(remToPixels(14.5)),
        editable: false,
    },
    {
        field: 'pageName',
        headerName: 'Page Name',
        width: Number(remToPixels(23.938)),
        editable: false,
    },
    {
        field: 'customerName',
        headerName: 'Customer Name',
        // type: 'number',
        width: Number(remToPixels(16.688)),
        editable: false,
    },
    // {
    //     field: 'Labels',
    //     headerName: 'Labels',
    //     description: 'This column has a value getter and is not sortable.',
    //     sortable: false,
    //     width: 160,
    //     valueGetter: (params: GridValueGetterParams) =>
    //         `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
    {
        field: 'label',
        headerName: 'Labels',
        // type: 'number',
        width: Number(remToPixels(12.313)),
        editable: false,
    },
    {
        field: 'chatdetails',
        headerName: 'Chat Details',
        width: 200,
        // renderCell: rowData => <Link target="_blank" style={{ color: 'blue' }} to={`/reports/totalcustomerreport/cid=${rowData.row.customerId}&pid=${rowData.row.pageId}`}>Chat Details</Link>
         renderCell: rowData => {
                return <Button
                    // style={{ visibility: hoveredIndex === index ? "visible" : "hidden" }}
                    variant={'outlined'}
                    target="_blank" 
                    component={Link} to={`/reports/totalcustomerreport/cid=${rowData.row.customerId}&pid=${rowData.row.pageId}`}
                    sx={{
                        width: '90px',
                        height: '27px',
                        //
                        borderRadius: "3px",
                        //
                        fontSize: '11px',
                        lineHeight: '16.5px',
                        padding: '5px 10px !important',
                        textTransform: 'capitalize',
                        borderColor: '#7F3F98',
                        color: '#7F3F98',
                        //
                        // opacity: 0,
                        // transition: 'opacity 0.2s',
                        // '&:hover': {
                        //     opacity: 1,
                        // },
                        //
                    }}

                >
                    View details
                </Button>;
            }
    // },
    },

    //     {
    //         field: 'ChatDetails',
    //         headerName: 'Chat Details',
    //         // type: 'number',
    //         width: Number(remToPixels(12.313)),
    //         editable: false,
    //         // renderCell: rowData => <Link target="_blank" style={{ color: 'blue' }} href={`/reports/totalcustomerreport/cid=${rowData.row.customerId}&pid=${PageNameWithPageCount.find((page) => page.name === rowData.row.PageName) ? PageNameWithPageCount.find((page) => page.name === rowData.row.PageName).pageId : null}`}>Chat Details</Link>

    //         renderCell: rowData => {
    //             return <Button
    //                 // style={{ visibility: hoveredIndex === index ? "visible" : "hidden" }}
    //                 variant={'outlined'}
    //                 sx={{
    //                     width: '90px',
    //                     height: '27px',
    //                     //
    //                     borderRadius: "3px",
    //                     //
    //                     fontSize: '11px',
    //                     lineHeight: '16.5px',
    //                     padding: '5px 10px !important',
    //                     textTransform: 'capitalize',
    //                     borderColor: '#7F3F98',
    //                     color: '#7F3F98',
    //                     //
    //                     // opacity: 0,
    //                     // transition: 'opacity 0.2s',
    //                     // '&:hover': {
    //                     //     opacity: 1,
    //                     // },
    //                     //
    //                 }}

    //             >
    //                 View details
    //             </Button>;
    //         }
    // },
];

// const rows = [
//     { id: 1, TimeStamp: '2023-03-01 06:18 PM', PageName: 'Cable TV, Local TV, Internet, and Phones Deals', CustomerName: 'Andrew Jones', Labels: 'ASpectrums', ChatDetails: 'Andrew Jones' },
//     { id: 2, TimeStamp: '2023-03-01 06:18 PM', PageName: 'Cable TV, Local TV, Internet, and Phones Deals', CustomerName: 'Andrew Jones', Labels: 'ASpectrums', ChatDetails: 'Andrew Jones' },
//     { id: 3, TimeStamp: '2023-03-01 06:18 PM', PageName: 'Cable TV, Local TV, Internet, and Phones Deals', CustomerName: 'Andrew Jones', Labels: 'ASpectrums', ChatDetails: 'Andrew Jones' },
//     // { id: 1, TimeStamp: '2023-03-01 06:18 PM', PageName: 'Cable TV, Local TV, Internet, and Phones Deals', CustomerName: 'Andrew Jones', Labels: 'ASpectrums', ChatDetails: 'Andrew Jones' },
//     // { id: 1, TimeStamp: '2023-03-01 06:18 PM', PageName: 'Cable TV, Local TV, Internet, and Phones Deals', CustomerName: 'Andrew Jones', Labels: 'ASpectrums', ChatDetails: 'Andrew Jones' },

// ];
// const rowsSkeleton = Array.from(Array(10).keys()).map((id) => ({
//     id: id + 1,
//     TimeStamp: <Skeleton variant="text" />,
//     PageName: <Skeleton variant="text" />,
//     CustomerName: <Skeleton variant="text" />,
//     Labels: <Skeleton variant="text" />,
//     ChatDetails: <Skeleton variant="text" />,
// }));


interface Props {
    tabularData: CustomerData[]
    tablularDataApiHandle: (i: number, newPage: React.SetStateAction<number>, greater: boolean) => void;
    pagination: any
    getCustomerTabularDatasLoading: boolean
}


const ReportsDataGridComp: React.FC<Props> = ({ getCustomerTabularDatasLoading, tabularData, tablularDataApiHandle, pagination }) => {
    // const rows: CustomerData = tabularData.customerdata
    console.log(tabularData, "tablulardata")
    const rows: any[] = tabularData


    // let rowss = [];
    // if (tabularData.length) {

    //     tabularData.map((row) => (
    //         rowss.push({
    //             id: row.id, customerId: row.customerId, CustomerName: row.customername, pageId: row.pageId, Timestamp: row.messagetimestamp, PageName: row.pagename, label: row.labels.includes("!-!-!-")
    //                 ? row.labels.split("!-!-!-")[0]
    //                 : row.labels, chatdetails: row.chatDetails
    //         })
    //     ))
    // }
    // const rows = rowss;
    // const hasNextPage: boolean = tabularData.hasNextPage
    // let lastCursorValue = 0;
    // let totalPages = 1; // Set the total number of pages
    const { hasNextPage, page, currentPage, lastCursorValue, firstCursorValue } = pagination

    const handlePageChange = (event: any, newPage: React.SetStateAction<number>) => {
        // Handle page change logic here
        console.log(`Page changed to ${newPage}`, pagination);
        const greater: boolean = Number(newPage) > Number(currentPage)
        const cursorValue = greater ? lastCursorValue : firstCursorValue[Number(newPage) - 1]
        console.log(Number(newPage), Number(currentPage), cursorValue, "cursorsadasdadasValue")
        tablularDataApiHandle(cursorValue, newPage, greater)
    };

    const CustomPagination = () => {
        return <Pagination
            count={page}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
        />
    };


    return (
        <Card sx={{
            // height: 300,
            width: '100%', borderRadius: "10px", overflowX: 'hidden',
            //  overflowY: 'auto',
            overflowY: 'hidden',
            //  height: "800px"
        }}  >
            {/* <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      /> */}
            {rows.length !== 0 ? (
                !getCustomerTabularDatasLoading ?
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        components={{
                            Toolbar: GridToolbar,
                            Pagination: CustomPagination,
                        }}
                        // components={{ Toolbar: GridToolbar }}
                        disableRowSelectionOnClick
                    />

                    :
                    <DataGrid
                        rows={rows}
                        // rows={rowsSkeleton}
                        columns={columns}
                        components={{
                            Toolbar: GridToolbar,
                            Pagination: CustomPagination,
                        }}
                        // components={{ Toolbar: GridToolbar }}
                        disableRowSelectionOnClick
                    />
            ) : (null)}




        </Card>
    );
}
export default ReportsDataGridComp