import React, {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { constants } from "../../config/constant";
import Loader from "../loader";
//new work
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Chip,
  Button,
  CircularProgress,
  circularProgressClasses,
} from "@mui/material";
import Profile from "./Profile";
import useStyles from "./SingleTableStyle";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
//header

interface User {
  __typename: string;
  id: string;
  picture: null | string;
  pseudonym: string;
  name: string;
}

type SingleTableProps = {
  setUserIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  loadMoreDataHandle: (i: React.SyntheticEvent) => void;
  ref: React.RefObject<MyComponentType>;
  setIsAddModalOpen: () => void;
  isLoading: boolean;
  //
  isSearchLoading: boolean;
  userSelected: boolean;
  isEditMode: boolean;
  //
  handleSelectedDataForDelete: (data: any) => void;
  handleOpenEditPanel: () => void;
  handleIsUserRowSelected: (item: User) => void;
  handleOpenUserEditProfile: (id: number, flag: boolean) => void;
  data: any[]; // replace any with the actual type of data
  actinsItems: { title: string; method: () => void }[];
  headers: { title: string }[];
};

type MyComponentType = {
  handleCloseActionModal: () => void;
};

const SingleTable = forwardRef<MyComponentType, SingleTableProps>(
  (
    {
      isEditMode,
      userSelected,
      isSearchLoading,
      setUserIndex,
      loadMoreDataHandle,
      setIsAddModalOpen,
      isLoading,
      handleSelectedDataForDelete,
      handleOpenEditPanel,
      handleIsUserRowSelected,
      handleOpenUserEditProfile,
      data,
      actinsItems,
      headers,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      handleCloseActionModal() {
        setIsActionModalOpen(false);
      },
    }));

    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<number | null>(null);

    const handleUserRowSelected = (item: User, index: number) => {
      //
      handleIsUserRowSelected(item);
      setSelectedUser(index);
      setUserIndex(index);
    };

    useEffect(() => {
      console.log("data of the users", data);
    }, [data]);

    const { classes } = useStyles();

    useEffect(() => {
      if (userSelected === false) {
        setSelectedUser(null);
      }
    }, [userSelected]);

    const chipSX = {
      height: "18px",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "18px",
      boxShadow: "none",
      //
      "& .MuiChip-label": {
        padding: "0px 2px",
      },
    };

    const chipBlue = {
      ...chipSX,
      color: "#0085FF",
      backgroundColor: "rgba(0, 133, 255,0.05)",
      borderRadius: "2px",
      height: "24px",
    };

    const chipOrange = {
      ...chipSX,
      color: "#FF8A00",
      backgroundColor: "rgba(255, 138, 0,0.05)",
      borderRadius: "2px",
      height: "24px",
    };

    const chipRed = {
      ...chipSX,
      color: "#E34D59",
      backgroundColor: "rgba(227, 77, 89,0.05)",
      borderRadius: "2px",
      height: "24px",
    };

    ///////////////////////

    interface dataUser {
        createdAt:String;
      agentlimitchatassign: number;
      designation: {
        __typename: string;
        id: string;
        name: string;
        paneltype: string;
      };
      email: string;
      id: string;
      jobTitle: string;
      managerId: null | string;
      name: string;
      number: string;
      pages: string;
      picture: null | any;
      pseudonym: string;
      status: string;
      username: string;
      __typename: string;
    }

    const [dummyData,setdummydata] = React.useState([
        {createdAt:"dsds",
        agentlimitchatassign: 3,
        designation: {
          __typename: "dss",
          id: 3,
          name: "dsadsd",
          paneltype: "dsadsd",
        },
        email: "dsadsd",
        id: "dsadsd",
        jobTitle: "dsadsd",
        managerId: null,
        name: "dsadsd",
        number: "dsadsd",
        pages: "dsadsd",
        picture: null,
        pseudonym: "dsadsd",
        status: "dsadsd",
        username: "dsadsd",
        __typename: "dsds"}])




    const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null);

    const handleMouseEnter = (item: dataUser, index: number) => {
      setHoveredIndex(index);
    };

    const handleMouseLeave = (item: dataUser, index: number) => {
      setHoveredIndex(null);
    };

const dateConversion = (dateStamp:any) =>{
    const timeNow = new Date(parseInt(dateStamp));
    const formattedDate = `${timeNow.getDate()}/${timeNow.getMonth() + 1}/${timeNow.getFullYear()} ${timeNow.getHours()}:${timeNow.getMinutes()}:${timeNow.getSeconds()}`;
    return <span>{formattedDate}</span>;
}


    return (
      <>
        {/* <Header/> */}
        <Box className={classes.outerBox} onScroll={loadMoreDataHandle}>
          <Table sx={{ width: "100%" }}>
            <TableHead>
              <TableRow
                className={classes.headRow}
                style={{
                  fontFamily: "poppins",
                  fontWeight: "300",
                  fontSize: "12px",
                  lineHeight: "20px",
                  height: "20px",
                  color: "#777777",
                }}
              >
                {headers.map((headerItem: { title: string }, indx: number) => (
                  <TableCell
                    colSpan={
                      headerItem.title === "Name" || headerItem.title === ""
                        ? 3
                        : 2
                    }
                    className={classes.head}
                  >
                    {headerItem.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {isSearchLoading && <div className='text-center' style={{ position: "absolute", width: "90%" }}>
                            <CircularProgress style={{ marginLeft: "3px", width: "25px", height: "25px", fontWeight: "400", color: "#4d1277" }} thickness={5} />
                        </div>} */}
              {/* !isSearchLoading &&  */}
              {/* {dummyData.length > 0 && dummyData.map((item: dataUser, index: number) => ( */}
              {data.length > 0 && data.map((item: dataUser, index: number) => (
                  <TableRow
                    onMouseEnter={() => handleMouseEnter(item, index)}
                    onMouseLeave={() => handleMouseLeave(item, index)}
                    className={classes.row}
                    onClick={() => {
                      setIsAddModalOpen();
                      setIsActionModalOpen(false);
                    }}
                    style={{
                      backgroundColor: `${
                        selectedUser === index
                          ? constants.theme.lightestGray
                          : ""
                      }`,
                    }}
                  >
                    <TableCell colSpan={3} className={classes.rowCell} style={{borderBottom: index === (data.length -1) ? "none":""}}>
                      <Profile
                        name={item.name}
                        email={item.email}
                        pic={item.picture}
                      />
                    </TableCell>
                    <TableCell colSpan={2} className={classes.rowCell} style={{borderBottom: index === (data.length -1) ? "none":""}}>
                      {item.designation?.paneltype === "SUPERADMIN" ? (
                        <Chip label="Superadmin" sx={chipRed} />
                      ) : item.designation?.paneltype === "MANAGER" ? (
                        <Chip label="Manager" sx={chipOrange} />
                      ) : (
                        <Chip label="Agent" sx={chipBlue} />
                      )}
                    </TableCell>
                    <TableCell colSpan={2} className={classes.rowCell} style={{borderBottom: index === (data.length -1) ? "none":""}}>
                      <FiberManualRecordIcon
                        style={{
                          fontFamily: "poppins",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "18px",
                          height: "18px",
                          color:
                            item.status === "BLOCKED"
                              ? "#E34D59"
                              : item.status === "ACTIVE"
                              ? "#00BA34"
                              : "#FF8A00",
                          marginRight: "3px",
                        }}
                      />
                      <span
                        style={{
                          color:
                            item.status === "BLOCKED"
                              ? "#E34D59"
                              : item.status === "ACTIVE"
                              ? "#00BA34"
                              : "#FF8A00",
                        }}
                      >
                        {item.status}
                      </span>
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      className={classes.rowCell}
                      style={{
                        fontFamily: "poppins",
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "18px",
                        height: "18px",
                        color: "#838383",
                        borderBottom: index === (data.length -1) ? "none":""
                      }}
                    >
                      {/* 10/03/23 */}
                      {dateConversion(item.createdAt)}
                    </TableCell>
                    <TableCell colSpan={3} className={classes.rowCell} style={{borderBottom: index === (data.length -1) ? "none":""}}>
                      <Button
                        // style={{ visibility: hoveredIndex === index ? "visible" : "hidden" }}
                        style={{
                          visibility: isEditMode
                            ? "hidden"
                            : hoveredIndex === index
                            ? "visible"
                            : "hidden",
                        }}
                        variant={"outlined"}
                        className={classes.buttonStyle}
                        onClick={() => {
                          handleUserRowSelected(item, index);
                        }}
                      >
                        View details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              {/*   isLoading*/}
            </TableBody>
          </Table>
          {/* {isSearchLoading && isLoading ? <div className='text-center'>
                    <CircularProgress style={{ marginLeft: "3px", width: "25px", height: "25px", fontWeight: "400", color: "#4d1277" }} thickness={5} />
                </div> :
                    isSearchLoading && <div className='text-center'>
                        <CircularProgress style={{ marginLeft: "3px", width: "25px", height: "25px", fontWeight: "400", color: "#4d1277" }} thickness={5} />
                    </div> || isLoading && <div className='text-center'>
                        <CircularProgress style={{ marginLeft: "3px", width: "25px", height: "25px", fontWeight: "400", color: "#4d1277" }} thickness={5} />
                    </div>} */}
          
            <div className={classes.circularLoader} style={{visibility:(isSearchLoading && isLoading) || isSearchLoading || isLoading ?"visible" : "hidden"}}>
              <CircularProgress
                style={{
                  marginLeft: "3px",
                  width: "25px",
                  height: "25px",
                  fontWeight: "400",
                  color: "#4d1277",
                }}
                thickness={5}
              />
            </div>

            {/* <div className={classes.circularLoader} style={{visibility:isSearchLoading || isLoading ? "visible" : "hidden"}}>
              <CircularProgress
                style={{
                  marginLeft: "3px",
                  width: "25px",
                  height: "25px",
                  fontWeight: "400",
                  color: "#4d1277",
                }}
                thickness={5}
              />
            </div> */}
          
        </Box>
      </>
    );
  }
);

export default React.memo(SingleTable);
