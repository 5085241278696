import { makeStyles } from 'tss-react/mui'

const useStyle = makeStyles()(() => ({
    DeleteIcon: {
        textAlign: 'center'
    },
    deleteIconStyle: {
        color: '#E34D59',
        fontSize: '55px'
    },
    headingStyle: {
        // fontSize: '16px',
        fontSize: '20px',
        fontWeight: '500',
        lineHeight: '24px',
        textAlign: 'center',
        margin: '10px 0px 5px 0px'

    },
    textStyle: {
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '18px',
        textAlign: 'center'

    },
    buttonStyle: {
        // height: '40px',
        // width: '30%',
        borderRadius: '3px',
        textTransform: 'capitalize',
        height: "32px",
        fontSize: "12px",
        lineHeight: "18px",
        fontWeight: "400",
        width: "135px",
    },
    deleteButton: {
        background: '#E34D59',
        color: '#FFFFFF',
        '&:hover': {
            background: '#E34D59',
            color: '#FFFFFF',
        }
    },
    cancelButton: {
        background: '#F5F5F5',
        color: '#777777',
        '&:hover': {
            background: '#F5F5F5',
            color: '#777777',
        },
        borderRadius: '3px',
        textTransform: 'capitalize',
        height: "32px",
        fontSize: "12px",
        lineHeight: "18px",
        fontWeight: "400",
        width: "135px",
    }
}))


export default useStyle