import { makeStyles } from 'tss-react/mui'
// import theme from '../../style/theme';
//
const useStyles = makeStyles()(() => ({
  selectInput: {
    '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
    {
      // Your custom styles here
      // border: '1px solid red',
      paddingRight: '12px',
      //
      marginRight: '-19px',
    },
  },

  root: {
    '& .MuiInputBase-input::placeholder': {
      // width: '420px',
      width: '100%',
      // height: '15px',
      height: '38px',
      // fontSize: '10px',
      fontSize: '12px',
      lineHeight: '15px',
      fontWeight: '600',
      fontFamily: 'poppins',
      //
      display: 'flex',
      alignItems: 'center',
    },
  },
}))
export default useStyles
