import { gql } from "apollo-boost";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

const GetPages = gql`
  query getAllPages($mainSuperAdminId: ID!) {
    getAllPages(mainSuperAdminId: $mainSuperAdminId) {
      id
      name
      pageId
      accesstoken
      picture
      isSelected
    }
  }
`;

export const useGetPagesQuery = () => {
  return useLazyQuery(GetPages, {
    fetchPolicy: "network-only",
  });
};

const disConnectPageMutation = gql`
  mutation disconnectPage($pageId: String!) {
    disconnectPage(pageId: $pageId) {
      success
      error
    }
  }
`;

export const useDisConnectPageMutation = () => {
  return useMutation(disConnectPageMutation);
};

const connectPageMutation = gql`
  mutation connectPage($pageId: String!) {
    connectPage(pageId: $pageId) {
      success
      error
    }
  }
`;

export const useConnectPageMutation = () => {
  return useMutation(connectPageMutation);
};

const AddPagesMutation = gql`
  mutation AddPages($objects: [pages_insert_input!]) {
    addpages(objects: $objects) {
      success
      error
    }
  }
`;

export const useAddPagesMutation = () => {
  return useMutation(AddPagesMutation);
};

const deletepageMutation = gql`
  mutation deletepage($pageId: String!) {
    deletepage(pageId: $pageId) {
      success
      error
    }
  }
`;

export const useDeletePageMutation = () => {
  return useMutation(deletepageMutation);
};
