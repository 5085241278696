import { makeStyles } from "tss-react/mui";
// import logo from '../../../../assets/img/BG.svg'
import logo from "../../../../assets/img/BG.svg";

const useStyles = makeStyles()((theme) => ({
  root: {

    [theme.breakpoints.down("md")]: {
      maxWidth: "50%",
      position: "fixed",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "50%",
      position: "fixed",
    },

    [theme.breakpoints.up("md")]: {
      width: "720px",
      position: "fixed",
    },
    //laptop
    [theme.breakpoints.up("md")]: {
      width: "50%",
      position: "fixed",
    },
    //extra large
    [theme.breakpoints.up("xl")]: {
      maxWidth: "100%",
      width: "50%",
    },
    //

    alignItems: "center",
    height: "100vh",
    background: `url(${logo})`,
    //
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    //
  },
  heading: {
    //
    fontSize: "38px",
    fontWeight: "600",
    lineHeight: "63px",
    width:"100%",

    [theme.breakpoints.down("md")]: {
      margin: "30px 70px 0px 0px",
      width: "auto",
      // backgroundColor:"aqua",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "30px 70px 0px 0px",
      width: "auto",
      // backgroundColor:"pink",
    },
    [theme.breakpoints.up("md")]: {
      margin: "30px 70px 0px 0px",
      width: "550px",
      lineHeight: "63px",
      // backgroundColor:"grey",
    },
    //
    //laptop
    [theme.breakpoints.down("lg")]: {
      fontSize:"26px",
      margin: "30px 70px 0px 0px",
      // backgroundColor:"blue",
      //
      height: "auto",
      width: "100%",
      lineHeight: "30px",
      //
      paddingRight: "25px",
      //
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    //extra large
    [theme.breakpoints.up("xl")]: {
      // maxWidth: "100%",
      // backgroundColor:"red",
    },
    color: "#fff",
    height: "63px",
  },
  text: {
    fontWeight: 500,
    fontSize: "18px",
    //
    display: "flex",
    flexDirection: "column",
    //
    lineHeight: "27px",
    color: "#fff",
    //
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      width: "300px",
      margin: "0px 70px 0px 30px",
      //
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      width: "300px",
      margin: "0px 70px 0px 30px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
      width: "545px",
      margin: "0px 70px 0px 60px",
      paddingRight: "0px",
      //
    },
    //laptop
    [theme.breakpoints.down("lg")]: {
      //
      fontSize: "14px",
      width: "auto",
      margin: "0px 70px 0px 30px",
      paddingRight: "25px",
    },
    //
    //extra large
    [theme.breakpoints.up("xl")]: {
      maxWidth: "100%",
      lineHeight: "33px",
    },
    //
  },
  green: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
    fontSize: "18px",
    //extra large
    [theme.breakpoints.up("xl")]: {
      lineHeight: "40px",
    },
  },
}));

export default useStyles;
