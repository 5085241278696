import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Openlist from "@mui/icons-material/Add";
import Closelist from "@mui/icons-material/Remove";
import { useHistory } from "react-router-dom";
import { Settingbar } from "../../../../store/actions/setttingbar";
import { useDispatch } from "react-redux";
import useStyle from "./Styles";
import { useLocation } from "react-router-dom";
interface Props {
  dropDownMainText?: string;
  dropDownData?: string[];
}
const CustomDropDown: React.FC<Props> = ({
  dropDownMainText,
  dropDownData,
}) => {
  const locationForPath = useLocation();
  const navigate = useHistory();
  const { classes } = useStyle();
  const [isDropDownClicked, setIsDropDownClicked] = React.useState<boolean>();
  const history = useHistory();
  const {
    location: { pathname },
  } = history;
  const trimedPath = pathname.replace("/setting/", "");

  const dispatch = useDispatch();
  const onItemClick = (data: string) => {
    navigate.push(`/settings/${data.toLowerCase()}`);
    dispatch(Settingbar(isDropDownClicked));
  };
  useEffect(() => {
    const lastPathName = locationForPath.pathname.split("/").pop();
    const currentPage: string =
      lastPathName.charAt(0).toUpperCase() + lastPathName.slice(1);
    const checkClick = dropDownData && dropDownData?.indexOf(currentPage) > -1;
    setIsDropDownClicked(checkClick === true && true);
  }, []);
  return (
    <>
      <List sx={{ width: "100%" }} component="nav">
        <ListItemButton
          onClick={() => setIsDropDownClicked(!isDropDownClicked)}
        >
          <ListItemText
            primary={
              <Typography className={classes.styleCustom}>
                {dropDownMainText}
              </Typography>
            }
          />
          {isDropDownClicked ? (
            <Closelist fontSize="small" className={classes.styleCustom} />
          ) : (
            <Openlist className={classes.styleCustom} fontSize="small" />
          )}
        </ListItemButton>

        <Collapse in={isDropDownClicked} timeout="auto" unmountOnExit>
          {dropDownData &&
            dropDownData.map((items: string, index) => {
              return (
                <List
                  component="div"
                  disablePadding
                  key={index}
                  sx={{ fontSize: "15px !important" }}
                >
                  {trimedPath?.toLowerCase() === items.toLowerCase() ? (
                    <ListItemButton
                      sx={{ pl: 4 }}
                      className={classes.activeButton}
                      onClick={() => onItemClick(items)}
                    >
                      {/* <ListItemText
                        primary={items}
                        className={classes.activeTextColor}
                      /> */}
                      <ListItemText className={classes.activeTextColor}>
                        <span className={classes.itemStyle}>{items}</span>
                      </ListItemText>
                    </ListItemButton>
                  ) : (
                    <ListItemButton
                      sx={{ pl: 4 }}
                      selected={false}
                      onClick={() => onItemClick(items)}
                    >
                      {/* <ListItemText primary={items} /> */}
                      <ListItemText><span className={classes.itemStyle}>{items}</span></ListItemText>
                    </ListItemButton>
                  )}
                </List>
              );
            })}
        </Collapse>
      </List>
    </>
  );
};
export default CustomDropDown;
