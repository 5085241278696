import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
// import TextFieldProps from '@mui/material/TextFieldProps'
// import Box from '@mui/material/Box/Box'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import useStyles from './Styles'
//
import { useTheme } from '@mui/system'
import { stubFalse } from 'lodash'

let count = 0;

interface FieldProps {
  name: string
  id: string
  disabled?: boolean
  label?: string
  typeField?: string
  value: string
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  helperText?: string | boolean | undefined
}

const KuikTextField: React.FC<FieldProps> = ({
  id,
  label,
  name,
  typeField,
  onChange,
  value,
  disabled,
  helperText,
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const { classes } = useStyles()
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const theme = useTheme()
  return (
    <>
      <TextField
        sx={{
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
          [theme.breakpoints.up('md')]: {
            width: '50%',
          },
          [theme.breakpoints.up('lg')]: {
            width: '300px',
          },
          //laptop
          [theme.breakpoints.down('lg')]: {
            width: '100%',
          },
          //extra large
          [theme.breakpoints.up('xl')]: {
            width: '100%',
          },
        }}
        id={id}
        value={value}
        helperText={helperText}
        // label={label}
        name={name}
        type={!showPassword ? typeField : 'text'}
        //
        placeholder={label}
        disabled={disabled}
        //
        size="small"
        onChange={onChange}
        InputProps={{
          endAdornment: typeField ? (
            <InputAdornment position="end" onClick={handleClickShowPassword} sx={{ cursor: "pointer" }}>
              {showPassword ? (
                <Visibility sx={{ fontSize: 20 }} />
              ) : (
                <VisibilityOff sx={{ fontSize: 20 }} />
              )}
            </InputAdornment>
          ) : null,
        }}
        FormHelperTextProps={{
          className: classes.helperText,
        }}
      />
    </>
  )
}

export default KuikTextField
