import React, { useEffect } from "react";
import ChatNotes from "../../ChatNotes";
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Box, Typography,
  Button, Grid,
} from '@mui/material'
import TextareaAutosize from '@mui/base/TextareaAutosize';
import useStyles from './StylesChatNoteMain'



function ChatNoteMain(props) {
  const { enqueueSnackbar } = useSnackbar();

  const [modalShow, setModalShow] = React.useState(false);
  const [chatNote, setChatNote] = React.useState("");
  const [chatNoteData, setChatNoteData] = React.useState([]);

  const getChatNoteQuery = gql`
    query getChatNotes($customerId: String!, $pageId: String!, $agentId: ID!) {
      getChatNotes(
        customerId: $customerId
        pageId: $pageId
        agentId: $agentId
      ) {
        customerId
        pageId
        chatnote
        agent{
          id
          name
        }
      }
    }
  `;
  let [
    getChatNotes,
    {
      loading: ResponseQueryLoading,
      error: ResponseQueryError,
      data: ResponseQueryResult,
    },
  ] = useLazyQuery(getChatNoteQuery, {
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (ResponseQueryError) {
      ResponseQueryError.graphQLErrors.map(({ message }, i) => {
        enqueueSnackbar(message, { variant: "error" });
      });
    }
  }, [ResponseQueryError]);

  useEffect(() => {
    if (ResponseQueryResult) {
      setChatNoteData(JSON.parse(ResponseQueryResult.getChatNotes.chatnote));
    }
  }, [ResponseQueryResult]);

  useEffect(() => {
    getChatNotes({
      variables: {
        customerId: props.customerId,
        pageId: props.pageId,
        agentId: props.agentId,
      },
    });
  }, [props.customerId, props.pageId, props.agentId]);

  const updateChatNoteQuery = gql`
    mutation updatechatnote(
      $chatnote: String!
      $agentId: ID!
      $customerId: String!
      $pageId: String!
    ) {
      updatechatnote(
        chatnote: $chatnote
        agentId: $agentId
        customerId: $customerId
        pageId: $pageId
      ) {
        success
        error
      }
    }
  `;
  let [
    updatechatnote,
    {
      loading: updateChatNoteQueryLoading,
      error: updateChatNoteQueryError,
      data: updateChatNoteQueryResult,
    },
  ] = useMutation(updateChatNoteQuery);

  useEffect(() => {
    if (updateChatNoteQueryError) {
      updateChatNoteQueryError.graphQLErrors.map(({ message }, i) => {
        enqueueSnackbar(message, { variant: "error" });
      });
    }
  }, [updateChatNoteQueryError]);

  useEffect(() => {
    if (updateChatNoteQueryResult && updateChatNoteQueryResult.updatechatnote) {
      if (updateChatNoteQueryResult.updatechatnote.success) {
        enqueueSnackbar("Chat Note Saved Successfully", { variant: "success" });
        getChatNotes({
          variables: {
            customerId: props.customerId,
            pageId: props.pageId,
            agentId: props.agentId,
          },
        });
      } else {
        enqueueSnackbar(updateChatNoteQueryResult.updatechatnote.error, {
          variant: "error",
        });
      }
    }
  }, [updateChatNoteQueryResult]);

  const sendChatNoteToBackend = () => {
    let queryData;
    if (chatNote.length) {
      if (chatNoteData == null || chatNoteData.length == 0) {
        queryData = [
          {
            id: 0,
            chatNote: chatNote,
            timeStamp: Date.now(),
            agentId: props.authUserId,
          },
        ];
      } else {
        queryData = [
          ...chatNoteData,
          {
            id: chatNoteData[chatNoteData.length - 1].id + 1,
            chatNote: chatNote,
            timeStamp: Date.now(),
            agentId: props.authUserId,
          },
        ];
      }

      updatechatnote({
        variables: {
          chatnote: JSON.stringify(queryData),
          agentId: props.agentId,
          customerId: props.customerId,
          pageId: props.pageId,
        },
      });
    }
    setChatNote("");
  };
  const { classes } = useStyles()
  const placeholderStyle = {
    width: "100%", height: "100%",
    /* Placeholder text color */
    color: "#B7B7B7",
    /* Placeholder font style */
    // fontStyle: "italic",
    /* Placeholder font size */
    padding: 10,
    fontSize: 12,
    /* Placeholder font weight */
    fontWeight: 400,
  };
  return (

    <Box>
      <Typography className={classes.text}>Chat notes</Typography>

      <Box mt={1} height={100}>
        <TextareaAutosize
          aria-label="minimum height"
          minRows={3}
          value={chatNote}
          onChange={(e) => setChatNote(e.target.value)}
          placeholder='Save chat notes here'
          style={
            placeholderStyle
          }
        />

      </Box>
      <Grid container alignItems="center" justifyContent="space-between" mt={1}>
        <Grid item >
          <Typography onClick={() => setModalShow(true)} className={classes.view}>View all chat notes</Typography>

        </Grid>
        <Grid item >
          <Button className={classes.save} color="primary" variant='contained'
            onClick={sendChatNoteToBackend}
          >Save</Button>
        </Grid>
      </Grid >
      <ChatNotes
        chatNoteData={chatNoteData}
        setModalShow={setModalShow}
        modalShow={modalShow}
        setChatNote={setChatNote}
        sendChatNoteToBackend={sendChatNoteToBackend}
        agentId={props.agentId}
        customerId={props.customerId}
        pageId={props.pageId}
        authUserId={props.authUserId}
      />
    </Box>

  );
}

export default ChatNoteMain;
