import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  root: {
    position: "relative",
    border: "1px solid #DFE2E5",
    borderRadius: 1,
    width: "98%",
    margin: "0 auto",
  },
  stack: {
    position: "absolute",
    bottom: 10,
    right: 20,
    // alignItems: 'center',
    // paddingBottom: 1
    // display: 'flex',
    // justifyContent: 'flex-end',
    // marginTop: 1,
    cursor: "pointer",
  },
  textarea: {
    width: "100%",
    marginTop: -15,
    border: "none",
    padding: 5,
    resize: "none",
    // fontFamily: 'Poppins',
    "&:hover": {},
    "&:focus": {
      outline: "none", // remove the default outline when the button is focused
    },
  },
  divider: {
    height: 25,
    width: "1px",
    color: "#DFE2E5",
    background: "#DFE2E5",
  },
  chatIcon: {
    height: "21px",
    width: "21px",
    color: "#4D1277",
    marginBottom: 5
    // marginBottom: 5,
  },
  chatIconChats: {
    height: "21px",
    width: "21px",
    color: "#4D1277",
    // marginRight: -10
    // marginBottom: 5
    // marginBottom: 5,
  },
  text: {
    fontSize: "10px",
    fontWeight: 400,
  },
  icon: {
    // marginLeft: 1.5,
    height: "21px",
    width: "21px",
    transform: "rotate(45deg)",
    // marginBottom: -2,
  },
  
}));

export default useStyles;
