import React, { useState } from "react";
import FacebookTypographyForRightChatCompHeader from "../FacebookTypographyForRightChatCompHeader";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import _ from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import ChatTransfer from "../ChatTransfer";
import { Container } from "@material-ui/core";
import { Box, Stack } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import RightChatComponentHeaderClose from "../../../componentsfromkuikwit/components/pages/chats/rightChatCompnent/rightChatComponentHeader/RightChatComponentHeaderClose";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles((theme) => ({
  color: {
    color: "red",
  },
  chatTabHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    background: "#fff !important",
    borderBottom: "1px solid lightgrey !important",
    // borderLeft: "1px solid lightgrey !important",
    height: 50,
    color: "black !important",
  },

  chatsTabContainer: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    height: 50,
    marginRight: 5,
  },
  chatsTabText: {
    fontSize: "24px",
    display: "inline",
    color: "black",
    textAlign: "center",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    flexGrow: 1,
    fontFamily: "Poppins",
    fontWeight: "600px !important",
  },

  menuItemTransfer: {
    color: "grey",
    "&:hover": {
      color: "green",
      background: "rgba(85, 165, 48,0.1)",
    },
  },
  menuItemClose: {
    color: "grey",
    "&:hover": {
      color: "red",
      background: "rgba(227, 77, 89,0.1)",
    },
  },
}));

const ChatContainerHeader = ({
  closeDisabled,
  closeLabelAction,
  closeChatWindowHandler,
  chatBoxSelectedChatsOnFloating,
  chatBoxContainerChatSearchToggle,
  chatTabHeaderStyles,
  setChatBoxSelectedChatsOnFloating,
  itemData,
  showUserDetailPanel,
  setChatBoxContainerChatSearchToggle,
  setContentHeight,
  selectedChatsOnFloatingTabpanelItem,
}) => {
  const [modalShow, setModalShow] = useState(false);

  const classes = useStyles();
 

  return (
    <Container
      maxWidth={false}
      disableGutters
      className={clsx(
        classes.chatTabHeaderContainer
        // chatTabHeaderStyles
      )}
    >
      <span className={classes.chatsTabContainer}>
        <FacebookTypographyForRightChatCompHeader
          pageNameChange={(name) => {
            var findItem = _.find(
              chatBoxSelectedChatsOnFloating,
              (list) =>
                list.pageId == itemData.pageId &&
                list.customerId == itemData.customerId
            );
            if (findItem) {
              if (findItem.pageName != name) {
                findItem.pageName = name;
                setChatBoxSelectedChatsOnFloating(
                  _.cloneDeep(chatBoxSelectedChatsOnFloating)
                );
              }
            }
          }}
          customerNameChange={(name) => {
            var findItem = _.find(
              chatBoxSelectedChatsOnFloating,
              (list) =>
                list.pageId == itemData.pageId &&
                list.customerId == itemData.customerId
            );

            if (findItem) {
              if (findItem.customerName != name) {
                findItem.customerName = name;
                setChatBoxSelectedChatsOnFloating(
                  _.cloneDeep(chatBoxSelectedChatsOnFloating)
                );
              }
            }
          }}
          icon={true}
          item={itemData}
          className={classes.chatsTabText}
          setModalShow={setModalShow}
          selectedChatsOnFloatingTabpanelItem={selectedChatsOnFloatingTabpanelItem}
        ></FacebookTypographyForRightChatCompHeader>

        <Stack
          direction="row"
          spacing={{ xs: 1, sm: 2, md: 1 }}
          sx={{
            display: "flex-end",
            alignItems: "center",
            marginRight: 1,
            // marginTop: 1,
          }}
        >
          <Box
            sx={{
              background: "#FBFBFB",
              border: "0.5px solid #E8E8E8",
              borderRadius: "3px",
              width: "35px",
              height: "30px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <SearchIcon
              onClick={() => {
                setChatBoxContainerChatSearchToggle(
                  !chatBoxContainerChatSearchToggle
                );
                setContentHeight(false); //will reduce the messagebox height to adjust
              }}
              sx={{
                fontSize: 20,
                color: "#4D1277",
              }}
            />
          </Box>
          <Tooltip
            title={closeDisabled ? "Already closed" : null}
            arrow
            disableFocusListener
          >
            <Box>
              <RightChatComponentHeaderClose
                disabled={closeDisabled ? true : false}
                onClick={closeLabelAction}
              />
            </Box>
          </Tooltip>

          <Box
            sx={{
              background: "#FBFBFB",
              border: "0.5px solid #E8E8E8",
              borderRadius: "3px",
              width: "35px",
              height: "30px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={showUserDetailPanel}
          >
            <InfoIcon
              sx={{
                fontSize: 20,
                color: "#4D1277",
              }}
            />
          </Box>
        </Stack>

        {
          <>
            <ChatTransfer
              closeChatWindowHandler={closeChatWindowHandler}
              modalShow={modalShow}
              setModalShow={setModalShow}
              cid={itemData?.customerId}
              pid={itemData?.pageId}
            />
          </>
        }
      </span>
    </Container>
  );
};

export default ChatContainerHeader;
