import React from "react";
import CustomDropDown from "./CustomDropDown/CustomDropDown";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Settingbar2 } from "../../../store/actions/settingbar2";
import useStyle from "./Styles";
import { ListItemButton, ListItemText, Typography } from "@mui/material";
import { Box } from "@mui/system";
interface ActiveUsersTypes {
  id: string;
  mainSuperAdminId: string;
  managerId: null | string;
  name: string;
  paneltype: string;
}
const SettingLeftPane = () => {
  const { classes } = useStyle();
  const history = useHistory();
  const {location: { pathname },} = history;
  const dispatch = useDispatch();
  //
  const activeUsers: string | number | null = localStorage.getItem("ActiveUserdetail");
  const user: ActiveUsersTypes = activeUsers && JSON.parse(activeUsers);
  const [activeUserData] = React.useState<ActiveUsersTypes>({ ...user });
  //
  const onCannedResponses = () => {
    history.push("/settings/cannedResponse");
    dispatch(Settingbar2(true));
  };
  const onSettingLabels = () => {
    history.push("/settings/label");
    dispatch(Settingbar2(true));
  };
  const onSettingNotification = () => {
    history.push("/settings/notification");
  };
  return (
    <Box>
      {activeUserData.paneltype === "SUPERADMIN" && (
        <>
          <CustomDropDown
            dropDownMainText="Channels"
            dropDownData={["Website", "Chatpage", "Facebook", "Twilio"]}
          />
          <CustomDropDown
            dropDownMainText="Chat Widget"
            dropDownData={["Customization", "Language", "Availability"]}
          />
          <ListItemButton
            className={
              pathname.includes("cannedResponse") && classes.activeButton
            }
            onClick={onCannedResponses}
          >
            <ListItemText
              primary={
                <Typography
                  className={
                    pathname.includes("cannedResponse") &&
                    classes.activeTextColor
                  }
                >
                  Canned Responses
                </Typography>
              }
            />
          </ListItemButton>
          <ListItemButton
            className={pathname.includes("label") && classes.activeButton}
            onClick={onSettingLabels}
          >
            <ListItemText
              primary={
                <Typography
                  className={
                    pathname.includes("label") && classes.activeTextColor
                  }
                >
                  Labels
                </Typography>
              }
            />
          </ListItemButton>
          <ListItemButton
            className={
              pathname.includes("notification") && classes.activeButton
            }
            onClick={onSettingNotification}
          >
            <ListItemText
              primary={
                <Typography
                  className={
                    pathname.includes("notification") && classes.activeTextColor
                  }
                >
                  Notifications
                </Typography>
              }
            />
          </ListItemButton>
        </>
      )}
      {activeUserData.paneltype === "AGENT" && (
        <>
          <ListItemButton
            className={
              pathname.includes("cannedResponse") && classes.activeButton
            }
            onClick={onCannedResponses}
          >
            <ListItemText
              primary={
                <Typography
                  className={
                    pathname.includes("cannedResponse") &&
                    classes.activeTextColor
                  }
                >
                  Canned Responses
                </Typography>
              }
            />
          </ListItemButton>
          <ListItemButton
            className={pathname.includes("label") && classes.activeButton}
            onClick={onSettingLabels}
          >
            <ListItemText
              primary={
                <Typography
                  className={
                    pathname.includes("label") && classes.activeTextColor
                  }
                >
                  Labels
                </Typography>
              }
            />
          </ListItemButton>
        </>
      )}
      {activeUserData.paneltype === "MANAGER" && (
        <>
          <ListItemButton
            className={
              pathname.includes("cannedResponse") && classes.activeButton
            }
            onClick={onCannedResponses}
          >
            <ListItemText
              primary={
                <Typography
                  className={
                    pathname.includes("cannedResponse") &&
                    classes.activeTextColor
                  }
                >
                  Canned Responses
                </Typography>
              }
            />
          </ListItemButton>
          <ListItemButton
            className={pathname.includes("label") && classes.activeButton}
            onClick={onSettingLabels}
          >
            <ListItemText
              primary={
                <Typography
                  className={
                    pathname.includes("label") && classes.activeTextColor
                  }
                >
                  Labels
                </Typography>
              }
            />
          </ListItemButton>
        </>
      )}
    </Box>
  );
};

export default SettingLeftPane;
