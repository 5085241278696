import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
//
import CloseIcon from "@mui/icons-material/Close";
//
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
//
import { FormEvent } from "react";
//
import { SvgIcon } from "@mui/material";
import useStyles from "./Styles";
//
import SingleInput from "../../../../input/singleInput";
import expressConfig from "../../../../../config/express.json";

const style = {
  //
  // position: 'absolute' as 'absolute',
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //
  width: "600px",
  // height: "170px",
  height: "auto",
  minHeight: "170px",
  //
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)",
  borderRadius: "3px",
};

interface Props {
  check: boolean;
  handleCloseDropDown: () => void;
  closeModal: () => void;
}

interface AppConfig {
  port?: number | string;
  graphql_endpoint?: string;
  graphql_domain?: string;
  graphql_subscription_domain?: string;
  graphql_subscription_endpoint?: string;
  facebook_app_id?: string;
  facebook_app_secret?: string;
  SHAREABLEINVITELINK?: string;
  GOOGLE_CLIENT_ID?: string;
  GOOGLE_CLIENT_SECRET?: string;

  FACEBOOK_APP_ID?: string;
  FACEBOOK_APP_SECRET?: string;

  LINKEDIN_API_KEY?: string;
  LINKEDIN_SECRET_KEY?: string;
}

const KeepMountedModal: React.FC<Props> = ({
  check,
  handleCloseDropDown,
  closeModal,
}) => {
  //for the copied link
  // const mainSuperAdminId = JSON.parse(localStorage.getItem("ActiveUserdetail")).mainSuperAdminId;

  const activeUserDetail = localStorage.getItem("ActiveUserdetail");
  const mainSuperAdminId = activeUserDetail
    ? JSON.parse(activeUserDetail).mainSuperAdminId
    : null;

  const [designation, setDesignation] = React.useState("4");
  const env = process.env.NODE_ENV || "development";
  const config: AppConfig = expressConfig[env];
  //
  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true)
  //   const handleClose = () => setOpen(false)
  const handleClose = () => {
    //closing Modal
    setOpen(false);
    //closing the dropdown
    handleCloseDropDown();
    //turning value to false - for not modal appearing for clicking on the openModal
    closeModal();
    // setOpen(false)
  };
  //
  React.useEffect(() => {
    setOpen(check);
  }, [check]);
  //

  const [showCopied, setShowCopied] = React.useState(false);
  const [showChooseRole, setShowChooseRole] = React.useState(false);

  const [roleLink, setRoleLink] = React.useState("");

  React.useEffect(() => {
    console.log("changed roleLink", roleLink);
    if (roleLink !== "") {
      setShowChooseRole(false);
    }
  }, [roleLink]);
  //

  React.useEffect(() => {
    if (showChooseRole === true) {
      setTimeout(() => {
        setShowChooseRole(false);
      }, 2000);
    }
  });

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
  };
  //
  const { classes } = useStyles();

  // interface FilledArrowIconProps {
  //   prop1:string
  // }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //props:FilledArrowIconProps
  function FilledArrowIcon() {
    return (
      <SvgIcon style={{ position: "relative", left: "-150px" }}>
        <path
          d="M16.5 9.5h-9c-.3 0-.5.2-.5.5v2c0 .2.1.4.2.5l3.4 3.4c.4.4 1 .4 1.4 0l3.4-3.4c.1-.1.2-.3.2-.5v-2c0-.3-.2-.5-.5-.5z"
          fill="currentColor"
        />
        <polygon points="7,10.5 12,15.1 17,10.5" fill="currentColor" />
      </SvgIcon>
    );
  }

  const arrowStyles = {
    position: "relative",
    top: "-2px",
    color: "#B7B7B7",
    fontSize: "19px",
  };

  const [futureTime, setFutureTime] = React.useState("");

  React.useEffect(() => {
    const currentUStime = new Date().toLocaleString("en-US", {
      timeZone: "America/New_York",
      hour12: false,
    });
    const currentUStimeFormat = new Date(currentUStime);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const localFutureTime = `${currentUStimeFormat.getDate() + 6} ${
      monthNames[currentUStimeFormat.getMonth()]
    } ${currentUStimeFormat.getFullYear()}`;
    //
    setFutureTime(localFutureTime);
    //
  });

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      {/* <span>Open Modal</span> */}
      <span>Shareable invite link</span>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        onClick={(event) => {
          event.stopPropagation();
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                width: "143px",
                height: "21px",
                fontWeight: "500",
                fontFamily: "poppins",
                fontSize: "14px",
                lineHeight: "21px",
                color: "#272525",
                marginTop: "10px",
                marginLeft: "20px",
              }}
            >
              Shareable invite link
            </Box>
            <span onClick={handleClose} style={{ height: "30px" }}>
              <CloseIcon
                style={{
                  // height: '10.8px',
                  // width: '10.8px',
                  height: "18.8px",
                  width: "18.8px",
                  color: "#B9B9B9",
                  marginTop: "13.61px",
                  marginRight: "13.6px",
                  cursor: "pointer",
                }}
              />
            </span>
          </Box>
          <Divider
            sx={{ width: "600px", marginTop: "10px", color: "#E8E8E8" }}
          />
          <Typography
            sx={{
              width: "349px",
              height: "18px",
              fontFamily: "poppins",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#272525",
              margin: "20px 20px 0px 20px",
            }}
            variant="body1"
          >
            Anyone can use the link below to join your team at Kuikwit.
          </Typography>

          <form onSubmit={handleSubmit}>
            <Box sx={{ padding: "10px 20px 0px 20px" }}>
              <div style={{ display: "flex", marginBottom: "0.5rem" }}>
                <SingleInput
                  inputId="invite-link"
                  isLabel={false}
                  disabled={true}
                  value={`${config.SHAREABLEINVITELINK}${
                    roleLink === "Super Admin"
                      ? 4
                      : roleLink === "Manager"
                      ? 3
                      : 14
                  }/${mainSuperAdminId}/asdk343ui3lkdjf34934jdklfje94i`}
                />

                <Select
                  className={classes.selectInput}
                  IconComponent={FilledArrowIcon}
                  sx={{
                    "& legend": {
                      width: 0,
                    },
                  }}
                  label="Gender"
                  value={roleLink}
                  onChange={(event) => setRoleLink(event.target.value)}
                  fullWidth
                  // margin="normal"
                  displayEmpty
                  inputProps={{
                    disableUnderline: true,
                    style: { backgroundColor: "grey", borderBottom: "none" },
                  }} //
                  disableUnderline
                >
                  <MenuItem
                    value=""
                    disabled
                    className={classes.selectMenuItem}
                    sx={{
                      display: "none",
                    }}
                  >
                    {/* select anyone... */}
                    <span style={{ position: "relative", left: "-8px" }}>
                      select anyone...
                    </span>
                  </MenuItem>
                  <MenuItem
                    value="Super Admin"
                    className={classes.selectMenuItem}
                  >
                    Super Admin
                  </MenuItem>
                  <MenuItem value="Manager" className={classes.selectMenuItem}>
                    Manager
                  </MenuItem>
                  <MenuItem value="Agent" className={classes.selectMenuItem}>
                    Agent
                  </MenuItem>
                </Select>

                {/* showCopied */}
                {!showCopied ? (
                  <Button
                    onClick={() => {
                      // const inviteLink = document.getElementById("invite-link")
                      // window.navigator.clipboard.writeText(inviteLink.value)
                      // setShowCopied(true)
                      const inviteLink = document.getElementById(
                        "invite-link"
                      ) as HTMLInputElement;
                      if (roleLink !== "") {
                        if (inviteLink) {
                          window.navigator.clipboard.writeText(
                            inviteLink.value
                          );
                          setShowCopied(true);
                        }
                      } else {
                        setShowChooseRole(true);
                      }
                    }}
                    sx={{
                      width: "75px",
                      // height: '30px',
                      height: "38px",
                      backgroundColor: "#F4F4F4",
                      borderLeft: "1px solid #E8E8E8",
                      borderRadius: "0px 3px 3px 0px",
                      color: "#777777",
                      fontFamily: "poppins",
                      fontSize: "10px",
                      lineHeight: "15px",
                      fontWeight: "400",
                      marginLeft: "10px",
                    }}
                  >
                    Copy Link
                  </Button>
                ) : (
                  <Button
                    sx={{
                      width: "75px",
                      // height: '30px',
                      height: "38px",
                      backgroundColor: "#22AA61",
                      borderLeft: "1px solid #E8E8E8",
                      borderRadius: "0px 3px 3px 0px",
                      fontFamily: "poppins",
                      fontWeight: "500",
                      fontSize: "10px",
                      color: "#FFFFFF",
                      lineHeight: "15px",
                      marginLeft: "10px",
                      //
                      "&:hover": {
                        backgroundColor: "#22AA61",
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    Copied!
                  </Button>
                )}
              </div>

              {showChooseRole && (
                <Typography
                  className={classes.errorStyle}
                  sx={{
                    color: "red",
                    marginTop: "5px",
                  }}
                  variant="body1"
                >
                  Select role before sharing link
                </Typography>
              )}

              <Typography
                sx={{
                  // width: '420px',
                  width: "100%",
                  height: "15px",

                  fontFamily: "poppins",
                  fontWeight: "400",
                  // fontSize: '10px',
                  // lineHeight: '15px',
                  fontSize: "12px",
                  lineHeight: "17px",
                  color: "#838383",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
                variant="body1"
              >
                For security reason, this link will expire in 6 days (
                {futureTime}).{" "}
                <span
                  style={{
                    color: "#4D1277",
                    cursor: "pointer",
                    fontWeight: "500",
                    fontFamily: "poppins",
                    fontSize: "12px",
                    lineHeight: "17px",
                  }}
                >
                  Generate new link
                </span>
              </Typography>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default KeepMountedModal;
