import React, { useState, useEffect } from "react";
//
import { gql, useMutation } from "@apollo/client";
// import { useNavigate } from "react-router-dom";
//
import { useSnackbar } from "notistack";
//
//
import Box from "@mui/material/Box/Box";
import useStyles from "./Styles";
import FormControlLabel from "@mui/material/FormControlLabel";

import SignUpTop from "../shared/atom/signUpTop/SignUpTop";
import Heading from "../shared/atom/heading/Heading";
import { Text } from "../shared/atom/text/Text";
import KuikTextField from "../shared/atom/kuikTextField/TextField";
import AuthButton from "../shared/atom/button/AuthButton";
import ThirdPartyLoginButton from "../shared/atom/thirdPartyLoginButton/ThirdPartyLoginButton";

import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
// import { Grid } from '@mui/material'
import { useTheme } from "@mui/system";
//
import FormHelperText from "@mui/material/FormHelperText";
import { useFormik } from "formik";
import * as Yup from "yup";
//
import { OutlinedInput, Button } from "@mui/material";
//
import { useHistory, useParams } from "react-router-dom";

const joinUserMutation = gql`
  mutation joinUser(
    $username: String!
    $password: String!
    $email: String!
    $number: String
    $designationId: ID!
    $inviteSuperAdminId: ID!
  ) {
    joinUser(
      username: $username
      password: $password
      email: $email
      number: $number
      designationId: $designationId
      inviteSuperAdminId: $inviteSuperAdminId
    ) {
      success
      error
    }
  }
`;

interface UserInterface {
  email: string;
  designation: string;
  token: string;
}
//{email,designation,token}

const SignUpRightContainer: React.FC<UserInterface> = ({ token }) => {
  // console.log("props in inviteAgent",props)
  const [emailValue,setEmailValue] = useState<string>("");
  const [emailForConfirm,setEmailForConfirm] = useState<string>("");
  const { email, designation, superAdminId } = useParams();
  console.log("inviteagent params:",email, designation, superAdminId)
  useEffect(()=>{
    if(email != null){
      setEmailValue(email)
    }
  },[email])
  console.log("params data", email, designation, superAdminId);
  console.log("props token", token);
  //
  const history = useHistory();
  //
  const { enqueueSnackbar } = useSnackbar();

  const { classes } = useStyles();
  const theme = useTheme();

  ///////

  interface FormValues {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
    check: boolean;
    number: string;
  }

  const [initialFormikValues, setInitialFormikValues] = useState<FormValues>({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    check: true,
    number: "",
  });

  useEffect(() => {
    formik.setValues({
      name: "",
      email: email,
      password: "",
      confirmPassword: "",
      check: true,
      number: "",
    });
  }, [email]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Please confirm your password"),
    check: Yup.boolean().oneOf(
      [true],
      "You must accept the terms and conditions."
    ),
    number: Yup.string().min(11, "Number must be at least 11 digits").max(11, "Number must be at least 11 digits"),
  });

  const updatedHandleSubmit = async (
    values: FormValues,
    formikHelpers: any
  ) => {
    try {
      formikHelpers.setSubmitting(false);
      console.log("values upon", values);
      //
      setEmailForConfirm(emailValue === "" ? values.email : emailValue)
      console.log("checking emailvalue",emailValue === "" ? values.email : emailValue)
      //
      await joinUser({
        variables: {
          username: values.name,
          password: values.confirmPassword,
          email: emailValue === "" ? values.email : emailValue,
          designationId: designation,
          inviteSuperAdminId: superAdminId,
          number: String(values.number),
        },
      });
      //
      //
    } catch (error) {
      formikHelpers.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: initialFormikValues,
    validationSchema: validationSchema,
    onSubmit: updatedHandleSubmit,
  });

  ///////

  const fieldStyle = {
    root: {
      width: "180px",
    },
    // width: '260px',
    width: "100%",
    height: "40px",
    border: "0.0px solid #E8E8E8",
    borderRadius: "3px",
    padding: "6px 10px 6px 10px",
    marginTop: "10px",
    marginBottom: "0px",
    //
    [theme.breakpoints.down("lg")]: {
      width: "180px",
    },
  };
  /////

  const [
    joinUser,
    {
      loading: joinUserMutationLoading,
      error: joinUserMutationError,
      data: joinUserMutationResult,
    },
  ] = useMutation(joinUserMutation);

  useEffect(() => {
    console.log("erorr", joinUserMutationError);
    if (joinUserMutationError) {
      joinUserMutationError.graphQLErrors.map(({ message }, i) => {
        enqueueSnackbar(message, { variant: "error" });
      });
    }
  }, [joinUserMutationError]);

  useEffect(() => {
    console.log("result", joinUserMutationResult);
    if (joinUserMutationResult && joinUserMutationResult.joinUser) {
      if (joinUserMutationResult.joinUser.success) {
        enqueueSnackbar("User added successfully.", { variant: "success" });
        // setIsSubmittedSuccess("false");

        console.log("emailValue before going to confirm",emailForConfirm)
        history.push({
          pathname: '/confirm',
          state: {
            email: emailForConfirm,
          },
        })

      } else {
        enqueueSnackbar(joinUserMutationResult.joinUser.error, {
          variant: "error",
        });
      }
    }
  }, [joinUserMutationResult]);

  return (
    <>
      <Box className={classes.root}>
        <SignUpTop />
        <Heading heading="Hey, welcome To Kuikwit" />
        <Text text="Join the network today by entering just a few details" />
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          className={classes.form}
        >
          <Box sx={{ maxWidth: "300px", width: "100%" }}>
            <OutlinedInput
              className={classes.outlineName}
              style={fieldStyle}
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              //
              placeholder="Enter your full name"
              inputProps={{
                style: {
                  width: "100%",
                  height: "40px",
                  fontWeight: "400",
                  fontFamily: "poppins",
                  fontSize: "16px",
                  lineHeight: "23px",
                  color: "#272525",
                  padding: "0",
                  //
                  [theme.breakpoints.down("lg")]: {
                    width: "180px",
                    backgroundColor: "black",
                  },
                }
              }}
            />

            {formik.touched.name && formik.errors.name && (
              <FormHelperText
                style={{
                  fontWeight: "400",
                  fontFamily: "poppins",
                  fontSize: "12px",
                  lineHeight: "18px",
                  width: "100%",
                }}
                error
              >
                {formik.errors.name}
              </FormHelperText>
            )}

            <OutlinedInput
            disabled = {emailValue === "" ? false : true}
              className={classes.outlineName}
              style={fieldStyle}
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              //
              placeholder="Enter your email address"
              inputProps={{
                style: {
                  width: "100%",
                  height: "40px",
                  fontWeight: "400",
                  fontFamily: "poppins",
                  fontSize: "16px",
                  lineHeight: "23px",
                  color: "#272525",
                  padding: "0",
                  //
                  [theme.breakpoints.down("lg")]: {
                    width: "180px",
                    backgroundColor: "black",
                  },
                },
              }}
            />

            {formik.touched.email && formik.errors.email && (
              <FormHelperText
                style={{
                  fontWeight: "400",
                  fontFamily: "poppins",
                  fontSize: "12px",
                  lineHeight: "18px",
                  width: "100%",
                }}
                error
              >
                {formik.errors.email}
              </FormHelperText>
            )}

            <OutlinedInput
              className={classes.outlineName}
              style={fieldStyle}
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              //
              placeholder="Enter your password"
              inputProps={{
                style: {
                  width: "100%",
                  height: "40px",
                  fontWeight: "400",
                  fontFamily: "poppins",
                  fontSize: "16px",
                  lineHeight: "23px",
                  color: "#272525",
                  padding: "0",
                  //
                  [theme.breakpoints.down("lg")]: {
                    width: "180px",
                    backgroundColor: "black",
                  },
                },
              }}
            />

            {formik.touched.password && formik.errors.password && (
              <FormHelperText
                style={{
                  fontWeight: "400",
                  fontFamily: "poppins",
                  fontSize: "12px",
                  lineHeight: "18px",
                  width: "100%",
                }}
                error
              >
                {formik.errors.password}
              </FormHelperText>
            )}

            <OutlinedInput
              className={classes.outlineName}
              style={fieldStyle}
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              //
              placeholder="Confirm your password"
              inputProps={{
                style: {
                  width: "100%",
                  height: "40px",
                  fontWeight: "400",
                  fontFamily: "poppins",
                  fontSize: "16px",
                  lineHeight: "23px",
                  color: "#272525",
                  padding: "0",
                  //
                  [theme.breakpoints.down("lg")]: {
                    width: "180px",
                    backgroundColor: "black",
                  },
                },
              }}
            />

            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <FormHelperText
                  style={{
                    fontWeight: "400",
                    fontFamily: "poppins",
                    fontSize: "12px",
                    lineHeight: "18px",
                    width: "100%",
                  }}
                  error
                >
                  {formik.errors.confirmPassword}
                </FormHelperText>
              )}

            <OutlinedInput
              className={classes.outlineName}
              style={fieldStyle}
              name="number"
              type="number"
              value={formik.values.number}
              onChange={formik.handleChange}
              error={formik.touched.number && Boolean(formik.errors.number)}
              //
              placeholder="Enter your contact number"
              inputProps={{
                style: {
                  width: "100%",
                  height: "40px",
                  fontWeight: "400",
                  fontFamily: "poppins",
                  fontSize: "16px",
                  lineHeight: "23px",
                  color: "#272525",
                  padding: "0",
                  //
                  [theme.breakpoints.down("lg")]: {
                    width: "180px",
                    backgroundColor: "black",
                  },
                },
              }}
            />

            {formik.touched.number && formik.errors.number && (
              <FormHelperText
                style={{
                  fontWeight: "400",
                  fontFamily: "poppins",
                  fontSize: "12px",
                  lineHeight: "18px",
                  width: "100%",
                }}
                error
              >
                {formik.errors.number}
              </FormHelperText>
            )}

            <AuthButton text="Join" loading={joinUserMutationLoading} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SignUpRightContainer;
