import { makeStyles } from 'tss-react/mui'
import theme from '../style/theme'

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    // height: 'auto',
    flexDirection: 'column',
    boxSizing: 'border-box',
    background: 'rgba(127, 63, 152, 0.05)',
    padding: '35px',
    // backgroundColor:"pink",
    //
    width: '100%',
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    flexDirection: 'column',
    // backgroundColor:"aqua",
    //
    minWidth: '100%', // default maximum width
    [theme.breakpoints.down('sm')]: {
      maxWidth: '400px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },


  label: {
    //
    margin: "0px !important",
    //
    fontSize: '11px',
    //
    [theme.breakpoints.up('md')]: {
      width: '295px',
      // backgroundColor:"purple",
      //
      '& .MuiLink-root': {
        WebkitAlignItems: 'flex-end',
        textDecoration: 'none',
        marginRight: 25,
      },
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '10px',
      // backgroundColor:"blue",
      //
      '& .MuiLink-root': {
        WebkitAlignItems: 'flex-end',
        textDecoration: 'none',
        marginRight: 0,
      },
    },
    //laptop
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    //extra large
    [theme.breakpoints.up('xl')]: {
      width: '18.5vw',
      // fontSize: '1.375rem',
    },
    //
    //
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    lineHeight: '15px',
    fontWeight: 450,
  },

  terms: {
    //
    margin: "0px !important",
    //
    maxWidth: '300px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiSvgIcon-root': { fontSize: 20 },
    //
    //extra large
    [theme.breakpoints.up('xl')]: {
      width: '100%',
      maxWidth: '100%',
      // backgroundColor: 'aqua',
    },
    //
  },

  terms1: {
    //
    marginBottom: "0px",
    [theme.breakpoints.up('md')]: {
      width: '100px',
      // backgroundColor:"green"
    },
    // fontSize: "0.875rem",
    [theme.breakpoints.down('sm')]: {
      // marginTop:"1.25vh",
      minWidth: '75px',
      // backgroundColor:"red"
    },
    //extra large
    [theme.breakpoints.up('xl')]: {
      width: '50%',
      // backgroundColor:"green"
    },
  },

  terms2: {
    //
    [theme.breakpoints.up('md')]: {
      marginLeft: '0px',
      // backgroundColor:"grey"
    },
    // fontSize: "0.875rem",
    [theme.breakpoints.down('sm')]: {
      marginLeft: '25px',
      // marginTop:"1.25vh",
      minWidth: '85px',
      // backgroundColor:"yellow"
    },
    //laptop
    [theme.breakpoints.down('lg')]: {
      marginLeft: '35px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '3px',
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: '1px',
    },
  },

  wrapup: {
    minWidth: '100%', // default maximum width
    [theme.breakpoints.down('sm')]: {
      maxWidth: '400px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },

  divider: {
    width: '100%',
    alignItems: 'center',
    color: '#B7B7B7',
  },
  dividerText: {
    color: theme.palette.text.secondary,
  },
  //
  footer: {
    //
    width: "100% !important",
    display: "flex",
    justifyContent: "center",
    marginTop: "24px",
    // marginBottom:"20px",
    //
    // display: 'flex',
    // bottom: 0,
    // position: 'fixed',
    '& .MuiTypography-root': {
      fontSize: '12px',
      color: theme.palette.text.primary,
      fontWeight: 500,
      lineHeight: '18px',
      //
      [theme.breakpoints.up('xl')]: {
        // fontSize: '1.375rem',
        textAlign: 'center',
        // width: '35% !impotant',
        // paddingLeft: '0px',
        // backgroundColor: 'black',
      },
      [theme.breakpoints.down('sm')]: {
        // textAlign: 'center',
        // width: '65%',
      },
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        width: '65%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '100%',
        textAlign: 'center',
        // paddingLeft: '25px',
      },

      //
    },
    '& .MuiLink-root': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
    //
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
    },
    // [theme.breakpoints.up('lg')]: {
    //   width: '20% !important',
    //   textAlign: 'center',
    // },
    [theme.breakpoints.up('xl')]: {
      width: '19%',
      textAlign: 'center',
    },
    //
  },
}))

export default useStyles

//  maxWidth: '300px',

// "@media (max-width: 600px)": {
//   display:"block",
//   backgroundColor:"blue"
// },
// "@media (min-width: 600px) and (max-width: 960px)": {
//   display:"block",
//   backgroundColor:"blue"
// },
// "@media (min-width: 960px)": {
//   display:"flex",
//   backgroundColor:"pink"
// },
