
import React, { useEffect, useState } from "react";
// import includes from "./includes";
import { connect } from "react-redux";
import { setChatBoxFacebookIDsWithProfileDetails } from "../../store/actions/ChatBoxActions";
import {
    List,
    Box,
    ListItem,
    ListItemAvatar, Avatar,
    ListItemText, Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const FacebookTypographyForRightChatCompHeader = (props) => {

    // const [pageName, setPageName] = useState("");
    // const [customerName, setCustomerName] = useState("");
    const { item } = props;
    // const includesObj = new includes();

    // useEffect(() => {
    //     if (!item.customername) {
    //         if (
    //             props.chatBoxFacebookIDsWithProfileDetails.find(
    //                 (curr) => curr.id == item.pageId
    //             )
    //         ) {
    //             setPageName(
    //                 props.chatBoxFacebookIDsWithProfileDetails.find(
    //                     (curr) => curr.id == item.pageId
    //                 ).name
    //             );
    //         } else {
    //             getPageData();
    //         }

    //         if (
    //             props.chatBoxFacebookIDsWithProfileDetails.find(
    //                 (curr) => curr.id == item.customerId
    //             )
    //         ) {
    //             setCustomerName(
    //                 props.chatBoxFacebookIDsWithProfileDetails.find(
    //                     (curr) => curr.id == item?.customerId
    //                 ).name
    //             );
    //         } else {
    //             getCustomerData();
    //         }
    //     }
    // }, [item]);

    // useEffect(() => {
    //     setCustomerName(item.customername)

    // }, [item])

    // const getPageData = () => {
    //     includesObj.resolvePageInfo(
    //         item?.pageId,
    //         props.chatBoxFacebookIDsWithProfileDetails,
    //         props.setChatBoxFacebookIDsWithProfileDetails,
    //         pageCallBack,
    //         props.authPagesData
    //     );
    // };
    // const getCustomerData = () => {
    //     includesObj.resolveClientInfo(
    //         item?.customerId,
    //         item?.pageId,
    //         props.chatBoxFacebookIDsWithProfileDetails,
    //         props.setChatBoxFacebookIDsWithProfileDetails,
    //         customerCallBack,
    //         props.authPagesData
    //     );
    // };
    // const pageCallBack = (result) => {
    //     if (result) {
    //         setPageName(result.name);
    //         props.pageNameChange && props.pageNameChange(result.name);
    //     }
    // };
    // const customerCallBack = (result) => {
    //     if (result) {
    //         setCustomerName(result.name);
    //         props.customerNameChange && props.customerNameChange(result.name);
    //     }
    // };
    const activeUserDetail = localStorage.getItem("ActiveUserdetail");

    const panelType = activeUserDetail
        ? JSON.parse(activeUserDetail).paneltype
        : null;


    return (
        <>
            {!props.special && !props.pageName && (
                <>
                    <List
                        sx={{
                            width: '100%',
                            py: 0,
                            mt: 1,
                            '& .MuiListItemText-root': {
                                top: 5,
                            },
                            '& .MuiListItemSecondaryAction-root': {
                                top: 10,
                                right: 5,
                            },
                            '& .MuiDivider-root': {
                                my: 0,
                            },
                            '& .list-container': {
                                pl: 6,
                            },
                        }}>
                        <ListItem sx={{
                            marginLeft: 1,
                            marginBottom: 1
                        }} alignItems="center">
                            <ListItemAvatar>



                                <Avatar

                                    src={item.customerPicture || ""}
                                ></Avatar>


                            </ListItemAvatar>

                            <ListItemText primary={item.customername ?
                                (
                                    <Typography sx={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: 'black'
                                    }}>{item.customername}
                                    </Typography>
                                ) : (
                                    // <Typography sx={{
                                    //     fontWeight: 500,
                                    //     fontSize: '14px',
                                    //     lineHeight: '18px',
                                    //     color: 'black'
                                    // }}>{customerName}
                                    // </Typography>
                                    <Typography sx={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: 'black'
                                    }}>Customer Name
                                    </Typography>
                                )
                            } secondary={

                                panelType === 'SUPERADMIN' &&
                                <>
                                    <Box sx={{
                                        display: 'flex',
                                        width: 80,
                                        cursor: 'pointer',
                                    }}
                                        onClick={() => {
                                            props.setModalShow(true);
                                        }}>
                                        <Typography sx={{
                                            fontSize: '10px',
                                            alignItems: 'center',
                                        }}
                                        >
                                            Transfer to...
                                        </Typography>
                                        <ArrowDropDownIcon sx={{
                                            fontSize: '15px',
                                        }}

                                        ></ArrowDropDownIcon >
                                    </Box>
                                </>
                            } />
                        </ListItem>
                    </List>
                </>
            )}
            {/* {props.special && customerName}
            {props.pageName && (
                <Typography className={props.className}>{`${pageName}`}</Typography>
            )} */}

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state.ChatBoxReducer,
        ...state.AuthReducer,
    };
};
export default connect(mapStateToProps, {
    setChatBoxFacebookIDsWithProfileDetails,
})(FacebookTypographyForRightChatCompHeader);
