import { makeStyles } from 'tss-react/mui'

const useStyle = makeStyles()(() => ({
  avtarStyle: {
    height: '90px',
    width: '90px',
  },
  cardContent: {
    paddingTop: '0px'
  },
  text: {
    width: '691px',
    fontWeight: "400",
    fontSize: '14px',
    lineHeight: '21px',
    marginBottom: '5px',
    color: '#838383',
    marginLeft: '5px'
  },
  heading: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: '5px'
  },
  fbButton: {
    width: '159px',
    height: '32px',
    padding: '0px',
    fontWeight:"500",
    fontSize: '12px',
    lineHeight:"18px",
    borderRadius: '3px'
  },
  emojies: {
    marginRight: '5px',
    height: '22px',
    width: '22px',
    paddingTop: '3px'
  },
  tickMark: {
    color: '#4D1277',
    marginRight: '5px',
    fontSize: '14px'
  },
  howToConnect: {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight:"18px",
    color:"#272525",
    marginLeft: '5px',
    marginBottom: '5px',
  },
  //
  pageFirstChar: {
    display: 'inline-block',
    width: '40px',
    height: '40px',
    fontWeight: '900',
    fontSize: '28px',
    textAlign: 'center',
    marginRight: '20px'
  },
  pageFirstCharConnectedColor: {
    color: '#2AD19B',
    background: '#D2F8EB',
  },
  pageName: {
    marginLeft:"10px",
    color:'#838383',
    fontWeight:'400',
    fontSize:'14px',
    lineHeight:"21px"
  },
  buttonStyleConnected: {
    color: '#22AA61',
    backgroundColor: '#ecf7f3',
    borderColor: '#22AA61',
    width: '105px',
    textTransform: 'none',
    padding: '2px',
    fontSize: '10px',
  },
  //
  loadingCircularProgress: {
    margin: "auto",
    display: "block",
    marginTop: 20,
    color: "#55A530",
  },
  settingFacebookMain: {
    width: "95%",
    margin: "15px auto",
  },
  settingFacebookInner: {
    width: "65%",
  },
  settingFacebookHeadingContainer: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid lightgrey",
    paddingBottom: "5px",
    marginBottom: "10px",
  },
  settingFacebookHeading: {
    fontFamily: "poppins",
    fontSize: "18px",
    lineHeight:"27px",
    fontWeight: "500",
    margin: 0,
  },
  settingFacebookMainDiv: {
    margin: "15px 0px",
    border: "1px solid lightgrey",
    padding: "10px",
    display: "grid",
    gridTemplateColumns: "20% auto",
    gridGap: "15px",
  },
  settingFacebookMainDivLeftImg: {
    width: "110px",
    height: "110px",
  },
  settingFacebookMainDivRight: {},
  settingFacebookMainDivRightButton: {
    border: "none",
    background: "#56a530",
    color: "white",
    fontFamily: "poppins",
    fontSize: "12px",
    padding: "6px 15px",
    borderRadius: "2px",
  },
  settingFacebookMainDivPara: {
    fontFamily: "poppins",
    fontSize: "13px",
    margin: 0,
  },
  settingFacebookMainHowItWork: {
    marginTop: 20,
  },
  settingFacebookMainDivHowPara: {
    fontFamily: "poppins",
    fontSize: "13px",
  },
  settingFacebookMainDivConnectPara: {
    fontFamily: "poppins",
    fontSize: "13px",
    marginBottom: "0px",
  },
  settingFacebookFBUserName: {
    width: "100%",
    padding: "7px 30px",
    background: "#eff0f2",
    marginBottom: "20px",
  },
  settingFacebookFBPara: {
    fontFamily: "poppins",
    fontSize: "13px",
  },
  settingFacebookPagesListHeader: {
    width: "100%",
    padding: "10px 17px",
    borderBottom: "1px solid rgba(119,119,119,.5)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: "10px",
  },
  settingFacebookPagesListLeft: {
    fontFamily: "poppins",
    fontSize: "13px",
    color: "#777777",
  },
  settingFacebookFBListContainer: {
    width: "100%",
    padding: "10px 17px",
    borderBottom: "1px solid rgba(119,119,119,.5)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  settingFacebookFBListRightBtn: {
    padding: "4px 8px",
    marginRight: "5px",
  },
  settingFacebookFBListLeft: {
    display: "flex",
    alignItems: "center",
  },
  settingFacebookFBListLeftText: {
    fontFamily: "poppins",
    fontSize: "13.5px",
    marginLeft: "5px",
  }
}));


export default useStyle

