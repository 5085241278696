import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme) => ({
  text: {
    fontSize: "14px",
    wordWrap: "break-word",
    fontWeight: 500,
    whiteSpace: "nowrap",
    wrap: true,
    maxWidth: 100,
    [theme.breakpoints.down("md")]: {
      // display: "inline-block",
      fontSize: "12px",
    },
  },
  textButton: {
    height: "21px",
    width: "45px",
    fontSize: "12px",
    fontWeight: 400,
    [theme.breakpoints.down("lg")]: {
      // display: "inline-block",
      fontSize: "10px",
    },
  },
  save: {
    height: "21px",
    width: "45px",
    fontSize: "10px",
    fontWeight: 400,
    [theme.breakpoints.down("lg")]: {
      // display: "inline-block",
      // width: '35px',
    },
  },
}));
export default useStyles;
