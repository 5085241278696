import { makeStyles } from 'tss-react/mui'

const useStyle = makeStyles()(() => ({
  listStyle: {
    padding: '10px 0px 10px 10px',
    marginBottom: '10px',
    width: '700px',
    borderBottom: '0.5px solid #DFE2E5',
    display: 'flex',
    justifyContent: 'center',
    //
    backgroundColor: '#FFFFFF',
  },
}))

export default useStyle
