import React, { useEffect, useState } from "react";
import twilioLogo from "../../../../../assets/setting/twilio.svg";
import mobileLogo from "../../../../../assets/setting/mobile.svg";
import addLogo from "../../../../../assets/setting/plus.svg";
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { Button } from "@mui/material";
import useStyle from "./Style";

interface typesForPhoneNumber{
  active : boolean
  phoneNumber: string
  __typename:string
}
interface Props {
  phnNumber: typesForPhoneNumber[]
  setPhnNumber: React.Dispatch<React.SetStateAction<typesForPhoneNumber[]|string>>
  data: typesForPhoneNumber
  accountSID: string 
  authToken: string
}

const TwilioBtn : React.FC<Props> = ({
  accountSID,
  authToken,
  data,
  phnNumber,
  setPhnNumber
}) => {
  const { classes} = useStyle();
  const { enqueueSnackbar } = useSnackbar();
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState<string | null>(null);

  const ConnectTwilioPhoneNumberQuery = gql`
    mutation connectTwilioPhoneNumber(
      $accountSID: String!
      $authToken: String!
      $phoneNumber: String!
    ) {
      connectTwilioPhoneNumber(
        accountSID: $accountSID
        authToken: $authToken
        phoneNumber: $phoneNumber
      ) {
        success
        error
      }
    }
  `;
  let [
    connectTwilioPhoneNumber,
    {
      loading: ConnectTwilioPhoneNumberQueryLoading,
      error: ConnectTwilioPhoneNumberQueryError,
      data: ConnectTwilioPhoneNumberQueryResult,
    },
  ] = useMutation(ConnectTwilioPhoneNumberQuery);

  useEffect(() => {
    if (ConnectTwilioPhoneNumberQueryError) {
      ConnectTwilioPhoneNumberQueryError.graphQLErrors.map(({ message }, i) => {
        enqueueSnackbar(message, { variant: "error" });
      });
    }
  }, [ConnectTwilioPhoneNumberQueryError]);

  useEffect(() => {
    if (
      ConnectTwilioPhoneNumberQueryResult &&
      ConnectTwilioPhoneNumberQueryResult.connectTwilioPhoneNumber
    ) {
      if (
        ConnectTwilioPhoneNumberQueryResult.connectTwilioPhoneNumber.success
      ) {
        const copyOfPhnNumber: typesForPhoneNumber[] = phnNumber;

        const anothercopy : any = Array.isArray(copyOfPhnNumber) && copyOfPhnNumber.map((curr:typesForPhoneNumber) => {
          return { ...curr, active: false, __typename:"twilioPhone"};
        });

        console.log("anothercopy", anothercopy);

        const objIndex: number | boolean = Array.isArray(anothercopy) && anothercopy.findIndex(
          (obj) => obj.phoneNumber === currentPhoneNumber
        );
        
        if(objIndex !== -1 && objIndex !== false){
          anothercopy[objIndex].active = true;
        }

        setPhnNumber(anothercopy);
      } else {
        enqueueSnackbar(
          ConnectTwilioPhoneNumberQueryResult.connectTwilioPhoneNumber.error,
          {
            variant: "error",
          }
        );
      }
    }
  }, [ConnectTwilioPhoneNumberQueryResult]);


  const DiscountTwilioPhoneNumberQuery = gql`
    mutation disconnectTwilioPhoneNumber(
      $accountSID: String!
      $authToken: String!
      $phoneNumber: String!
    ) {
      disconnectTwilioPhoneNumber(
        accountSID: $accountSID
        authToken: $authToken
        phoneNumber: $phoneNumber
      ) {
        success
        error
      }
    }
  `;
  let [
    disconnectTwilioPhoneNumber,
    {
      loading: DiscountTwilioPhoneNumberQueryLoading,
      error: DiscountTwilioPhoneNumberQueryError,
      data: DiscountTwilioPhoneNumberQueryResult,
    },
  ] = useMutation(DiscountTwilioPhoneNumberQuery);

  useEffect(() => {
    if (DiscountTwilioPhoneNumberQueryError) {
      DiscountTwilioPhoneNumberQueryError.graphQLErrors.map(
        ({ message }, i) => {
          enqueueSnackbar(message, { variant: "error" });
        }
      );
    }
  }, [DiscountTwilioPhoneNumberQueryError]);

  useEffect(() => {
    if (
      DiscountTwilioPhoneNumberQueryResult &&
      DiscountTwilioPhoneNumberQueryResult.disconnectTwilioPhoneNumber
    ) {
      if (
        DiscountTwilioPhoneNumberQueryResult.disconnectTwilioPhoneNumber.success
      ) {
        
        const newArray : any = Array.isArray(phnNumber) && phnNumber.map((curr) =>
          curr.phoneNumber === currentPhoneNumber
            ? {
                ...curr,
                active: false,
              }
            : { ...curr }
        );

        setPhnNumber(newArray);
      } else {
        enqueueSnackbar(
          DiscountTwilioPhoneNumberQueryResult.disconnectTwilioPhoneNumber
            .error,
          {
            variant: "error",
          }
        );
      }
    }
  }, [DiscountTwilioPhoneNumberQueryResult]);

  const disconnectPhoneNumber = (data:typesForPhoneNumber) => {
    setCurrentPhoneNumber(data.phoneNumber);
    disconnectTwilioPhoneNumber({
      variables: {
        accountSID: accountSID,
        authToken: authToken,
        phoneNumber: data.phoneNumber,
      },
    });
  };
  const connectPhoneNumber = (data:typesForPhoneNumber) => {
    connectTwilioPhoneNumber({
      variables: {
        accountSID: accountSID,
        authToken: authToken,
        phoneNumber: data.phoneNumber,
      },
    });
    setCurrentPhoneNumber(data.phoneNumber);
  };

  return (
    <Button 
    style={{height:"32px",fontSize:"12px",lineHeight:"18px",fontWeight:"400"}}
      variant='contained'  
      onClick={() =>
        data.active
          ? disconnectPhoneNumber(data)
          : connectPhoneNumber(data)
      }
      className={data.active ? classes.buttonStyleDisconnected : classes.buttonStyleConnected } 
      sx={{ "&:hover": { backgroundColor: "#22AA61" } }}
    >
      {!data.active ? "Connect" : "Disconnect"}
    </Button>
  );
}

export default TwilioBtn;
