import React, { useState, useReducer, useEffect, useRef } from "react";
import { constants } from "../../../../config/constant";
import User from "../../../icons/user";
import gql from "graphql-tag";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { setAllPagesData } from "../../../../store/actions/AdmindataActions";
import { useDispatch, useSelector } from "react-redux";
import { helpers } from "../../../../utils/newHelper";
import useSnackBar from "../../../../hooks/useSnackBar";
//
import expressConfig from ".././../../../config/express.json";
import axios from "axios";
//
import ControlPointIcon from "@mui/icons-material/ControlPoint";
//
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
//
import { Avatar } from "@mui/material";
//
import fblogo from "../../../../../src/assets/img/fblogo.png";
//
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
//
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
//
//
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Collapse from "@mui/material/Collapse";
//
import { OutlinedInput } from "@mui/material";
//
import useStyles from "./Styles";
//
import FormHelperText from "@mui/material/FormHelperText";
import { useFormik } from "formik";
// import { Formik, FormikHelpers} from 'formik'
import * as Yup from "yup";
//
import Store from "../../../../store/index";

const initialState = {
  error: {},
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case "ON_ERROR":
      return { ...state, error: { ...action.payload } };
    default:
      return state;
  }
}

interface EditDetailProp {
  agentlimitchatassign: number;
  designation: {
    id: string;
    name: string;
    paneltype: string;
    __typename: string;
  };
  email: string;
  id: string;
  jobTitle: string;
  managerId: {
    id: string;
    __typename: string;
  };
  name: string;
  number: string;
  pages: string;
  picture: null | any;
  pseudonym: string;
  status: string;
  username: string;
  __typename: string;
}

interface userdataProp {
  confirmPassword: string;
  email: string;
  firstName?: string;
  jobTitle?: string;
  lastName: string;
  mobile?: string;
  newPassword: string;
  oldPassword: string;
  pages: string[];
  picture: string | null;
  pseudonym?: string;
  status?: string;
  comments: number;
}

interface pagePropdata {
  accessToken: string;
  id: string;
  name: string;
  pageId: string;
  __typename: string;
}

interface Props {
  selectedUserData: EditDetailProp;
  handleOnProfileUpdate: () => void;
  onClose: () => void;
  handleDelete: (data: any) => void;
  setRightPanelOpened: any;
  setIsEditMode: any;
}

const EditProfileSideBar: React.FC<Props> = ({
  setIsEditMode,
  setRightPanelOpened,
  selectedUserData,
  handleOnProfileUpdate,
  onClose,
  handleDelete,
}) => {
  useEffect(() => {});
  //
  const { getState } = Store;
  const getdata = getState();
  useEffect(() => {});
  //
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatch_ = useDispatch();
  const store = useSelector((store) => store);
  const { success, error } = useSnackBar();
  //
  const env = process.env.NODE_ENV || "development";
  const config = expressConfig[env];
  //

  const [image, setImage] = useState(null);

  const handleSelectImage = async (e: any) => {
    console.log("coming in handleSelectImage", e.target.files[0]);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const data = await axios.post(
      `${config.graphql_domain}:${config.port}/uploads`,
      formData
    );
    setImage(data.data.filePath);
  };


  const [data, setData] = useState<userdataProp>({
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    comments: 0,
    status: "",
    pseudonym: "",
    mobile: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    picture: null,
    pages: [],
  });

  const [deleteData, setDeleteData] = useState<EditDetailProp>();

  useEffect(() => {
    const tempData = { ...data };

    // tempData.firstName = helpers.nameDivider(selectedUserData.name).firstName
    // tempData.lastName = helpers.nameDivider(selectedUserData.name).lastName
console.log("selectedUserData",selectedUserData)

    const nameResult = helpers.nameDivider(selectedUserData.name);
    if (nameResult) {
      tempData.firstName = nameResult.firstName;
      tempData.lastName = nameResult.lastName;
    }
    tempData.email = selectedUserData.email;
    // tempData.mobile = Number(props.selectedUserData.number)
    tempData.mobile = selectedUserData.number;
    tempData.status = selectedUserData.status;
    tempData.comments = selectedUserData?.agentlimitchatassign;
    tempData.pseudonym = selectedUserData.pseudonym;
    // tempData.pages = JSON.parse(selectedUserData.pages);
    // tempData.pages = JSON.parse(selectedUserData.pages)===null ? [] : JSON.parse(selectedUserData.pages);
    tempData.pages = selectedUserData.pages === "" ? [] : JSON.parse(selectedUserData.pages);
    tempData.jobTitle = selectedUserData.designation.paneltype;
    tempData.picture = selectedUserData.picture;

    setData({ ...tempData });
    setDeleteData(selectedUserData);
    //
  }, []);
  const [initialFormikValues, setInitialFormikValues] = useState({
    name: "null name",
    pname: "",
    title: "",
    email: "",
    phone: "",
    oldpass: "",
    newpass: "",
    conpass: "",
    chatlimit: "",
    rolelink: "",
  });
  //

  useEffect(() => {

    formik.setValues({
      name: data.firstName + " " + data.lastName,
      pname: data.pseudonym || "",
      title: data.jobTitle || "",
      email: data.email || "",
      phone: data.mobile || "",
      oldpass: data.oldPassword || "",
      newpass: data.newPassword || "",
      conpass: data.confirmPassword || "",
      // chatlimit: data.comments || '',
      chatlimit: data.comments ? data.comments.toString() : "",
      rolelink: data.status || "",
    });
  }, [data]);
  //
  useEffect(() => {}, [initialFormikValues]);

  // get pages //
  const getPagesApi = gql`
    query getPages($mainSuperAdminId: ID!) {
      pages(mainSuperAdminId: $mainSuperAdminId) {
        id
        name
        pageId
        accesstoken
      }
    }
  `;

  let [
    getPages,
    {
      loading: getPagesQueryLoading,
      error: getPagesQueryError,
      data: getPagesQueryResult,
    },
  ] = useLazyQuery(getPagesApi, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    document.title = "Add/Edit Users";
    window.Object.freeze = function (obj: any) {
      return obj;
    }; //keep_an_eye

    const activeUserDetail = localStorage.getItem("ActiveUserdetail");
    const mainSuperAdminId = activeUserDetail
      ? JSON.parse(activeUserDetail).mainSuperAdminId
      : null;

    if (mainSuperAdminId) {
      getPages({
        variables: {
          // mainSuperAdminId: JSON.parse(localStorage.getItem("ActiveUserdetail")).mainSuperAdminId,
          mainSuperAdminId,
        },
      });
    }
  }, [localStorage.getItem("ActiveUserdetail")]);
  // get pages //

  useEffect(() => {
    if (getPagesQueryResult && getPagesQueryResult.pages) {
      dispatch_(setAllPagesData(getPagesQueryResult.pages));
    }
  }, [getPagesQueryResult]);

  // const constants = {
  //   status: {
  //     ACTIVE: 1,
  //     SUSPENDED: 2,
  //     DELETE: 3,
  //     BLOCKED: 4,
  //   } as { [key: string]: number }
  // };

  const statusKey = data.status;

  const handleUpdatePassword = () => {
    const isValid = checkPasswordValidation(data);
    if (isValid) {
      updatePassword({
        variables: {
          id: selectedUserData.id,
          oldPassword: data.oldPassword,
          password: data.newPassword,
        },
      });
    }
  };

  interface ProfileData {
    firstName: string;
    lastName: string;
    jobTitle: string[];
    mobile: string;
    pseudonym: string;
    email: string;
  }

  interface ValidationErrors {
    firstName?: string;
    lastName?: string;
    jobTitle?: string;
    mobile?: string;
    pseudonym?: string;
    email?: string;
  }


  interface PasswordData {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  }

  interface PasswordError {
    oldPassword?: string;
    newPassword?: string;
    confirmPassword?: string;
    notmatch?: string;
  }

  const checkPasswordValidation = (data: PasswordData) => {
    try {
      // const error = {};
      const error: PasswordError = {};
      if (data.oldPassword.trim() === "") {
        error.oldPassword = "Old password required";
      }
      if (data.newPassword.trim() === "") {
        error.newPassword = "New password required";
      }
      if (data.confirmPassword.trim() === "") {
        error.confirmPassword = "Confirm password required";
      }
      if (data.confirmPassword.trim() !== data.newPassword.trim()) {
        error.notmatch = "Confirm password & New password not match";
      }
      dispatch({ type: "ON_ERROR", payload: error });

      return !Object.keys(error).length;
    } catch (error) {}
  };

  // update user //
  const updateUserMutation = gql`
    mutation UpdateUser(
      $id: ID!
      $username: String!
      $name: String!
      $pseudonym: String
      $picture: String
      $email: String!
      $number: String
      $status: ID!
      $comments: String
      $designationId: ID!
      $managerId: ID
      $settings: String
      $agentlimitchatassign: Int
      $jobTitle: String
      $pages: String
    ) {
      updateuser(
        id: $id
        username: $username
        name: $name
        pseudonym: $pseudonym
        picture: $picture
        jobTitle: $jobTitle
        email: $email
        number: $number
        status: $status
        comments: $comments
        designationId: $designationId
        managerId: $managerId
        settings: $settings
        agentlimitchatassign: $agentlimitchatassign
        pages: $pages
      ) {
        success
        error
      }
    }
  `;
  const [
    updateUser,
    {
      loading: updateMutationLoading,
      error: updateMutationError,
      data: updateMutationResult,
    },
  ] = useMutation(updateUserMutation);
  // update user //

  // update password //
  const updatePasswordMutation = gql`
    mutation updatePassword($id: ID!, $password: String, $oldPassword: String) {
      updatePassword(id: $id, password: $password, oldPassword: $oldPassword) {
        success
        error
        result
      }
    }
  `;

  const [
    updatePassword,
    {
      loading: updatePasswordMutationLoading,
      error: updatePasswordMutationError,
      data: updatePasswordMutationResult,
    },
  ] = useMutation(updatePasswordMutation);

  interface PageProp {
    id: number;
  }

  const handleAddPage = (id: string, flag: boolean) => {
    // const tempData = { ...data };
    const tempData: userdataProp = { ...data };
    if (flag) {
      const isExist = tempData.pages.includes(id);
      // const isExist = tempData.pages.includes(id as any as never)
      if (!isExist) {
        // tempData.pages.push(id)
        tempData.pages.push(id as any as never);
      }
    }
    if (!flag) {
      const index = tempData.pages.findIndex((item) => item === id);
      tempData.pages.splice(index, 1);
    }
    setData(tempData);
    //
  };

  useEffect(() => {
    if (updatePasswordMutationError && updatePasswordMutationError) {
      error("Something went wrong");
    }
  }, [updatePasswordMutationError]);

  useEffect(() => {
    if (updatePasswordMutationResult && updatePasswordMutationResult) {
      success("Invite sent successfully");
    }
  }, [updatePasswordMutationResult]);

  useEffect(() => {
    if (updateMutationError && updateMutationError) {
      error("Something went wrong");
    }
  }, [updateMutationError]);

  useEffect(() => {
    if (updateMutationResult && updateMutationResult) {
      success("Profile updated successfully");
      //calling data to be called again
      handleOnProfileUpdate();
      //closing rightpanel and removing selection of user
      setRightPanelOpened(false);
      setIsEditMode(false);
    }
  }, [updateMutationResult]);

  const myRef = useRef();

  ///////////////////////////////////////////////////////////////////////////
  //
  const theme = useTheme();
  //
  const { classes } = useStyles();
  //
  const [expanded, setExpanded] = useState(false);

  const fieldStyle = {
    root: {
      width: "180px",
    },
    // width: '260px',
    width: "100%",
    height: "30px",
    border: "0.0px solid #E8E8E8",
    borderRadius: "3px",
    padding: "6px 10px 6px 10px",
    marginTop: "0px",
    marginBottom: "0px",
    //
    [theme.breakpoints.down("lg")]: {
      width: "180px",
    },
  };

  const fieldStyle2 = {
    // width: '125px',
    width: "100%",
    height: "30px",
    border: "0.0px solid #E8E8E8",
    borderRadius: "3px",
    padding: "6px 10px 6px 10px",
    marginTop: "0px",
    marginBottom: "0px",
  };

  const fieldStyle3 = {
    // width: '125px',
    width: "100%",
    height: "30px",
    border: "0.0px solid #E8E8E8",
    borderRadius: "3px",
    padding: "6px 10px 6px 10px",
    marginTop: "0px",
    marginBottom: "0px",
  };

  const fieldStylepass = {
    // width: '260px',
    width: "100%",
    height: "30px",
    border: "0.0px solid #E8E8E8",
    borderRadius: "3px",
    padding: "6px 10px 6px 10px",
    marginTop: "5px",
    marginBottom: "0px",
  };

  ///////////////////////////////////////////////////////////////////////////

  // validation schema using Yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("name is required"),
    pname: Yup.string().required("pseudonym is required"),
    title: Yup.string().required("title is required"),
    phone: Yup.string().required("phone is required"),
    chatlimit: Yup.string().required("chat-limit is required"),
    rolelink: Yup.string().required("status is required"),
    email: Yup.string()
      .email("invalid email address")
      .required("email is required"),
  });

  interface FormValuesProps {
    chatlimit?: string;
    email?: string;
    name?: string;
    phone?: string;
    pname?: string;
    rolelink?: string;
    title?: string;
  }



  const updatedHandleSubmit = async (
    values: FormValuesProps,
    formikHelpers: any
  ) => {
    try {
      formikHelpers.setSubmitting(false);
      //////////
      const show = {
        id: selectedUserData.id,
        username: selectedUserData.username,
        name: values.name,
        pseudonym: values.pname,
        // picture: image,
        jobTitle: values.title,
        email: values.email,
        number: values.phone,
        status: values.rolelink,
        agentlimitchatassign: values.chatlimit,
        designationId: selectedUserData.designation.id,
        managerId: selectedUserData.managerId?.id,
        pages: JSON.stringify(data.pages),
      };
      //
      let tdata: userdataProp = { ...data };
      tdata.firstName = values.name;
      tdata.pseudonym = values.pname;
      tdata.jobTitle = values.title;
      tdata.mobile = String(values.phone);
      tdata.status = values.rolelink;
      tdata.comments = Number(values.chatlimit);
      //
      if(image){
        try {
          await updateUser({
            variables: {
              id: selectedUserData.id,
              username: selectedUserData.username,
              name: values.name,
              pseudonym: values.pname,
              picture: image,
              jobTitle: values.title,
              email: values.email,
              number: String(values.phone),
              status:
                constants.status[
                  values.rolelink === "ACTIVE"
                    ? "ACTIVE"
                    : values.rolelink === "BLOCKED"
                    ? "BLOCKED"
                    : "SUSPENDED"
                ],
              agentlimitchatassign: Number(values.chatlimit),
              designationId: selectedUserData.designation.id,
              managerId: selectedUserData.managerId?.id,
              pages: JSON.stringify(data.pages),
            },
          });
        } catch (e) {
          console.log("error:", e);
        }
      }else{
        try {
          await updateUser({
            variables: {
              id: selectedUserData.id,
              username: selectedUserData.username,
              name: values.name,
              pseudonym: values.pname,
              // picture: image,
              jobTitle: values.title,
              email: values.email,
              number: String(values.phone),
              status:
                constants.status[
                  values.rolelink === "ACTIVE"
                    ? "ACTIVE"
                    : values.rolelink === "BLOCKED"
                    ? "BLOCKED"
                    : "SUSPENDED"
                ],
              agentlimitchatassign: Number(values.chatlimit),
              designationId: selectedUserData.designation.id,
              managerId: selectedUserData.managerId?.id,
              pages: JSON.stringify(data.pages),
            },
          });
        } catch (e) {
          console.log("error:", e);
        }
      }

      //////////
    } catch (error) {
      formikHelpers.setSubmitting(false);
    }
  };



  const formik = useFormik({
    initialValues: initialFormikValues,
    validationSchema: validationSchema,
    onSubmit: updatedHandleSubmit,
  });

  //////////////////////////////////////////////
  //Settings for password

  const passwordValidationSchema = Yup.object().shape({
    oldpass: Yup.string()
      .min(3, "password must be at least 3 characters")
      .required("old password is required"),
    newpass: Yup.string()
      .min(3, "password must be at least 3 characters")
      .required("new password is required"),
    conpass: Yup.string()
      .oneOf([Yup.ref("newpass")], "password must match")
      .required("please confirm your password"),
  });

  interface PasswordProps {
    oldpass?: string;
    newpass?: string;
    conpass?: string;
  }

  const passwordHandleSubmit = async (
    values: PasswordProps,
    formikHelpers: any
  ) => {
    try {
      formikHelpers.setSubmitting(false);
      //
      updatePassword({
        variables: {
          id: selectedUserData.id,
          oldPassword: values.oldpass,
          password: values.newpass,
        },
      });
    } catch (error) {
      formikHelpers.setSubmitting(false);
    }
  };

  const formik2 = useFormik({
    initialValues: initialFormikValues,
    validationSchema: passwordValidationSchema,
    onSubmit: passwordHandleSubmit,
  });

  const handleClear = () => {
    formik2.resetForm();
  };

  //////////////////////////////////////////////

  const handleDeleteUser = () => {
    console.log("deleting user...", data);
    if (data) {
      handleDelete(deleteData);
    } else {
      // console.log("no data for deleting...")
    }
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        className={classes.formBox}
      >
        <Box className={classes.profileSection}>
          {/* image section */}
          <Box sx={{ display: "flex",paddingLeft:"26px" }}>
            {image ?
            <Avatar
              alt="John Doe"
              src={image}
              sx={{ height: "80px", width: "80px"}}
            />
            : data.picture ? (
              <Avatar
              alt="John Doe"
              src={data.picture}
              sx={{ height: "80px", width: "80px" }}
            />
            ) : (
              <User
                height={80}
                width={80}
                color={[constants.theme.gray, "black"]}
              />
            )}

            <div
              className="border border-white cursor-pointer"
              style={{
                cursor: "pointer",
                // position: "absolute",
                position: "relative",
                left:"-10px",
                height:"28px",
                zIndex: 10000,
                // margin: "0px 62px",
                borderRadius: "16px",
                backgroundColor: constants.theme.lightestGray,
              }}
            >
              <ControlPointIcon style={{ cursor: "pointer" }}/>
            </div>
            <div
              className=" cursor-pointer"
              style={{
                position: "absolute",
                width: "100px",
                zIndex: 10000,
                opacity: "0",
                margin: "0px 30px",
              }}
            >
              <input
                type="file"
                onChange={handleSelectImage}
                style={{ width: "100%" }}
              />
            </div>
          </Box>

          <Typography variant="subtitle2" className={classes.profileName}>
            {/* Andrew Jones */}
            {data.firstName + " " + data.lastName}
          </Typography>

          <Typography variant="subtitle2" className={classes.profilePseudonym}>
            {/* Walter White */}
            {data.pseudonym}
          </Typography>

          <Typography variant="subtitle2" className={classes.profileRole}>
            {/* Agent */}
            {data.jobTitle}
          </Typography>
        </Box>

        <Typography variant="subtitle2" className={classes.profileInfo}>
          Personal info
        </Typography>

        <Typography variant="subtitle2" className={classes.text1}>
          Full name
        </Typography>

        <OutlinedInput
          className={classes.outlineName}
          style={fieldStyle}
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          //
          placeholder="Enter your full name"
          inputProps={{
            style: {
              // width: '260px',
              width: "100%",
              height: "18px",
              fontWeight: "400",
              fontFamily: "poppins",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#272525",
              padding: "0",
              //
              [theme.breakpoints.down("lg")]: {
                width: "180px",
                backgroundColor: "black",
              },
            },
          }}
        />

        {formik.touched.name && formik.errors.name && (
          <FormHelperText
            style={{
              fontWeight: "400",
              fontFamily: "poppins",
              fontSize: "10px",
              lineHeight: "15px",
            }}
            error
          >
            {formik.errors.name}
          </FormHelperText>
        )}

        <Box className={classes.rowBox}>
          <Box className={classes.rowBox1}>
            <Typography variant="subtitle2" className={classes.text2}>
              Pseudonym
            </Typography>

            <OutlinedInput
              className={classes.outlineName}
              style={fieldStyle2}
              // value={pname}
              // onChange={(event) => setPname(event.target.value)}
              //
              name="pname"
              value={formik.values.pname}
              onChange={formik.handleChange}
              error={formik.touched.pname && Boolean(formik.errors.pname)}
              //
              fullWidth
              placeholder="Enter Pseudonym"
              inputProps={{
                //
                classes: {
                  root: classes.responsiveness,
                },
                //
                disableUnderline: true,
                style: {
                  // width: '125px',
                  width: "100%",
                  height: "18px",
                  fontWeight: "400",
                  fontFamily: "poppins",
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#272525",
                  padding: "0",
                },
              }}
            />
            {formik.touched.pname && formik.errors.pname && (
              <FormHelperText
                style={{
                  fontWeight: "400",
                  fontFamily: "poppins",
                  fontSize: "10px",
                  lineHeight: "15px",
                }}
                error
              >
                {formik.errors.pname}
              </FormHelperText>
            )}
          </Box>

          <Box className={classes.rowBox2}>
            <Typography variant="subtitle2" className={classes.text3}>
              Job title
            </Typography>

            <OutlinedInput
              className={classes.outlineName}
              style={fieldStyle3}
              // value={title}
              // onChange={(event) => setTitle(event.target.value)}
              //
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              //
              fullWidth
              placeholder="Enter job title"
              inputProps={{
                //
                classes: {
                  root: classes.responsiveness,
                },
                //
                disableUnderline: true,
                style: {
                  // width: '125px',
                  width: "100%",
                  height: "18px",
                  fontWeight: "400",
                  fontFamily: "poppins",
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#272525",
                  padding: "0",
                },
              }}
            />
            {formik.touched.title && formik.errors.title && (
              <FormHelperText
                style={{
                  fontWeight: "400",
                  fontFamily: "poppins",
                  fontSize: "10px",
                  lineHeight: "15px",
                }}
                error
              >
                {formik.errors.title}
              </FormHelperText>
            )}
          </Box>
        </Box>

        <Typography variant="subtitle2" className={classes.text4}>
          Email
        </Typography>

        <OutlinedInput
          className={classes.outlineName}
          style={fieldStyle}
          // value={email}
          // onChange={(event) => setEmail(event.target.value)}
          //
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          //
          fullWidth
          placeholder="Enter your email address"
          inputProps={{
            //
            classes: {
              root: classes.responsiveness,
            },
            //
            disableUnderline: true,
            style: {
              // width: '260px',
              width: "100%",
              height: "18px",
              fontWeight: "400",
              fontFamily: "poppins",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#272525",
              padding: "0",
            },
          }}
        />

        {formik.touched.email && formik.errors.email && (
          <FormHelperText
            style={{
              fontWeight: "400",
              fontFamily: "poppins",
              fontSize: "10px",
              lineHeight: "15px",
            }}
            error
          >
            {formik.errors.email}
          </FormHelperText>
        )}

        <Typography variant="subtitle2" className={classes.text5}>
          Phone number
        </Typography>

        <OutlinedInput
          className={classes.outlineName}
          style={fieldStyle}
          // type="number"
          // value={phone}
          // onChange={(event) => setPhone(event.target.value)}
          //
          name="phone"
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          //
          fullWidth
          placeholder="Enter your contact number"
          inputProps={{
            //
            classes: {
              root: classes.responsiveness,
            },
            //
            disableUnderline: true,
            style: {
              // width: '260px',
              width: "100%",
              height: "18px",
              fontWeight: "400",
              fontFamily: "poppins",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#272525",
              padding: "0",
            },
          }}
        />
        {formik.touched.phone && formik.errors.phone && (
          <FormHelperText
            style={{
              fontWeight: "400",
              fontFamily: "poppins",
              fontSize: "10px",
              lineHeight: "15px",
            }}
            error
          >
            {formik.errors.phone}
          </FormHelperText>
        )}

        {/* password ...................*/}
        {/* <form onSubmit={formik2.handleSubmit} form="formik2"> */}

        <Typography variant="subtitle2" className={classes.changepasswordtext}>
          Change password
        </Typography>

        <OutlinedInput
          className={classes.outlinedInput}
          style={fieldStylepass}
          // value={oldpass}
          // onChange={(event) => setOldpass(event.target.value)}
          //
          name="oldpass"
          value={formik2.values.oldpass}
          onChange={formik2.handleChange}
          error={formik2.touched.oldpass && Boolean(formik2.errors.oldpass)}
          //
          fullWidth
          placeholder="Enter your old password"
          inputProps={{
            //
            classes: {
              root: classes.responsiveness,
            },
            //
            disableUnderline: true,
            style: {
              // width: '260px',
              width: "100%",
              height: "18px",
              fontWeight: "400",
              fontFamily: "poppins",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#272525",
              padding: "0",
            },
          }}
        />
        {formik2.touched.oldpass && formik2.errors.oldpass && (
          <FormHelperText
            style={{
              fontWeight: "400",
              fontFamily: "poppins",
              fontSize: "10px",
              lineHeight: "15px",
            }}
            error
          >
            {formik2.errors.oldpass}
          </FormHelperText>
        )}

        <OutlinedInput
          className={classes.outlinedInput}
          style={fieldStylepass}
          // value={newpass}
          // onChange={(event) => setNewpass(event.target.value)}
          //
          name="newpass"
          value={formik2.values.newpass}
          onChange={formik2.handleChange}
          error={formik2.touched.newpass && Boolean(formik2.errors.newpass)}
          //
          fullWidth
          placeholder="Enter your new password"
          inputProps={{
            //
            classes: {
              root: classes.responsiveness,
            },
            //
            disableUnderline: true,
            style: {
              width: "100%",
              height: "18px",
              fontWeight: "400",
              fontFamily: "poppins",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#272525",
              padding: "0",
            },
          }}
        />
        {formik2.touched.newpass && formik2.errors.newpass && (
          <FormHelperText
            style={{
              fontWeight: "400",
              fontFamily: "poppins",
              fontSize: "10px",
              lineHeight: "15px",
            }}
            error
          >
            {formik2.errors.newpass}
          </FormHelperText>
        )}

        <OutlinedInput
          className={classes.outlinedInput}
          style={fieldStylepass}
          // value={conpass}
          // onChange={(event) => setConpass(event.target.value)}
          //
          name="conpass"
          value={formik2.values.conpass}
          onChange={formik2.handleChange}
          error={formik2.touched.conpass && Boolean(formik2.errors.conpass)}
          //
          fullWidth
          placeholder="Re-enter your new password"
          inputProps={{
            //
            classes: {
              root: classes.responsiveness,
            },
            //
            disableUnderline: true,
            style: {
              width: "100%",
              height: "18px",
              fontWeight: "400",
              fontFamily: "poppins",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#272525",
              padding: "0",
            },
          }}
        />
        {formik2.touched.conpass && formik2.errors.conpass && (
          <FormHelperText
            style={{
              fontWeight: "400",
              fontFamily: "poppins",
              fontSize: "10px",
              lineHeight: "15px",
            }}
            error
          >
            {formik2.errors.conpass}
          </FormHelperText>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "7px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            className={classes.clearButton}
            onClick={handleClear}
          >
            Clear
          </Button>

          <Button
            type="button"
            // type="submit"
            variant="contained"
            color="primary"
            className={classes.submitButton}
            onClick={formik2.handleSubmit}
          >
            Save
          </Button>
        </Box>
        {/* </form> */}
        {/* password ...................*/}

        <Typography variant="subtitle2" className={classes.chatLimit1}>
          Chat limit
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            type="number"
            //
            // value={chatlimit}
            // onChange={(event) => setChatlimit(Number(event.target.value))}
            //
            name="chatlimit"
            value={formik.values.chatlimit}
            onChange={formik.handleChange}
            error={formik.touched.chatlimit && Boolean(formik.errors.chatlimit)}
            //
            //
            inputProps={{ min: 0, max: 100 }}
            //
            InputProps={{
              disableUnderline: true,
              inputProps: {
                style: {
                  padding: "4px 0px 4px 5px",
                  width: "40px",
                  height: "20px",
                },
              },
            }}
          />

          <Typography variant="subtitle2" className={classes.chatLimit2}>
            Concurrent chat
          </Typography>
        </Box>
        {formik.touched.chatlimit && formik.errors.chatlimit && (
          <FormHelperText
            style={{
              fontWeight: "400",
              fontFamily: "poppins",
              fontSize: "10px",
              lineHeight: "15px",
            }}
            error
          >
            {formik.errors.chatlimit}
          </FormHelperText>
        )}

        <Typography variant="subtitle2" className={classes.selectBoxText}>
          Select status
        </Typography>

        <Select
          // className={classes.selectButton}
          //
          style={{
            border: "0.5px solid #E8E8E8",
            color: "#777777",
            width: "100px",
            height: "25px",
            borderRadius: "3px",
            paddingLeft: "7px",
            fontFamily: "poppins",
            fontSize: "12px",
            lineHeight: "15px",
            fontWeight: "400",
          }}
          //
          // value={roleLink}
          // onChange={(event) => setRoleLink(event.target.value)}
          //
          name="rolelink"
          value={
            formik.values.rolelink === "ACTIVE"
              ? "ACTIVE"
              : formik.values.rolelink === "BLOCKED"
              ? "BLOCKED"
              : "SUSPENDED"
          }
          onChange={formik.handleChange}
          error={formik.touched.rolelink && Boolean(formik.errors.rolelink)}
          //
          fullWidth
          displayEmpty
          inputProps={{
            disableUnderline: true,
            style: { borderBottom: "none" },
          }} //
          disableUnderline
          //
        >
          <MenuItem value="" disabled style={{ color: "red", display: "none" }}>
            <FiberManualRecordIcon
              style={{
                color: "#00BA34",
                width: "5px",
                height: "5px",
                marginRight: "5px",
              }}
            />
            Active
          </MenuItem>
          <MenuItem value="ACTIVE" className={classes.menuItem}>
            <FiberManualRecordIcon
              style={{
                color: "#00BA34",
                width: "5px",
                height: "5px",
                marginRight: "5px",
              }}
            />
            Active
          </MenuItem>
          <MenuItem value="SUSPENDED" className={classes.menuItem}>
            <FiberManualRecordIcon
              style={{
                color: "#FF8A00",
                width: "5px",
                height: "5px",
                marginRight: "5px",
              }}
            />
            Suspended
          </MenuItem>
          <MenuItem value="BLOCKED" className={classes.menuItem}>
            <FiberManualRecordIcon
              style={{
                color: "#E34D59",
                width: "5px",
                height: "5px",
                marginRight: "5px",
              }}
            />
            Block
          </MenuItem>
        </Select>

        {formik.touched.rolelink && formik.errors.rolelink && (
          <FormHelperText
            style={{
              fontWeight: "400",
              fontFamily: "poppins",
              fontSize: "10px",
              lineHeight: "15px",
            }}
            error
          >
            {formik.errors.rolelink}
          </FormHelperText>
        )}

        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              paddingRight: "10px",
              cursor: "pointer",
            }}
            onClick={() => setExpanded(!expanded)}
          >
            <Typography variant="subtitle2" className={classes.collapseText}>
              Chat Channels
            </Typography>{" "}
            {expanded ? (
              <RemoveIcon
                style={{
                  width: "12.6px",
                  height: "12.6px",
                  color: "#272525",
                  marginTop: "3px",
                }}
              />
            ) : (
              <AddIcon
                style={{
                  width: "12.6px",
                  height: "12.6px",
                  color: "#272525",
                  marginTop: "3px",
                }}
              />
            )}
          </Box>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box className={classes.collapseBox}>
              {/* {store.AdminDataReducer.allpagesdata.length > 0 &&
                store.AdminDataReducer.allpagesdata.map((item, index) => {
                  const isSelected = data.pages.includes(item.pageId) */}
              {getdata.AdminDataReducer.allpagesdata.length > 0 &&
                getdata.AdminDataReducer.allpagesdata.map(
                  (item: pagePropdata, index: number) => {
                    const isSelected = data.pages.includes(item.pageId);
                    return (
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <input
                            onChange={() => {
                              handleAddPage(
                                item.pageId,
                                isSelected ? false : true
                              );
                              console.log("pageItem:", item);
                            }}
                            checked={isSelected}
                            type="checkbox"
                            style={{
                              marginRight: "10px",
                              appearance:
                                "none" /* hide the default checkbox */,
                              borderRadius: "50%",
                              width: "15px",
                              height: "14px",
                              border: "2px solid #ccc",
                              display: "inline-block",
                              verticalAlign: "middle",
                              backgroundColor: isSelected ? "#4D1277" : "white", // set a different color when the checkbox is checked
                              outline: "none" /* remove the focus outline */,
                              cursor:
                                "pointer" /* change the cursor to a pointer when hovering over the checkbox */,
                            }}
                          />
                          <Avatar
                            alt="John Doe"
                            src={fblogo}
                            sx={{ height: "20px", width: "20px" }}
                          />
                          <Typography
                            variant="subtitle2"
                            className={classes.collapseBoxText}
                          >
                            {/* Internet deals */}
                            {item.name}
                          </Typography>
                        </Box>
                        <Divider
                          sx={{ marginTop: "5px", marginBottom: "5px" }}
                        />
                      </Box>
                    );
                  }
                )}
            </Box>
          </Collapse>
        </Box>

        {/* <Button type='submit' className={classes.saveButton}>Save changes</Button> */}

        <Typography variant="subtitle2" className={classes.deleteUser}>
          Delete user?
        </Typography>

        <Button onClick={handleDeleteUser} className={classes.deleteButton}>
          Delete
        </Button>

        <Box className={classes.bottomButton}>
          <Button
            variant="contained"
            color="primary"
            className={classes.cancelPanel}
            onClick={() => {
              setRightPanelOpened(false);
              setIsEditMode(false);
            }}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.saveButton}
          >
            Save changes
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default React.memo(EditProfileSideBar);
