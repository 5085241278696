import React, { useEffect } from "react";
//
import { gql, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
//
import { useSnackbar } from "notistack";
//
import { Formik } from "formik";
import * as Yup from "yup";
//
import Box from "@mui/material/Box/Box";
import useStyles from "./Styles";

import SignUpTop from "../shared/atom/signUpTop/SignUpTop";
import Heading from "../shared/atom/heading/Heading";
import { Text, EmailText } from "../shared/atom/text/Text";
import KuikTextField from "../shared/atom/kuikTextField/TextField";
import AuthButton from "../shared/atom/button/AuthButton";
//
import { useTheme } from "@mui/system";

interface Props {
  token:any
}

const ResetPasswordRightComponent: React.FC<Props> = ({token}) => {
  const [resetToken,setResetToken] = React.useState<string>("")
  const [resetEmail,setResetEmail] = React.useState<string>("")

  useEffect(()=>{
  })
  const history = useHistory()
  //
  //
  const { enqueueSnackbar } = useSnackbar();
  
  let ResetPasswordQuery = gql`
  mutation ChangePasswordFromValidResetToken(
    $token: String!
    $password: String!
  ) {
    changepasswordfromvalidresettoken(token: $token, password: $password) {
      success
      error
    }
  }
`;

const [
  resetPassword,
  {
    loading: resetPasswordQueryLoading,
    error: resetPasswordQueryError,
    data: resetPasswordQueryResult,
  },
] = useMutation(ResetPasswordQuery);

useEffect(() => {
  if (resetPasswordQueryError) {
    resetPasswordQueryError.graphQLErrors.map(({ message }, i) => {
      enqueueSnackbar(message, { variant: "error" });
    });
  }
}, [resetPasswordQueryError]);

useEffect(() => {
  if (
    resetPasswordQueryResult &&
    resetPasswordQueryResult.changepasswordfromvalidresettoken
  ) {
    console.log("changed password",resetPasswordQueryResult.changepasswordfromvalidresettoken)
    enqueueSnackbar("Passsword has been successfully changed", { variant: "success" });
    //
    setTimeout(()=>{history.push("/login");},500)
  }
}, [resetPasswordQueryResult]);

let changePasswordValidate = null;
let changeConfirmPasswordValidate = null;


  // interface for the form values
  interface FormValues {
    password: string;
    confirmPassword: string;
  }

  // validation schema using Yup
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(7, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Please confirm your password"),
  });

  const handleSubmit = async (
    values: FormValues,
    // { setSubmitting }: FormikHelpers<FormValues>
    { setSubmitting }: any
  ) => {
    // handle form submission
    setSubmitting(false);
    try {
      await resetPassword({
        variables: {
          token: resetToken,
          password: values.password,
        },
      });
    } catch (e) {}
  };

function parseQueryString(queryString: string): Record<string, string> {
  var params: Record<string, string> = {};
  var pairs = queryString.split('&');
  
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    var key = decodeURIComponent(pair[0]);
    var value = decodeURIComponent(pair[1]);
    params[key] = value;
  }
  
  return params;
}

let parsedParams = parseQueryString(token);

useEffect(()=>{
if(Object.keys(parsedParams).length > 0) {
  setResetToken(parsedParams.token)
  setResetEmail(parsedParams.email)
}
},[parsedParams])
  ////////////////////////////
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <>
      <Box className={classes.root}>
        <SignUpTop />
        <Heading heading="Set new password" />
        <Text text="Set the new password for your account:" />
        <EmailText text={`${resetEmail}`} />
        {/* <EmailText text='undefied' /> */}
        <Box className={classes.form}>
          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik:any) => (
              <Box component="form" onSubmit={formik.handleSubmit}>
                <Box mt={1}>
                  <KuikTextField
                    id="password"
                    label="Password"
                    name="password"
                    typeField="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </Box>
                <Box mt={1}>
                  <KuikTextField
                    id="confirmPassword"
                    label="Confirm Password"
                    name="confirmPassword"
                    typeField="password"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                  />
                </Box>

                <AuthButton text="Reset password" loading={resetPasswordQueryLoading}/>
              </Box>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default ResetPasswordRightComponent;
