import React, { useReducer, useEffect, useState } from "react";
import removeIcon from "../../../assets/setting/close-02.svg";
import VerticallyCenteredModal from "../VerticallyModal";
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import EditModal from "./EditModal";
import _ from "lodash";
import { CircularProgress } from "@material-ui/core";
//
import { Card, Box, Button, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
//
import Response from './UpdateCannedResponse/Response/Response'
import DeleteBox from './UpdateCannedResponse/DeleteBox/DeleteBox'
import AddModal from './UpdateCannedResponse/AddNewModal/AddNewModal'
//
import useStyle from "./Styles";

interface refProp {
  alterModalShow: () => void;
}


function CannedResponse() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyle();
  const verticalRef = React.useRef<refProp>(null);
  const editModalRef = React.useRef<refProp>(null);

  interface setdataProp {
    text: string
    shortcut: string
  }

  const [dataEntered, setdata] = useState<setdataProp>({ text: "", shortcut: "" })
  //
  // const [updatedata, setUpdatedata] = useState()
  //
  const [deleteno, setDeleteNo] = useState(undefined)
  const [openModal, setOpenModal] = useState<boolean>(false)

  const deleteResponse = (id: any) => {
    onDeleteHandler(id)
  }

  const editResponse = (id: any) => {
    onEditHandler(id)
  }


  const closeModal = () => {
    setOpenModal(false)
  }
  const OpenModal = () => {
    setOpenModal(true)

  }

  // React.useEffect(() => {
  //   console.log('changed...', deleteno)
  // }, [deleteno])


  const ResponseQuery = gql`
    query getResponse($accessToken: String) {
      getResponse(accessToken: $accessToken) {
        id
        responses
      }
    }
  `;

  let [
    getResponse,
    {
      loading: ResponseQueryLoading,
      error: ResponseQueryError,
      data: ResponseQueryResult,
    },
  ] = useLazyQuery(ResponseQuery, {
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (ResponseQueryError) {
      ResponseQueryError.graphQLErrors.map(({ message }, i) => {
        enqueueSnackbar(message, { variant: "error" });
      });
    }
  }, [ResponseQueryError]);

  useEffect(() => {
    getResponse();
  }, []);


  interface listProp {
    id: number;
    short: string;
    long: string;
  }


  interface listDataProp {
    id: number;
    shortCut: string;
    longText: string;
  }
  

  useEffect(() => {
    if (ResponseQueryResult && ResponseQueryResult.getResponse) {
      var listData_:listDataProp[] = [];

      var decodeResult = JSON.parse(ResponseQueryResult.getResponse.responses);

      decodeResult &&
        decodeResult.map((item:listProp) => {
          listData_.push({
            id: item.id,
            shortCut: item.short,
            longText: item.long,
          });
        });

      dispatch({ type: "RESPONSEFROMDB", data: listData_ });
      dispatch({ type: "DUPLICATE" });
      console.log("got the response data",listData_)
    }
    //
    //
  }, [ResponseQueryResult]);

  const updateresponseQuery = gql`
    mutation updateresponse($response: String!) {
      updateresponse(response: $response) {
        success
        error
      }
    }
  `;
  let [
    updateresponse,
    {
      loading: updateresponseQueryLoading,
      error: updateresponseQueryError,
      data: updateresponseQueryResult,
    },
  ] = useMutation(updateresponseQuery);

  useEffect(() => {
    if (updateresponseQueryError) {
      updateresponseQueryError.graphQLErrors.map(({ message }, i) => {
        enqueueSnackbar(message, { variant: "error" });
      });
    }
  }, [updateresponseQueryError]);

  useEffect(() => {
    if (updateresponseQueryResult && updateresponseQueryResult.updateresponse) {
      if (updateresponseQueryResult.updateresponse.success) {
        dispatch({ type: "DUPLICATE" });
      } else {
        enqueueSnackbar(updateresponseQueryResult.updateresponse.error, {
          variant: "error",
        });
      }
    }
  }, [updateresponseQueryResult]);

  const initialState = {
    shortCut: "",
    longText: "",
    btnDisabled: true,
    cannedList: [],
    activeCannedId: null,
    searchText: "",
    duplicate: [],
  };

  const reducer = (state:any, action:any) => {
    switch (action.type) {
      case "SHORTCUT":
        return {
          ...state,
          shortCut: action.data,
        };
      case "LONGTEXT":
        return {
          ...state,
          longText: action.data,
        };
      case "BTNDISABLED":
        return {
          ...state,
          btnDisabled: action.data,
        };
      case "ACTIVEID":
        return {
          ...state,
          activeCannedId: action.data,
        };
      case "ADDRESPONSE":
        let newCannedListed = [...state.cannedList, action.data];
        let listData__:listProp[] = [];
        newCannedListed.map((item) => {
          listData__.push({
            id: item.id,
            short: item.shortCut,
            long: item.longText,
          });
        });

        updateresponse({
          variables: {
            response: JSON.stringify(listData__),
          },
        });
        return {
          ...state,
          cannedList: [...state.cannedList, action.data],
        };
      case "DELETERESPONSE":
        //
        let newStateofCannedList = state.cannedList.filter((curr:listDataProp) => curr.id != state.activeCannedId);
        let listData___:listProp[] = [];
        newStateofCannedList.map((item:listDataProp) => {
          listData___.push({
            id: item.id,
            short: item.shortCut,
            long: item.longText,
          });
        });

        updateresponse({
          variables: {
            response: JSON.stringify(listData___),
          },
        });

        return {
          ...state,
          cannedList: newStateofCannedList,
        };
      case "DUPLICATE":
        return {
          ...state,
          duplicate: state.cannedList,
        };
      case "SEARCHTEXT":
        return {
          ...state,
          searchText: action.data,
        };
      case "UPDATERESPONSE":
        const copyOfCannedList = state.cannedList;
        const removeUpdatedData = copyOfCannedList.filter((curr: any) => curr.id != action.data.id);
        const newCannedList = [...removeUpdatedData, action.data];
        const sortListWithId = _.orderBy(newCannedList, "id", "asc");
        const listData_: any = [];
        sortListWithId.map((item) => {
          listData_.push({
            id: item.id,
            short: item.shortCut,
            long: item.longText,
          });
        });

        updateresponse({
          variables: {
            response: JSON.stringify(listData_),
          },
        });
        return {
          ...state,
          cannedList: sortListWithId,
        };
      case "SEARCHFUNCTIION":
        if (state.searchText.length) {
          return {
            ...state,
            cannedList: state.duplicate.filter((curr:listDataProp) =>
              curr.shortCut.includes(state.searchText)
            ),
          };
        } else {
          return {
            ...state,
            cannedList: state.duplicate,
          };
        }
      case "RESPONSEFROMDB":
        return {
          ...state,
          cannedList: action.data,
        };
      default:
        return state;
    }
  };
  useEffect(() => {
    dispatch({ type: "DUPLICATE" });
  }, []);
  const [inputData, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (inputData.shortCut.length && inputData.longText.length) {
      dispatch({ type: "BTNDISABLED", data: false });
    } else {
      dispatch({ type: "BTNDISABLED", data: true });
    }
  }, [inputData.shortCut, inputData.longText]);

  useEffect(() => {
    dispatch({ type: "SEARCHFUNCTIION" });
  }, [inputData.searchText]);

  const onDeleteHandler = (id:number) => {
    if(verticalRef.current){
      verticalRef.current.alterModalShow();
    dispatch({ type: "ACTIVEID", data: id });
    }
  };
  const onEditHandler = (id:number) => {
    dispatch({ type: "ACTIVEID", data: id });

    editModalRef.current?.alterModalShow();
  };

  const listItems = inputData.cannedList.length ? (
    inputData.cannedList.map((curr:listDataProp) => deleteno !== curr.id ? (
      <Response
        text={curr.longText}
        label={curr.shortCut}
        keyIndex={curr.id}
        setting={setDeleteNo}
        editResponse={editResponse}
      />
    ) : (
      <DeleteBox setting={setDeleteNo} keyIndex={curr.id} deleteResponse={deleteResponse} />
    ))
  ) : (
    <div>No Canned Response</div>
  );
interface setdataProp{
  text:string
  shortcut:string
}

  const addResponseHandler = (newdata:null|setdataProp = null) => {
    dispatch({
      type: "ADDRESPONSE",
      data: {
        id: inputData.cannedList.length,
        shortCut: newdata === null ? "" : (newdata as { text: string }).text,
        longText: newdata === null ? "" : (newdata as { shortcut: string }).shortcut,
      },
    });
    dispatch({ type: "SHORTCUT", data: "" });
    dispatch({ type: "LONGTEXT", data: "" });
    dispatch({ type: "DUPLICATE" });
  };


  useEffect(() => {
    if(dataEntered.text!=="" && dataEntered.shortcut!==""){
      addResponseHandler(dataEntered)
      console.log("dataEntered is saved",dataEntered)
    }else{
      console.log("dataEntered: empty not saved",dataEntered)
    }
  }, [dataEntered])


  // const addResponseHandler = () => {
  //   dispatch({
  //     type: "ADDRESPONSE",
  //     data: {
  //       id: inputData.cannedList.length,
  //       shortCut: inputData.shortCut,
  //       longText: inputData.longText,
  //     },
  //   });
  //   dispatch({ type: "SHORTCUT", data: "" });
  //   dispatch({ type: "LONGTEXT", data: "" });
  //   dispatch({ type: "DUPLICATE" });
  // };


  const updateCannedResponse = (e:listDataProp) => {
    dispatch({ type: "UPDATERESPONSE", data: e });
    editModalRef.current?.alterModalShow();
  };


  const deleteCannedResponse = () => {
    dispatch({ type: "DELETERESPONSE" });
    verticalRef.current?.alterModalShow();
  };

  return (
    <>
      {' '}
      <AddModal check={openModal} closeModal={closeModal} setdata={setdata} />
      <Card
        sx={{ minWidth: 275 }}
        style={{
          // height: 'calc(100vh - 166px)',
          height: 'calc(100vh - 112px)',
          borderRadius: '10px',
          boxShadow: '0px 5px 10px rgba(127, 63, 152, 0.1)',
          //
        }}
      >
        <Box sx={{ width: '100%', display: 'flex' }}>
          <Box
            sx={{
              width: '620px',
              height: '67px',
              marginTop: '20px',
              marginLeft: '20px',
            }}
          >
            <Typography
              sx={{
                width: '100%',
                fontWeight: '500',
                fontFamily: 'poppins',
                fontSize: '16px',
                height: '24px',
                lineHeight: '24px',
                color: '#272525',
              }}
              variant="body1"
            >
              What are canned responses
            </Typography>
            <Typography
              sx={{
                width: '100%',
                fontFamily: 'poppins',
                fontWeight: '400',
                fontSize: '14px',
                height: '21px',
                lineHeight: '18px',
                color: '#838383',
                //
                marginTop: '5px',
              }}
              variant="subtitle1"
            >
              Canned responses are pre-made messages you can recall easily
              during chat.
            </Typography>
          </Box>
          <Box sx={{ width: '420px', height: '67px' }}>
            <Button
              variant="contained"
              // color="primary"
              type="submit"
              // startIcon={<AddIcon/>}
              sx={{
                width: '104px',
                height: '32px',
                backgroundColor: '#4D1277',
                borderRadius: '3px',
                fontFamily: 'poppins',
                fontWeight: '500',
                fontSize: '12px',
                lineHeight: '18px',
                color: '#FFFFFF',
                //
                textTransform: 'capitalize',
                boxShadow: 'none',
                //
                marginTop: '37px',
              }}
              //
              onClick={OpenModal}
            >
              <AddIcon style={{ fontSize: '18px' }} />
              <span style={{ textTransform: 'capitalize' }}>
                Add
              </span>&nbsp;{' '}
              <span style={{ textTransform: 'lowercase' }}>new</span>
            </Button>
          </Box>
        </Box>

        {/* <Box sx={{ width: '910px', marginLeft: '20px', marginRight: '20px' }}> */}
        <Box sx={{ width: "100%",paddingLeft:"20px" }}>
          <Box sx={{ padding: '7px 0px 8px 10px' }}>
            <Typography
              sx={{
                width: '100%',
                fontWeight: '400',
                fontFamily: 'poppins',
                fontSize: '12px',
                lineHeight: '18px',
                height: '15px',
                color: '#777777',
              }}
              variant="subtitle1"
            >
              {listItems.length > 0 ? listItems.length : 0} canned responses
            </Typography>
          </Box>

          <Box sx={{
            marginTop: '2px', 
            height: "auto", 
            maxHeight: "67vh", 
            overflowY: "auto",
             '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#ccc',
              borderRadius: '3px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
          }}>
            {ResponseQueryLoading ? (
              <div>
                <CircularProgress
                  className={classes.classes.loadingCircularProgress}
                  size={24}
                />
              </div>
            ) : (
              ""
            )}
            {ResponseQueryLoading == false ? listItems.length > 0 ? <>{listItems}</> : <div
              style={{
                fontFamily: "poppins",
                fontSize: "16px",
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              No CannedResponse
            </div> : ""}
          </Box>

          <VerticallyCenteredModal
            img={removeIcon}
            text={"Are you sure you want to delete this canned response"}
            btnText={"Delete"}
            removeHandler={deleteCannedResponse}
            ref={verticalRef}
          />
          <EditModal
            ref={editModalRef}
            data={inputData.cannedList.find(
              (curr:listDataProp) => curr.id == inputData.activeCannedId
            )}
            updatedData={(e:listDataProp) => updateCannedResponse(e)}
          />
        </Box>
      </Card>
    </>
  );
}

export default CannedResponse;