import React from 'react'
import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography'
import SvgIcon from '@mui/material/SvgIcon'
// import useStyles from './Styles';
import { useTheme } from '@mui/system'

interface Props {
  text: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClick: any
}

const ThirdPartyLoginButton: React.FC<Props> = ({
  text,
  icon,
  handleClick,
}) => {
  // const { classes } = useStyles()
  const theme = useTheme()
  return (
    <Box
      sx={{
        //
        [theme.breakpoints.up('md')]: {
          width: '300px',
          paddingLeft: "2%",
          // backgroundColor:"grey"
        },
        [theme.breakpoints.down('md')]: {
          width: '300px',
          paddingLeft: "5%",
          // backgroundColor:"yellow"
        },
        [theme.breakpoints.up('sm')]: {
          width: '100%',
          paddingLeft: "20%",
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          paddingLeft: "5%",
          // backgroundColor:"aqua"
        },
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          paddingLeft: "5%",
          // backgroundColor:"green"
        },
        //laptop
        [theme.breakpoints.between('md', 'lg')]: {
          width: '100%',
          paddingLeft: "5%",
          //
          // backgroundColor:"red"
        },
        //Extra large
        [theme.breakpoints.up('xl')]: {
          width: '20vw',
          paddingLeft: "25%",
        },
        //
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
        justifyContent: 'flex-start',
        height: '40px',
        background: '#fff',
        boxShadow: '0px 1px 2px rgba(24, 24, 28, 0.1)',
        borderRadius: '5px',
        cursor: 'pointer',
      }}
      mt={1}
      onClick={handleClick}
    >
      <Box
        sx={{
          [theme.breakpoints.up('md')]: {
            marginLeft: '5vw',
          },
          [theme.breakpoints.down('sm')]: {
            marginLeft: 6,
          },
          [theme.breakpoints.up('xs')]: {
            marginLeft: 8,
          },
          [theme.breakpoints.up('xl')]: {
            marginLeft: 2,
          },
          fontSize: '12px',
          marginTop: 1,
        }}
      >
        <SvgIcon>{icon}</SvgIcon>
      </Box>
      <Typography
        ml={1}
        sx={{
          color: '#272525',
          fontSize: '12px',
          fontWeight: '500',
          // marginRight: '35px',
          marginRight: '10px',
        }}
      >
        {text}
      </Typography>
    </Box >
  )
}

export default ThirdPartyLoginButton
