import React from 'react'
import { Box, } from '@mui/material';
import Noti from '../../Notification/index'
const Notification: React.FC = () => {
    return (




        <Box
        >
            {/* <NotificationsIcon /> */}
            <Noti />
        </Box>

    )
}

export default Notification