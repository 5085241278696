import React, { useEffect } from "react";
import SettingLeftPane from "./LeftContainer/SettingLeftPane";
import SettingRightPane from "./rightContainer/SettingRightPane";
import { Facebook } from "../../auth/Facebook";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

const Setting = (props:any) => {
  useEffect(() => {
    Facebook.fbInt();
    document.title = "Settings"
  }, []);
  return (

    <Grid container xs={12} marginTop={10} style={{height:'calc(100vh - 80px)'}}>
      <Grid xs={3} sx={{paddingRight:'10px', overflowY: 'auto', height: 'calc(100vh - 80px)'}} padding={2}>
          <SettingLeftPane />
      </Grid>
      <Grid xs={9} sx={{ padding:'16px 20px 16px 10px'}}>
          <SettingRightPane setPage={props.match.url} />
      </Grid>
    </Grid>

  );
};

export default Setting;
