import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import useStyle from './Style';

interface Props{
  img   : any
  text    :any
  btnText   :any
  modalOpen : boolean
  modalClose : React.Dispatch<React.SetStateAction<boolean>>
  removeItem : ()=> void
}

const Modal:React.FC<Props> = ({
  btnText,
  text,
  modalOpen,
  modalClose,
  removeItem,
  img
}) => {
  const {classes} = useStyle();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const removeHandler = () => {
    removeItem();
    modalClose(false);
  }

  const handleClose = () => {
    modalClose(false);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent sx={{padding:'25px 35px 25px 35px'}}>
          <Box className={classes.DeleteIcon} style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
          <img
            src={img}
            alt={btnText}
            style={{ width: "30px" }}
          />
            {/* <DeleteIcon fontSize='large' className={classes.deleteIconStyle} /> */}
          </Box>
          <Typography component={'h1'} className={classes.headingStyle}>
              Are you sure?
          </Typography>
          <DialogContentText>
              {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{paddingBottom:'20px', justifyContent:'center'}}>
          <Button className={`${classes.buttonStyle} ${classes.cancelButton}`} onClick={handleClose} variant='contained'>
            Cancel
          </Button>
          <Button className={`${classes.buttonStyle} ${classes.deleteButton}`} variant='contained' onClick={removeHandler}>
            {btnText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Modal