import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '148px',
    // margin: '30px 0px 0px 30px',
    //
    [theme.breakpoints.down('md')]: {
      margin: '70px 0px 0px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '70px 0px 0px 0px',
    },
    [theme.breakpoints.up('md')]: {
      // fontSize: '42px',
      width: '545px',
      margin: '30px 0px 0px 0px',
    },
    //laptop
    [theme.breakpoints.down('lg')]: {
      margin: '30px 0px 0px 0px',
    },
  },
  logo: {
    overflow: 'visible',
  },
}))

export default useStyles




// import { makeStyles } from 'tss-react/mui'

// const useStyles = makeStyles()((theme) => ({
//   root: {
//     width: '148px',
//     // margin: '30px 0px 0px 30px',
//     //
//     [theme.breakpoints.down('md')]: {
//       margin: '70px 0px 0px 70px',
//     },
//     [theme.breakpoints.down('sm')]: {
//       margin: '70px 0px 0px 70px',
//     },
//     [theme.breakpoints.up('md')]: {
//       // fontSize: '42px',
//       width: '545px',
//       margin: '30px 0px 0px 30px',
//     },
//     //laptop
//     [theme.breakpoints.down('lg')]: {
//       margin: '30px 0px 0px 30px',
//     },
//   },
//   logo: {
//     overflow: 'visible',
//   },
// }))

// export default useStyles
