import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";

const getDashboardGraphicalDataQuery = gql`
  query getDashboardGraphicalData($idArray: [ID], $dateMode: String) {
    getDashboardGraphicalData(idArray: $idArray, dateMode: $dateMode) {
      dateMode
      data {
        name
        customerCount
      }
    }
  }
`;

export const useGetDashboardGraphicalDataQuery = () => {
  return useLazyQuery(getDashboardGraphicalDataQuery, {
    fetchPolicy: "network-only",
  });
};

const getAgentTotalChatQuery = gql`
  query getAgentTotalChat($agentId: ID!) {
    getAgentTotalChat(agentId: $agentId) {
      totalChat
    }
  }
`;

export const useGetAgentTotalChatQuery = () => {
  return useLazyQuery(getAgentTotalChatQuery, {
    fetchPolicy: "network-only",
  });
};

const getAgentsAndActiveCustomersQuery = gql`
  query getAgentsAndActiveCustomers($agentId: ID!, $paneltype : String!) {
    getAgentsAndActiveCustomers(agentId: $agentId, paneltype: $paneltype) {
      activeAgents
      myActiveCustomers
    }
  }
`;

export const useGetAgentsAndActiveCustomersQuery = () => {
  return useLazyQuery(getAgentsAndActiveCustomersQuery, {
    fetchPolicy: "network-only",
  });
};
