import { makeStyles } from 'tss-react/mui'
// import theme from '../../style/theme';
//
const useStyles = makeStyles()(() => ({

  selectInput: {
    // border: '1px solid #E8E8E8',
    //
    overflow:"hidden",
    //
    cursor: "pointer",
    //
    border: "0px solid #E8E8E8",
    color: "#B7B7B7",
    width: "120px",
    // height: "28px",
    height: "38px",
    borderRadius: "3px",
    marginLeft: "10px",
    // paddingLeft: "10px",
    // paddingTop: "7.5px",
    // paddingBottom: "7.5px",
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "poppins",
    position: "relative",
    top: "-1px",
    //
    // "& .MuiInputBase-input": {
    //   // padding: "0",
    //   padding: "10px",
    //   // paddingRight: '12px',
    //   paddingRight: "0px !important",
    //   marginRight: "-7px !important",
    //   //
    //   width: "100% !important",
    //   minWidth: "0px !important",
    // },
  },


  // selectInput: {
  //   //
  //   // border: '1px solid #E8E8E8',
  //   border: "0px solid #E8E8E8",
  //   color: "#B7B7B7",
  //   width: "120px",
  //   // height: "28px",
  //   height: '38px',
  //   borderRadius: "3px",
  //   marginLeft: "10px",
  //   // paddingLeft: "10px",
  //   // paddingTop: "7.5px",
  //   // paddingBottom: "7.5px",
  //   fontSize: "12px",
  //   fontWeight: "400",
  //   fontFamily: "poppins",
  //   position: "relative",
  //   top: "-1px",
  //   //
  //   //
  //   '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
  //   {
  //     // padding: "0",
  //     padding: "10px",
  //     // paddingRight: '12px',
  //     paddingRight: '0px !important',
  //     marginRight: '-7px !important',
  //     //
  //     width: "100% !important",
  //     minWidth: "0px !important"
  //     //
  //     // minWidth:"0px !important",
  //     // padding:"0",
  //     // paddingRight: '12px',
  //     // marginRight: '-23px',
  //     // //
  //     // marginTop:"4px",
  //     //
  //   },
  // },

  root: {
    '& .MuiInputBase-input::placeholder': {
      //   color: '#777777',
      //   color: 'red',
      //   color: 'rgb(119,119,119)',
      //   fontStyle: 'italic',
      width: '420px',
      height: '15px',
      fontSize: '10px',
      lineHeight: '15px',
      fontWeight: '600',
      fontFamily: 'poppins',
      //
      display: 'flex',
      alignItems: 'center',
    },
  },
  selectMenuItem: {
    width: "116px",
    height: "25px",
    borderRadius: "3px",
    backgroundColor: "#FFFFFF",
    color: "#777777",
    fontFamily: "poppins",
    // fontSize: "10px",
    // lineHeight: "15px",
    fontSize: "12px",
    lineHeight: "17px",
    //
    fontWeight: "400",
  },
  errorStyle:{
    fontWeight: "400",
    fontFamily: "poppins",
    fontSize: "12px",
    lineHeight: "18px",
  }
}))
export default useStyles
