const remToPixels = (remValue: number) => {
  const fontSize = parseFloat(
    getComputedStyle(document.documentElement).fontSize
  );
  return Number(remValue * fontSize);
};

export default remToPixels;


// const remToPixels = (remValue: number) => {
//     const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
//     const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
//     const pixelValue = remValue * rootFontSize;
  
//     return Math.floor((pixelValue / 100) * screenWidth);
//   };
  
//   export default remToPixels;
  