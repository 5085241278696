
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 250,
    zIndex: 99,
  },
  icon: {
    // marginLeft: 1.5,
    height: '23px',
    width: '21px',
    transform: 'rotate(35deg)',
    // marginBottom: -2,
  },
  content: {
    display: 'grid',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  upload: {
    fontSize: '14px',
    cursor: 'pointer',
    padding: '0 5px 5px 5px',
    width: 'fit-content',
    borderBottom: '4px solid #4D1277',
    marginBottom: '0',
    marginRight: '10px',
    color: theme.palette.primary.main,
  },
  file: {
    display: 'flex',
    // cursor: 'pointer',
    position: 'relative',
    alignItems: 'center',
    padding: '10px 5px',
    '&:hover': {
      background: '#fafafa',
    },
    borderLeft: '5px solid #4D1277',
    background: '#fafafa',
  },

  preview: {
    width: '40px',
    height: '40px',
    // borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'lightgrey',
  },
  size: {
    marginBottom: '0',
    fontSize: '14px',
    color: 'grey',
  },
  name: {
    marginBottom: '0',
    fontSize: '16px',
  },
  image: {
    width: '75%',
    height: '75%',
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
  },
  cancel: {
    textTransform: 'capitalize',
    color: 'BLACK',
    marginLeft: '19rem',
    background: theme.palette.background.default,
    "&:hover": {
      backgroundColor: "#2E2B2F",
    },
  },
  done: {
    textTransform: 'capitalize',
    color: 'white',
  },
}))
export default useStyles
