import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 260,
    height: 180
};
interface Props {
    onClick: () => void
    disabled: boolean
}
const RightChatComponentHeaderClose: React.FC<Props> = ({ onClick, disabled }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleClick = () => {
        onClick();
        setOpen(false);
    }
    return (
        <>
            <IconButton disabled={disabled}>
                <Box sx={{
                    background: "#FBFBFB",
                    border: "0.5px solid #E8E8E8",
                    borderRadius: "3px", width: "35px", height: "30px", display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    margin: "0px -10px",
                }} onClick={handleOpen} >
                    <CloseIcon sx={{ fontSize: 20, color: !disabled ? "#4D1277" : "black" }} />
                </Box>
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Card>
                        <CardContent sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <CancelOutlinedIcon color='primary' sx={{ fontSize: 35 }} />
                            <Typography mt={1} sx={{ fontSize: '16px', fontWeight: 500 }}>Close this chat?</Typography>
                            <Typography mt={1} ml={2} sx={{ fontSize: 10, fontWeight: 400 }}>Are you sure you want to stop the chat </Typography>
                            <Stack mt={2} direction="row" spacing={{ xs: 1, sm: 2, md: 1 }}>
                                <Button sx={{
                                    height: '30px', width: "105px",
                                    fontSize: "12px", fontWeight: 400, background: "#F5F5F5", textDecoration: 'none'
                                }} onClick={handleClose}>Cancel</Button>
                                <Button sx={{
                                    height: '30px', width: "105px",
                                    fontSize: "12px", fontWeight: 400, textDecoration: 'none'
                                }} onClick={handleClick} disabled={disabled}
                                    color="primary" variant='contained'>Close </Button>
                            </Stack>
                        </CardContent>
                    </Card>
                </Box>
            </Modal>
        </>
    )
}

export default RightChatComponentHeaderClose