import React, { useEffect, useReducer, useState, FormEvent } from "react";
import { CircularProgress, OutlinedInput, Box, Modal, Typography, Divider, Button } from "@mui/material"
//
import CloseIcon from "@mui/icons-material/Close";
//
import AddIcon from "@mui/icons-material/Add";
//
import AgentModal from "../ChooseAgents/ChooseAgents";
import ManagerModal from "../ChooseManagers/ChooseManager";
//
import useStyles from "./Styles";
///////////////////////////////////////////////////
import { gql } from "apollo-boost";
import { useDispatch, useSelector } from "react-redux";
import { setManagersListData } from "../../../../../store/actions/ManagersListActions";
import { setAgentsListData } from "../../../../../store/actions/AgentsListActions";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import useSnackBar from "../../../../../hooks/useSnackBar";
///////////////////////////////////////////////////
import { Avatar, ListItem, Chip } from "@mui/material";
import image from "../../../../../assets/img/profile.jpg";
//
import Store from '../../../../../store/index'

interface dataProp {
  designation: {
    id: string;
    name: string;
    paneltype: string;
    __typename: string;
    // ... other properties of the designation object
  };
  email: string;
  id: string;
  isSelected: boolean;
  isUserLoggedIn: null;
  name: string;
  number: string;
  pages: string;
  status: string;
  username: string;
  __typename: string;
}


const initialState = {
  error: {},
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case "ON_ERROR":
      return { ...state, error: { ...action.payload } };
    default:
      return state;
  }
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //
  width: "400px",
  // height: '150px',
  height: "auto",
  minHeight: "150px",
  maxHeight: "397px",
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 5px 10px rgba(127, 63, 152, 0.1)",
  borderRadius: "3px",
};

interface Props {
  check: boolean;
  handleCloseDropDown: () => void;
  closeModal: () => void;
}

const KeepMountedModal: React.FC<Props> = ({
  check,
  handleCloseDropDown,
  closeModal,
}) => {
  /////////////////////////////////////////////////////

  const { getState } = Store;
  const getdata = getState();


  const dispatch = useDispatch();
  const [state, dispatch_] = useReducer(reducer, initialState);
  const { success, error } = useSnackBar();
  const store = useSelector((store) => store);
  const history = useHistory();
  const [isListOfAgentManagerOpen, setIsListOfAgentManagerOpen] =
    useState(false);
  const [openPanelCategory, setOpenPanelCategory] = useState("");
  const [teamName, setTeamName] = useState("");

  interface PaginationProps {
    page: number;
    hasAgentNextPage?: boolean;
    hasManagerNextPage?: boolean;
    limit: number;
  }


  const [pagination, setPagination] = useState<PaginationProps>({
    page: 0,
    hasAgentNextPage: false,
    hasManagerNextPage: false,
    limit: 10,
  });
  const [inviteList, setInviteList] = useState<dataProp[]>([]);

  // get managers //
  const handleGetManagers = gql`
    query getManagers($mainSuperAdminId: ID!, $cursor: Int, $limit: Int) {
      getManagers(
        mainSuperAdminId: $mainSuperAdminId
        cursor: $cursor
        limit: $limit
      ) {
        users {
          id
          name
          username
          email
          status
          number
          agentlimitchatassign
          isUserLoggedIn
          pages
          number
          designation {
            id
            name
            paneltype
          }
        }
        hasNextPage
      }
    }
  `;

  let [
    getManagers,
    {
      loading: getManagersQueryLoading,
      error: getManagersQueryError,
      data: getManagersQueryResult,
    },
  ] = useLazyQuery(handleGetManagers, {
    fetchPolicy: "network-only",
  });
  // get managers //

  // get agents //
  const handleGetAgents = gql`
    query getAgents($mainSuperAdminId: ID!, $cursor: Int, $limit: Int) {
      getAgents(
        mainSuperAdminId: $mainSuperAdminId
        cursor: $cursor
        limit: $limit
      ) {
        users {
          id
          name
          username
          email
          status
          number
          agentlimitchatassign
          isUserLoggedIn
          pages
          number
          designation {
            id
            name
            paneltype
          }
        }
        hasNextPage
      }
    }
  `;

  let [
    getAgents,
    {
      loading: getAgentsQueryLoading,
      error: getAgentsQueryError,
      data: getAgentsQueryResult,
    },
  ] = useLazyQuery(handleGetAgents, {
    fetchPolicy: "network-only",
  });
  // get agents //

  useEffect(() => {
    if (getAgentsQueryResult && getAgentsQueryResult.getAgents.users) {
      fetchAgents(getAgentsQueryResult);
    }
  }, [getAgentsQueryResult]);

  useEffect(() => {
    if (getManagersQueryResult && getManagersQueryResult.getManagers.users) {
      fetchManagers(getManagersQueryResult);
    }
  }, [getManagersQueryResult]);

  interface fetchProp {
    hasNextPage: boolean
    users: dataProp[]
    __typename: string
  }

  interface fetchAgentProp {
    getAgents: fetchProp
  }

  const fetchAgents = (data: fetchAgentProp) => {
    setPagination({
      page: pagination.page + 1,
      limit: 10,
      hasAgentNextPage: data.getAgents.hasNextPage,
    });
    dispatch(setAgentsListData(data.getAgents.users, pagination.page));
  };


  interface fetchManagerProp {
    getManagers: fetchProp
  }

  const fetchManagers = (data: fetchManagerProp) => {
    setPagination({
      page: pagination.page + 1,
      limit: 10,
      hasManagerNextPage: data.getManagers.hasNextPage,
    });
    dispatch(setManagersListData(data.getManagers.users, pagination.page));
  };

  useEffect(() => {
    document.title = "Add/Edit Users";
    window.Object.freeze = function (obj: any) {
      return obj;
    }; //keep_an_eye

    const activeUserDetail = localStorage.getItem("ActiveUserdetail");
    const mainSuperAdminId = activeUserDetail ? JSON.parse(activeUserDetail).mainSuperAdminId : null;


    if (localStorage.getItem("ActiveUserdetail")) {
      getManagers({
        variables: {
          // mainSuperAdminId: JSON.parse(localStorage.getItem("ActiveUserdetail")).mainSuperAdminId,
          mainSuperAdminId,
          cursor:
            pagination.page == 0
              ? pagination.limit * pagination.page
              // : Number(store.ManagersListReducer.managersListData.at(-1).id),
              : Number(getdata.ManagersListReducer.managersListData.at(-1).id),
          limit: pagination.limit,
        },
      });
      getAgents({
        variables: {
          // mainSuperAdminId: JSON.parse(localStorage.getItem("ActiveUserdetail")).mainSuperAdminId,
          mainSuperAdminId,
          cursor:
            pagination.page == 0
              ? pagination.limit * pagination.page
              // : Number(store.AgentsListReducer.setAgentsListData.at(-1).id),
              : Number(getdata.AgentsListReducer.setAgentsListData.at(-1).id),
          limit: 9,
        },
      });
    }
  }, [localStorage.getItem("ActiveUserdetail")]);

  const loadMoreDataHandle = (i: React.SyntheticEvent) => {
    //
    const activeUserDetail = localStorage.getItem("ActiveUserdetail");
    const mainSuperAdminId = activeUserDetail ? JSON.parse(activeUserDetail).mainSuperAdminId : null;
    //
    // let bottom = i.target.scrollHeight - i.target.clientHeight - i.target.scrollTop <= 1;
    let bottom = (i.target as HTMLElement).scrollHeight - (i.target as HTMLElement).clientHeight - (i.target as HTMLElement).scrollTop < 1;
    if (bottom) {
      if (
        openPanelCategory === "managerlist" &&
        pagination.hasManagerNextPage
      ) {
        getManagers({
          variables: {
            // mainSuperAdminId: JSON.parse(localStorage.getItem("ActiveUserdetail")).mainSuperAdminId,
            mainSuperAdminId,
            cursor:
              pagination.page == 0
                ? pagination.limit * pagination.page
                // : Number(store.ManagersListReducer.managersListData.at(-1).id),
                : Number(getdata.ManagersListReducer.managersListData.at(-1).id),
            limit: pagination.limit,
          },
        });
      }
      if (openPanelCategory === "agentlist" && pagination.hasAgentNextPage) {
        getAgents({
          variables: {
            // mainSuperAdminId: JSON.parse(localStorage.getItem("ActiveUserdetail")).mainSuperAdminId,
            mainSuperAdminId,
            cursor:
              pagination.page == 0
                ? pagination.limit * pagination.page
                // : Number(store.AgentsListReducer.setAgentsListData.at(-1).id),
                : Number(getdata.AgentsListReducer.setAgentsListData.at(-1).id),
            limit: pagination.limit,
          },
        });
      }
    }
  };

  // interface TeamData {
  //   teamName: string;
  //   agentArr: number[];
  //   managerId: number;
  // }

  type ErrorProp = {
    teamName?: string;
    listError?: string;
    managerId?: number | null;
  };
  // const checkValidation = (data: TeamData) => {
  const checkValidation = (teamName: string, agentArr: number[], managerId: number | null) => {
    const error: ErrorProp = {};

    // if (data.teamName.trim() == "") {
    //   error.teamName = "Team name required";
    // }
    // if (data.agentArr.length == 0) {
    //   error.listError = "Please add members";
    // }
    // if (data.managerId == null) {
    //   error.listError = "Please add members";
    // }

    if (teamName.trim() == "") {
      error.teamName = "Team name required";
    }
    if (agentArr.length == 0) {
      error.listError = "Please add members";
    }
    if (managerId == null) {
      error.listError = "Please add members";
    }
    dispatch_({ type: "ON_ERROR", payload: error });

    return !Object.keys(error).length;
  };

  const handleSelectedUsers = (data: dataProp[]) => {
    // let tempData = [...inviteList];
    let tempData: dataProp[] = [...inviteList];
    const arrForNewManagerAgentList: dataProp[] = [...inviteList];
    if (tempData.length > 0) {
      for (let index = 0; index < data.length; index++) {

        const isExist = tempData.filter((item) => item.id == data[index].id);

        if (isExist.length == 0) {
          arrForNewManagerAgentList.push(data[index]);
        }
      }
      tempData = [...arrForNewManagerAgentList];
      setInviteList(tempData);
    } else {
      setInviteList([...data]);
    }
  };

  const handleRemoveUser = (index: number) => {
    let tempData = [...inviteList];
    tempData.splice(index, 1);
    setInviteList(tempData);
  };

  // create team  //
  const handleCreateTeam = gql`
    mutation creamTeam(
      $managerId: ID!
      $agentArray: [ID!]
      $teamName: String!
    ) {
      createTeam(
        managerId: $managerId
        agentArray: $agentArray
        teamName: $teamName
      ) {
        success
        error
      }
    }
  `;

  let [
    creamTeam,
    {
      loading: CreateTeamMutationLoading,
      error: CreateTeamMutationError,
      data: CreateTeamMutationResult,
    },
  ] = useMutation(handleCreateTeam);
  // create team  //

  useEffect(() => {
    if (CreateTeamMutationResult && CreateTeamMutationResult.createTeam) {
      // CreateTeamMutationResult.createTeam.success == 1 && onClose();
      success("Team created successfully");
    }
  }, [CreateTeamMutationResult]);

  const handleCreateTeamSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    //
    const agentArr: number[] = [];
    let managerId = null;
    inviteList.forEach((element) => {
      if (element.designation.paneltype == "AGENT") {
        agentArr.push(Number(element.id));
      }
      if (element.designation.paneltype == "MANAGER") {
        managerId = Number(element.id);
      }
    });

    const isValid = checkValidation(teamName, agentArr, managerId);
    if (isValid) {
      creamTeam({
        variables: {
          managerId: managerId,
          agentArray: agentArr,
          teamName: teamName,
        },
      });
    }
  };

  useEffect(() => {
    // if (CreateTeamMutationError && CreateTeamMutationError.errors) {
    if (CreateTeamMutationError && CreateTeamMutationError.graphQLErrors) {
      error("Something went wrong");
    }
  }, [CreateTeamMutationError]);

  /////////////////////////////////////////////////////
  const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true)
  //   const handleClose = () => setOpen(false)
  const handleClose = () => {
    //closing Modal
    setOpen(false);
    //closing the dropdown
    handleCloseDropDown();
    //turning value to false - for not modal appearing for clicking on the openModal
    closeModal();
    // setOpen(false)
  };
  //
  useEffect(() => {
    setOpen(check);
  }, [check]);
  //

  const [name, setName] = useState("");

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
  };
  //
  const fieldStyle = {
    width: "360px",
    // height: "25px",
    height: "38px",
    border: "0.0px solid #BDC0CC",
    borderRadius: "3px",
    background: "#FFFFFF",
    padding: "0px",
    marginTop: "0px",
    marginBottom: "0px",
    //
    "& .MuiInputBase-input": {
      "&::placeholder": {
        color: "purple",
      },
      "&$input": {
        color: "red",
        fontWeight: "bold",
      },
    },
  };

  const [openChooseAgents, setOpenChooseAgents] = useState(false);
  const closeNestedModal = () => {
    setOpenChooseAgents(false);
  };

  const [openChooseManager, setOpenChooseManager] = useState(false);
  const closeNestedModal2 = () => {
    setOpenChooseManager(false);
  };

  const chipSX = {
    height: "14px",
    fontWeight: "400",
    fontSize: "8px",
    lineHeight: "14px",
    boxShadow: "none",
    //
    "& .MuiChip-label": {
      padding: "0px 2px",
    },
  };
  const chipOrange = {
    ...chipSX,
    color: "#FF8A00",
    backgroundColor: "rgba(255, 138, 0,0.05)",
    borderRadius: "2px",
    height: "18px",
  };

  const chipSX2 = {
    // width: "60px",
    height: "14px",
    fontWeight: "400",
    fontSize: "8px",
    lineHeight: "14px",
    boxShadow: "none",
    //
    "& .MuiChip-label": {
      padding: "0px 2px",
    },
  };

  //admnin
  const chipBlue = {
    ...chipSX2,
    color: "#0085FF",
    backgroundColor: "rgba(0, 133, 255,0.05)",
    borderRadius: "2px",
    height: "18px",
  };

  const { classes } = useStyles();

  const data = [1, 2, 3, 4, 5, 6, 7];

  return (
    <div>
      <span>Create Team</span>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        onClick={(event) => {
          event.stopPropagation();
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        {inviteList.length === 0 ? (
          <Box sx={style}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                sx={{
                  width: "100%",
                  height: "21px",
                  fontWeight: "500",
                  fontFamily: "poppins",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#272525",
                  marginTop: "10px",
                  marginLeft: "20px",
                  //
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Create Team
              </Box>
              <span onClick={handleClose} style={{ height: "30px" }}>
                <CloseIcon
                  style={{
                    // height: '10.8px',
                    // width: '10.8px',
                    height: "18.8px",
                    width: "18.8px",
                    color: "#B9B9B9",
                    marginTop: "13.61px",
                    marginRight: "13.6px",
                    cursor: "pointer",
                  }}
                />
              </span>
            </Box>
            <Divider
              sx={{ width: "100%", marginTop: "10px", color: "#E8E8E8" }}
            />
            <Typography
              sx={{
                width: "61px",
                height: "15px",
                fontFamily: "poppins",
                fontWeight: "400",
                // fontSize: "10px",
                // lineHeight: "15px",
                fontSize: "11px",
                lineHeight: "17px",
                // color: "#A0A0A0",
                color: "#272525",
                margin: "10px 20px 0px 20px",
              }}
              variant="body1"
            >
              Team name
            </Typography>

            <form onSubmit={handleSubmit}>
              <Box sx={{ padding: "2px 20px 0px 20px" }}>
                <OutlinedInput
                  className={classes.root}
                  style={fieldStyle}
                  value={teamName}
                  onChange={(event) => setTeamName(event.target.value)}
                  fullWidth
                  placeholder="Add a name"
                  inputProps={{
                    disableUnderline: true,
                    style: {
                      //
                      width: "100%",
                      height: "38px",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "400",
                      fontFamily: "poppins",
                      //
                      padding: "0",
                      paddingLeft: "10px",
                    },
                  }}
                />

                <div
                  style={{
                    display: "flex",
                    marginTop: "20.5px",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    onClick={() => {
                      setOpenChooseAgents(true);
                      //
                      setOpenPanelCategory("agentlist");
                      setIsListOfAgentManagerOpen(!isListOfAgentManagerOpen);
                    }}
                    style={{
                      display: "flex",
                      marginRight: "50px",
                      cursor: "pointer",
                    }}
                  >
                    <button
                      style={{
                        width: "18px",
                        height: "16px",
                        // height: '18px',
                        borderRadius: "2px",
                        backgroundColor: "rgba(77, 18, 119, 0.1)",
                        //
                        border: "none",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AddIcon
                        style={{
                          width: "12.4px",
                          height: "12.4px",
                          color: "#4D1277",
                          fontWeight: "bold",
                        }}
                      />
                    </button>
                    <Typography
                      sx={{
                        // width: '81px',
                        width: "100%",
                        height: "15px",
                        fontFamily: "poppins",
                        fontWeight: "400",
                        // fontSize: "10px",
                        // lineHeight: "15px",
                        fontSize: "12px",
                        lineHeight: "17px",
                        //
                        color: "#272525",
                        //   margin: '10px 20px 0px 20px',
                        marginLeft: "5px",
                      }}
                      variant="body1"
                    >
                      {/* Choose Agents */}
                      <AgentModal
                        check={openChooseAgents}
                        closeModal={closeNestedModal}
                        //
                        handleSelectedUsers={handleSelectedUsers}
                        loadMoreDataHandle={loadMoreDataHandle}
                        openPanelCategory={openPanelCategory}
                        getManagersQueryLoading={getManagersQueryLoading}
                        getAgentsQueryLoading={getAgentsQueryLoading}
                      />
                    </Typography>
                  </div>

                  <div
                    onClick={() => {
                      setOpenChooseManager(true);
                      //
                      setOpenPanelCategory("managerlist");
                      setIsListOfAgentManagerOpen(!isListOfAgentManagerOpen);
                    }}
                    style={{ display: "flex", cursor: "pointer" }}
                  >
                    <button
                      style={{
                        width: "18px",
                        height: "16px",
                        // height: '18px',
                        borderRadius: "2px",
                        backgroundColor: "rgba(77, 18, 119, 0.1)",
                        //
                        border: "none",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AddIcon
                        style={{
                          //   width: '8.4px',
                          //   height: '8.4px',
                          width: "12.4px",
                          height: "12.4px",
                          color: "#4D1277",
                          fontWeight: "bold",
                        }}
                      />
                    </button>
                    <Typography
                      sx={{
                        // width: '100px',
                        width: "100%",
                        height: "15px",
                        fontFamily: "poppins",
                        fontWeight: "400",
                        // fontSize: "10px",
                        // lineHeight: "15px",
                        fontSize: "12px",
                        lineHeight: "17px",
                        //
                        color: "#272525",
                        //   margin: '10px 20px 0px 20px',
                        marginLeft: "5px",
                      }}
                      variant="body1"
                    >
                      {/* Choose Managers */}
                      <ManagerModal
                        check={openChooseManager}
                        closeModal={closeNestedModal2}
                        //
                        handleSelectedUsers={handleSelectedUsers}
                        loadMoreDataHandle={loadMoreDataHandle}
                        openPanelCategory={openPanelCategory}
                        getManagersQueryLoading={getManagersQueryLoading}
                        getAgentsQueryLoading={getAgentsQueryLoading}
                      />
                    </Typography>
                  </div>
                </div>
              </Box>
            </form>
          </Box>
        ) : (
          <Box sx={style}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                sx={{
                  width: "100%",
                  height: "21px",
                  fontWeight: "500",
                  fontFamily: "poppins",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#272525",
                  marginTop: "10px",
                  marginLeft: "20px",
                  //
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Create Team
              </Box>
              <span onClick={handleClose} style={{ height: "30px" }}>
                <CloseIcon
                  style={{
                    // height: '10.8px',
                    // width: '10.8px',
                    height: "18.8px",
                    width: "18.8px",
                    color: "#B9B9B9",
                    marginTop: "13.61px",
                    marginRight: "13.6px",
                    cursor: "pointer",
                  }}
                />
              </span>
            </Box>
            <Divider
              sx={{ width: "100%", marginTop: "10px", color: "#E8E8E8" }}
            />

            <form onSubmit={handleCreateTeamSubmit}>
              <Box sx={{ padding: "0px 10px 0px 10px" }}>
                <Typography
                  variant="subtitle2"
                  style={{
                    width: "100%",
                    fontFamily: "poppins",
                    fontWeight: "400",
                    // fontSize: "10px",
                    // lineHeight: "15px",
                    fontSize: "12px",
                    lineHeight: "17px",
                    //
                    color: "#A0A0A0",
                    marginTop: "10px",
                  }}
                >
                  Team name
                </Typography>

                <OutlinedInput
                  type="text"
                  value={teamName}
                  placeholder={teamName.length === 0 ? "Team name is required" : ""}
                  onChange={(e) => setTeamName(e.target.value)}
                  style={{
                    width: "100%",
                    // height: "25px",
                    height: "38px",
                    fontFamily: "poppins",
                    fontWeight: "500",
                    // fontSize: "10px",
                    // lineHeight: "15px",
                    fontSize: "16px",
                    lineHeight: "24px",
                    marginTop: "0px",
                    padding: "5px",
                    //
                    background: "#FFFFFF",
                    border: "0px solid #BDC0CC",
                    borderRadius: "3px",
                  }}
                  inputProps={{
                    style: {
                      padding: "5px",
                      fontFamily: "poppins",
                      fontWeight: "500",
                      // fontSize: "10px",
                      // lineHeight: "15px",
                      fontSize: "12px",
                      lineHeight: "17px",
                      //
                      color: teamName.length === 0 ? "red" : "#272525",
                    }
                  }}
                />

                <Typography
                  variant="subtitle2"
                  style={{
                    width: "100%",
                    fontFamily: "poppins",
                    fontWeight: "400",
                    // fontSize: "10px",
                    // lineHeight: "15px",
                    fontSize: "12px",
                    lineHeight: "17px",
                    //
                    color: "#A0A0A0",
                    marginTop: "10px",
                  }}
                >
                  Members
                </Typography>

                <Box
                  // onScroll={loadMoreDataHandle}
                  sx={{
                    marginTop: "10px",
                    // height: "165px",
                    maxHeight: "165px",
                    overflowY: "auto",
                    //
                    scrollbarWidth: "thin",
                    scrollbarColor: "#ccc transparent",
                    "&::-webkit-scrollbar": {
                      width: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#ccc",
                      borderRadius: "3px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                    //
                  }}
                >
                  {inviteList.map((item, index) => (
                    <ListItem
                      alignItems="center"
                      sx={{ padding: "0px", justifyContent: "space-between" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <input checked={false} type="checkbox" />
                        <Avatar
                          alt="John Doe"
                          src={image}
                          sx={{
                            width: "30px",
                            height: "30px",
                            marginLeft: "10px",
                            marginRight: "5px",
                            marginTop: "5px",
                            marginBottom: "5px",
                          }}
                        />
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontFamily: "poppins",
                            fontWeight: "500",
                            fontSize: "12px",
                            lineHeight: "18px",
                            marginTop: 0,
                            color: "#272525",
                            //
                            marginRight: "7px",
                          }}
                        >
                          {/* JohhnyDev */}
                          {item.name}
                        </Typography>
                        {/* <Chip label="Agent" sx={chipBlue} /> */}
                        <Chip
                          label={item.designation.paneltype}
                          sx={
                            item.designation.paneltype === "AGENT"
                              ? chipBlue
                              : chipOrange
                          }
                        />
                      </Box>
                      <CloseIcon
                        onClick={() => handleRemoveUser(index)}
                        sx={{
                          height: "18px",
                          width: "18px",
                          color: "#B9B9B9",
                          marginRight: "8px",
                          //
                          cursor: "pointer",
                        }}
                      />
                    </ListItem>
                  ))}
                </Box>

                <div
                  style={{
                    display: "flex",
                    marginTop: "15px",
                  }}
                >
                  <div
                    onClick={() => {
                      setOpenChooseAgents(true);
                      //
                      setOpenPanelCategory("agentlist");
                      setIsListOfAgentManagerOpen(!isListOfAgentManagerOpen);
                    }}
                    style={{
                      display: "flex",
                      marginRight: "50px",
                      cursor: "pointer",
                    }}
                  >
                    <button
                      style={{
                        width: "18px",
                        height: "16px",
                        // height: '18px',
                        borderRadius: "2px",
                        backgroundColor: "rgba(77, 18, 119, 0.1)",
                        //
                        border: "none",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AddIcon
                        style={{
                          width: "12.4px",
                          height: "12.4px",
                          color: "#4D1277",
                          fontWeight: "bold",
                        }}
                      />
                    </button>
                    <Typography
                      sx={{
                        // width: '81px',
                        width: "100%",
                        height: "15px",
                        fontFamily: "poppins",
                        fontWeight: "400",
                        // fontSize: "10px",
                        // lineHeight: "15px",
                        fontSize: "12px",
                        lineHeight: "17px",
                        //
                        color: "#272525",
                        //   margin: '10px 20px 0px 20px',
                        marginLeft: "5px",
                      }}
                      variant="body1"
                    >
                      {/* Choose Agents */}
                      <AgentModal
                        check={openChooseAgents}
                        closeModal={closeNestedModal}
                        //
                        handleSelectedUsers={handleSelectedUsers}
                        loadMoreDataHandle={loadMoreDataHandle}
                        openPanelCategory={openPanelCategory}
                        getManagersQueryLoading={getManagersQueryLoading}
                        getAgentsQueryLoading={getAgentsQueryLoading}
                      />
                    </Typography>
                  </div>

                  <div
                    onClick={() => {
                      setOpenChooseManager(true);
                      //
                      setOpenPanelCategory("managerlist");
                      setIsListOfAgentManagerOpen(!isListOfAgentManagerOpen);
                    }}
                    style={{ display: "flex", cursor: "pointer" }}
                  >
                    <button
                      style={{
                        width: "18px",
                        height: "16px",
                        // height: '18px',
                        borderRadius: "2px",
                        backgroundColor: "rgba(77, 18, 119, 0.1)",
                        //
                        border: "none",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AddIcon
                        style={{
                          //   width: '8.4px',
                          //   height: '8.4px',
                          width: "12.4px",
                          height: "12.4px",
                          color: "#4D1277",
                          fontWeight: "bold",
                        }}
                      />
                    </button>
                    <Typography
                      sx={{
                        // width: '100px',
                        width: "100%",
                        height: "15px",
                        fontFamily: "poppins",
                        fontWeight: "400",
                        // fontSize: "10px",
                        // lineHeight: "15px",
                        //
                        fontSize: "12px",
                        lineHeight: "17px",
                        //
                        color: "#272525",
                        //   margin: '10px 20px 0px 20px',
                        marginLeft: "5px",
                      }}
                      variant="body1"
                    >
                      {/* Choose Managers */}
                      <ManagerModal
                        check={openChooseManager}
                        closeModal={closeNestedModal2}
                        //
                        handleSelectedUsers={handleSelectedUsers}
                        loadMoreDataHandle={loadMoreDataHandle}
                        openPanelCategory={openPanelCategory}
                        getManagersQueryLoading={getManagersQueryLoading}
                        getAgentsQueryLoading={getAgentsQueryLoading}
                      />
                    </Typography>
                  </div>
                </div>
                <Divider
                  sx={{ width: "100%", marginTop: "10px", color: "#E8E8E8" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    type="submit"
                    sx={{
                      width: "73px",
                      height: "28px",
                      background: "#F5F5F5",
                      borderRadius: "3px",
                      //
                      fontFamily: "poppins",
                      fontWeight: "400",
                      fontSize: "12px",
                      lineHeight: "18px",
                      color: "#777777",
                      marginRight: "5px",
                      "&:hover": {
                        color: "#777777",
                        backgroundColor: "#F5F5F5",
                      },
                      //
                      textTransform: "capitalize",
                      boxShadow: "none",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      width: "63px",
                      height: "28px",
                      backgroundColor: "#4D1277",
                      borderRadius: "3px",
                      fontFamily: "poppins",
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: "18px",
                      color: "#FFFFFF",
                      //
                      textTransform: "capitalize",
                      boxShadow: "none",
                    }}
                  >
                    <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>Create {CreateTeamMutationLoading ? <CircularProgress style={{ marginLeft: "3px", width: "15px", height: "15px", color: "white" }} /> : ""}</Box>
                  </Button>
                </div>
              </Box>
            </form>
          </Box>
        )}
      </Modal>
    </div>
  );
};

export default KeepMountedModal;
