import { makeStyles } from 'tss-react/mui'
const useStyles = makeStyles()((theme) => ({
  close: {
    height: 15,
    width: 15,
    marginTop: -10,
    color: '#B9B9B9',
    cursor: 'pointer',
  },
  tab:{
    minWidth: '5%'
  },
  icon:{
    borderBottom:`1px solid ${theme.palette.primary.main}` ,
  },
  color:{
    color: theme.palette.primary.main,
  }
}))
export default useStyles
