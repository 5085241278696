import { makeStyles } from 'tss-react/mui'

const useStyle = makeStyles()(() => ({
  listStyle: {
    padding: '10px 0px 10px 10px',
    marginBottom: '10px',
    width: '700px',
    borderBottom: '0.5px solid #DFE2E5',
    display: 'flex',
    justifyContent: 'space-between',
    //
    backgroundColor: '#FFFFFF',
    // backgroundColor: '#ddd5d5',
  },
  iconBox: {
    display: 'flex',
    paddingRight: '15px',
    height: '37px',
    paddingTop: '25px',
  },
  hover: {
    backgroundColor: '#FFFFFF',
    // opacity: 0,
    transition: 'opacity 0.2s',
    '&:hover': {
    //   opacity: 1,
      backgroundColor: '#ddd5d5',
    },
  },

  hover2: {
    opacity: 0,
    transition: 'opacity 0.2s',
    '&:hover': {
      opacity: 1,
    },
  },


}))

export default useStyle
