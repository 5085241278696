import React, { useState, useEffect } from 'react'

import SendMessageModal from '../modals/SendMessageModal'

import { gql } from "apollo-boost";
import { useSnackbar } from "notistack";
import { useLazyQuery, } from "@apollo/react-hooks";
import { connect } from "react-redux";

import {

  setSmsChatListData
} from "../../store/actions/ChatBoxActions";

import {
  Divider, Typography,
  Button, Grid,
} from '@mui/material'
import useStyles from './StylesSendMessage'

function SendMessage(props) {
  const { enqueueSnackbar } = useSnackbar();

  const [modalShow, setModalShow] = React.useState(false)
  const [twilioConfiguration, setTwilioConfiguration] = useState(null);
  console.log(twilioConfiguration, "twilioConfiguration")
  const getTwilioConfigurationQuery = gql`
    query getTwilioConfiguration($accessToken: String) {
      getTwilioConfiguration(accessToken: $accessToken) {
        id
        authToken
        accountSID
        phoneNumber{
          phoneNumber
          active
        }
        active
      }
    }
  `;
  let [
    getTwilioConfiguration,
    {
      loading: getTwilioConfigurationQueryLoading,
      error: getTwilioConfigurationQueryError,
      data: getTwilioConfigurationQueryResult,
    },
  ] = useLazyQuery(getTwilioConfigurationQuery, {
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (getTwilioConfigurationQueryError) {
      getTwilioConfigurationQueryError.graphQLErrors.map(({ message }, i) => {
        enqueueSnackbar(message, { variant: "error" });
      });
    }
  }, [getTwilioConfigurationQueryError]);

  useEffect(() => {
    getTwilioConfiguration();
  }, []);

  useEffect(() => {
    if (getTwilioConfigurationQueryResult && getTwilioConfigurationQueryResult.getTwilioConfiguration) {
      const { getTwilioConfiguration } = getTwilioConfigurationQueryResult
      if (getTwilioConfiguration && getTwilioConfiguration.phoneNumber && getTwilioConfiguration.phoneNumber.length) {
        const { phoneNumber } = getTwilioConfiguration;
        let activePhnRecord = phoneNumber.find(curr => curr.active == true);
        if (activePhnRecord) {
          setTwilioConfiguration({
            accountSID: getTwilioConfiguration.accountSID,
            authToken: getTwilioConfiguration.authToken,
            phoneNumber: activePhnRecord.phoneNumber
          })
        }
      }
    }
  }, [getTwilioConfigurationQueryResult])
  const { classes } = useStyles()


  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between"
        sx={{ height: 58, }}>
        <Grid item >
          <Typography className={classes.text} >Send messages</Typography>
        </Grid>
        <Grid item >
          <Button onClick={() => setModalShow(true)}
            disabled={twilioConfiguration != null ? false : true}
            className={classes.textButton}
            color="primary" variant='contained'>
            Send</Button>
        </Grid>
      </Grid >
      <Divider />





      <SendMessageModal
        customerId={props.customerId}
        agentId={props.agentId}
        twilioConfig={twilioConfiguration}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>

  )
}

const mapStateToProps = (state) => {
  return {
    ...state.ChatBoxReducer,
  };
};
export default connect(mapStateToProps, {
  setSmsChatListData,
})(SendMessage);

