import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import { styled, } from '@mui/material/styles';
import useStyles from './Styles'


const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    paddingLeft: `3em`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '15ch',
    },
  },
}));

interface Props {
  type?: string
  value?: string
  onChange?: any
}
const placeholderStyle = {
  width: "100%",
  height: "100%",
  /* Placeholder text color */
  color: "#838383",
  /* Placeholder font style */
  // fontStyle: "italic",
  /* Placeholder font size */
  fontSize: 12,
  // height: 50,
  /* Placeholder font weight */
  fontWeight: 400,
};

const LeftChatComponentSearch: React.FC<Props> = ({ type, value, onChange }) => {
  const { classes } = useStyles()
  return (

    <Box className={classes.search}>
      <Box className={classes.searchIcon}>
        <SearchIcon />
      </Box>
      <StyledInputBase
        placeholder={'search'
        }
        style={placeholderStyle}
        type={type} value={value} onChange={onChange}
        // placeholder={<Typography className={classes.placeholder}>Search</Typography>}
        inputProps={{ 'aria-label': 'search' }}
      />
    </Box>
  )
}

export default LeftChatComponentSearch


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: 2,
  // backgroundColor: grey[100],
  '&:hover': {
    backgroundColor: theme.custom?.background
  },
  marginTop: 3,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    // marginLeft: theme.spacing(3),  
    width: 'auto',
  },
}));
const SearchIconWrapper = styled('div')(() => ({
  padding: 10,
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const ComponentSearch: React.FC<Props> = ({ type, value, onChange }) => {
  return (

    <Box sx={{
      height: '25px',
      width: '98%',
      paddingLeft: 1,
      // marginTop: 20,
    }}>
      <Box sx={{
        position: 'relative',
        borderRadius: 1,
        minHeight: 'unset',
        // border: '0.5px solid #E8E8E8',
        border: '0.5px solid #E8E8E8',
        // backgroundColor: grey[100],
        '&:hover': {
          // backgroundColor: "blue",
        },
        height: '33px',
        // marginRight: 2,
        // marginTop: 10,
        marginLeft: 0,
        width: '100%',

      }}>

        <Search>
          <SearchIconWrapper>
            <SearchIcon sx={{ color: "#838383" }} />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder={'search'
            }
            style={placeholderStyle}
            type={type} value={value} onChange={onChange}
            

          />
        </Search>

      </Box>
    </Box>
  )
}


