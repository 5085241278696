import React, { useEffect } from "react";

import { connect } from "react-redux";
import {
  setUserPanelFullscreenToggle,
  setUserPanelChatOnline,
} from "../../store/actions/UserPanelActions";
import {
  setAuthMainAppBarHeight,
  setAuthUserWsSubscriptionReady,
} from "../../store/actions/AuthActions";
import Fullscreen from "fullscreen-react";

import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

import { Redirect } from "react-router-dom";
import { setRedirectToPath } from "../../store/actions/RedirectToPathActions";

import includes from "../chatBox/includes";

import MainContentContainer from "./MainContentContainer";
import { BrowserRouter } from "react-router-dom";


const UserPanel = (props) => {


  useEffect(() => {
    new includes().setSubscriptionReadyIfUserIdIsAvailable(
      props.authUserId,
      props.wsLink,
      props.setAuthUserWsSubscriptionReady
    );
  }, [props.authUserId]);


  const MeQuery = gql`
    query Me($accessToken: String) {
      me(accessToken: $accessToken) {
        id
        name
        picture
        email
        pseudonym
        designation {
          name
        }
      }
    }
  `;

  let {
    loading: meQueryLoading,
    error: meQueryQueryError,
    data: meQueryResult,
  } = useQuery(MeQuery, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (meQueryResult && meQueryResult.me.name) {
      document.title = `Welcome ${meQueryResult.me.name}`;
    }
  }, [meQueryResult]);



  if (props.redirectToPath) {
    const path = props.redirectToPath;
    props.setRedirectToPath(null);
    return <Redirect to={{ pathname: path }} />;
  }

  return (
    // <Fullscreen isEnter={props.userPanelFullscreenToggle}>




    <BrowserRouter>

      <MainContentContainer />
    </BrowserRouter>

    // </Fullscreen>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.UserPanelReducer,
    ...state.RedirectToPathReducer,
    ...state.AuthReducer,
    ...state.ChatBoxReducer,
  };
};
export default connect(mapStateToProps, {
  setRedirectToPath,
  setUserPanelFullscreenToggle,
  setAuthMainAppBarHeight,
  setUserPanelChatOnline,
  setAuthUserWsSubscriptionReady,
})(UserPanel);
