import React, { useEffect, useState, useMemo, } from "react";
import {
  Container,
  Tabs,
  Tab, Grid, CardHeader

} from "@mui/material";
import { connect } from "react-redux";
import {
  setChatBoxRecentSearchInputText,
  setChatBoxRecentSearchText,
  setChatBoxRecentChatListData,
  setChatBoxSelectedChatsOnFloating,
  setChatBoxFacebookIDsWithProfileDetails,
  setChatBoxMarkNotToAddInChatCircleForLabel,
  setChatBoxRecentSearchChatIds,
  setChatBoxRecentChatListShowAllListToggle,
  setChatBoxRecentChatListShowAllListByManagerToggle,
} from "../../store/actions/ChatBoxActions";
import {
  setUsersListSelectedUser,
  setUsersListData,
} from "../../store/actions/UsersListActions";
import {
  setLabelListData,
  setLabelListTextInput,
} from "../../store/actions/LabelListActions";
import { setUserPanelChatOnline } from "../../store/actions/UserPanelActions";
import { makeStyles } from "@material-ui/core/styles";
import ChatList from "./ChatList";
import _ from "lodash";
import "../../otherComponents/react-splitter-layout/stylesheets/index.css";
import ChatContainer from "./ChatContainer";
import ChatSubscription from "./ChatSubscription";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Facebook } from "../../auth/Facebook";
import includes from "./includes";
import UsersList from "./UsersList";
import ManagerList from "./ManagerList";
import { styled } from "@mui/material/styles";
import RightPanelChatBox from "./ChatContainerComponents/rigthPanelChatBox/RightPanelChatBox";
import { Card } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import ChatMainCardHeader from "../../componentsfromkuikwit/components/pages/chats/chatMainCard/ChatMainCardHeader";
import SearchIcon from '@mui/icons-material/Search';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    paddingLeft: `3em`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: 2,
  // backgroundColor: grey[100],
  '&:hover': {
    backgroundColor: theme.custom?.background
  },
  marginTop: 3,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    // marginLeft: theme.spacing(3),  
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(() => ({
  padding: 10,
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));


const useStyles = makeStyles((theme) => ({

  searchTodayChatsTextField: { width: "100%" },

  textFieldInputRoot: {
    width: "100%",
    background: "#eeeeee",
    color: "black",
    // border:'1px solid red',
    // height:'35px',
    paddingLeft: "35px !important",
    borderBottom: "none",
  },
  textFieldInput: {
    height: 41,

    padding: "0px 4px",
    borderBottom: 0,
  },
  recentPagesLeftPane: {
    // borderRight: "1px solid #dedede",
    flex: 0.26,
    margin: 0,
  },
  clearSearchButton: {
    padding: 0,
    marginRight: 9,
    order: 1,
  },
  bottomChatsTabs: {
    position: "fixed",
    bottom: 0,
    zIndex: 1000,
    height: 52,
    maxWidth: "100%",
  },
  bottomChatsTab: {
    background: "#1a2733",
    maxWidth: 300,

    marginRight: 6,
  },
  bottomChatsTabText: {
    display: "inline",
    color: "black",
    marginLeft: 5,
    width: 150,
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontStyle: "bold",
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  bottomChatsTabContainer: {
    display: "flex",
    alignItems: "center",
  },
  bottomChatsCloseButton: {
    color: "white",
    marginLeft: 15,
  },
  bottomChatsTabPageImage: {
    marginRight: 2,
    background: "#737272",
  },
  bottomChatsTabPanel: {
    zIndex: 1000,
    background: "#1a2733",
    position: "fixed",
    bottom: 0,
    right: 0,
    width: 400,
  },
  bottomChatsTabScrollButtons: {
    background: "#66c047",
    color: "white",
  },
  chatMainContainer: {},
  chatTabHeader: {
    padding: 5,
    background: "#1a2733",
  },
  chatBoxRecentSearchList: {
    overflowY: "auto",
    overflowX: "hidden",
    marginTop: 4,
  },
  totalRecentChatsLoadText: {
    background: "#bdbdbd",
    color: "white",
    padding: "0 5px",
    fontSize: 13,
    display: "flex",
    alignItems: "center",
  },
  chatBoxSerachTextBoxContainer: {
    display: "flex",
  },
  chatBoxRecentShowAllChatListToggleButton: {
    color: "white",
    background: "#f50057",
    borderRadius: 0,
    minWidth: 0,
    "&:hover": {
      background: "#e14079",
    },
  },
  chatBoxRecentShowAllChatListToggleButtonDisabled: {
    background: "#b7b3b3",
  },
  chatBoxRecentChatListShowAllListByManagerCheckbox: {
    borderBottom: "1px solid #949494",
    borderRadius: 0,
    paddingBottom: 0,
    paddingTop: 1,
  },
  horizontalDropdownActive: {
    fontStyle: "bold",
  },
  activeClass: {
    fontStyle: "bold",
  },

  "&:hover": {
    background: "white",
  },
}));


const ChatBox = (props) => {
  const [valueTab, setValueTab] = useState(0)
  const [value, setValue] = useState(new Date());
  const [openReminder, setOpenReminder] = useState(false);
  const [ifManagertabPressed, setIfManagertabPressed] = useState(false);
  const [ifMyChatsTabPressed, setIfMyChatsTabPressed] = useState(true);
  const [ifAgentsTabPressed, setIfAgentsTabPressed] = useState(false);
  const [showUserDetail, setShowUserDetail] = useState(false);
  const [showLeadFormDetail, setShowLeadFormDetail] = useState(false);
  const [openAddLabelInput, setOpenAddLabelInput] = useState(false);
  const [activeUserDetail, setActiveUserDetail] = useState({});
  const [TemporarySelection, setTemporarySelection] = useState([]);
  var selectedChatsOnFloatingTabpanelItem = _.find(
    props.chatBoxSelectedChatsOnFloating,
    (itm) => itm.selected === true
  );

  document.title = "Chats";

  const classes = useStyles();
  let notificationMutation = gql`
    mutation addNotification(
      $customerId: String!
      $pageId: String!
      $agentId: ID!
      $timeStamp: String!
      $read: Boolean!
      $customername: String
    ) {
      addNotification(
        customerId: $customerId
        pageId: $pageId
        agentId: $agentId
        timeStamp: $timeStamp
        read: $read
        customername: $customername
      ) {
        success
        error
      }
    }
  `;
  const [
    addNotification,
    {
      // loading: notificationLoading,
      // error: notificationError,
      data: notificationResult,
    },
  ] = useMutation(notificationMutation);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handledateTime = () => {
    addNotification({
      variables: {
        agentId: TemporarySelection?.agentId,
        customerId: TemporarySelection?.customerId,
        pageId: TemporarySelection?.pageId,
        timeStamp: value,
        read: false,
        customername: selectedChatsOnFloatingTabpanelItem.customername,
      },
    });
  };

  useEffect(() => {
    console.log("handledateTime is changing",props)
  }, [handledateTime]);



  useEffect(() => {
    if (notificationResult) {
      setOpenReminder(true);
    }
  }, [notificationResult]);
  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    props.chatBoxSelectedChatsOnFloating.map((item) => {
      if (item.selected) {
        setTemporarySelection(item);
      }
    });
  }, [props.chatBoxSelectedChatsOnFloating]);


  useEffect(() => {
    Facebook.fbInt();
  }, []);
  useEffect(() => {
    if (props.usersListSelectedUser) {
      setIfAgentsTabPressed(false);
      setIfManagertabPressed(false);
      setIfMyChatsTabPressed(false);
    }
  }, [props.usersListSelectedUser]);

  const AddChatDetailQuery = gql`
    mutation AddChatDetail(
      $customerId: String!
      $pageId: String!
      $messageId: String
      $messagetext: String!
      $messagetimestamp: String!
      $messagetype: String!
      $agentId: ID!
      $alternateagentId: ID
      $read: Int!
    ) {
      addchatdetail(
        customerId: $customerId
        pageId: $pageId
        messageId: $messageId
        messagetext: $messagetext
        messagetimestamp: $messagetimestamp
        messagetype: $messagetype
        agentId: $agentId
        alternateagentId: $alternateagentId
        read: $read
      ) {
        success
        error
      }
    }
  `;

  let [
    // addChatDetail,
    {
      // loading: addChatDetailQueryLoading,
      error: addChatDetailQueryError,
      // data: addChatDetailQueryResult,
    },
  ] = useMutation(AddChatDetailQuery);

  useEffect(() => {
    if (addChatDetailQueryError) {
      //error
    }
  }, [addChatDetailQueryError]);

  var includesObj = new includes();

  var otherHeightDeduction = 0;
  if (new includes().showChatBoxManagersList(props.authPanelType)) {
    otherHeightDeduction = 52;
  }

  var heightOfListSearch = 41;
  var layoutHeightOfLists =
    props.chatBoxSelectedChatsOnFloating.length > 1
      ? `calc(100vh - ${props.authMainAppBarHeight}px - 52px - ${heightOfListSearch}px - ${otherHeightDeduction}px)`
      : `calc(100vh - ${props.authMainAppBarHeight}px - ${heightOfListSearch}px - ${otherHeightDeduction}px)`;

  const showMyChats = () => {
    setIfMyChatsTabPressed(true);
    setIfManagertabPressed(false);
    setIfAgentsTabPressed(false);
    props.setUsersListSelectedUser(null);
  };

  const showManagers = () => {
    setIfManagertabPressed(true);
    setIfMyChatsTabPressed(false);
    setIfAgentsTabPressed(false);
    props.setUsersListSelectedUser(null);
  };

  const showAllAgents = () => {
    setIfAgentsTabPressed(true);
    setIfManagertabPressed(false);
    setIfMyChatsTabPressed(false);
    props.setUsersListSelectedUser(null);
    props.setUsersListData([]);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showUserDetailPanel = () => {
    setShowUserDetail(!showUserDetail);
  };
  // eslint-disable-next-line no-unused-vars
  const showLeadFormPanel = () => {
    setShowLeadFormDetail(true);
  };
  const backBtnHandler = (id) => {
    let paneltypes =
      props.managersListData &&
      props.managersListData.length &&
      props.managersListData.find((curr) => curr.id === id)?.panelType;

    if (paneltypes === 0 || paneltypes === undefined) {
      paneltypes = "AGENT";
    }

    let paneltype = paneltypes;
    if (paneltype === "AGENT") {
      showAllAgents();
    } else if (paneltype === "MANAGER") {
      showManagers();
    }
  };



  const chatListContainer = (
    <Container disableGutters={true}>
      <Container
        disableGutters={true}
        className={classes.chatBoxSerachTextBoxContainer}
      >
        <Box sx={{
          height: '30px',
          width: '98%',
          paddingLeft: 1,
        }}>
          <Box sx={{
            position: 'relative',
            borderRadius: 1,
            minHeight: 'unset',
            border: '0.5px solid #E8E8E8',
            '&:hover': {
            },
            height: '40px',
            marginLeft: 0,
            width: '100%',

          }}>

            <Search>
              <SearchIconWrapper>
                <SearchIcon sx={{ color: "#838383" }} />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder={'search'
                }
                value={props.chatBoxRecentSearchInputText}
                onInput={(e) => {
                  props.setChatBoxRecentSearchInputText(e.target.value);
                }}
              />
            </Search>
          </Box>
        </Box>

        {new includes().chatBoxRecentShowAllChatListToggleButtonToPanelType(
          props.authPanelType
        ) && <></>}
      </Container>

      <ChatList
        ifMyChatsTabPressed={ifMyChatsTabPressed}
        activeUserDetail={activeUserDetail}
        backBtnHandler={backBtnHandler}
        className={classes.chatBoxRecentSearchList}
        containerHeight={layoutHeightOfLists}
        searchText={props.chatBoxRecentSearchText}
        searchIds={props.chatBoxRecentSearchChatIds}
        onItemClick={(item) => {
          includesObj.bindItemToMainChat(
            item,
            props.chatBoxRecentChatListData,
            props.setChatBoxRecentChatListData,
            props.chatBoxSelectedChatsOnFloating,
            props.setChatBoxSelectedChatsOnFloating,
            props.setChatBoxMarkNotToAddInChatCircleForLabel
          );
        }}
        onLabelRemove={(text, pageId, customerId) => { }}
      />
    </Container>
  );
  const rightPanelChatBox = useMemo(() =>
    <RightPanelChatBox
      showUserDetail={showUserDetail}
      showLeadFormDetail={showLeadFormDetail}
      selectedChatsOnFloatingTabpanelItem={selectedChatsOnFloatingTabpanelItem}
      setOpenAddLabelInput={setOpenAddLabelInput}
      openAddLabelInput={openAddLabelInput}
      authUserId={props.authUserId}
      handledateTime={handledateTime}
      value={value}
      setValue={setValue}
      showUserDetailPanel={showUserDetailPanel}
      setFollowUpDialogToggle={props.setFollowUpDialogToggle}
      openReminder={openReminder}
      setOpenReminder={setOpenReminder}
    />,
    [showUserDetail, showLeadFormDetail, selectedChatsOnFloatingTabpanelItem, openAddLabelInput, props.authUserId, props.setFollowUpDialogToggle, value, openReminder]
  );
  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  let panelWidth;

  if (showUserDetail) {
    panelWidth = 3.811

  } else {
    panelWidth = 3;

  }
  return (
    <>


      <div
        style={{
          marginTop: 90,
          display: "grid",

          height: `calc(100% - ${100}px)`,
          gridTemplateColumns: !showUserDetail ? "auto 1%" : "auto 21.739%",
        }}
      >
        {/* left panel of chats */}
        <Card sx={{
          height: "99%", marginLeft: "20px", marginRight: 0, boxShadow: '0px 5px 10px rgba(127, 63, 152, 0.1)',
          borderRadius: '10px',
        }} >
          <CardHeader sx={{
            height: '42px',
            borderBottom: "1px solid #DFE2E5"
          }} subheader={<ChatMainCardHeader />}></CardHeader>
          {/* 3 tabs below chat */}
          {/* <Divider  /> */}
          <Grid container >

            <Grid item xs={6} md={panelWidth} sx={{
              minWidth: 250,
              width: 300,
              borderRight: "1px solid #dedede",
            }}>

              <Box sx={{
                height: '50px',
                width: '98%',
                paddingTop: 1,
                paddingLeft: 1,
                paddingBottom: 1,
              }}>

                <Tabs value={valueTab} onChange={handleChange} disableRipple>

                  <Tab label="My Chats"
                    onClick={showMyChats} disableRipple />
                  {props.isSuperAdmin && (
                    <Tab label="Managers"
                      onClick={showManagers} disableRipple
                    />
                  )}
                  {!props.isAgent && (
                    <Tab label="Agents"
                      onClick={showAllAgents} disableRipple
                    />
                  )}
                </Tabs>
              </Box>
              {/* Left panel of chats below 3 tabs */}
              <Container disableGutters className={classes.recentPagesLeftPane}>

                {props.authUserWsSubscriptionReady && <ChatSubscription />}


                {/*  */}
                {/* checks if user is manager or SA */}
                {new includes().showChatBoxUsersList(props.authPanelType) ? (
                  <div>
                    <Container disableGutters className={classes.userListPane}>
                      {!props.usersListSelectedUser && ifAgentsTabPressed && (

                        <UsersList
                          managerId={new includes().getUserIdForUserListQuery(
                            props.authUserId,
                            props.authPanelType,
                            props.managersListSelectedManager
                          )}
                          containerHeight={layoutHeightOfLists}
                          onItemClick={(item) =>
                            setActiveUserDetail({
                              id: item.id,
                              paneltype: item.panelType,
                            })
                          }
                        />
                      )}
                      {ifMyChatsTabPressed ? chatListContainer : null}
                      {ifManagertabPressed && (
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <ManagerList authId={props.authUserId} />{" "}
                        </div>
                      )}
                      {props.usersListSelectedUser && chatListContainer}
                    </Container>
                  </div>
                ) : (
                  chatListContainer
                )}

              </Container>
            </Grid>
            <Grid item xs={6} md={12 - panelWidth} sx={{
              height: `calc(100vh - ${150}px)`,
            }}>
              <Grid
                container
                spacing={0}
              >

                {/* Right panel of chats */}

                <Container
                  maxWidth={false}
                  disableGutters={true}
                >

                  {selectedChatsOnFloatingTabpanelItem ? (
                    <Grid item>

                      <ChatContainer
                        chatBoxMessageBoxDynamicHeight={true}
                        chatTabHeaderStyles={classes.chatTabHeader}

                        itemData={selectedChatsOnFloatingTabpanelItem}
                        showUserDetailPanel={showUserDetailPanel}

                      ></ChatContainer>
                    </Grid>
                  ) : (

                    <Grid item sx={{
                      borderLeft: '1px solid #DFE2E5',
                      width: "100%",
                      height: `calc(100vh - ${150}px)`,
                      // border: "1px solid red",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#bbbbbb",
                    }}>
                      No Chats Selected
                    </Grid>

                  )}
                </Container>
              </Grid>
            </Grid>
          </Grid>
        </Card>

        {/* user detail panel onClick */}


        {selectedChatsOnFloatingTabpanelItem !== undefined && showUserDetail && (
          <>
            <Box style={{
              // height: "calc(100vh - 70px)",
              overflowY: "auto",
              margin: "0px 20px",
            }}>
              {rightPanelChatBox}

            </Box>
          </>
        )}
      </div>

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.UserPanelReducer,
    ...state.ChatBoxReducer,
    ...state.AuthReducer,
    ...state.UsersListReducer,
    ...state.ManagersListReducer,
    ...state.LabelListReducer,
  };
};
export default connect(mapStateToProps, {
  setLabelListData,
  setLabelListTextInput,
  setChatBoxRecentSearchInputText,
  setUsersListSelectedUser,
  setUsersListData,
  setChatBoxRecentSearchText,
  setChatBoxRecentChatListData,
  setChatBoxSelectedChatsOnFloating,
  setChatBoxFacebookIDsWithProfileDetails,
  setUserPanelChatOnline,
  setChatBoxMarkNotToAddInChatCircleForLabel,
  setChatBoxRecentSearchChatIds,
  setChatBoxRecentChatListShowAllListToggle,
  setChatBoxRecentChatListShowAllListByManagerToggle,
})(ChatBox);
