import React, { useEffect, useState } from 'react'
import Twillio from '../../../../../assets/setting/twilio.svg';
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import TwilioBtn from './TwilioBtn';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Avatar, FormControl, OutlinedInput, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Typography from '@mui/material/Typography';
import useStyle from './PhoneStyles';
import AddIcon from '@mui/icons-material/Add';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Button from '@mui/material/Button';
interface typesForPhoneNumber {
  phoneNumber: string
  __typename: string
  active: boolean
}

interface twilioDataType {
  accountSID: string
  active: boolean
  authToken: string
  id: string
  phoneNumber: typesForPhoneNumber[]
  __typename: string
}
interface Props {
  setIsAuth: React.Dispatch<React.SetStateAction<boolean>>
  twiliodata: twilioDataType | null | undefined
}

const TwilioPhoneConfiguration: React.FC<Props> = ({ setIsAuth, twiliodata }) => {
  const { accountSID = '', authToken = '', phoneNumber = '' } = twiliodata ? twiliodata : {};
  const [phnNumber, setPhnNumber] = useState<typesForPhoneNumber[] | string>(phoneNumber);
  const [number, setNumber] = useState("");
  const [btnConnect, setBtnConnect] = useState(false);
  const { classes } = useStyle();
  const { enqueueSnackbar } = useSnackbar();



  const disconnectTwilioConfigurationQuery = gql`
    mutation disconnectTwilioConfiguration(
      $accountSID:String!
      $authToken:String!

    ){
      disconnectTwilioConfiguration(
      accountSID:$accountSID
      authToken:$authToken

    ) {
        success
        error
      }
    }
    `;
  let [
    disconnectTwilioConfiguration,
    {
      loading: disconnectTwilioConfigurationQueryLoading,
      error: disconnectTwilioConfigurationQueryError,
      data: disconnectTwilioConfigurationQueryResult,
    },
  ] = useMutation(disconnectTwilioConfigurationQuery);

  useEffect(() => {
    if (disconnectTwilioConfigurationQueryError) {
      disconnectTwilioConfigurationQueryError.graphQLErrors.map(({ message }, i) => {
        enqueueSnackbar(message, { variant: "error" });
      });
    }
  }, [disconnectTwilioConfigurationQueryError]);

  useEffect(() => {
    if (disconnectTwilioConfigurationQueryResult && disconnectTwilioConfigurationQueryResult.disconnectTwilioConfiguration) {
      if (disconnectTwilioConfigurationQueryResult.disconnectTwilioConfiguration.success) {
        setIsAuth(false);

      } else {
        enqueueSnackbar(disconnectTwilioConfigurationQueryResult.disconnectTwilioConfiguration.error, {
          variant: "error",
        });
      }
    }
  }, [disconnectTwilioConfigurationQueryResult]);




  const AddTwilioPhoneNumberQuery = gql`
      mutation addTwilioPhoneNumber(
        $accountSID:String!
        $authToken:String!
        $phoneNumber:String!

      ){
        addTwilioPhoneNumber(
        accountSID:$accountSID
        authToken:$authToken
        phoneNumber:$phoneNumber

      ) {
          success
          error
        }
      }`;
  let [
    addTwilioPhoneNumber,
    {
      loading: AddTwilioPhoneNumberQueryLoading,
      error: AddTwilioPhoneNumberQueryError,
      data: AddTwilioPhoneNumberQueryResult,
    },
  ] = useMutation(AddTwilioPhoneNumberQuery);

  useEffect(() => {
    if (AddTwilioPhoneNumberQueryError) {
      AddTwilioPhoneNumberQueryError.graphQLErrors.map(({ message }, i) => {
        enqueueSnackbar(message, { variant: "error" });
      });
    }
  }, [AddTwilioPhoneNumberQueryError]);

  useEffect(() => {
    if (AddTwilioPhoneNumberQueryResult && AddTwilioPhoneNumberQueryResult.addTwilioPhoneNumber) {
      if (AddTwilioPhoneNumberQueryResult.addTwilioPhoneNumber.success) {
        const copyOfPhnNumber = phnNumber ? phnNumber : [];
        if (Array.isArray(copyOfPhnNumber)) {
          copyOfPhnNumber.push({
            __typename: "twilioPhone",
            phoneNumber: number,
            active: false
          })
        }

        setPhnNumber(copyOfPhnNumber);
        setNumber("");
      } else {
        enqueueSnackbar(AddTwilioPhoneNumberQueryResult.addTwilioPhoneNumber.error, {
          variant: "error",
        });
      }
    }
  }, [AddTwilioPhoneNumberQueryResult]);


  const disconnectTwilioConf = () => {
    disconnectTwilioConfiguration({
      variables: {
        accountSID: accountSID,
        authToken: authToken,
      },
    });
  }

  const addTwilioPhoneNumberHandler = () => {

    console.log(
      'accountSID:', accountSID,
      'authToken:', authToken,
      'phoneNumber:', number
    )
    addTwilioPhoneNumber({
      variables: {
        accountSID: accountSID,
        authToken: authToken,
        phoneNumber: number
      },
    });
  }
   console.log(phnNumber, "phnNumber") 
  return (
    <Card sx={{ minWidth: 275 }} style={{ height: 'calc(100vh - 112px)', borderRadius: '10px', boxShadow: '0px 5px 10px rgba(127, 63, 152, 0.1)', overflowY: 'auto' }}>
      <Box className={classes.headerBox}>
        <Avatar src={Twillio} className={classes.avtarStyle} />
        <Box className={classes.headingBox}>
          <Typography component={'p'} className={classes.headerHeading}>
            Connected to Twilio
          </Typography>
          <Box className={classes.buttonBox}>
            <Button variant={'contained'} className={classes.disconnectButton} onClick={disconnectTwilioConf} >Disconnect</Button>
          </Box>
        </Box>
      </Box>
      <CardContent className={classes.cardContent}>
        <Box>
          <Table className={classes.tableStyle}>
            <TableHead>
              <TableRow style={{ background: '#FBFBFB' }}>
                <TableCell className={classes.pageName}>
                  PHONE NUMBER
                </TableCell>
                <TableCell colSpan={3} className={classes.pageName}>
                  GROUP
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AddIcon style={{ fontSize: '15px', marginRight: '6.8px' }} />
                    <Typography component={'p'} sx={{ display: 'inline-block', fontSize: '14px', fontWeight: '500', lineHeight: '21px', }}>
                      Add Twilio Phone Number
                    </Typography>
                  </Box>
                </TableCell>

                <TableCell className={classes.tableCell}>
                  <OutlinedInput
                    inputProps={{ style: { fontSize: '12px', lineHeight: "18px", fontWeight: "400" } }}
                    placeholder='Phone number'
                    sx={{ maxWidth: "200px", width: "100%", height: "32px" }}
                    onChange={(e) => setNumber(e.target.value)}
                    value={number} />
                </TableCell>
                <TableCell align='right' className={classes.tableCell}>
                  <Button variant='contained' type='submit' className={classes.addButton} onClick={addTwilioPhoneNumberHandler} >
                    <AddIcon style={{ fontSize: '15px', marginRight: '6.8px' }} />
                    Add
                  </Button>
                </TableCell>
              </TableRow>

              {
                phnNumber && Array.isArray(phnNumber) ? phnNumber.map((curr: typesForPhoneNumber, index: number) => (
                  <TableRow key={index}>
                    <TableCell sx={{ display: "flex" }} className={classes.tableCell}>
                      <Box className={classes.avtarBox}>
                        <Avatar variant='circular' className={classes.mobileAvtar} >
                          <PhoneIphoneIcon sx={{ height: '15.75px', color: '#272525' }} />
                        </Avatar>
                        <Typography component={'p'} sx={{ display: 'inline-block' }} className={classes.pageName}>
                          {curr.phoneNumber}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <FormControl sx={{ width: '150px', height: 'auto' }}>
                      </FormControl>
                    </TableCell>
                    <TableCell className={classes.tableCell} align='right'>
                      <TwilioBtn phnNumber={phnNumber} setPhnNumber={setPhnNumber} data={curr} accountSID={accountSID} authToken={authToken} />
                    </TableCell>
                  </TableRow>
                )) : "No Phone Number "
              }

            </TableBody>
          </Table>
        </Box>
        {
          // pageStatus ? 
          // <SnackBar text='Successfully connected Page!' coolor='green' snackbarStatus={pageStatus} />
          // :
          // <SnackBar text='Page disconnected!' coolor='#E34D59' snackbarStatus={pageStatus} />
        }
      </CardContent>
    </Card>
  )
}

export default React.memo(TwilioPhoneConfiguration)