import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  footer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cancel: {
    textTransform: "capitalize",
    color: "BLACK",
    marginLeft: "12rem",
    background: "#fff",
    "&:hover": {
      color: "BLACK",
      background: "#fff",
    },
  },
  done: {
    textTransform: "capitalize",
    color: "white",
  },
}));
export default useStyles;
