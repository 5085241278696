import * as React from 'react'
import { ListItem, Button } from '@mui/material'
//
import useStyle from './Styles'
//
import { Dispatch, SetStateAction } from 'react';

interface Props {
  keyIndex:number
  setting: any
  deleteResponse:any
}

const Response: React.FC<Props> = ({ setting ,keyIndex,deleteResponse}) => {
  const { classes } = useStyle()
  const [index, setIndex] = React.useState(keyIndex)
  React.useEffect(() => {
    setIndex(keyIndex)
  }, [keyIndex])

const deleteFunc = ()=>{
  deleteResponse(index)
  setting(undefined)
}

  return (
    <>
      <ListItem className={classes.listStyle}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{
            width: '66px',
            height: '30px',
            //
            background: '#F5F5F5',
            borderRadius: '3px',
            //
            fontFamily: 'poppins',
            fontWeight: '400',
            fontSize: '11px',
            lineHeight: '18px',
            color: '#777777',
            //
            textTransform: 'capitalize',
            boxShadow: 'none',
            //
            '&:hover': {
              color: '#777777',
              backgroundColor: '#F5F5F5',
            },
            //
            marginRight: '5px',
          }}
          //
          onClick={() => setting(undefined)}
          //
        >
          Cancel
        </Button>

        <Button
        onClick={deleteFunc}
          variant="contained"
          color="primary"
          sx={{
            width: '142px',
            height: '30px',
            //
            background: '#4D1277',
            borderRadius: '3px',
            //
            fontFamily: 'poppins',
            fontWeight: '500',
            fontSize: '11px',
            lineHeight: '18px',
            color: '#FFFFFF',
            //
            padding: '0px',
            //
            '&:hover': {
              color: '#FFFFFF',
              background: '#4D1277',
            },
            //
            textTransform: 'capitalize',
            boxShadow: 'none',
          }}
        >
          Remove this response
        </Button>
      </ListItem>
    </>
  )
}

export default Response
