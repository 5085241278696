import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  Button, Box
} from '@mui/material'
// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

interface Props {
  handledateTime: any
  setValue: any
}

const ChatDetailsComponentSetReminder: React.FC<Props> = ({ handledateTime ,setValue}) => {
  const [value, ] = React.useState<Dayjs | null>(dayjs());
  return (
    <>
      <Box sx={{ justifyContent: "center", alignItems: "center" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>

          <DateTimePicker
            // label="Select date and time"
            value={value}
            onChange={(newValue) => setValue(newValue)} />
          <Button
            onClick={handledateTime}
            disabled={value != null ? false : true}
            // className={classes.textButton}
            sx={{
              height: "21px",
              // width: "45px",
              width: "100%",
              fontSize: "12px",
              fontWeight: 400,
              marginTop: 1,
              marginBottom: 1

            }}

            color="primary" variant='contained'>
            Set Reminder</Button>
        </LocalizationProvider>
      </Box>
    </>
  );
}

export default ChatDetailsComponentSetReminder