import React from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
// import Link from '@mui/material/Link';
import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/system'
import { Link } from 'react-router-dom'

interface Props {
  route: string
  text: string
}

const SignUpFooter: React.FC<Props> = ({ route, text }) => {
  const theme = useTheme()
  return (
    <Box sx={{ display: 'flex' }}>
      {/* <Link to='/signin'> */}
      <Link
        to={route}
        style={{
          [theme.breakpoints.up('xl')]: {
            marginTop: '5px',
          },
        }}
      >
        <KeyboardBackspaceIcon />
      </Link>
      &nbsp;
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '13px',
          lineHeight: '18px',
          //extra large
          [theme.breakpoints.up('xl')]: {
            fontSize: '1.375rem',
          },
          //
          color: theme.palette.text.primary,
          marginTop: 0.3,
        }}
      >
        Back To {text}
      </Typography>
    </Box>
  )
}

export default SignUpFooter
