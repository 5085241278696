import React from "react";
import { Route, Switch,Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { setAdminPanelDrawerToggle } from "../../store/actions/AdminpanelActions";
import ChatBox from "../chatBox";
import TotalCustomer from "../Report/TotalCustomer/TotalCustomer";
import DailySummary from "../Report/DailySummary/DailySummary";
import ChatDetail from "../Report/ChatDetail/ChatDetail";
import Report from "../Report/Report";
import Setting from "../setting";
import MainLayout from "../layout";
import Dashboard from "../dashboard/Dashboard";

const MainContentContainer = (props) => {


  return (

    <MainLayout>
      <Switch>
        <Route exact path="/dashboard">
          <Dashboard />
        </Route>
        <Route exact path="/reports">
          <Report />
        </Route>
        <Route
          exact
          path="/reports/totalcustomerreport"
          render={(props) => <TotalCustomer {...props} />}
        ></Route>
        <Route
          exact
          path="/reports/dailysummary"
          render={(props) => <DailySummary {...props} />}
        ></Route>
        <Route
          exact
          path="/reports/totalcustomerreport/:id"
          render={(props) => <ChatDetail />}
        ></Route>


        {/* <Route
          exact
          // strict
          path={[
            "/setting",
            "/setting/facebook",
            "/setting/website",
            "/setting/chatpage",
            "/setting/cannedResponse",
            "/setting/label",
            "/setting/notification"
          ]}
          render={(props) => <Setting {...props} />}
        ></Route> */}



<Redirect exact from="/settings" to={`settings/label`} />
<Redirect exact from="/chat" to={`chats`} />

<Route
  exact
  path={["/settings", "/settings/:pageName"]}
  component={(props) => <Setting {...props} />}
></Route>


        <Route path={["/chats", "/admin", "/", '/dashboard']} render={(props) => <ChatBox {...props} isAgent={true} />} />

      </Switch>
    </MainLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.AdminPanelReducer,
    ...state.AuthReducer
  };
};
export default connect(mapStateToProps, {
  setAdminPanelDrawerToggle,
})(MainContentContainer);