import React, { useRef, useState, useReducer, useEffect } from "react";
import { IconButton, OutlinedInput } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Avatar, CardHeader,  Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Typography from '@mui/material/Typography';
import profile from '../../../../assets/img/profile.jpg';
import useStyle from './Style';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import removeIcon from "../../../../assets/setting/close-02.svg";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import VerticallyCenteredModal from "../../VerticallyCenteredModal";
import { type } from "os";
import Modal from "../../Modal/Modal"
interface Props{
  getProfile?: any
  getDisconnect?: any
}

interface getLabelsTypes {
  id: number | string
  text : string | number | forStateTypes
  count : number | string
  createdBy : string | null
  dateOfcreation : string | null
  __typename?: string
}

interface forLabelDelete {
  id: number | null
}

interface forStateTypes {
  labelList : getLabelsTypes[]
  delLabelDetail: forLabelDelete | getLabelsTypes
}
interface forGetLocalStorageData {
  id: string
  mainSuperAdminId: string
  managerId: string | null
  name: string
  paneltype: string
  check?: boolean
}
const SettingLabel: React.FC<Props>  = ({getProfile, getDisconnect}) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [getActiveUserData, setActAvtiveUserData] = React.useState<forGetLocalStorageData>({
      id: "",
      mainSuperAdminId: "",
      managerId: "",
      name: "",
      paneltype: "",
      check: false
    })
    const storageKey : string = "ActiveUserdetail" ;
    if (typeof window.localStorage !== 'undefined') {
      const item = window.localStorage.getItem(storageKey);
      if (item !== null) {
        const parsedItem:forGetLocalStorageData = JSON.parse(item);
        if(getActiveUserData.check === false){
          setLocalData({...parsedItem,check:true})
        }
      }
    }
    function setLocalData(data:forGetLocalStorageData){
      setActAvtiveUserData(data)
    }
    const { classes } = useStyle();
    const [iconStyle, setIconStyle] = React.useState<number | null>(null);
    const [labelRemoveRow,setLabelRemoveRow] = React.useState<number | null>(null)

    const { enqueueSnackbar } = useSnackbar();
    const LabelsQuery = gql`
        query settingLabels($accessToken: String) {
        settingLabels(accessToken: $accessToken) {
            id
            text
            count
            createdBy
            dateOfcreation
        }
        }
    `;
    const [
        settingLabels,
        {
        loading: labelsQueryLoading,
        error: labelsQueryError,
        data: labelsQueryResult,
        },
    ]:any = useLazyQuery(LabelsQuery, {
        fetchPolicy: "network-only",
    });
  useEffect(() => {
    if (labelsQueryError) {
      labelsQueryError.graphQLErrors.map(({ message }:any) => {
        enqueueSnackbar(message, { variant: "error" });
      });
    }
  }, [labelsQueryError]);

  useEffect(() => {
    settingLabels();
  }, []);

  const UpdateLabelsQuery = gql`
    mutation UpdateLabels($labels: String!) {
      updatelabels(labels: $labels) {
        success
        error
      }
    }
  `;
  const [
    updateLabels,
    {
      loading: updateLabelsQueryLoading,
      error: updateLabelsQueryError,
      data: updateLabelsQueryResult,
    },
  ] = useMutation(UpdateLabelsQuery);

  useEffect(() => {
    if (updateLabelsQueryError) {
      updateLabelsQueryError.graphQLErrors.map(({ message }, i) => {
        enqueueSnackbar(message, { variant: "error" });
      });
    }
  }, [updateLabelsQueryError]);

  useEffect(() => {
    if (updateLabelsQueryResult && updateLabelsQueryResult.updatelabels) {
      if (updateLabelsQueryResult.updatelabels.success) {
      } else {
        enqueueSnackbar(updateLabelsQueryResult.updatelabels.error, {
          variant: "error",
        });
      }
    }
  }, [updateLabelsQueryResult]);
  const verticalRef = useRef(null);
  const [addInput, setAddInput] = useState("");
  const [compState, setCompState] = useState<forStateTypes>({
    labelList: [],
    delLabelDetail: {
      id: null
    }
  })

  const labelFunction = (data:forStateTypes | string | number,type:string) => {
    switch (type) {
      case "ADDLABEL":
        const listData__ = compState.labelList.map((curr:getLabelsTypes) => {
          return {
            id: curr.id,
            text: curr.text,
            dateOfcreation: curr.dateOfcreation,
          };
        });

        updateLabels({
          variables: {
            labels: JSON.stringify([
              ...listData__,
              {
                id: compState.labelList.length,
                text: data,
                dateOfcreation: (moment().unix() * 1000).toString(),
              },
            ]),
          },
        });
        return setCompState({...compState,
          labelList: [
            ...compState.labelList,
            {
              id: compState.labelList.length,
              text: data,
              count: 0,
              dateOfcreation: (moment().unix() * 1000).toString(),
              createdBy: getActiveUserData.check === true ? getActiveUserData.name : null
            },
          ]});
      case "DELETELABELDETAIL":
        const findData: getLabelsTypes | undefined = compState.labelList.find(
          (curr:getLabelsTypes) => curr.id === data
        )
        if(findData){
          setCompState({
            ...compState,
            delLabelDetail: findData
          });
        }
        break
      case "DELETELABEL":
        const newList = compState.labelList.filter(
          (curr:getLabelsTypes) => curr.id !== compState.delLabelDetail.id
        );
        const listData_ = newList.map((curr:getLabelsTypes) => {
          return {
            id: curr.id,
            text: curr.text,
            dateOfcreation: curr.dateOfcreation,
          };
        });
        updateLabels({
          variables: {
            labels: JSON.stringify(listData_),
          },
        });
        setCompState({
          ...compState,
          labelList: compState.labelList.filter(
            (curr:getLabelsTypes) => curr.id !== compState.delLabelDetail.id
          ),
        })
        break
      case "GETRESPONSEFROMDB":
        if (typeof data === "object" && Array.isArray(data)){
        setCompState({...compState,labelList:data})}
        break
        
        default:
        return compState;
    }
  };
  const openDelModal = (id:number) => {
    labelFunction(id,"DELETELABELDETAIL");
    // dispatch({ type: "DELETELABELDETAIL", data: id });
    setModalOpen(true)
    // verticalRef.current.alterModalShow();
  };
  const deleteLabel = () => {
    labelFunction('',"DELETELABEL");
    // dispatch({ type: "DELETELABEL" });
    // verticalRef.current.alterModalShow();
    //
    setLabelRemoveRow(null)
  };

  const addLabelHandler = () => {
    if (addInput.length) {
      labelFunction(addInput,"ADDLABEL");
      // dispatch({ type: "ADDLABEL", data: addInput });
      setAddInput("");
    }
  };
  useEffect(() => {
      if (labelsQueryResult && labelsQueryResult.settingLabels) {
        labelFunction(labelsQueryResult.settingLabels, "GETRESPONSEFROMDB")
      }
      }, [labelsQueryResult]);
  return (
    <Card sx={{ minWidth: 275 }} style={{height:'calc(100vh - 112px)', borderRadius:'10px', boxShadow: '0px 5px 10px rgba(127, 63, 152, 0.1)', overflowY: 'auto', padding:'20px'}}>
        <Box>
            <Typography component={'p'} className={classes.headerText}>Manage labels</Typography>
            <Typography component={'p'} className={classes.subHeading}>
                This allows you to add custom labels. Added custom labels will be visible on chat page.
            </Typography>
        </Box>
        <Box className={classes.inputBox}>
            <OutlinedInput placeholder='Labels' type="text" value={addInput} name='label' onChange={(e) => setAddInput(e.target.value)} className={classes.labelInput} sx={{marginRight:'5px'}}/>
            <Button variant='contained' className={classes.addButton} onClick={addLabelHandler} > <AddIcon className={classes.addButton} /> Add</Button>
        </Box>
        <CardContent className={classes.cardContent}>
            <Box>
                <Table className={classes.tableStyle} sx={{padding:'0px'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeadings}>
                                Labels
                            </TableCell>
                            <TableCell className={`${classes.tableHeadings}`}>
                                Uses
                            </TableCell>
                            <TableCell colSpan={4} className={classes.tableHeadings}>
                                Created by
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    
                    {labelsQueryLoading ? (
                        <div>
                            <CircularProgress
                            className={classes.loadingCircularProgress}
                            size={24}
                            />
                        </div>
                        ) : (
                        ""
                        )}
                        {labelsQueryLoading === false ? (
                        <>
                            { 
                              compState.labelList.length > 0 ? 
                                compState.labelList?.map((curr:any, index:number) => (
                                  labelRemoveRow !== index ?
                                  <TableRow hover={true} onMouseEnter={(e)=>{
                                          setIconStyle(index)
                                      }}
                                      onMouseLeave={(e)=>{
                                          setIconStyle(null)    
                                      }}
                                      >
                                      <TableCell className={classes.tbody}>
                                          <Typography component={'p'} className={classes.tableText}>
                                              {curr.text}
                                          </Typography>
                                      </TableCell>
                                      <TableCell className={classes.tbody}>
                                          <Typography component={'p'} className={classes.tableText}>
                                              {curr.count} chats
                                          </Typography>
                                      </TableCell>
                                      <TableCell className={classes.tbody}>
                                          <Typography component={'p'} className={classes.tableText}>
                                              {curr.createdBy}
                                          </Typography>
                                          <Typography component={'p'} className={classes.tableText} style={{color:'#838383'}}>
                                          on {moment
                                              .unix(curr.dateOfcreation / 1000)
                                              .format("DD MMM YYYY hh:mm a")}{" "}
                                          </Typography>
                                      </TableCell>
                                      <TableCell className={classes.tbody} align='right' style={{padding:'0px'}}>
                                          <IconButton sx={{visibility:iconStyle === index?"visible":"hidden"}} onClick={(e)=>{
                                              e.preventDefault();
                                              setLabelRemoveRow(index)
                                          }}>
                                              <DeleteIcon />
                                          </IconButton>
                                      </TableCell>
                                  </TableRow>
                                  :
                                  <TableRow selected={true} hover={false} className={classes.tableRowSelectedStyle} >
                                    <TableCell className={classes.tbody} style={{padding:'0px'}} align='center' colSpan={4}>
                                        <Box className={classes.labelRemoveBox}>
                                            <Button variant='contained' className={classes.labelCancelButton} onClick={(e)=>{
                                                e.preventDefault();
                                                setLabelRemoveRow(null)
                                            }}>
                                                Cancel
                                            </Button>
                                            <Button variant='contained' className={classes.labelRemoveButton} onClick={() => openDelModal(curr.id)}>
                                                Remove this label
                                            </Button>
                                        </Box>
                                    </TableCell>
                                  </TableRow>
                                  
                                ))
                                : (
                                <TableRow >
                                    <TableCell className={classes.tbody} colSpan={4}>
                                    No Labels
                                    </TableCell>
                                </TableRow>
                                )}
                            </>
                            ) : (
                            ""
                            )}
                    </TableBody>
                </Table>
            </Box>
        </CardContent>
        {/* <VerticallyCenteredModal
            img={removeIcon}
            text={"Are you sure you want to delete this Label"}
            btnText={"Delete"}
            removeHandler={deleteLabel}
            ref={verticalRef}
            // callLabelRemove={reoveLabel}
        /> */}
        <Modal
          img={removeIcon}
          text={"Are you sure you want to delete this Label"}
          btnText={"Delete"}
          modalOpen= {modalOpen}
          modalClose={setModalOpen}
          removeItem={deleteLabel}
        />
    </Card>
  );
}

export default SettingLabel
