import { gql } from "apollo-boost";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

const ResponseQuery = gql`
  query getResponse($accessToken: String) {
    getResponse(accessToken: $accessToken) {
      id
      responses
    }
  }
`;

export const useResponseQuery = () => {
  return useLazyQuery(ResponseQuery, {
    fetchPolicy: "network-only",
  });
};

const updateresponseQuery = gql`
  mutation updateresponse($response: String!) {
    updateresponse(response: $response) {
      success
      error
    }
  }
`;

export const useUpdateresponseQuery = () => {
  return useMutation(updateresponseQuery);
};
