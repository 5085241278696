import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ChatNoteMain from "../chatNoteMain/ChatNoteMain";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LabelsOnRightPanel from "../../LabelsOnRightPanel";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import FacebookTypography from "../../FacebookTypography";
import SendMessage from "../../SendMessage";
import {
  Card,
  Divider,
  Box,
  Typography,
  Button,
  CardContent,
  ListItemText,
  Avatar,
} from "@mui/material";
import useStyles from "./StylesRightPanelChatBox";

import { Title12500 } from "../../../../componentsfromkuikwit/components/shared/atoms/text/Text";
import ChatDetailsComponentSetReminder from "../../../../componentsfromkuikwit/components/pages/chats/chatDetailsComponent/chatDetailsComponentSetReminder/ChatDetailsComponentSetReminder";
import ChatDetailsComponentHeader from "../../../../componentsfromkuikwit/components/pages/chats/chatDetailsComponent/chatDetailsComponentHeader/ChatDetailsComponentHeader";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    fontSize: "10px !important",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  titlebutton: {
    color: "#4D1277",
  },
}));

const RightPanelChatBox = ({
  showUserDetailPanel,
  showUserDetail,
  showLeadFormDetail,
  selectedChatsOnFloatingTabpanelItem,
  setOpenAddLabelInput,
  openAddLabelInput,
  authUserId,
  handledateTime,
  value,
  setValue,
  setOpenReminder,
  openReminder,
  setFollowUpDialogToggle,
}) => {
  // const classes = useStyles()

  const [iconpos, seticonpos] = useState(false);
  const { classes } = useStyles();
  const handleOk = () => {
    setOpenReminder(false);
    seticonpos(false);
  };
  return (
    <>
      <Card
        sx={{
          borderRadius: "10px",
          boxShadow: "0px 5px 10px rgba(127, 63, 152, 0.1)",
          overflowY: "auto",
          height: "99%",
          scrollbarWidth: "thin",
          scrollbarColor: "#ccc transparent",
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "3px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
        }}
      >
        <ChatDetailsComponentHeader
          rightPanelOpened={showUserDetail}
          setRightPanelOpened={showUserDetailPanel}
        />
        <Divider />

        <CardContent>
          <Box sx={{ height: "42px" }}>
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
              Details
            </Typography>
          </Box>
          <Divider />

          <Box sx={{ height: "75px" }}>
            <Box sx={{ height: "100%" }}>
              <Box mt={1}>
                <Title12500 text="General Info" />
              </Box>
              <Box className={classes.root} mt={1}>
                {/* avatar */}
                {/* <Avatar
                  src={selectedChatsOnFloatingTabpanelItem.customerPicture}
                >
                  className={classes.bottomChatsTabCustomerImage}
                </Avatar> */}
                <Avatar src={selectedChatsOnFloatingTabpanelItem.customerPicture || ""}>
                  className={classes.bottomChatsTabCustomerImage}
                </Avatar>
                <ListItemText
                  primary={
                    selectedChatsOnFloatingTabpanelItem.customername ? (
                      <Typography className={classes.textfb}>
                        {selectedChatsOnFloatingTabpanelItem.customername}
                      </Typography>
                    ) : (
                      // <FacebookTypography
                      //     item={selectedChatsOnFloatingTabpanelItem}
                      //     className={classes.textfb}
                      // ></FacebookTypography>
                      <Typography className={classes.textfb}>
                        Customer Name
                      </Typography>
                    )
                  }
                />
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ minHeight: "80px" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 500,
                marginTop: "5px",
                fontFamily: "Poppins",
              }}
            >
              Facebook
            </Typography>
            {/* secondary */}
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                fontSize: "12px",
                fontWeight: 400,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  fontSize: "12px",
                  fontWeight: 500,
                  marginTop: "5px",
                }}
              >
                Name:&nbsp;&nbsp;&nbsp;
                {selectedChatsOnFloatingTabpanelItem.customername ? (
                  <Typography
                    sx={{ display: "flex", fontSize: "12px", fontWeight: 400 }}
                  >
                    {selectedChatsOnFloatingTabpanelItem.customername}
                  </Typography>
                ) : (
                  // <FacebookTypography
                  //     item={selectedChatsOnFloatingTabpanelItem}
                  //     className={classes.nameandpage}
                  // ></FacebookTypography>
                  <Typography
                    sx={{ display: "flex", fontSize: "12px", fontWeight: 400 }}
                  >
                    Customer Name
                  </Typography>
                )}
              </Typography>
            </div>

            <div style={{ display: "flex" }}>
              <Typography
                sx={{
                  display: "flex",
                  fontSize: "12px",
                  fontWeight: 500,
                  marginTop: "5px",
                }}
              >
                Page name:&nbsp;&nbsp;&nbsp;
                <FacebookTypography
                  pageName
                  item={selectedChatsOnFloatingTabpanelItem}
                  className={classes.nameandpage}
                ></FacebookTypography>
                
              </Typography>
            </div>
          </Box>
          <Divider />

          <SendMessage
            customerId={selectedChatsOnFloatingTabpanelItem.customerId}
            agentId={selectedChatsOnFloatingTabpanelItem.agentId}
          />

          <Box>
            <Typography className={classes.text} mt={1} mb={1}>
              Set reminder
            </Typography>
            <ChatDetailsComponentSetReminder
              setValue={setValue}
              handledateTime={handledateTime}
            />
          </Box>
          <Divider />

          <Box sx={{ height: "174px" }} mt={1}>
            <ChatNoteMain
              customerId={selectedChatsOnFloatingTabpanelItem.customerId}
              pageId={selectedChatsOnFloatingTabpanelItem.pageId}
              agentId={selectedChatsOnFloatingTabpanelItem.agentId}
              authUserId={authUserId}
            />
          </Box>
          <Divider />
          <Box sx={{ height: "105px" }}>
            <LabelsOnRightPanel
              customerId={selectedChatsOnFloatingTabpanelItem.customerId}
              pageId={selectedChatsOnFloatingTabpanelItem.pageId}
              agentId={selectedChatsOnFloatingTabpanelItem.agentId}
              setOpenAddLabelInput={setOpenAddLabelInput}
              openAddLabelInput={openAddLabelInput}
              setFollowUpDialogToggle={setFollowUpDialogToggle}
            />
          </Box>
          <>
            <BootstrapDialog
              classes={{ paper: classes.dialogCustomizedWidth }}
              onClose={handleOk}
              aria-labelledby="customized-dialog-title"
              open={openReminder}
            >
              <div style={{ display: "flex" }}>
                <DialogContent dividers>
                  <Typography
                    variant="p"
                    component="div"
                    style={{
                      color: "#4D1277",
                      fontFamily: "poppins",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    gutterBottom
                  >
                    <NotificationsIcon />
                  </Typography>
                  <Typography
                    variant="p"
                    component="div"
                    style={{
                      color: "#777777",
                      fontFamily: "poppins",
                      fontSize: "16px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    gutterBottom
                  >
                    Your Reminder has been added !
                  </Typography>
                </DialogContent>
              </div>

              <div className={classes.buttonhere}>
                <Button
                  variant="light"
                  className={classes.titlebutton}
                  onClick={handleOk}
                >
                  Ok
                </Button>
              </div>
            </BootstrapDialog>
          </>
        </CardContent>
      </Card>
    </>
  );
};

export default RightPanelChatBox;
