import { makeStyles } from 'tss-react/mui'

const useStyle = makeStyles()(() => ({
  loadingCircularProgress: {
    margin: "auto",
    display: "block",
    marginTop: 20,
    color: "#55A530",
  },
  cannedMain: {
    width: "70%",
    marginTop: "20px",
    padding: "10px",
  },
  whatAreHeading: {
    fontFamily: "poppins",
    fontSize: "14px",
    padding: "5px 0px",
    borderBottom: "1px solid rgba(119, 119, 119,.5)",
  },
  whatArePara: {
    fontFamily: "poppins",
    fontSize: "12px",
    color: "rgba(119, 119, 119,1)",
    marginBottom: "30px",
  },
  addCannedResponseMain: {
    width: "80%",
  },
  addCannedResponseShortcutMain: {
    display: "grid",
    gridTemplateColumns: "65% auto",
    gridGap: "7px",
    marginBottom: "5px",
  },
  addCannedResponseShortcutInput: {
    fontFamily: "poppins",
    fontSize: "12px",
    color: "#777777",
    border: "1px solid rgba(119,119,119,.5)",
    borderRadius: "3px",
    paddingLeft: "5px",
  },
  addCannedResponseShortcutBtn: {
    border: "none",
    borderRadius: "3px",
    color: "white",
    background: "#55A530",
    fontFamily: "poppins",
    fontSize: "12px",
    padding: "6px 15px",
    cursor: "pointer",
  },
  addCannedResponseMainLong: {
    display: "grid",
    gridTemplateColumns: "65%",
  },
  addCannedResponseLongInput: {
    fontFamily: "poppins",
    fontSize: "12px",
    color: "#777777",
    border: "1px solid rgba(119,119,119,.5)",
    borderRadius: "3px",
    paddingLeft: "5px",
  },
  addCannedResponseLongPara: {
    marginTop: "10px",
    fontFamily: "poppins",
    fontSize: "11.5px",
    color: "#777777",
  },
  addCannedResponseListSearch: {
    display: "flex",

    paddingBottom: "20px",
    borderBottom: "1px solid rgba(119,119,119,.5)",
  },
  addCannedResponseListSearchInput: {
    fontFamily: "poppins",
    fontSize: "12px",
    color: "#777777",
    border: "1px solid rgba(119,119,119,.5)",
    borderRadius: "3px",
    paddingLeft: "5px",
    width: "200px",
    height: "29px",
  },
  addCannedResponseListItemMain: {
    padding: "15px 0px 10px 0",
    borderBottom: "1px solid rgba(119,119,119,.5)",
  },
  addCannedResponseListItemLongText: {
    color: "black",
    fontSize: "13.5px",
    fontFamily: "poppins",
    fontWeight: "500",
    marginBottom: "5px",
  },
  addCannedResponseListItemShortTextMain: {
    fontFamily: "poppins",
    fontSize: "12px",
    color: "#777777",
  },
  addCannedResponseListItemShortText: {
    marginLeft: "5px",
    border: "1px solid rgba(119,119,119,.5)",
    padding: "1px 6px",
    fontSize: "12px",
    color: "black",
  },
  addCannedResponseListItemBtnMain: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "10px",
    marginTop: "-25px",
  },
  addCannedResponseListItemBtnEdit: {
    border: "none",
    background: "none",
    fontFamily: "poppins",
    fontSize: "13px",
    color: "green",
  },
  addCannedResponseListItemBtnDelete: {
    border: "none",
    background: "none",
    fontFamily: "poppins",
    fontSize: "13px",
    color: "red",
  },
  focusRemove: {
    width: "160px",
    height: "30px",
    fontFamily: "poppins",
    fontSize: "13px",
    marginLeft: "10px",
    outline: "none",
    boxShadow: "none !important",
  },
}))

export default useStyle
