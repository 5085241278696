import React from 'react'
import { Grid, Typography, Box } from '@mui/material'
import ChatMainCardHeaderSelect from './ChatMainCardHeaderSelect'
// import RightChatComponentTopTabs from '../rightChatCompnent/rightChatComponentTopTabs/RightChatComponentTopTabs'
function ChatMainCardHeader() {
    return (
        <Grid container alignItems="center" justifyContent="space-between"
        >
            <Grid item >
                {/* <RightChatComponentTopTabs 
                
                // tabValues={['All messages', 'Messenger', 'Instagram', 'Whatsapp', 'Twilio']} 
                tabValues={['All messages', ]} 
                
                /> */}
                <Box sx={{ padding: 1 }}>
                    <Typography sx={{ height: 25, textTransform: "none", color: "#4D1277", fontWeight: 500, fontSize: 14, borderBottom: "2px solid #4D1277" }}>All Messages</Typography>
                </Box>
            </Grid>
            <Grid item >
                <ChatMainCardHeaderSelect />
            </Grid>
        </Grid >
    )

}

export default ChatMainCardHeader