import React, { useEffect, } from "react";
import {
  Container,
  CircularProgress,
  ListItem,
  List,
  Typography,
  TextField, Box,
  Divider,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import {
  setLabelListData,
  setLabelListTextInput,
} from "../../store/actions/LabelListActions";
import { connect } from "react-redux";
import { styled, } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

import { ComponentSearch } from "../../componentsfromkuikwit/components/pages/chats/leftChatComponent/leftchatComponentSearch/LeftChatComponentSearch";
const useStyles = makeStyles((theme) => ({
  mainContainerRoot: {
    // padding: 2,
    padding: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: "90rem",
    height: "30px",
    borderRadius: 0,
    minHeight: 100,
  },
  listItemButton: {
    // borderBottom: "1px solid #d0cfcf",
    height: "30px",
    display: "flex",
    // padding:'10px 0px'
  },
  loadingCircularProgress: {
    margin: "auto",
    display: "block",
    marginTop: 20,
  },
  listItemButtonText: {
    padding: 15,
    border: "1p solid gray",
    color: "#4D1277",
    flex: 1,
  },
  listItem: {
    background: "white",
    padding: 0,
  },
  labelAddForm: {
    // margin: "10px 0px",
    padding: 0,
    // display: "flex",
  },
  labelAddFormButton: {
    color: "white",
    background: "#f50057",

    "&:hover": {
      background: "#e14079",
    },
    marginLeft: 6,
  },
  labelTextInput: {
    paddingTop: 2,
    paddingBottom: 4,
    fontFamily: "Poppins",
    fontSize: "13px",
  },
  labelAddFormButtonIcon: {
    fontSize: 25,
  },
  deleteButton: {
    color: "#f40057",
    background: "gray",
    // background: "#e0dfdf",
    "&:hover": {
      background: "#dcdcdc",
    },
  },
  followUpIcon: {
    marginRight: 12,
  },
  closeLabelIcon: {
    marginRight: 12,
    color: "red",
  },
  closeSalesIcon: {
    marginRight: 12,
    color: "blue",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    paddingLeft: `3em`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '15ch',
    },
  },
}));
const placeholderStyle = {
  width: "100%",
  padding: 0,
  // height: "100%",
  /* Placeholder text color */
  color: "#838383",
  /* Placeholder font style */
  // fontStyle: "italic",
  /* Placeholder font size */
  resize: "none",
  fontSize: 14,
  // height: 50,
  /* Placeholder font weight */
  fontWeight: 400,
};

const LabelsList = (props) => {

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const UpdateLabelsQuery = gql`
    mutation UpdateLabels($labels: String!) {
      updatelabels(labels: $labels) {
        success
        error
      }
    }
  `;
  let [
    updateLabels,
    {
      loading: updateLabelsQueryLoading,
      error: updateLabelsQueryError,
      data: updateLabelsQueryResult,
    },
  ] = useMutation(UpdateLabelsQuery);

  useEffect(() => {
    if (updateLabelsQueryError) {
      updateLabelsQueryError.graphQLErrors.map(({ message }, i) => {
        enqueueSnackbar(message, { variant: "error" });
      });
    }
  }, [updateLabelsQueryError]);

  useEffect(() => {
    if (updateLabelsQueryResult && updateLabelsQueryResult.updatelabels) {
      if (updateLabelsQueryResult.updatelabels.success) {
      } else {
        enqueueSnackbar(updateLabelsQueryResult.updatelabels.error, {
          variant: "error",
        });
      }
    }
  }, [updateLabelsQueryResult]);

  const LabelsQuery = gql`
    query GetLabels($accessToken: String) {
      getlabels(accessToken: $accessToken) {
        id
        labels
      }
    }
  `;
  let [
    getLabels,
    {
      loading: labelsQueryLoading,
      error: labelsQueryError,
      data: labelsQueryResult,
    },
  ] = useLazyQuery(LabelsQuery, {
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (labelsQueryError) {
      labelsQueryError.graphQLErrors.map(({ message }, i) => {
        enqueueSnackbar(message, { variant: "error" });
      });
    }
  }, [labelsQueryError]);

  useEffect(() => {
    getLabels();
  }, [props.customerId]);

  var followUpText = "Follow Up";

  useEffect(() => {
    if (labelsQueryResult && labelsQueryResult.getlabels) {
      var listData_ = [{ id: 0, text: followUpText }];

      var decodeResult = JSON.parse(labelsQueryResult.getlabels.labels);

      if (decodeResult) {
      }

      decodeResult &&
        decodeResult.map((item) => {
          listData_.push(item);
        });

      props.setLabelListData(_.cloneDeep(listData_));
    }
  }, [labelsQueryResult, props.marknottoaddinchatcircle]);

  const isLoading = labelsQueryLoading;

  var labelListDataSearch = [];
  if (!isLoading) {
    if (props.labelListTextInput != "") {
      labelListDataSearch = _.filter(props.labelListData, (label) => {
        if (!_.find(props.usedLabels, (label_) => label.text == label_.text))
          return label.text
            .toLowerCase()
            .includes(props.labelListTextInput.toLowerCase());
      });
    }
  }

  useEffect(() => { }, [labelListDataSearch]);


  return (
    <>
      <Container
        classes={{
          root: classes.mainContainerRoot,
        }}
      >
        <Container disableGutters={true} className={classes.labelAddForm}>
          {/* <TextField
            style={placeholderStyle}
            value={props.labelListTextInput}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                props.onItemClick(props.labelListTextInput);
                props.setLabelListTextInput("");
              }
            }}
            InputProps={{
              classes: {
                input: classes.labelTextInput,
              },
            }}
            onInput={(e) => props.setLabelListTextInput(e.target.value)}
            autoFocus
            variant={"outlined"}
            placeholder={"Search or add"}
          /> */}
          <Box sx={{
            position: 'relative',
            borderRadius: 1,
            minHeight: 'unset',
            border: '0.5px solid #E8E8E8',
            // backgroundColor: grey[100],
            '&:hover': {
              //  backgroundColor: theme.custom?.background,
            },
            // marginRight: 2,
            // marginTop: 10,
            //  marginLeft: 0,
            width: '125%',
            marginLeft: -3
          }}>
            <StyledInputBase
              placeholder={"Search or add"}
              style={placeholderStyle}
              autoFocus
              onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  props.onItemClick(props.labelListTextInput);
                  props.setLabelListTextInput("");
                }
              }}
              InputProps={{
                classes: {
                  input: classes.labelTextInput,
                },
              }}
              onInput={(e) => props.setLabelListTextInput(e.target.value)}
              type="text"
              value={props.labelListTextInput}
              // placeholder={<Typography className={classes.placeholder}>Search</Typography>}
              inputProps={{ 'aria-label': 'search' }}
            />
          </Box>
          {/* <Button
          disabled={isLoading}
          onClick={() => {
            addItem();
          }}
          className={classes.labelAddFormButton}
        >
          <AddIcon className={classes.labelAddFormButtonIcon} />
        </Button> */}
        </Container>

        {isLoading ? (
          <CircularProgress
            className={classes.loadingCircularProgress}
            size={24}
          />
        ) : (
          <List
            style={{
              height: props.containerHeight,
              background: "white",
              width: "100%",
              boxShadow: "0px 0px 3px 1px rgba(0,0,0,0.4)",
              // marginTop: "-10px",
              display: labelListDataSearch.length ? "block" : "none",
            }}
          >
            {labelListDataSearch.map((item) => {

              return (<>
                <ListItem
                  classes={{
                    root: classes.listItem,
                  }}
                  button
                  className={classes.listItemButton}
                >
                  <Typography
                    onClick={(e) => {
                      if (item.id != 0) {
                        var listData_ = props.labelListData;
                        _.remove(listData_, (itm) => itm.id == item.id);
                        props.setLabelListData(_.cloneDeep(listData_));
                      }
                      props.onItemClick && props.onItemClick(item.text);
                    }}
                    className={classes.listItemButtonText}
                  >
                    {item.text}
                  </Typography>


                </ListItem>
                <Divider /></>
              );
            })}
          </List>
        )}
      </Container>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    ...state.LabelListReducer,
  };
};

export default connect(mapStateToProps, {
  setLabelListData,
  setLabelListTextInput,
})(LabelsList);
