import React from 'react';

function Team(props) {
    return (
        <svg viewBox="0 0 100 100" width={props.width}
            className={props.class}
            height={props.height} xmlns="http://www.w3.org/2000/svg">
            <title>{"54. Team 2"}</title>
            <g
                fill='none'
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                stroke={props.color}
                strokeWidth={8}
            >
                <path d="M12.635 71.349c-.336.21-.968 1.526-.682 2.682.426 1.718 1.94 2.389 1.94 2.389s2.37 3.172 2.37 5.017c0 1.846 0 2.562-.636 4.102C14.992 87.078 2.53 88.923 2 97.998h18.14L38.48 98c-.53-9.075-12.992-10.92-13.627-12.46-.635-1.539-.635-2.255-.635-4.1 0-1.846 2.369-5.018 2.369-5.018s1.514-.671 1.94-2.39c.286-1.155-.346-2.471-.682-2.681.898-2.779 2.69-12.358-7.505-12.703a.779.779 0 0 0-.136-.005c-10.26.348-8.467 9.927-7.569 12.706ZM72.155 71.349c-.336.21-.968 1.526-.682 2.682.426 1.718 1.94 2.389 1.94 2.389s2.37 3.172 2.37 5.017c0 1.846 0 2.562-.636 4.102-.635 1.539-13.098 3.384-13.627 12.459h18.14L98 98c-.53-9.075-12.992-10.92-13.627-12.46-.635-1.539-.635-2.255-.635-4.1 0-1.846 2.369-5.018 2.369-5.018s1.514-.671 1.94-2.39c.286-1.155-.346-2.471-.682-2.681.898-2.779 2.69-12.358-7.505-12.703a.779.779 0 0 0-.136-.005c-10.26.348-8.467 9.927-7.569 12.706ZM42.395 14.709c-.336.21-.968 1.526-.682 2.682.426 1.718 1.94 2.389 1.94 2.389s2.37 3.172 2.37 5.017c0 1.846 0 2.562-.636 4.102-.635 1.539-13.098 3.384-13.627 12.459H49.9l18.34.002c-.53-9.075-12.992-10.92-13.627-12.46-.635-1.539-.635-2.255-.635-4.1 0-1.846 2.369-5.018 2.369-5.018s1.514-.671 1.94-2.39c.286-1.155-.346-2.471-.682-2.681.898-2.779 2.69-12.358-7.505-12.703a.779.779 0 0 0-.136-.005c-10.26.348-8.467 9.927-7.569 12.706ZM50 64.4l12.48 7.68M50 51.92V64.4l-12.48 7.68" />
            </g>
        </svg>
    );
}

Team.defaultProps = {
    width: 15,
    isBorder: false,
    class: "align-self-center",
    color: 'black',
    height: 15
}

export default React.memo(Team);