import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
//
import SearchBar from "./SearchBar/SearchBar";
import AddButton from "./LeftPanelAddButton/AddButton";
//
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
//
import useStyles from "./Styles";

import Store from "../../../store/index";
//
import { useDispatch } from "react-redux";
//
import { setSearchListDatas } from "../../../store/actions/UsersListActions";

interface Props {
  setSearchText: any;
  setSearchLoader: any;
  hitSearchScrolling: boolean;
  setHitSearchScrolling: any;
  setSearchNextPage: any;
}

////////////////////////
const Header: React.FC<Props> = ({
  setSearchText,
  setSearchLoader,
  hitSearchScrolling,
  setHitSearchScrolling,
  setSearchNextPage,
}) => {
  const { classes } = useStyles();

  const [pagination, setPagination] = useState({
    page: 0,
    hasNextPage: false,
    limit: 10,
  });

  const [queryText, setQueryText] = useState<null | string>(null);
  const handleSearch = (query: string) => {
    if (query === "") {
      setSearchText(null);
      //for local component
      setQueryText(null);
      //
    } else {
      setSearchText(query);
      //for local component
      setQueryText(query);
    }
    //
    setPagination({ page: 0, limit: 10, hasNextPage: false });
    userApiCallingHandle(query, "force");
  };

  const dispatch = useDispatch();

  const { getState } = Store;
  const usersListReducerState = getState().UsersListReducer;

  const GetUsers = gql`
    query getSearchedUsers(
      $accessToken: String
      $cursor: Int
      $limit: Int
      $query: String
    ) {
      getSearchedUsers(
        accessToken: $accessToken
        cursor: $cursor
        limit: $limit
        query: $query
      ) {
        users {
          id
          picture
          pseudonym
          name
          jobTitle
          username
          email
          status
          managerId {
            id
          }
          agentlimitchatassign
          designation {
            id
            name
            paneltype
          }
          number
          pages
        }
        hasNextPage
      }
    }
  `;
  let [
    getUsers,
    {
      loading: getUsersQueryLoading,
      error: getUsersQueryError,
      data: getUsersQueryResult,
    },
  ] = useLazyQuery(GetUsers, {
    fetchPolicy: "network-only",
  });

  const userApiCallingHandle = async (
    userquery: string,
    overwrite: string | null = null
  ) => {
    const activeUserDetail = localStorage.getItem("ActiveUserdetail");
    const mainSuperAdminId = activeUserDetail
      ? JSON.parse(activeUserDetail).mainSuperAdminId
      : null;

    // const num = savedData.length > 0 ? savedData.at(-1).id : 0;
    if (overwrite === "force") {
      getUsers({
        variables: {
          mainSuperAdminId,
          cursor: 0,
          limit: pagination.limit,
          query: userquery,
        },
      });
    } else {
      getUsers({
        variables: {
          mainSuperAdminId,
          cursor:
            pagination.page == 0
              ? pagination.limit * pagination.page
              : Number(usersListReducerState.searchListData.at(-1).id),
          // cursor: 0,
          limit: pagination.limit,
          query: userquery,
        },
      });
    }
  };

  const fetchUsers = (data: any) => {
    // setAllUsersData(data.users)
    dispatch(setSearchListDatas(data.users, pagination.page));
    //
    setSearchNextPage(data.hasNextPage);
    //
    if (hitSearchScrolling === true) {
      //
      setHitSearchScrolling(false);
      setPagination({
        page: pagination.page + 1,
        limit: 10,
        hasNextPage: data.hasNextPage,
      });
    }
  };

  useEffect(() => {
    if (
      getUsersQueryResult &&
      getUsersQueryResult.getSearchedUsers &&
      getUsersQueryResult.getSearchedUsers.users
    ) {
      fetchUsers(getUsersQueryResult.getSearchedUsers);
    }
  }, [getUsersQueryResult]);

  useEffect(() => {
    setSearchLoader(getUsersQueryLoading);
  }, [getUsersQueryLoading]);

  useEffect(() => {}, [getUsersQueryError]);

  ////////////////////
  useEffect(() => {
    //
    if (hitSearchScrolling === true && queryText !== null) {
      userApiCallingHandle(queryText);
    }
    //
  }, [hitSearchScrolling]);

  return (
    <>
      <Box className={classes.headerBox}>
        <SearchBar onSearch={handleSearch} />
        <AddButton />
      </Box>
    </>
  );
};

export default Header;
