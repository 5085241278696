// import FileUploadModal from './FileUploadModal'
// import SavedRepliesContainer from "./savedRepliesContainer/SavedRepliesContainer";
import ChatContainerTypingMessageStatus from "../ChatContainerTypingMessageStatus";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import SendIcon from "@mui/icons-material/Send";

import React, { useRef, useState, useMemo, useEffect } from 'react'

import useStyles from './Styles';

import { Box, CardContent, TextareaAutosize, } from '@mui/material'
import Stack from '@mui/material/Stack';
import EmojiModal from '../../../componentsfromkuikwit/components/shared/atoms/emojiModal/EmojiModal';
import FileUploadModal from "../../../componentsfromkuikwit/components/shared/molecules/fileUploadModal/FileUploadModal";
import { styled } from "@mui/material/styles";
import SavedRepliesComponent from "../../../componentsfromkuikwit/components/pages/chats/rightChatCompnent/savedRepliedComponent/SavedRepliesComponent";

const StyledTextField = styled(TextareaAutosize)({
    transition: "all 0.1s ease-in-out",
    resize: "none",
    "&:focus": {
        resize: "both",
        overflow: "auto"
    }
});
const BottomChatContainer = ({
    chatBoxMessageTextInputRef, setContainerHeightFunction,
    setIsShown,
    // stopRecording,
    // setContainerHeightFunction,
    setChatBoxMessageTextInput,
    file,
    isRecording,
    wrapperRef,
    setFile,
    // audioURL,
    setChange,
    change,
    // startRecording,
    isShown,
    chatBoxSubscriptionStatus,
    textFieldValue,
    setTextFieldValue,
    setSearchText,
    onEnterMessageTextInput,
    emojiBtnRef,
    emojiShow,
    setEmojiShow,
    addChatToFacebookQueryLoading,
    addMessageInputText,
    emojiRef,
    buttonRef,
    setcloseSavedRepliesWindow,
    hiddenFileInput,
    closeSavedRepliesWindow,
    searchText,
    setInputTextField,
    onEmojiClick
}) => {
    function SwitchThumb({ isChecked }) {
        return (
            <div className={`switch ${isChecked ? "checked" : "unchecked"}`}>
                {isChecked ? (
                    <LockIcon style={{ fontSize: "19px", marginTop: "-15%" }} />
                ) : (
                    <LockOpenIcon style={{ fontSize: "19px", marginTop: "-15%" }} />
                )}
            </div>
        );
    }
    function openuploader() {
        setFile(null)
        hiddenFileInput.current.click();
        setFileModal(true)
    }

    const [fileModal, setFileModal] = useState(false);
    const chatBoxMessageTextInputDivRef = useRef(null);

    const handleChange = async (event) => {
        const fileUploaded = event.target.files[0];
        // CircularProgresssetIsShown(true);
        setFile(fileUploaded);
        setContainerHeightFunction(
            chatBoxMessageTextInputDivRef.current.clientHeight
        );
    };


    const fileUploadModal = useMemo(
        () => (
            <FileUploadModal
                fileModal={fileModal}
                setFileModal={setFileModal}
                file={file}
                setFile={setFile}
                addMessageInputText={addMessageInputText}
            />

        ), [addMessageInputText, file, fileModal, setFile]
    )


    const bottomChatContainerRef = useRef(null);
    const { classes } = useStyles()
    const [textareaHeight, setTextareaHeight] = useState(90);
    const [anchorE2, setAnchorE2] = useState(null);

    const handleTextareaChange = async (event) => {
        setTextFieldValue(event.target.value);
        if (chatBoxMessageTextInputRef.current) {
            let height = chatBoxMessageTextInputRef.current.scrollHeight;
            // if (textFieldValue === '') height = 31
            if (chatBoxMessageTextInputRef.current.scrollHeight === 31) height = 31
            setTextareaHeight(Math.min(height, 136) + 59)
            setContainerHeightFunction(
                chatBoxMessageTextInputRef.current.scrollHeight
            );
        }
    };

    // when all the input is removed this will set the height of both content and text area field
    useEffect(() => {
        if (chatBoxMessageTextInputRef.current?.value == '') {
            setTextareaHeight(90)
            setContainerHeightFunction(48);
        }
    }, [textFieldValue])


    const style = {
        width: "100%",
        // height: "100%",
        /* Placeholder text color */
        color: "#838383",
        /* Placeholder font style */
        // fontStyle: "italic",
        /* Placeholder font size */
        resize: "none",
        fontSize: 14,
        // height: 50,
        /* Placeholder font weight */
        fontWeight: 400,
    };

    return (
        <CardContent
            ref={bottomChatContainerRef}

            sx={{
                position: "relative",
                border: "1px solid #DFE2E5",
                borderRadius: 1,
                width: "98%",
                margin: "0 auto",
                height: textareaHeight,
                // height: "190px",

            }}
        >


            <StyledTextField placeholder='Type a Message'

                ref={chatBoxMessageTextInputRef}
                autoFocus

                minRows={1} // minimum number of rows
                maxRows={5} // maximum number of rows
                fullWidth
                className={classes.textarea}
                onChange={handleTextareaChange}
                style={
                    style
                }
                disabled={!chatBoxSubscriptionStatus}
                onKeyUp={() => {
                    setChatBoxMessageTextInput();
                }}
                onKeyPress={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            addMessageInputText();
                            setTextFieldValue("");
                            setSearchText("");
                        }
                        return;
                    }

                    if (e.key === 'Enter' && !e.shiftKey) {

                        e.preventDefault();
                        setTextFieldValue("");
                        setSearchText("");
                    }

                }}

                value={textFieldValue}
                onInput={
                    (e) => onEnterMessageTextInput(e)
                }

            />


            <Stack direction='row'
                spacing={{ xs: 1, sm: 2, md: 2 }}

                className={classes.stack}

            >
                <Box>
                    <SavedRepliesComponent
                        setSearchText={setSearchText}
                        searchText={searchText}
                        setInputTextField={(e) => setInputTextField(e)}
                        setcloseSavedRepliesWindow={
                            setcloseSavedRepliesWindow
                        } />
                </Box>
                <Box>
                    {
                        <>
                            {fileUploadModal}
                        </>
                    }
                </Box>

                <Box sx={{
                    // marginTop: 0.2,
                    // marginRight: 1
                }}>
                    <EmojiModal onEmojiClick={onEmojiClick} />
                </Box>

                <Box >
                    <SendIcon disabled={
                        !chatBoxSubscriptionStatus ||
                        addChatToFacebookQueryLoading || !textFieldValue
                    }

                        onClick={() => {
                            addMessageInputText();
                            setSearchText("");
                        }}

                        className={classes.chatIcon} />
                </Box>
                <ChatContainerTypingMessageStatus />
            </Stack>

        </CardContent>

    )
}

export default BottomChatContainer