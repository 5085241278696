import { makeStyles } from 'tss-react/mui'

const useStyle = makeStyles()(()=>({
headerBox:{
    width: '65%',
    display: 'flex',
    padding: '22.5px 12.6px 0px 32.39px'
},
avtarStyle : {
    height:'45.44px', 
    width:'45.44px', 
    marginRight:'12.17px'
},
headerHeading:{
    fontWeight: '500',
    fontSize: '16px',
    lineHeight:"24px",
    display: 'flex',
    alignItems: 'center',
},
headingBox:{
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
},
buttonBox:{
    display: 'flex',
    alignItems: 'center',
},
cardContent : {
    paddingTop:'10px'
},
addButton:{
    width: '76px',
    height: '32px',
    fontSize: '12px',
    lineHeight:"18px",
    fontWeight: '500'
},
text:{

},
tableStyle:{
    width:'65%;'
},
pageFirstCharConnectedColor:{
    color:'#2AD19B',
    background:'#D2F8EB',
},
pageFirstCharDisconnectedColor:{
    color:'#D9515D',
    background:'#FADCDE',
},
pageFirstChar:{
    display: 'inline-block',
    width: '40px',
    height: '40px',
    fontWeight: '900',
    fontSize: '28px',
    textAlign: 'center',
    marginRight:'20px'
},
disconnectButton:{
    width: '131px',
    height: '32px',
    fontSize: '12px',
    lineHeight:"18px",
    fontWeight: '500',
    textTransform: 'none',
    background: 'rgba(227, 77, 89, 1)'
},
buttonStyleDisconnected:{
    color:'#FFFFFF',
    backgroundColor:'#E34D59',
    width:'131px',
    height:'32px', 
    textTransform:'none',
    padding: '5px 15px 5px 15px',
    fontSize: '12px',
    lineHeight:"18px",
    fontWeight: '500',
},
buttonStyleConnected:{
    color:'#FFFFFF',
    backgroundColor:'#22AA61', 
    width:'116px',
    height:'32px', 
    textTransform:'none',
    padding: '5px 15px 5px 15px',
    fontSize: '12px',
    lineHeight:"18px",
    fontWeight: '500',
},
pageName:{
    color:'#838383',
    fontWeight:'400',
    fontSize:'14px',
    lineHeight: '21px',
    borderBottom: 'unset'
},
tableCell:{
    padding:'0px',
    height: '50px'
},
avtarBox:{
    display: 'flex',
    width: '100%',
    alignItems: 'center',
},
mobileAvtar:{
    width: '29.63px', 
    height: '29.63px', 
    marginRight:'10.19px',
    border: '0.5px solid #707070', 
    background:'none', 
    color:'#000'
},
selectText:{
    fontSize: '11px',
    fontWeight: '400',
    lineHeight: '15px',
    color: '#838383',
}
}))

export default useStyle