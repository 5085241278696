import { makeStyles } from 'tss-react/mui'

const useStyle = makeStyles()(()=>({
    buttonStyleDisconnected:{
        color:'#FFFFFF',
        backgroundColor:'#E34D59',
        width:'87px',
        height:'25px', 
        textTransform:'none',
        padding: '5px 15px 5px 15px',
        fontSize:'10px',
        fontWeight:'500'
    },
    buttonStyleConnected:{
        color:'#FFFFFF',
        backgroundColor:'#22AA61', 
        width:'73px',
        height:'25px', 
        textTransform:'none',
        padding: '5px 15px 5px 15px',
        fontSize:'10px',
        fontWeight:'500'
    },
    headerBox:{
        display: 'flex',
        padding: '20px',
        alignItems: 'center',
    },
    avtarStyle : {
        height:'46px', 
        width:'46px', 
        marginRight:'10px'
    },
    headerHeading:{
        fontSize: '16px',
        fontWeight: '500',
        lineHeight:"24px",
        height: '24px',
        marginBottom: '5px'
    },
    headerSubHeading:{
        // width: '401px',
        width: '100%',
        fontWeight: '400',
        fontSize: '14px',
        height: '21px',
        color: '#838383',
    },
    formControl:{
        display:'block'
    },
    formStyle :{
        margin: '0px 0px 0px 20px'
    },
    formInput:{
        padding: '0px',
        // width: '60%',
        maxWidth:"300px",
        width: '100%',
        //
        height: '30px',
        borderRadius: '3px',
        // color:'#E8E8E8'
    },
    inputLabel:{
        // width: '60px',
        width: '100%',
        height: '18px',
        lineHeight: '18px',
        display: 'block',
        fontSize: '12px',
        fontWeight: '400',
        color: '#777777'

    },
    buttonStyle:{
        height: '30px',
        // width: '60%',
        maxWidth:"300px",
        width: '100%',
        borderRadius: '3px',
        fontWeight:'500',
        fontSize:'12px',
        lineHeight: '18px',
        marginTop:'10px'
    },
    text : {
        // width: '60%',
        width: '100%',
        fontSize: '12px',
        lineHeight:"18px",
        fontWeight:'400',
        color:'#838383',
        marginTop:'10px'
    },
    replySection:{
        fontSize: '24px',
        fontWeight: '600',
        lineHeight: '26px',
        textAlign: 'center',
        color:'#272525',
        height: '52px',
        width: '278px',
        borderRadius: 'nullpx',
        marginLeft:'130px'

    },
    internalCard:{
        height: '400px',
        width: '100%',
        margin:'20px 0px 148px 0px',
        borderRadius: '5px',
        boxShadow:'0px 2px 5px 0px rgba(0, 0, 0, 0.05)',
        marginLeft: '-20px'
    }
}));


export default useStyle

