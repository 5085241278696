import React from "react";
import CannedResponse from "../cannedResponses/CannedResponse";
import SettingLabel from "./labels/SettingLabel";
import SettingFacebook from "./chanels/settingFacebook/SettingFacebook";
import SettingHeader from "../SettingHeader";
import ChatPage from "./chanels/settingChatPage/SettingChatPage";
import SettingWebsite from "./chanels/settingWebsite/SettingWebsite";
import SettingNotification from "../SettingNotification";
import TwilioSetting from "./chanels/twilio/TwilioSetting";
interface TypesForRoute{
  setPage:any
}

const SettingRightPane:React.FC <TypesForRoute>= ({ setPage }) => {
  // let route =
  //   setPage === "/setting/facebook"
  //     ? "facebook"
  //     : setPage === "/setting/website"
  //     ? "website"
  //     : setPage === "/setting/cannedResponse"
  //     ? "cannedResponse"
  //     : setPage === "/setting/label"
  //     ? "labels"
  //     : setPage === "/setting/chatpage"
  //     ? "chatpage"
  //     : setPage === "/setting/website"
  //     ? "website"
  //     : setPage === "/setting/twilio"
  //     ? "twilio"
  //     :  setPage === "/setting/notification"
  //     ? "notification":"/setting/chatpage";

  let route =
  setPage === "/settings/facebook"
    ? "facebook"
    : setPage === "/settings/website"
    ? "website"
    : setPage === "/settings/cannedResponse"
    ? "cannedResponse"
    : setPage === "/settings/label"
    ? "labels"
    : setPage === "/settings/chatpage"
    ? "chatpage"
    : setPage === "/settings/website"
    ? "website"
    : setPage === "/settings/twilio"
    ? "twilio"
    :  setPage === "/settings/notification"
    ? "notification":"/settings/chatpage";


  return (
    <>
      {route === "facebook" && <SettingFacebook  />}
      {route === "cannedResponse" && <CannedResponse />}
      {route === "labels" && <SettingLabel />}
      {route === "chatpage" && <ChatPage />}
      {/* {route === "twilio" && <TwilioConfiguration />} */}
      
      {route === "twilio" && <TwilioSetting/>}
      {route === "website" && <SettingWebsite />}
      {route === "notification" && <SettingNotification/>}
    </>
  );
}

export default SettingRightPane;
