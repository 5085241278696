import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme) => ({
  root: {
    "&::before": {
      // content: '""',
      // display: 'block',
      // position: 'absolute',
      // width: 0,
      // height: 0,
      // borderTop: ' 15px solid transparent',
      // borderRight: '15px solid transparent',
      // borderBottom: '15px solid lightgrey',
      // right: 35,
      // bottom: '0.3%',
      // transform: 'rotate(-45deg)',
    },
    // background: 'red',
    zIndex: 99,
    height: 350,
    width: 400,
    // borderRadius: '5px',
    // border: '1px solid lightgrey',
    // marginBottom: '10px',
    overflowY: "auto",
    overflowX: "hidden",
  },
  container: {
    width: "90%",
    margin: "0 auto",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "38px",
  },
  chatIcon: {
    height: "21px",
    width: "21px",
    // marginBottom: 5,
  },
  ListItemText: {
    paddingLeft: 10,
  },
  ListItem: {
    "&: hover": {
      background: theme.palette.primary.light,
    },
  },
}));
export default useStyles;
