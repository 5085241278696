import React from 'react'
// import { useTheme } from '@mui/system';
import { useTheme } from '@mui/system'
import { CircularProgress,Box,Button,Typography } from '@mui/material';

interface Props {
  text: string
  loading:any
  // onClick: any
}

// const AuthButton: React.FC<Props> = ({ text, onClick }) => {
const AuthButton: React.FC<Props> = ({ text,loading }) => {
  const theme = useTheme()
  return (
    <Button
    //
      // onClick={onClick}
      type="submit"
      fullWidth
      variant="contained"
      sx={{
        width: '100% !important',
        //
        [theme.breakpoints.up('md')]: {
          width: '300px',
        },
        [theme.breakpoints.down('sm')]: {
          maxWidth: '300px',
        },
        [theme.breakpoints.up('xs')]: {
          maxWidth: '300px',
        },
        //
        [theme.breakpoints.up('xl')]: {
          maxWidth: '100%',
          width: '20vw',
        },
        //
        height: '40px',
        margin: '10px 0px 10px 0px',
        borderRadius: '5px',
        textTransform: 'capitalize',
        // color: theme.palette.secondary.main
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '14px',
          lineHeight:"20px",
          //
          // [theme.breakpoints.up('xl')]: {
          //   fontSize: '25px',
          // },
        }}
      >
        {' '}
        <Box style={{display:"flex",flexDirection:"row",alignItems:"center"}}>{text} {loading?<CircularProgress style={{marginLeft:"5px",width:"18px",height:"18px",color:"white"}}/>:""}</Box>
      </Typography>
    </Button>
  )
}

export default AuthButton
