import React, { useEffect, useState, useRef } from 'react'
import { Avatar, Box, Chip, CircularProgress } from '@mui/material';
import useStyles from './Styles';
import { useSelector, useDispatch } from 'react-redux';
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import PersonAdd from '@mui/icons-material/PersonAdd';
// import Settings from '@mui/icons-material/Settings';
// import Logout from '@mui/icons-material/Logout';
// import User from '../../icons/user';
// import { constants } from '../../../config/constant';
// import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
// import TextField from '@mui/material/TextField';
import KuikTextField from '../../../componentsfromkuikwit/components/shared/atoms/kuikTextField/TextField';
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Title } from '../../../componentsfromkuikwit/components/shared/atoms/text/Text'
import addAlpha from '../../../style/functions/addAlpha';
import AddImageAvatar from '../../../componentsfromkuikwit/components/shared/atoms/addImageAvatar/AddImageAvatar';
import axios from "axios";
import expressConfig from "../../../config/express.json";
import { setUserPanelChatOnline } from '../../../store/actions/UserPanelActions';


export default function ProfileSection() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [editProfile, setEditProfile] = useState<boolean>(false)
    const open = Boolean(anchorEl);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClick = (event: { stopPropagation: () => void; }) => {
        // Handle menu item click here
        event.stopPropagation();
    };
    const handleClose = () => {
        setAnchorEl(null);
        setEditProfile(false)
    };

    const handleEditProfile = (event: { stopPropagation: () => void; }) => {
        // Handle menu item click here
        event.stopPropagation();
        setEditProfile(!editProfile);
    };
    const { classes } = useStyles()
    const [profile, setProfile] = useState({
        id: '',
        name: '',
        email: '',
        picture: '',
        number: '',
        pseudonym: '',
        designation: ''
    })
    const [loaded, setLoaded] = useState(false);



    const { userPanelChatOnline } = useSelector((state: any) => state.UserPanelReducer)
    const MeQuery = gql`
  query Me($accessToken: String) {
    me(accessToken: $accessToken) {
      id
      name
      email
      picture
      number
      pseudonym
      designation {
        paneltype
      }
    }
  }
`;

    let [
        meQuery,
        { loading: meQueryLoading, error: meQueryQueryError, data: meQueryResult },
    ] = useLazyQuery(MeQuery, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        // Facebook.fbInt();
        meQuery();
    }, []);

    useEffect(() => {
        if (meQueryResult) {
            const { name, email, picture, pseudonym, designation, number, id } = meQueryResult.me

            setProfile({
                id,
                name,
                email,
                number,
                picture,
                pseudonym,
                designation: designation.paneltype
            })
        }
    }, [meQueryResult])
    const handleLoad = () => {
        setLoaded(true);
    };



    const chipSX = {
        height: 20,
        borderRadius: 0,
        fontSize: '9px',
        whiteSpace: 'normal',

    }
    const chipMoreSX = {
        ...chipSX,
        color: '#0085FF',
        backgroundColor: addAlpha('#0085FF', .1),
        display: "flex",
        justifyContent: "center"

    };



    // accepting chats functionality

    const [acceptingChats, SetAcceptingChats] = useState(false)

    useEffect(() => {
        SetAcceptingChats(userPanelChatOnline)
    }, [userPanelChatOnline])

    const dispatch = useDispatch()

    const ChangeOnlineStatusMutation = gql`
    mutation ChangeOnlineStatus($online: Boolean!) {
      changeonlinestatus(online: $online) {
        success
        error
        result
      }
    }
  `;

    let [
        changeOnlineStatusMutation,
        {
            loading: changeOnlineStatusMutationLoading,
            error: changeOnlineStatusMutationError,
            data: changeOnlineStatusMutationResult,
        },
    ] = useMutation(ChangeOnlineStatusMutation);

    React.useEffect(() => {
        if (
            changeOnlineStatusMutationResult &&
            changeOnlineStatusMutationResult.changeonlinestatus
        ) {
            dispatch(setUserPanelChatOnline(
                JSON.parse(changeOnlineStatusMutationResult.changeonlinestatus.result)
            ))
        }
    }, [changeOnlineStatusMutationResult]);

    React.useEffect(() => {
        if (changeOnlineStatusMutationError) {
            changeOnlineStatusMutationError.graphQLErrors.map(({ message }, i) => {
                // enqueueSnackbar(message, { variant: "error" });
            });
        }
    }, [changeOnlineStatusMutationError]);

    const handleChange = () => {
        SetAcceptingChats(!acceptingChats)
        changeOnlineStatusMutation({
            variables: {
                online: !acceptingChats
            }
        })
    }

    return (
        <React.Fragment>

            <Box
                className={classes.profile}>

                {meQueryLoading && !loaded ? (
                    <CircularProgress size={24} sx={{
                        margin: "auto",
                        display: "block",
                        marginTop: 2,
                        fontWeight: "400", color: "#4d1277"
                    }} />
                ) : (<Badge color={userPanelChatOnline ? ("secondary") : ('error')} badgeContent=" " variant="dot" overlap="circular"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >

                    <Avatar src={profile.picture} onLoad={handleLoad} onClick={handleOpen}
                    />
                </Badge>)
                }
            </Box >
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                // onClick={handleClose}

                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: 330,
                        height: 490,
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >

                {editProfile ? (<EditProfile setAnchorEl={setAnchorEl} profile={profile} setEditProfile={setEditProfile} />) : (
                    <Box>
                        <MenuItem onClick={handleClick} className={classes.menuItem} disableRipple>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    // height: "400px",
                                    width: "100%",
                                    //
                                }}
                            >

                                <Avatar src={profile.picture} onLoad={handleLoad} onClick={handleClick} sx={{ height: '80px !important', width: '80px!important' }} />


                            </Box>


                        </MenuItem>
                        <Typography
                            variant="subtitle2" className={classes.profileName}>
                            {profile.name}
                        </Typography>
                        <Typography
                            variant="subtitle2" className={classes.profilePseudonym}>
                            {profile.pseudonym}
                        </Typography>
                        <Box sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 1

                        }}>

                            <Chip sx={chipMoreSX} label={profile.designation} />
                        </Box>



                        <MenuItem onClick={handleClick} disableRipple sx={{
                            paddingTop: '10px',
                        }} className={classes.menuItem}>

                            <Button variant="outlined" sx={{ height: "100%", width: "100%", borderRadius: 15 }} onClick={handleEditProfile}><ModeEditIcon sx={{ height: 17, width: 17 }} /><Typography ml={1}>
                                Edit profile

                            </Typography> </Button>
                        </MenuItem>


                        <MenuItem onClick={handleClick} className={classes.menuItem} disableRipple sx={{
                            // paddingTop: '10px',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',

                        }}>
                            <Box p={2} sx={{
                                backgroundColor: addAlpha('#FF8A00', .1), width: "100%"
                            }}>
                                <Typography
                                    // mt={1}
                                    variant="subtitle2" >
                                    Upgrade your plan
                                </Typography>

                                <Typography mt={1} variant="body1" style={{ overflowWrap: 'break-word', wordWrap: 'break-word', fontSize: 12 }}>
                                    05 Days left in your trial, Upgrade your plan<br />  now
                                    before your account is hold.    </Typography>

                                <Button sx={{
                                    marginTop: 1,
                                    //  marginBottom: 1,
                                    backgroundColor: '#FF8A00', textTransform: 'Capitalize',
                                }} variant="contained">Upgrade now</Button>

                            </Box>

                        </MenuItem>


                        <MenuItem onClick={handleClick} className={classes.menuItem} disableRipple sx={{
                            // paddingTop: '10px',
                            height: 40
                        }}>
                            <FormControlLabel
                                sx={{
                                    display: 'block',
                                }}
                                control={
                                    <Switch
                                        checked={acceptingChats}
                                        onChange={handleChange}
                                        name="loading"
                                        color="primary"
                                    />
                                }
                                label="Accepting chats"
                            />

                        </MenuItem>


                        <MenuItem
                            // onClick={handleClick}
                            className={classes.menuItem} disableRipple sx={{
                                // paddingTop: '10px',
                                height: 40
                            }}>
                            <FormControlLabel
                                sx={{
                                    display: 'block',
                                }}
                                control={
                                    <Switch
                                        disabled
                                        // checked={loading}
                                        // onChange={() => setLoading(!loading)}
                                        name="loading"
                                        color="primary"
                                    />
                                }
                                label="Dark mode"
                            />

                        </MenuItem>

                    </Box>

                )}

            </Menu>
        </React.Fragment>
    );
}

// interface FormValuesProps {
//     shortcut: string
//     messagebox: string
//     id: null | string

// }

interface Props {
    setEditProfile: (a: boolean) => void
    profile: any
    setAnchorEl: (a: any) => void
}

const EditProfile: React.FC<Props> = ({ setEditProfile, profile, setAnchorEl }) => {

    const { classes } = useStyles()
    const { name, email, picture, pseudonym, designation, number } = profile


    const handleClick = (event: { stopPropagation: () => void; }) => {
        // Handle menu item click here
        event.stopPropagation();
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(6, "name must be at least 6 characters")
            .required("name is required"),
        pseudonym: Yup.string()
            .min(3, "pseudonym must be at least 1 character")
            .required("pseudonym is required"),
        email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
        number: Yup.string()
            .min(8, "Number must be at correct")
        // .required("Number is required"),
    });

    const handleCancel = () => {
        setEditProfile(false)
    };

    // update user //
    const updateUserMutation = gql`
  mutation UpdateUser(
    $id: ID!
    $name: String!
    $pseudonym: String
    $picture: String
    $number: String
   
  ) {
    updateuser(
      id: $id
      name: $name
      pseudonym: $pseudonym
      picture: $picture
      number: $number
      
    ) {
      success
      error
    }
  }
`;



    interface FormValues {
        name: string;
        email: string;
        pseudonym: string;
        designation: string;
        number: string;
    }

    const env = process.env.NODE_ENV || "development";
    const config = expressConfig[env];
    const MeQuery = gql`
    query Me($accessToken: String) {
      me(accessToken: $accessToken) {
        id
        name
        email
        picture
        number
        pseudonym
        designation {
          name
        }
      }
    }
  `;
    let [
        meQuery,
        { loading: meQueryLoading, error: meQueryQueryError, data: meQueryResult },
    ] = useLazyQuery(MeQuery, {
        fetchPolicy: "network-only",
    });


    const handleSubmit = async (
        FormValues: FormValues,
        // { setSubmitting }: FormikHelpers<FormValues>
        { setSubmitting }: any
    ) => {


        try {
            const formData = new FormData();
            formData.append("file", file);
            console.log(FormValues, "FormValuesFormValues")
            let imagePath = profile.picture;

            if (newImageUploaded) {
                const data = await axios.post(
                    `${config.graphql_domain}:${config.port}/uploads`,
                    formData
                );
                imagePath = data.data.filePath
            }


            console.log(imagePath, "imagePathimagePathimagePath")
            await updateUser({
                variables: {
                    id: profile.id,
                    name: FormValues.name,
                    pseudonym: FormValues.pseudonym,
                    picture: imagePath,
                    number: FormValues.number,
                },
            })
        } catch (e) {
            console.log("error in signing up", e);
        }
        setAnchorEl(null)
        meQuery();
        setEditProfile(false)
    };

    const [
        updateUser,
        {
            loading: updateMutationLoading,
            error: updateMutationError,
            data: updateMutationResult,
        },
    ] = useMutation(updateUserMutation);


    useEffect(() => {

        if (updateMutationResult) {
            console.log(updateMutationResult)
        }
    }, [updateMutationResult])


    useEffect(() => {

        if (updateMutationError) {
            console.log(updateMutationError)
        }
    }, [updateMutationError])


    // update user //

    const [loaded, setLoaded] = useState(false);

    const handleLoad = () => {
        setLoaded(true);
    };
    const chipSX = {
        height: 20,
        borderRadius: 0,
        fontSize: '9px',
        whiteSpace: 'normal',

    }
    const chipMoreSX = {
        ...chipSX,
        color: '#0085FF',
        backgroundColor: addAlpha('#0085FF', .1),
        display: "flex",
        justifyContent: "center"

    };

    const [file, setFile] = useState<any>(null)
    const [newImageUploaded, setNewImageUploaded] = useState(false);
    const hiddenFileInput = useRef<HTMLInputElement | null>(null);

    const openFileUpload = () => {
        setFile(null)
        setNewImageUploaded(true)
        if (hiddenFileInput.current !== null) {
            hiddenFileInput?.current.click();
        }

    }
    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            setFile(file);
        }
    };

    const [preview, setPreview] = useState<string | undefined>(picture)
    console.log(picture, "picturepicturepicture")
    useEffect(() => {
        if (!file) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(file)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [file])

    return (

        <>
            <MenuItem onClick={handleClick} disableRipple className={classes.menuItem}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // height: "400px",
                        width: "100%",
                        //
                    }}
                >

                    <AddImageAvatar id="uploadFile" onClick={openFileUpload} imageUrl={!preview ? picture : preview} sx={{ height: '80px !important', width: '80px!important' }} />

                    <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        // onClick={(event) => {
                        //     event.target.value = null
                        // }}
                        id="uploadFile"
                        name="uploadFile"
                        style={{ display: "none" }}
                    />
                </Box>


            </MenuItem>
            <Typography
                variant="subtitle2" className={classes.profileName}>
                {profile.name}
            </Typography>
            <Typography
                variant="subtitle2" className={classes.profilePseudonym}>
                {profile.pseudonym}
            </Typography>
            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 1

            }}>

                <Chip sx={chipMoreSX} label={profile.designation} />
            </Box>
            <MenuItem onClick={handleClick} disableRipple className={classes.menuItem} >
                <Formik
                    initialValues={{
                        name,
                        pseudonym,
                        email,
                        number,
                        designation,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {(formik: any) => (
                        <Box component="form" onSubmit={formik.handleSubmit} sx={{
                            width: "100%"
                        }}
                        >
                            <Box mt={1}>
                                <Title text='Full name' />
                            </Box>
                            <Box >
                                <KuikTextField
                                    id="name"
                                    // label=""
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    helperText={formik.touched.name && formik.errors.name}
                                />
                            </Box>
                            {/* <Box mt={1} sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}> */}
                            <Box mt={1}>
                                <Title text='Pseudonym' />

                                <KuikTextField
                                    id="pseudonym"
                                    // label=""
                                    name="pseudonym"
                                    value={formik.values.pseudonym}
                                    onChange={formik.handleChange}
                                    helperText={formik.touched.pseudonym && formik.errors.pseudonym}
                                />
                            </Box>
                            {/* <Box>
                                    <Title text='Job title' />

                                    <KuikTextField
                                        id="designation"
                                        // label=""
                                        disabled={true}
                                        name="designation"
                                        value={formik.values.designation}
                                        onChange={formik.handleChange}
                                        helperText={formik.touched.designation && formik.errors.designation}
                                    />
                                </Box> */}

                            {/* </Box> */}

                            <Box mt={1}>
                                <Title text='Email' />
                            </Box>
                            <Box >
                                <KuikTextField
                                    id="email"
                                    // label=""
                                    disabled={true}
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Box>
                            <Box mt={1}>
                                <Title text='Phone number' />
                            </Box>
                            <Box >
                                <KuikTextField
                                    id="number"
                                    // label=""
                                    name="number"
                                    value={formik.values.number}
                                    onChange={formik.handleChange}
                                    helperText={formik.touched.number && formik.errors.number}
                                />
                            </Box>
                            <Box mt={1} sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-end',
                            }}>
                                <Box pr={1} >
                                    {/* <Button variant="contained"
                                        onClick={handleCancel}
                                    >Cancel</Button> */}
                                    <Button sx={{
                                        height: '30px', width: "85px",
                                        fontSize: "12px", fontWeight: 400, background: "#F5F5F5", textDecoration: 'none', textTransform: "capitalize"
                                    }} onClick={handleCancel}>Cancel</Button>
                                </Box>

                                <Box>
                                    {/* <LoadingButtonKuikwit type="submit" text="Save" loading ={true}/> */}
                                    <Button type="submit" variant="contained" sx={{
                                        height: '30px', width: "85px",
                                        fontSize: "12px", fontWeight: 400, textDecoration: 'none', textTransform: "capitalize"
                                    }}
                                        disabled={updateMutationLoading}
                                        endIcon={updateMutationLoading && <CircularProgress size={20} color="inherit" />} >Save</Button>
                                </Box>

                            </Box>


                        </Box>)}
                </Formik>
            </MenuItem >
        </>
    );
}