import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

import { Tooltip } from "@material-ui/core";
import { Divider } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  userLabel: {
    textAlign: "center",
    // borderBottom: "1px solid gray",
    fontSize: 14,
    fontWeight: 400,
    display: "block",
    color: "#A0A0A0",
    fontFamily: "Poppins",
  },
}));
const LabelMessageContainer = ({ item, textCreated, searchCount }) => {
  const classes = useStyles();

  return (
    <div>
      <Tooltip
        arrow={true}
        placement={"top"}
        title={moment.unix(item.timestamp / 1000).format("DD MMM YYYY hh:mm a")}
        className={classes.userLabel}
      >
        <Divider sx={{
          margin: "0px 20px 0px 20px",
        }}>
          <span
            data-search={
              textCreated.containsSearch ? "Search" + searchCount : undefined
            }
            dangerouslySetInnerHTML={{
              __html: textCreated.text.includes("!-!-!-")
                ? textCreated.text.split("!-!-!-")[0]
                : textCreated.text,
            }}
          ></span>
        </Divider>
      </Tooltip>
    </div>
  );
};

export default LabelMessageContainer;
