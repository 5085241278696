import { Box, Button } from '@mui/material'
import useStyles from './Styles'
import { ContentSmall, Title } from '../../../../shared/atoms/text/Text'
import KuikTextField from '../../../../shared/atoms/kuikTextField/TextField'
import ChatDetailsComponentTextArea from '../../chatDetailsComponent/chatDetailsComponentTextArea/ChatDetailsComponentTextArea'
import { Formik } from 'formik'
import * as Yup from 'yup'

// interface for the form values
interface FormValuesProps {
    shortcut: string
    messagebox: string
    id: null | string

}

interface Props {
    handleSave: (values: any) => void,
    FormValues: FormValuesProps
    setCreateReply: any
    handleListItemMenuClose: () => void
    setFormValues: any
}

const CreateSavedReply: React.FC<Props> = ({ handleSave, setFormValues, FormValues, setCreateReply, handleListItemMenuClose }) => {

    // validation schema using Yup
    const validationSchema = Yup.object().shape({
        messagebox: Yup.string()
            .min(6, "Message must be at least 6 characters")
            .required("Message is required"),
        shortcut: Yup.string()
            .min(1, "Shortcut must be at least 1 character")
            .required("Shortcut is required"),
    });


    const handleSubmit = async (
        values: FormValuesProps,
        // { setSubmitting }: FormikHelpers<FormValues>
        { setSubmitting }: any
    ) => {
        // handle form submission
        setSubmitting(false)

        // e.preventDefault()
        try {
            await handleSave(values);

        } catch (e) {
            console.log('error in submitting', e)
        }
        handleListItemMenuClose()
    }

    const { classes } = useStyles();

    const handleCancel = () => {
        setCreateReply(false)
        setFormValues({
            id: null,
            shortcut: "",
            messagebox: "",
        })
        handleListItemMenuClose()
    }
    const placeholderStyle = {
        width: "100%", height: "100%",
        /* Placeholder text color */
        color: "#B7B7B7",
        /* Placeholder font style */
        // fontStyle: "italic",
        /* Placeholder font size */
        padding: 10,
        fontSize: 12,
        /* Placeholder font weight */
        fontWeight: 400,
        // height: 50,
        /* Placeholder font weight */
    };
    return (
        <>

            <Box m={1}>
                <Box >
                    <Title text='Create saved reply' />
                </Box>
                <Box mt={1}>

                    <ContentSmall text='Add a text shortcut to quickly insert this reply. Shortcuts must be
                                     at least three characters.' />
                </Box>
                <Formik
                    initialValues={FormValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {(formik: any) => (
                        <Box component="form" onSubmit={formik.handleSubmit}>
                            <Box mt={1}>
                                <Title text='Shortcut' />
                            </Box>
                            <Box mt={1}>
                                <KuikTextField
                                    id="shortcut"
                                    label="shortcut"
                                    name="shortcut"
                                    value={formik.values.shortcut}
                                    onChange={formik.handleChange}
                                    helperText={formik.touched.shortcut && formik.errors.shortcut}
                                />
                            </Box>
                            <Box mt={1}>
                                <Title text='Message' />
                            </Box>
                            <Box mt={1} sx={{ height: '107px' }}>
                                <ChatDetailsComponentTextArea
                                    id="messagebox"
                                    label="messagebox"
                                    name="messagebox"
                                    style={placeholderStyle}
                                    placeholder='Write a message to' value={formik.values.messagebox}
                                    onChange={formik.handleChange}
                                    helperText={formik.touched.messagebox && formik.errors.messagebox} />
                            </Box>
                            <Box mt={3} className={classes.footer}>
                                <Box ml={2}>
                                    <Button variant="contained" className={classes.cancel}
                                        onClick={handleCancel}
                                    >Cancel</Button>
                                </Box>
                                <Box>
                                    <Button variant="contained" color="success" sx={{
                                        textTransform: 'capitalize',
                                        color: 'white',
                                    }} type="submit"
                                    >Save</Button>
                                </Box>
                            </Box>

                        </Box>)}
                </Formik>
            </Box>



        </>


    )
}

export default CreateSavedReply
