import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(()=>({
    SettingLeftPaneContentSection: {
        width: "90%",
        margin: "15px auto"
    },
    dropDownMainText: {
        fontFamily: "poppins",
        fontSize: "14px",
        margin: "15px 0px",
        cursor: "pointer"
    },
    styleCustom:{
        fontFamily : "Poppins",
        color: '#4F3065'
    },
    activeButton:{
        backgroundColor: '#4F3065',
        borderRadius: '3px',
        "&:hover": {
            backgroundColor: "#4F3065",
            borderRadius: "3px"
          },
    },
    activeTextColor:{
        color: '#fff',
        textDecoration: 'none'
    }
}));

export default useStyles