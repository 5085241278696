import React, { useRef, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useStyles from './Styles'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import formatBytes from '../../../../../style/functions/formatBytes';

interface Props {
    fileModal: boolean
    setFileModal: (a: boolean) => void
    file: File | null
    setFile: (a: File | null) => void
    addMessageInputText: () => void
}
const FileUploadModal: React.FC<Props> = ({
    fileModal: open,
    setFileModal: setOpen,
    file,
    setFile,
    addMessageInputText,
}) => {
    const hiddenFileInput = useRef<HTMLInputElement | null>(null);
    const handleClose = () => {
        setOpen(false);
        setFile(null);

    }
    const [preview, setPreview] = useState<string | undefined>()

    const { classes } = useStyles();

    const openFileUpload = () => {
        setFile(null)
        if (hiddenFileInput.current !== null) {
            hiddenFileInput?.current.click();
        }

    }
    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            setFile(file);
            setOpen(true)
        }
    };

    useEffect(() => {
        if (!file) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(file)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [file])
    
    return (
        <>
            <AttachFileIcon id="uploadFile" color="info"
                onClick={() => openFileUpload()} className={classes.icon} />
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                // onClick={(event) => {
                //     event.target.value = null
                // }}
                id="uploadFile"
                name="uploadFile"
                style={{ display: "none" }}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.modal}>
                    <Card>
                        <CardHeader
                            sx={{ height: "54px" }} subheader={
                                <Box>
                                    <Typography>File To Upload</Typography>
                                </Box>
                            }
                            action={<CloseIcon onClick={handleClose} />}

                        ></CardHeader>
                        <Divider />
                        <CardContent sx={{ height: "160px", }}>
                            <Box className={classes.content}>
                                <Typography className={classes.upload}>
                                    Ready To Upload:
                                </Typography>
                            </Box>

                            <Box mt={1}>
                                <Box className={classes.file} >
                                    <Box className={classes.preview}>
                                        <img src={preview} alt="preview" className={classes.image} />
                                    </Box>
                                    <span style={{ marginLeft: '8px' }}>
                                        <Typography className={classes.name}>{file?.name}</Typography>
                                        <Typography className={classes.size}>{file && formatBytes(file.size)}</Typography>
                                    </span>
                                </Box>
                            </Box>
                        </CardContent>
                        <Divider />
                        <Box className={classes.footer}>
                            <Box>
                                <Button variant="contained" className={classes.cancel}

                                    onClick={handleClose}>Cancel</Button>
                            </Box>
                            <Box>
                                <Button variant="contained" color="success" className={classes.done}

                                    onClick={() => { addMessageInputText(); setOpen(false) }}
                                >Upload</Button>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            </Modal>
        </>
    );
}

export default FileUploadModal



