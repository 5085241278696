import React, { useState } from 'react'
// import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ContentSmall } from '../../atoms/text/Text';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 260,
    height: 180,
    // bgcolor: 'background.paper',
    // boxShadow: 24,
    // zIndex: 99
    // p: 4,
};

interface MyButtonProps {
    onClick: (a: string) => void
    // a: string | null;
    a: any
}

function AreYouSureModal(props: MyButtonProps) {
    console.log(props.a, "props.a")

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <>

            <MenuItem onClick={handleOpen}><ContentSmall text='Delete' /></MenuItem>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Card>
                        <CardContent sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <DeleteIcon color='primary' sx={{ fontSize: 35 }} />
                            <Typography mt={1} sx={{ fontSize: '16px', fontWeight: 500 }}>Are you sure?</Typography>
                            <Typography mt={1} ml={2} sx={{ fontSize: '12px', fontWeight: 400 }}>You want to delete this saved reply?</Typography>
                            <Stack mt={2} direction="row" spacing={{ xs: 1, sm: 2, md: 1 }}>
                                <Button sx={{
                                    height: '30px', width: "105px",
                                    fontSize: "12px", fontWeight: 400, background: "#F5F5F5", textDecoration: 'none'
                                }} onClick={handleClose}>Cancel</Button>
                                <Button sx={{
                                    height: '30px', width: "105px",
                                    fontSize: "12px", fontWeight: 400, textDecoration: 'none'
                                }}
                                    onClick={() => {props.onClick(props.a)}}

                                    color="primary" variant='contained'>Delete</Button>
                            </Stack>
                        </CardContent>
                    </Card>
                </Box>
            </Modal>
        </>
    )
}

export default AreYouSureModal