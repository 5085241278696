import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
//
import { FormControl, InputLabel } from "@mui/material";
//
import Loader from "../../../../loader";
//
import FormHelperText from "@mui/material/FormHelperText";
import { useFormik } from "formik";
import * as Yup from "yup";
//
import CloseIcon from "@mui/icons-material/Close";
//
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormEvent } from "react";
//
import { SvgIcon } from "@mui/material";
//
import useStyles from "./Styles";
import { OutlinedInput } from "@mui/material";
/////////////////////////////////
import { constants } from "../../../../../config/constant";
// import Button from '../../../../../../src/components/atoms/buttons/button';
import Add from "../../../../icons/add";
import Back from "../../../../icons/back";
import Close from "../../../../icons/close";
import SingleInput from "../../../../input/singleInput";
import expressConfig from "../../../../../config/express.json";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import useSnackBar from "../../../../../hooks/useSnackBar";
import { invalid } from "moment";
import Dropdown from "react-bootstrap/Dropdown";

interface AppConfig {
  port?: number | string;
  graphql_endpoint?: string;
  graphql_domain?: string;
  graphql_subscription_domain?: string;
  graphql_subscription_endpoint?: string;
  facebook_app_id?: string;
  facebook_app_secret?: string;
  SHAREABLEINVITELINK?: string;
  GOOGLE_CLIENT_ID?: string;
  GOOGLE_CLIENT_SECRET?: string;

  FACEBOOK_APP_ID?: string;
  FACEBOOK_APP_SECRET?: string;

  LINKEDIN_API_KEY?: string;
  LINKEDIN_SECRET_KEY?: string;
}

const initialState = {
  error: {},
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case "ON_ERROR":
      return { ...state, error: { ...action.payload } };
    default:
      return state;
  }
}

// invite agents //
const handleInviteAgents = gql`
  mutation inviteAgent($argsArray: [invite_agents_data!], $superAdminId: ID!) {
    inviteAgent(argsArray: $argsArray, superAdminId: $superAdminId) {
      success
      error
    }
  }
`;

/////////////////////////////////////////////
const style = {
  // position: 'absolute' as 'absolute',
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //
  width: "600px",
  // height: "390px",
  height: "auto",
  maxHeight: "450px",
  //
  boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)",
  borderRadius: "3px",
  backgroundColor: "#FFFFFF",
};

// interface FilledArrowIconProps {
//   prop1:string
// }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function FilledArrowIcon() {
  return (
    <SvgIcon style={{ position: "relative", left: "-150px" }}>
      <path
        d="M16.5 9.5h-9c-.3 0-.5.2-.5.5v2c0 .2.1.4.2.5l3.4 3.4c.4.4 1 .4 1.4 0l3.4-3.4c.1-.1.2-.3.2-.5v-2c0-.3-.2-.5-.5-.5z"
        fill="currentColor"
      />
      <polygon points="7,10.5 12,15.1 17,10.5" fill="currentColor" />
    </SvgIcon>
  );
}

interface Props {
  check: boolean;
  handleCloseDropDown: () => void;
  closeModal: () => void;
}

const KeepMountedModal: React.FC<Props> = ({
  check,
  handleCloseDropDown,
  closeModal,
}) => {
  ///////////////////////////////////////////
  const [state, dispatch_] = React.useReducer(reducer, initialState);
  const { success, error } = useSnackBar();
  const [designation, setDesignation] = React.useState("4");
  const emailRegex =
    /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/;
  const env = process.env.NODE_ENV || "development";
  const config: AppConfig = expressConfig[env];
  const role = ["Admin", "Manager", "Agent"];
  const [isCopied, setIsCopied] = React.useState(false);
  const ref = React.useRef(null);
  const [inviteEmail, setInviteEmail] = React.useState([
    { id: 1, email: "", role: "", designation: "" },
    { id: 2, email: "", role: "", designation: "" },
    { id: 3, email: "", role: "", designation: "" },
  ]);

  // const mainSuperAdminId = JSON.parse(localStorage.getItem("ActiveUserdetail")).mainSuperAdminId;
  const activeUserDetail = localStorage.getItem("ActiveUserdetail");
  const mainSuperAdminId = activeUserDetail
    ? JSON.parse(activeUserDetail).mainSuperAdminId
    : null;

  // invite agents //
  const handleInviteAgents = gql`
    mutation inviteAgent(
      $argsArray: [invite_agents_data!]
      $superAdminId: ID!
    ) {
      inviteAgent(argsArray: $argsArray, superAdminId: $superAdminId) {
        success
        error
      }
    }
  `;

  let [
    inviteAgent,
    {
      loading: inviteAgentsQueryLoading,
      error: inviteAgentsQueryError,
      data: inviteAgentsQueryResult,
    },
  ] = useMutation(handleInviteAgents);
  // invite agents //

  React.useEffect(() => {
    if (inviteAgentsQueryError && inviteAgentsQueryError.graphQLErrors) {
      error("Something went wrong");
    }
  }, [inviteAgentsQueryError]);

  React.useEffect(() => {
    if (
      inviteAgentsQueryResult &&
      inviteAgentsQueryResult.inviteAgent &&
      inviteAgentsQueryResult.inviteAgent.success == 1
    ) {
      success("Invite sent successfully");
      handleClose();
    }
  }, [inviteAgentsQueryResult]);

  //////////////////////////////////////////////
  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true)
  //   const handleClose = () => setOpen(false)
  const handleClose = () => {
    //closing Modal
    setOpen(false);
    //closing the dropdown
    handleCloseDropDown();
    //turning value to false - for not modal appearing for clicking on the openModal
    closeModal();
    // setOpen(false)
  };
  //
  React.useEffect(() => {
    setOpen(check);
  }, [check]);
  //

  const [showCopied, setShowCopied] = React.useState(false);
  const [showChooseRole, setShowChooseRole] = React.useState(false);

  //error decision and message
  const [showError, setShowError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");

  const [name1, setName1] = React.useState("");
  const [role1, setRole1] = React.useState("");

  const [name2, setName2] = React.useState("");
  const [role2, setRole2] = React.useState("");

  const [name3, setName3] = React.useState("");
  const [role3, setRole3] = React.useState("");

  const [roleLink, setRoleLink] = React.useState("");

  React.useEffect(() => {
    console.log("changed roleLink", roleLink);
    if (roleLink !== "") {
      setShowChooseRole(false);
    }
  }, [roleLink]);
  //

  React.useEffect(() => {
    if (showChooseRole === true) {
      setTimeout(() => {
        setShowChooseRole(false);
      }, 2000);
    }
  });

  //
  const fieldStyle = {
    width: "430px",
    //height: "30px",
    height: "38px",
    border: "0.0px solid #BDC0CC",
    borderRadius: "3px",
    background: "#FFFFFF",
    padding: "0px",
    marginTop: "0px",
    marginBottom: "0px",
  };

  React.useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, 3000);
    }
  }, [showError]);

  const { classes } = useStyles();

  const anchorRef = React.useRef(null);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleButtonClick = () => {
    // Toggle the menu open state when the button is clicked
    setMenuOpen(!menuOpen);
  };

  //////////////////////

  const [initialFormikValues, setInitialFormikValues] = React.useState({
    email1: "",
    role1: "",
    email2: "",
    role2: "",
    email3: "",
    role3: "",
  });
  //truly working
  // const validationSchema = Yup.object().shape({
  //   email1: Yup.string().email("Invalid email address"),
  //   role1: Yup.string().test(
  //     "is-required-for-email1",
  //     "Role is required for email 1",
  //     function (
  //       value: string | undefined,
  //       { parent }: { parent: Record<string, any> }
  //     ) {
  //       if (parent.email1 && parent.email1.length > 0) {
  //         return !!value;
  //       }
  //       return true;
  //     }
  //   ),
  //   //
  //   email2: Yup.string().email("Invalid email address"),
  //   role2: Yup.string().test(
  //     "is-required-for-email2",
  //     "Role is required for email 2",
  //     function (
  //       value: string | undefined,
  //       { parent }: { parent: Record<string, any> }
  //     ) {
  //       if (parent.email2 && parent.email2.length > 0) {
  //         return !!value;
  //       }
  //       return true;
  //     }
  //   ),
  //   //
  //   email3: Yup.string().email("Invalid email address"),
  //   role3: Yup.string().test(
  //     "is-required-for-email3",
  //     "Role is required for email 3",
  //     // function (value, { parent }) {
  //     function (
  //       value: string | undefined,
  //       { parent }: { parent: Record<string, any> }
  //     ) {
  //       if (parent.email3 && parent.email3.length > 0) {
  //         return !!value;
  //       }
  //       return true;
  //     }
  //   ),
  // });

  const validationSchema = Yup.object().shape({
    email1: Yup.string().email("Invalid email address"),
    role1: Yup.string().test(
      "is-required-for-email1",
      "Role is required for email 1",
      function (
        value: string | undefined,
        { parent }: { parent: Record<string, any> }
      ) {
        if (parent.email1 && parent.email1.length > 0) {
          return !!value && value !== parent.email2 && value !== parent.email3;
        }
        return true;
      }
    ),
    email2: Yup.string()
      .email("Invalid email address")
      .test(
        "is-unique-email2",
        "Email 2 cannot be the same as Email 1",
        function (
          value: string | undefined,
          { parent }: { parent: Record<string, any> }
        ) {
          if (parent.email1 && parent.email1.length > 0) {
            return value !== parent.email1;
          }
          return true;
        }
      ),
    role2: Yup.string().test(
      "is-required-for-email2",
      "Role is required for email 2",
      function (
        value: string | undefined,
        { parent }: { parent: Record<string, any> }
      ) {
        if (parent.email2 && parent.email2.length > 0) {
          return !!value && value !== parent.email1 && value !== parent.email3;
        }
        return true;
      }
    ),
    email3: Yup.string()
      .email("Invalid email address")
      .test(
        "is-unique-email3",
        "Email 3 cannot be the same as Email 1 or Email 2",
        function (
          value: string | undefined,
          { parent }: { parent: Record<string, any> }
        ) {
          if (parent.email3 && parent.email3.length > 0) {
            return value !== parent.email1 && value !== parent.email2;
          }
          return true;
        }
      ),
    role3: Yup.string().test(
      "is-required-for-email3",
      "Role is required for email 3",
      function (
        value: string | undefined,
        { parent }: { parent: Record<string, any> }
      ) {
        if (parent.email3 && parent.email3.length > 0) {
          return !!value && value !== parent.email1 && value !== parent.email2;
        }
        return true;
      }
    ),
  });

  interface FormValuesProps {
    email1: string;
    role1: string;
    email2: string;
    role2: string;
    email3: string;
    role3: string;
  }
  const [emailRequired, setEmailRequired] = React.useState(false);

  const updatedHandleSubmit = async (
    values: FormValuesProps,
    formikHelpers: any
  ) => {
    formikHelpers.setSubmitting(false);
    try {
      //
      if (!values.email1 && !values.email2 && !values.email3) {
        setEmailRequired(true);
        setTimeout(() => {
          setEmailRequired(false);
        }, 3000);
      } else {
        setEmailRequired(false);

        /////inviting agent

        let tempData = [];
        if (values.email1 !== "") {
          tempData.push({
            email: values.email1,
            designation:
              values.role1 === "Super Admin"
                ? 4
                : values.role1 === "Manager"
                ? 3
                : 14,
          });
        }
        if (values.email2 !== "") {
          tempData.push({
            email: values.email2,
            designation:
              values.role2 === "Super Admin"
                ? 4
                : values.role2 === "Manager"
                ? 3
                : 14,
          });
        }
        if (values.email3 !== "") {
          tempData.push({
            email: values.email3,
            designation:
              values.role3 === "Super Admin"
                ? 4
                : values.role3 === "Manager"
                ? 3
                : 14,
          });
        }
        const activeUserDetail = localStorage.getItem("ActiveUserdetail");
        const mainSuperAdminId = activeUserDetail
          ? JSON.parse(activeUserDetail).mainSuperAdminId
          : null;

        inviteAgent({
          variables: {
            argsArray: tempData,
            superAdminId: mainSuperAdminId,
          },
        });
        /////

        // formikHelpers.resetForm();
      }
      //
    } catch (error) {
      console.log("error:", error);
    }
  };

  const formik = useFormik({
    initialValues: initialFormikValues,
    validationSchema: validationSchema,
    onSubmit: updatedHandleSubmit,
  });

  //////////////////////
  const [futureTime, setFutureTime] = React.useState("");

  React.useEffect(() => {
    const currentUStime = new Date().toLocaleString("en-US", {
      timeZone: "America/New_York",
      hour12: false,
    });
    const currentUStimeFormat = new Date(currentUStime);

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    const localFutureTime = `${currentUStimeFormat.getDate() + 6} ${monthNames[currentUStimeFormat.getMonth()]} ${currentUStimeFormat.getFullYear()}`;
    //
    setFutureTime(localFutureTime);
    //
  });

  ////////////////////////
  return (
    <div>
      <span>Invite Agents</span>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        onClick={(event) => {
          event.stopPropagation();
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                width: "92px",
                height: "21px",
                fontWeight: "500",
                fontFamily: "poppins",
                fontSize: "14px",
                lineHeight: "21px",
                color: "#272525",
                marginTop: "15px",
                marginLeft: "20px",
              }}
            >
              Invite Agents
            </Box>
            <span onClick={handleClose} style={{ height: "36px" }}>
              <CloseIcon
                style={{
                  // height: '10.8px',
                  // width: '10.8px',
                  height: "18.8px",
                  width: "18.8px",
                  color: "#B9B9B9",
                  marginTop: "13.61px",
                  marginRight: "13.6px",
                  cursor: "pointer",
                }}
              />
            </span>
          </Box>
          <Divider
            sx={{ width: "600px", marginTop: "10px", color: "#E8E8E8" }}
          />

          {/* {showError && (
            <Typography
              variant="body2"
              style={{
                width: "100%",
                fontFamily: "poppins",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "18px",
                color: "red",
                margin: "0px 20px 0px 20px",
              }}
            >
              {errorMsg}
            </Typography>
          )} */}

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {" "}
            <Typography
              sx={{
                height: "18px",
                fontFamily: "poppins",
                // fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "18px",
                color: "#272525",
                // margin: '10px 20px 0px 20px',
                margin: showError ? "-8px 20px 0px 20px" : "10px 20px 0px 20px",
              }}
              variant="body1"
            >
              Email
            </Typography>
            {emailRequired && (
              <Typography
                sx={{
                  height: "18px",
                  fontFamily: "poppins",
                  // fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "red",
                  margin: showError
                    ? "-8px 20px 0px 20px"
                    : "10px 20px 0px 20px",
                }}
                variant="body1"
              >
                At least one email address is required
              </Typography>
            )}
          </Box>

          {/* <form onSubmit={handleSubmit}> */}
          <Box component="form" onSubmit={formik.handleSubmit}>
            <Box sx={{ padding: "2px 20px 0px 20px" }}>
              <div style={{ marginBottom: "0.5rem" }}>
                <OutlinedInput
                  className={classes.root}
                  style={fieldStyle}
                  // value={name1}
                  // onChange={(event) => setName1(event.target.value)}
                  //
                  name="email1"
                  value={formik.values.email1}
                  onChange={formik.handleChange}
                  onError={
                    formik.touched.email1 && Boolean(formik.errors.email1)
                  }
                  //
                  fullWidth
                  placeholder="Enter email address to invite"
                  //
                  inputProps={{
                    disableUnderline: true,
                    style: {
                      width: "100%",
                      height: "38px",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "400",
                      fontFamily: "poppins",
                      //
                      padding: "0",
                      paddingLeft: "10px",
                    },
                  }}
                />

                <Select
                  className={classes.selectInput}
                  IconComponent={FilledArrowIcon}
                  sx={{
                    "& legend": {
                      width: 0,
                    },
                  }}
                  // value={roleLink}
                  // onChange={(event) => setRoleLink(event.target.value)}
                  //
                  name="role1"
                  value={formik.values.role1}
                  onChange={formik.handleChange}
                  onError={formik.touched.role1 && Boolean(formik.errors.role1)}
                  //
                  fullWidth
                  displayEmpty
                  inputProps={{
                    disableUnderline: true,
                    style: { borderBottom: "none" },
                  }} //
                  disableUnderline
                  //
                >
                  <MenuItem
                    value=""
                    disabled
                    className={classes.selectMenuItem}
                    style={{
                      display: "none",
                    }}
                  >
                    {/* select anyone... */}
                    <span style={{ position: "relative", left: "-8px" }}>
                      select anyone...
                    </span>
                  </MenuItem>
                  <MenuItem
                    value="Super Admin"
                    className={classes.selectMenuItem}
                  >
                    Super Admin
                  </MenuItem>
                  <MenuItem value="Manager" className={classes.selectMenuItem}>
                    Manager
                  </MenuItem>
                  <MenuItem value="Agent" className={classes.selectMenuItem}>
                    Agent
                  </MenuItem>
                </Select>
              </div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "-0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                {formik.touched.email1 && formik.errors.email1 && (
                  <FormHelperText className={classes.errorStyle} error>
                    {formik.errors.email1}
                  </FormHelperText>
                )}
                {formik.touched.role1 && formik.errors.role1 && (
                  <FormHelperText className={classes.errorStyle} error>
                    {formik.errors.role1}
                  </FormHelperText>
                )}
              </Box>

              {/*  */}
              <div style={{ marginBottom: "0.5rem" }}>
                <OutlinedInput
                  className={classes.root}
                  style={fieldStyle}
                  // value={name2}
                  // onChange={(event) => setName2(event.target.value)}
                  //
                  name="email2"
                  value={formik.values.email2}
                  onChange={formik.handleChange}
                  onError={
                    formik.touched.email2 && Boolean(formik.errors.email2)
                  }
                  //
                  fullWidth
                  placeholder="Enter email address to invite"
                  inputProps={{
                    disableUnderline: true,
                    style: {
                      width: "100%",
                      height: "38px",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "400",
                      fontFamily: "poppins",
                      //
                      padding: "0",
                      paddingLeft: "10px",
                    },
                  }}
                />
                <Select
                  className={classes.selectInput}
                  IconComponent={FilledArrowIcon}
                  sx={{
                    "& legend": {
                      width: 0,
                    },
                  }}
                  // value={roleLink}
                  // onChange={(event) => setRoleLink(event.target.value)}
                  //
                  name="role2"
                  value={formik.values.role2}
                  onChange={formik.handleChange}
                  onError={formik.touched.role2 && Boolean(formik.errors.role2)}
                  //
                  fullWidth
                  displayEmpty
                  inputProps={{
                    disableUnderline: true,
                    style: { borderBottom: "none" },
                  }} //
                  disableUnderline
                  //
                >
                  <MenuItem
                    value=""
                    disabled
                    className={classes.selectMenuItem}
                    style={{
                      display: "none",
                    }}
                  >
                    {/* select anyone... */}
                    <span style={{ position: "relative", left: "-8px" }}>
                      select anyone...
                    </span>
                  </MenuItem>
                  <MenuItem
                    value="Super Admin"
                    className={classes.selectMenuItem}
                  >
                    Super Admin
                  </MenuItem>
                  <MenuItem value="Manager" className={classes.selectMenuItem}>
                    Manager
                  </MenuItem>
                  <MenuItem value="Agent" className={classes.selectMenuItem}>
                    Agent
                  </MenuItem>
                </Select>
              </div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "-0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                {formik.touched.email2 && formik.errors.email2 && (
                  <FormHelperText className={classes.errorStyle} error>
                    {formik.errors.email2}
                  </FormHelperText>
                )}
                {formik.touched.role2 && formik.errors.role2 && (
                  <FormHelperText className={classes.errorStyle} error>
                    {formik.errors.role2}
                  </FormHelperText>
                )}
              </Box>
              {/*  */}
              <div style={{ marginBottom: "5px" }}>
                <OutlinedInput
                  className={classes.root}
                  style={fieldStyle}
                  // value={name3}
                  // onChange={(event) => setName3(event.target.value)}
                  //
                  name="email3"
                  value={formik.values.email3}
                  onChange={formik.handleChange}
                  onError={
                    formik.touched.email3 && Boolean(formik.errors.email3)
                  }
                  //
                  fullWidth
                  placeholder="Enter email address to invite"
                  inputProps={{
                    disableUnderline: true,
                    style: {
                      width: "100%",
                      height: "38px",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "400",
                      fontFamily: "poppins",
                      //
                      padding: "0",
                      paddingLeft: "10px",
                    },
                  }}
                />
                <Select
                  className={classes.selectInput}
                  IconComponent={FilledArrowIcon}
                  sx={{
                    "& legend": {
                      width: 0,
                    },
                  }}
                  // value={roleLink}
                  // onChange={(event) => setRoleLink(event.target.value)}
                  //
                  name="role3"
                  value={formik.values.role3}
                  onChange={formik.handleChange}
                  onError={formik.touched.role3 && Boolean(formik.errors.role3)}
                  //
                  fullWidth
                  displayEmpty
                  inputProps={{
                    disableUnderline: true,
                    style: { borderBottom: "none" },
                  }} //
                  disableUnderline
                  //
                >
                  <MenuItem
                    value=""
                    disabled
                    className={classes.selectMenuItem}
                    style={{
                      display: "none",
                    }}
                  >
                    {/* select anyone... */}
                    <span style={{ position: "relative", left: "-8px" }}>
                      select anyone...
                    </span>
                  </MenuItem>
                  <MenuItem
                    value="Super Admin"
                    className={classes.selectMenuItem}
                  >
                    Super Admin
                  </MenuItem>
                  <MenuItem value="Manager" className={classes.selectMenuItem}>
                    Manager
                  </MenuItem>
                  <MenuItem value="Agent" className={classes.selectMenuItem}>
                    Agent
                  </MenuItem>
                </Select>
              </div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "-0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                {formik.touched.email3 && formik.errors.email3 && (
                  <FormHelperText className={classes.errorStyle} error>
                    {formik.errors.email3}
                  </FormHelperText>
                )}
                {formik.touched.role3 && formik.errors.role3 && (
                  <FormHelperText className={classes.errorStyle} error>
                    {formik.errors.role3}
                  </FormHelperText>
                )}
              </Box>
              <Typography
                sx={{
                  // width: "120px",
                  width: "100%",
                  height: "18px",
                  fontFamily: "poppins",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#272525",
                  //
                  marginTop: "15px",
                  marginBottom: "10px",
                }}
                variant="body1"
              >
                Shareable invite link
              </Typography>

              <div style={{ display: "flex" }}>
                <SingleInput
                  inputId="invite-link"
                  isLabel={false}
                  disabled={true}
                  value={`${config.SHAREABLEINVITELINK}${
                    roleLink === "Super Admin"
                      ? 4
                      : roleLink === "Manager"
                      ? 3
                      : 14
                  }/${mainSuperAdminId}/asdk343ui3lkdjf34934jdklfje94i`}
                />

                <Select
                  className={classes.selectInput}
                  IconComponent={FilledArrowIcon}
                  sx={{
                    "& legend": {
                      width: 0,
                    },
                  }}
                  value={roleLink}
                  onChange={(event) => setRoleLink(event.target.value)}
                  fullWidth
                  displayEmpty
                  inputProps={{
                    disableUnderline: true,
                    style: { borderBottom: "none" },
                  }} //
                  disableUnderline
                  //
                >
                  <MenuItem
                    value=""
                    disabled
                    className={classes.selectMenuItem}
                    style={{
                      display: "none",
                    }}
                  >
                    {/* select anyone... */}
                    <span style={{ position: "relative", left: "-8px" }}>
                      select anyone...
                    </span>
                  </MenuItem>
                  <MenuItem
                    value="Super Admin"
                    className={classes.selectMenuItem}
                  >
                    Super Admin
                  </MenuItem>
                  <MenuItem value="Manager" className={classes.selectMenuItem}>
                    Manager
                  </MenuItem>
                  <MenuItem value="Agent" className={classes.selectMenuItem}>
                    Agent
                  </MenuItem>
                </Select>

                {/* showCopied */}
                {!showCopied ? (
                  <Button
                    onClick={() => {
                      //
                      const inviteLink = document.getElementById(
                        "invite-link"
                      ) as HTMLInputElement;
                      if (roleLink !== "") {
                        if (inviteLink) {
                          window.navigator.clipboard.writeText(
                            inviteLink.value
                          );
                          setShowCopied(true);
                        }
                      } else {
                        setShowChooseRole(true);
                      }
                    }}
                    sx={{
                      width: "75px",
                      // height: "30px",
                      height: "38px",
                      backgroundColor: "#F4F4F4",
                      borderLeft: "1px solid #E8E8E8",
                      borderRadius: "0px 3px 3px 0px",
                      color: "#777777",
                      fontFamily: "poppins",
                      fontSize: "10px",
                      lineHeight: "15px",
                      fontWeight: "400",
                      marginLeft: "10px",
                    }}
                  >
                    Copy Link
                  </Button>
                ) : (
                  <Button
                    sx={{
                      width: "75px",
                      // height: "30px",
                      height: "38px",
                      backgroundColor: "#22AA61",
                      borderLeft: "1px solid #E8E8E8",
                      borderRadius: "0px 3px 3px 0px",
                      fontFamily: "poppins",
                      fontWeight: "500",
                      fontSize: "10px",
                      color: "#FFFFFF",
                      lineHeight: "15px",
                      marginLeft: "10px",
                      //
                      "&:hover": {
                        backgroundColor: "#22AA61",
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    Copied!
                  </Button>
                )}
              </div>

              {showChooseRole && (
                <Typography
                  className={classes.errorStyle}
                  sx={{
                    color: "red",
                    marginTop: "5px",
                  }}
                  variant="body1"
                >
                  Select role before sharing link
                </Typography>
              )}

              <Typography
                sx={{
                  // width: "420px",
                  width: "100%",
                  height: "15px",

                  fontFamily: "poppins",
                  fontWeight: "400",
                  // fontSize: "10px",
                  // lineHeight: "15px",
                  //
                  fontSize: "12px",
                  lineHeight: "17px",
                  //
                  color: "#838383",
                  marginTop: "10px",
                  // paddingTop: '7.5px',
                  // paddingBottom: '7.5px',
                }}
                variant="body1"
              >
                For security reason, this link will expire in 6 days (
                {futureTime}).{" "}
                <span
                  style={{
                    color: "#4D1277",
                    cursor: "pointer",
                    fontWeight: "500",
                    fontFamily: "poppins",
                    // fontSize: "10px",
                    // lineHeight: "15px",
                    fontSize: "12px",
                    lineHeight: "17px",
                  }}
                >
                  Generate new link
                </span>
              </Typography>
            </Box>
            <Divider
              sx={{
                width: "600px",
                marginTop: "23px",
                marginBottom: "10px",
                color: "#E8E8E8",
              }}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "10px",
                marginBottom: "10px",
              }}
            >
              <Button
                onClick={handleClose}
                variant="contained"
                // color="primary"
                type="submit"
                sx={{
                  width: "73px",
                  height: "28px",
                  background: "#F5F5F5",
                  borderRadius: "3px",
                  //
                  fontFamily: "poppins",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#777777",
                  marginRight: "5px",
                  "&:hover": {
                    color: "#777777",
                    backgroundColor: "#F5F5F5",
                  },
                  //
                  textTransform: "capitalize",
                  boxShadow: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                // color="primary"
                type="submit"
                sx={{
                  width: "63px",
                  height: "28px",
                  backgroundColor: "#4D1277",
                  borderRadius: "3px",
                  fontFamily: "poppins",
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "18px",
                  color: "#FFFFFF",
                  //
                  textTransform: "capitalize",
                  boxShadow: "none",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  Invite{" "}
                  {inviteAgentsQueryLoading ? (
                    <CircularProgress
                      style={{
                        marginLeft: "3px",
                        width: "15px",
                        height: "15px",
                        color: "white",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Button>
            </div>
            {/* </form> */}
          </Box>
          {/* </Box> */}
        </Box>
      </Modal>
    </div>
  );
};

export default KeepMountedModal;
