import TextareaAutosize from '@mui/base/TextareaAutosize';
import { Typography, Box, } from '@mui/material';
import useStyles from './Styles'
interface Props {
  placeholder: string
  value: string
  onChange: () => void
  helperText?: string | boolean | undefined
  id: string
  label: string
  name: string
  style?: any
}



const ChatDetailsComponentTextArea: React.FC<Props> = ({ style, placeholder, value, onChange, helperText, id, label, name }) => {
  const { classes } = useStyles();
  return (
    <>
      <TextareaAutosize
        aria-label={label}
        minRows={3}
        className={classes.root}
        id={id}
        name={name}
        placeholder={placeholder}
        // style={{ width: "100%", height: "90%", color: "#838383", fontSize: 14, fontWeight: 400, }}
        style={style}
        value={value}
        onChange={onChange}
      // style={style}
      />
      <Box mb={5} sx={{ height: "10%", }}>
        {helperText ? (<Typography ml={1} sx={{ fontSize: 12, color: "red" }}>{helperText} </Typography>) : null}
      </Box>
    </>
  )
}

export default ChatDetailsComponentTextArea