import { Box, Typography, Button, IconButton, Menu, MenuItem, Container } from '@mui/material'
import React, { useState, useRef, useEffect } from "react";
import useStyles from './Styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ChatIcon from '@mui/icons-material/Chat';
import AddIcon from '@mui/icons-material/Add';
import MoreVert from '@mui/icons-material/MoreVert';
// import SearchBox from '../../../../shared/atoms/searchBox/SearchBox';
import CreateSavedReply from '../createSavedReply/CreateSavedReply';
import { ContentSmall } from '../../../../shared/atoms/text/Text';
import AreYouSureModal from '../../../../shared/molecules/AreYouSureModal/AreYouSureModal';
import LeftChatComponentSearch, { ComponentSearch } from '../../leftChatComponent/leftchatComponentSearch/LeftChatComponentSearch';
// import ChatMainCardHeaderSelect from '../../chatMainCard/ChatMainCardHeaderSelect';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import _ from "lodash";
import { useSnackbar } from "notistack";

import { useResponseQuery, useUpdateresponseQuery } from './SavedRepliesComponentQueries'

import { FormValuesProps, SavedReplyType, SavedRepliesComponentType } from './SavedRepliesComponentTypes'

function generateId() {
    return Math.random().toString(36).substring(2, 11);
}

const SavedRepliesComponent: React.FC<SavedRepliesComponentType> = ({ setSearchText,
    searchText,
    setInputTextField,
    setcloseSavedRepliesWindow, }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { classes } = useStyles()
    const [savedReplies, setSavedReplies] = useState<any>([]);
    const [dataArray, setDataArray] = useState(savedReplies);
    const [selectValue, setSelectValue] = useState<string>("Frequently Used")
    const [createReply, setCreateReply] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorE2, setAnchorE2] = useState<null | HTMLElement>(null);
    const [FormValues, setFormValues] = useState<any>({
        id: null,
        shortcut: "",
        messagebox: "",
    });

    const [searchData, setSearchData] = useState<SavedReplyType>({
        id: null,
        short: "",
        long: "",
        count: 0,
    });
    const [searchArray, setSearchArray] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleanchorE2Open = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorE2(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setAnchorE2(null);
        setCreateReply(false)
    };

    const handleListItemMenuClose = () => {
        setAnchorEl(null);

    };



    const editShortCut = (id: string | null) => {
        let dataArray = savedReplies.find((curr: SavedReplyType) => curr.id == id);
        setFormValues(() => {
            return {
                id: id,
                shortcut: dataArray.short,
                messagebox: dataArray.long,
            };
        });
        setCreateReply(true);
    };



    let [
        updateresponse,
        {
            loading: updateresponseQueryLoading,
            error: updateresponseQueryError,
            data: updateresponseQueryResult,
        },
    ] = useUpdateresponseQuery()


    useEffect(() => {
        if (updateresponseQueryError) {
            updateresponseQueryError.graphQLErrors.map(({ message }, i) => {
                enqueueSnackbar(message, { variant: "error" });
            });
        }
    }, [updateresponseQueryError]);


    useEffect(() => {
        if (updateresponseQueryResult && updateresponseQueryResult.updateresponse) {
            if (updateresponseQueryResult.updateresponse.success) {
            } else {
                enqueueSnackbar(updateresponseQueryResult.updateresponse.error, {
                    variant: "error",
                });
            }
        }
    }, [updateresponseQueryResult]);

    let [
        getResponse,
        {
            loading: ResponseQueryLoading,
            error: ResponseQueryError,
            data: ResponseQueryResult,
        },
    ] = useResponseQuery()


    useEffect(() => {
        if (savedReplies) {
            setDataArray(savedReplies);
        }
    }, [savedReplies]);


    useEffect(() => {
        if (ResponseQueryError) {
            ResponseQueryError.graphQLErrors.map(({ message }, i) => {
                return enqueueSnackbar(message, { variant: "error" });
            });
        }
    }, [ResponseQueryError, enqueueSnackbar]);

    useEffect(() => {
        if (ResponseQueryResult && ResponseQueryResult.getResponse) {
            var listData_: any[] = [];

            var decodeResult = JSON.parse(ResponseQueryResult.getResponse.responses);

            decodeResult &&
                // eslint-disable-next-line array-callback-return
                decodeResult.map((item: any) => {
                    listData_.push(item);
                });
            let finalArray: any = _.orderBy(listData_, "count", "desc");
            setSavedReplies(finalArray);


        }
    }, [ResponseQueryResult]);

    useEffect(() => {
        getResponse();
    }, [getResponse]);

    // save saved reply function
    const handleSave = (values: any) => {
        if (values.id != null) {
            let editRecord = savedReplies.find((curr: { id: any; }) => curr.id == values.id);
            editRecord.short = values.shortcut;
            editRecord.long = values.messagebox;
            let RemainingArray =
                savedReplies.length &&
                savedReplies.filter((curr: { id: any; }) => curr.id != values.id);
            let finalArray = [editRecord, ...RemainingArray];
            finalArray = _.orderBy(finalArray, "id", "asc");
            updateresponse({
                variables: {
                    response: JSON.stringify(finalArray),
                },
            });
        } else {
            setSavedReplies([
                ...savedReplies,
                {
                    id: generateId(),
                    short: values.shortcut,
                    long: values.messagebox,
                    count: 0,
                },
            ]);
            updateresponse({
                variables: {
                    response: JSON.stringify([
                        ...savedReplies,
                        {
                            id: generateId(),
                            short: values.shortcut,
                            long: values.messagebox,
                            count: 0,
                        },
                    ]),
                },
            });
        }
        setCreateReply(!createReply)

    };


    // delete saved reply funciton
    const deleteSavedReply = (id: string) => {
        let updatedArray = [];

        updatedArray =
            savedReplies &&
            savedReplies.length &&
            savedReplies.filter((curr: SavedReplyType) => curr.id !== id);

        setSavedReplies(updatedArray);
        updateresponse({
            variables: {
                response: JSON.stringify(updatedArray),
            },
        });

        handleListItemMenuClose()
    };

    // select change function
    const handleChange = (event: SelectChangeEvent) => {

        setSelectValue(event.target.value);
        ListSortedHandler(event)

    };
    const ListSortedHandler = (e: SelectChangeEvent<unknown>) => {
        let value = e.target.value;
        if (value == "Recently used") {
            let finalArray = _.orderBy(dataArray, "id", "asc");
            setDataArray(finalArray);
            // setSortValue("Recently used");
        } else if (value == "Frequently used") {
            let finalArray = _.orderBy(dataArray, "count", "desc");
            setDataArray(finalArray);
            // setSortValue("Frequently used");
        }

    };

    const sendMessageDataToParentComponent = (data: { long: string; }) => {
        let cloneOfDataArray = dataArray;
        // let arrayWithoutMatchRecord = cloneOfDataArray.filter((curr)=>curr != data);
        //  arrayWithoutMatchRecord.unshift(data);
        let index = cloneOfDataArray.indexOf(data);

        for (let i = 0; i < cloneOfDataArray.length; i++) {
            if (i == index) {
                cloneOfDataArray[i].id = 0;
                cloneOfDataArray[i].count = cloneOfDataArray[i].count + 1;
            } else {
                cloneOfDataArray[i].id = cloneOfDataArray[i].id + 1;
            }
            // arrayWithoutMatchRecord[i].id = arrayWithoutMatchRecord[i].id + 1;
        }

        updateresponse({
            variables: {
                response: JSON.stringify(cloneOfDataArray),
            },
        });

        setInputTextField(data.long);
        setcloseSavedRepliesWindow(false);
        setSearchText("");

        setAnchorEl(null);
        setAnchorE2(null);
        setCreateReply(false)
    };
    const handleAdd = () => {
        setCreateReply(true)
        setFormValues({
            id: null,
            shortcut: "",
            messagebox: "",
        })
        handleListItemMenuClose()
    }

    //search
    useEffect(() => {
        if (searchText.length) {
            let value = searchText;
            let searchedArray = [];
            let SearchArray: string[] = [];
            let FormatArray: any[] = [];
            if (value.length) {
                savedReplies.length &&
                    savedReplies.map((curr: { short: string; }) =>
                        SearchArray.push(`${curr.short.toLowerCase()}`)
                    );

                if (SearchArray.length) {
                    searchedArray = SearchArray.filter((curr) =>
                        curr.startsWith(value.toLowerCase())
                    );
                    searchedArray.length &&
                        searchedArray.map((currs) =>
                            FormatArray.push({
                                id:
                                    savedReplies &&
                                    savedReplies.find((curr: { short: string; }) => curr.short.toLowerCase() == currs)
                                        .id,
                                short: currs,
                                long:
                                    savedReplies &&
                                    savedReplies.find((curr: { short: string; }) => curr.short.toLowerCase() == currs)
                                        .long,
                            })
                        );

                    // setSearchArray(FormatArray);
                    if (FormatArray.length == 1) {
                        setSearchData(FormatArray[0]);
                    } else {
                        setSearchData({
                            id: null,
                            short: "",
                            long: "",
                            count: 0,
                        });
                    }
                } else {
                    setSearchData({
                        id: null,
                        short: "",
                        long: "",
                        count: 0,
                    });
                }
            } else {
                setSearchData({
                    id: null,
                    short: "",
                    long: "",
                    count: 0,
                });
            }
        }
    }, [searchText]);

    const SearchReplies = (e: { target: { value: any; }; }) => {
        setSearchValue(e.target.value);
        let value = e.target.value;
        let searchedArray = [];
        let SearchArray: string[] = [];
        let FormatArray: { id: any; short: any; long: any; }[] = [];
        if (value.length) {
            savedReplies.length &&
                savedReplies.map((curr: { short: string; }) =>
                    SearchArray.push(`${curr.short.toLowerCase()}`)
                );
            if (SearchArray.length) {
                searchedArray = SearchArray.filter((curr) =>
                    curr.includes(value.toLowerCase())
                );
                searchedArray.length &&
                    searchedArray.map((currs) =>
                        FormatArray.push({
                            id:
                                savedReplies &&
                                savedReplies.find((curr: { short: string; }) => curr.short.toLowerCase() == currs)
                                    .id,
                            short: currs,
                            long:
                                savedReplies &&
                                savedReplies.find((curr: { short: string; }) => curr.short.toLowerCase() == currs)
                                    .long,
                        })
                    );

                // setSearchArray(FormatArray);
                setDataArray(FormatArray);
            } else {
                setSearchArray([]);
                setDataArray([]);
                return;
            }
        } else {
            setDataArray(savedReplies);
        }
    };

    return (
        <>
            {/* <Box> */}
            <IconButton onClick={handleanchorE2Open} className={classes.chatIcon}  >
                <ChatIcon color="info" />
            </IconButton>

            <Menu anchorEl={anchorE2}
                open={Boolean(anchorE2)}
                onClose={handleMenuClose}
                // sx={{ width: 400, height: 400, }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <Box className={classes.root}>
                    {!createReply ? (
                        <>
                            <Box className={classes.container}>

                                <Box className={classes.header}>
                                    <Typography sx={{ fontSize: 16, fontWeight: 500 }}>Saved Replies</Typography>
                                    {/* <Button onClick={() => setCreateReply(true)} variant='text'><AddIcon /> <Typography sx={{ fontSize: "12px" }}>Add New</Typography> </Button> */}
                                    <Button
                                        style={{ height: 30, fontWeight: 500, fontFamily: 'poppins', color: "#fff", backgroundColor: "#4D1277", }}
                                        onClick={handleAdd}

                                    ><AddIcon />
                                        <Typography sx={{ fontSize: "12px" }}>Add New</Typography>
                                    </Button>
                                </Box>

                                <Box className={classes.header}>
                                    <Box sx={{ width: '210px', height: '25px', }}>
                                        {/* <SearchBox /> */}
                                        <ComponentSearch type="text" value={searchValue} onChange={(e: { target: { value: any; }; }) => SearchReplies(e)} />
                                        {/* <LeftChatComponentSearch type="text" value={searchValue} onChange={(e: { target: { value: any; }; }) => SearchReplies(e)} /> */}
                                    </Box>
                                    <Box sx={{ marginRight: 0, marginTop: 1, }} mr={-1}>
                                        <FormControl size="small">
                                            <Select
                                                sx={{
                                                    "& legend": {
                                                        width: 0,
                                                    }, height: "33px", width: "140px",
                                                }}

                                                value={selectValue}
                                                onChange={handleChange}
                                                // className={classes.select}
                                                renderValue={(value) => {
                                                    return (
                                                        <Box sx={{ display: "flex", gap: 1 }}>

                                                            <Typography sx={{ fontWeight: 400, fontSize: "12px", marginTop: 0.25 }}> {value}</Typography>
                                                        </Box>
                                                    );
                                                }}

                                            >
                                                <MenuItem value={"Frequently Used"} sx={{
                                                    width: '98%',
                                                    margin: '0 auto',
                                                    height: '25px',
                                                    borderRadius: '3px',
                                                    backgroundColor: '#FFFFFF',
                                                    color: '#272525',
                                                    fontFamily: 'poppins',
                                                    fontSize: 12,
                                                    fontWeight: '400', display: "flex",
                                                }}>

                                                    {"Frequently Used"}</MenuItem>

                                                <MenuItem value={"Recently Used"} sx={{
                                                    width: '98%',
                                                    margin: '0 auto',
                                                    height: '25px',
                                                    borderRadius: '3px',
                                                    backgroundColor: '#FFFFFF',
                                                    color: '#272525',
                                                    fontFamily: 'poppins',
                                                    fontSize: 12,
                                                    fontWeight: '400', display: "flex",
                                                }}>

                                                    {"Recently Used"}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Box>
                            <Box mt={1}>
                                <Container>
                                    <List sx={{ width: '100%' }}>
                                        {dataArray ? (dataArray.map((data: SavedReplyType, index: React.Key | null | undefined) => {

                                            if (data.long !== "" && data.short !== "") {
                                                return (

                                                    <ListItem key={index} className={classes.ListItem}>
                                                        < ListItemText className={classes.ListItemText} onClick={() => sendMessageDataToParentComponent(data)} primary={<Typography sx={{ fontSize: "12px", fontWeight: 500 }}>{data.short}</Typography>}
                                                            secondary={<Typography sx={{ fontSize: "10px", fontWeight: 400 }}>{data.long}</Typography>} />
                                                        <IconButton onClick={handleMenuOpen}>
                                                            <MoreVert />
                                                        </IconButton>
                                                        <Menu
                                                            anchorEl={anchorEl}
                                                            open={Boolean(anchorEl)}
                                                            onClose={handleListItemMenuClose}
                                                            sx={{ width: 96 }}
                                                        >
                                                            <MenuItem onClick={() => { editShortCut(data.id) }} ><ContentSmall text='Edit' /></MenuItem>
                                                            <AreYouSureModal onClick={deleteSavedReply} a={data.id} />
                                                        </Menu>
                                                    </ListItem >
                                                )
                                            }
                                            // eslint-disable-next-line array-callback-return
                                            return;
                                        })
                                        ) : (
                                            <Typography>No Saved Replies</Typography>
                                        )}
                                    </List></Container>
                            </Box>
                        </>

                    ) : (
                        <CreateSavedReply setFormValues={setFormValues} handleListItemMenuClose={handleListItemMenuClose} handleSave={handleSave} FormValues={FormValues} setCreateReply={setCreateReply} />
                    )}
                </Box>
            </Menu>

        </>
    )
}

export default SavedRepliesComponent


