export const LOGIN_USERNAME_TEXT = "LOGIN_USERNAME_TEXT";
export const LOGIN_PASSWORD_TEXT = "LOGIN_PASSWORD_TEXT";
export const LOGIN_EMAIL_TEXT = "LOGIN_EMAIL_TEXT";
export const LOGIN_REDIRECT_TO_PATH = "LOGIN_REDIRECT_TO_PATH";
export const FORCE_LOGOUT_USER = "FORCE_LOGOUT_USER";
export const LOGIN_IS_LOADING = "LOGIN_IS_LOADING";
export const LOGIN_FORGET_PASSWORD_TOGGLE_FORM_NAME =
  "LOGIN_FORGET_PASSWORD_TOGGLE_FORM_NAME";

export const FORGET_PASSWORD_IS_LOADING = "FORGET_PASSWORD_IS_LOADING";
export const FORGET_PASSWORD_EMAIL_TEXT = "FORGET_PASSWORD_EMAIL_TEXT";
export const FORGET_PASSWORD_REQUESTED = "FORGET_PASSWORD_REQUESTED";
export const RESET_PASSWORD_IS_LOADING = "RESET_PASSWORD_IS_LOADING";
export const RESET_PASSWORD_CHANGE_PASSWORD_TEXT =
  "RESET_PASSWORD_CHANGE_PASSWORD_TEXT";
export const RESET_PASSWORD_CHANGE_CONFIRM_PASSWORD_TEXT =
  "RESET_PASSWORD_CHANGE_CONFIRM_PASSWORD_TEXT";
export const RESET_PASSWORD_CHANGE_SUCCESS = "RESET_PASSWORD_CHANGE_SUCCESS";

export const DIALOG_OPEN = "DIALOG_OPEN";
export const DIALOG_CANCEL_TEXT = "DIALOG_CANCEL_TEXT";
export const DIALOG_OK_TEXT = "DIALOG_OK_TEXT";
export const DIALOG_TITLE = "DIALOG_TITLE";
export const DIALOG_CONTENT = "DIALOG_CONTENT";
export const DIALOG_OK_CLICK = "DIALOG_OK_CLICK";

export const REDIRECT_TO_PATH = "REDIRECT_TO_PATH";

export const ADMIN_PANEL_DRAWER_TOGGLE = "ADMIN_PANEL_DRAWER_TOGGLE";
export const ADMIN_PANEL_CLOSED_DRAWER_TOGGLE =
  "ADMIN_PANEL_CLOSED_DRAWER_TOGGLE";
export const ADMIN_PANEL_ACTIVE_LINK = "ADMIN_PANEL_ACTIVE_LINK";
export const ADMIN_PANEL_NOTIFICATION_COUNT = "ADMIN_PANEL_NOTIFICATION_COUNT";
export const ADMIN_PANEL_CHAT_ONLINE = "ADMIN_PANEL_CHAT_ONLINE";
export const ADMIN_PANEL_FULLSCREEN_TOGGLE = "ADMIN_PANEL_FULLSCREEN_TOGGLE";
export const ADMIN_PANEL_PROFILE_PIC_MENU_ANCHOR_EL =
  "ADMIN_PANEL_PROFILE_PIC_MENU_ANCHOR_EL";
export const ADMIN_PANEL_SETTINGS_MENU_ANCHOR_EL =
  "ADMIN_PANEL_SETTINGS_MENU_ANCHOR_EL";
export const ADMIN_PANEL_CHAT_BOX_DRAWER_TOGGLE =
  "ADMIN_PANEL_CHAT_BOX_DRAWER_TOGGLE";

export const ACCOUNT_USERINFO_SETTINGS_MODAL_TOGGLE =
  "ACCOUNT_USERINFO_SETTINGS_MODAL_TOGGLE";
export const ACCOUNT_USERINFO_SETTINGS_MODAL_ID =
  "ACCOUNT_USERINFO_SETTINGS_ID";
export const ACCOUNT_USERINFO_SETTINGS_MODAL_NAME =
  "ACCOUNT_USERINFO_SETTINGS_NAME";
export const ACCOUNT_USERINFO_SETTINGS_MODAL_PSEUDONYM =
  "ACCOUNT_USERINFO_SETTINGS_PSEUDONYM";
export const ACCOUNT_USERINFO_SETTINGS_MODAL_PICTURE =
  "ACCOUNT_USERINFO_SETTINGS_PICTURE";
export const ACCOUNT_USERINFO_SETTINGS_MODAL_EMAIL =
  "ACCOUNT_USERINFO_SETTINGS_EMAIL";
export const ACCOUNT_USERINFO_SETTINGS_MODAL_USERNAME =
  "ACCOUNT_USERINFO_SETTINGS_USERNAME";
export const ACCOUNT_USERINFO_SETTINGS_MODAL_NUMBER =
  "ACCOUNT_USERINFO_SETTINGS_NUMBER";
export const ACCOUNT_USERINFO_SETTINGS_MODAL_CURRENT_PASSWORD =
  "ACCOUNT_USERINFO_SETTINGS_CURRENT_PASSWORD";
export const ACCOUNT_USERINFO_SETTINGS_MODAL_NEW_PASSWORD =
  "ACCOUNT_USERINFO_SETTINGS_NEW_PASSWORD";
export const ACCOUNT_USERINFO_SETTINGS_MODAL_RESET =
  "ACCOUNT_USERINFO_SETTINGS_RESET";

export const ADD_EDIT_USER_MODAL_TOGGLE = "ADD_EDIT_USER_MODAL_TOGGLE";
export const ADD_EDIT_USER_MODAL_SELECTED_ROW_DATA =
  "ADD_EDIT_USER_MODAL_SELECTED_ROW_DATA";
export const ADD_EDIT_USER_MODAL_PICTURE = "ADD_EDIT_USER_MODAL_PICTURE";
export const ADD_EDIT_USER_MODAL_NAME = "ADD_EDIT_USER_MODAL_NAME";
export const ADD_EDIT_USER_MODAL_PSEUDONYM = "ADD_EDIT_USER_MODAL_PSEUDONYM";
export const ADD_EDIT_USER_MODAL_USERNAME = "ADD_EDIT_USER_MODAL_USERNAME";
export const ADD_EDIT_USER_MODAL_PASSWORD = "ADD_EDIT_USER_MODAL_PASSWORD";
export const ADD_EDIT_USER_MODAL_EMAIL = "ADD_EDIT_USER_MODAL_EMAIL";
export const ADD_EDIT_USER_MODAL_NUMBER = "ADD_EDIT_USER_MODAL_NUMBER";
export const ADD_EDIT_USER_MODAL_DESIGNATION =
  "ADD_EDIT_USER_MODAL_DESIGNATION";
export const ADD_EDIT_USER_MODAL_MANAGER = "ADD_EDIT_USER_MODAL_MANAGER";
export const ADD_EDIT_USER_MODAL_STATUS = "ADD_EDIT_USER_MODAL_STATUS";
export const ADD_EDIT_USER_MODAL_COMMENTS = "ADD_EDIT_USER_MODAL_COMMENTS";
export const ADD_EDIT_USER_MODAL_SETTINGS = "ADD_EDIT_USER_MODAL_SETTINGS";
export const ADD_EDIT_USER_MODAL_RESET = "ADD_EDIT_USER_MODAL_RESET";
export const ADD_EDIT_USER_MODAL_MANAGER_FIELD_TOGGLE =
  "ADD_EDIT_USER_MODAL_MANAGER_FIELD_TOGGLE";
export const ADD_EDIT_USER_MODAL_FACEBOOK_PAGES_FIELD_TOGGLE =
  "ADD_EDIT_USER_MODAL_FACEBOOK_PAGES_FIELD_TOGGLE";
export const ADD_EDIT_USER_MODAL_AGENT_LIMIT_CHATS_ASSIGN_FIELD_TOGGLE =
  "ADD_EDIT_USER_MODAL_AGENT_LIMIT_CHATS_ASSIGN_FIELD_TOGGLE";
export const ADD_EDIT_USER_MODAL_AGENT_LIMIT_CHATS_ASSIGN =
  "ADD_EDIT_USER_MODAL_AGENT_LIMIT_CHATS_ASSIGN";
export const ADD_EDIT_USER_MODAL_FACEBOOK_PAGES =
  "ADD_EDIT_USER_MODAL_FACEBOOK_PAGES";

export const CROP_IMAGE_MODAL_TOGGLE = "CROP_IMAGE_MODAL_TOGGLE";
export const CROP_IMAGE_MODAL_IMAGE = "CROP_IMAGE_MODAL_IMAGE";
export const CROP_IMAGE_MODAL_CROP = "CROP_IMAGE_MODAL_CROP";
export const CROP_IMAGE_MODAL_ZOOM = "CROP_IMAGE_MODAL_ZOOM";
export const CROP_IMAGE_MODAL_ASPECT = "CROP_IMAGE_MODAL_ASPECT";
export const CROP_IMAGE_MODAL_LOADING = "CROP_IMAGE_MODAL_LOADING";
export const CROP_IMAGE_MODAL_CROPPED_AREA_PIXELS =
  "CROP_IMAGE_MODAL_CROPPED_AREA_PIXELS";
export const CROP_IMAGE_MODAL_RESET = "CROP_IMAGE_MODAL_RESET";

export const ADD_EDIT_DESIGNATION_MODAL_TOGGLE =
  "ADD_EDIT_DESIGNATION_MODAL_TOGGLE";
export const ADD_EDIT_DESIGNATION_MODAL_SELECTED_ROW_DATA =
  "ADD_EDIT_DESIGNATION_MODAL_SELECTED_ROW_DATA";
export const ADD_EDIT_DESIGNATION_MODAL_NAME =
  "ADD_EDIT_DESIGNATION_MODAL_NAME";
export const ADD_EDIT_DESIGNATION_MODAL_PANELTYPE =
  "ADD_EDIT_DESIGNATION_MODAL_PANELTYPE";
export const ADD_EDIT_DESIGNATION_MODAL_RESET =
  "ADD_EDIT_DESIGNATION_MODAL_RESET";

export const ADD_EDIT_PROFILE_MODAL_TOGGLE = "ADD_EDIT_PROFILE_MODAL_TOGGLE";
export const ADD_EDIT_PROFILE_MODAL_SELECTED_ROW_DATA =
  "ADD_EDIT_PROFILE_MODAL_SELECTED_ROW_DATA";
export const ADD_EDIT_PROFILE_MODAL_NAME = "ADD_EDIT_PROFILE_MODAL_NAME";
export const ADD_EDIT_PROFILE_MODAL_PANELTYPE =
  "ADD_EDIT_PROFILE_MODAL_PANELTYPE";
export const ADD_EDIT_PROFILE_MODAL_SETTINGS =
  "ADD_EDIT_PROFILE_MODAL_SETTINGS";
export const ADD_EDIT_PROFILE_MODAL_RESET = "ADD_EDIT_PROFILE_MODAL_RESET";

export const ADD_EDIT_PAGES_MODAL_TOGGLE = "ADD_EDIT_PAGES_MODAL_TOGGLE";
export const ADD_EDIT_PAGES_MODAL_PAGES = "ADD_EDIT_PAGES_MODAL_PAGES";
export const ADD_EDIT_PAGES_MODAL_SELECTED_PAGES =
  "ADD_EDIT_PAGES_MODAL_SELECTED_PAGES";
export const ADD_EDIT_PAGES_MODAL_RESET = "ADD_EDIT_PAGES_MODAL_RESET";

export const AUTH_SETTINGS = "AUTH_SETTINGS";
export const AUTH_USERID = "AUTH_USERID";
export const AUTH_USER_NAME = "AUTH_USER_NAME";
export const AUTH_PANELTYPE = "AUTH_PANELTYPE";
export const AUTH_PAGES_DATA = "AUTH_PAGES_DATA";
export const AUTH_USER_PAGES_ASSIGNED = "AUTH_USER_PAGES_ASSIGNED";
export const AUTH_USER_SWITCH_ACCOUNT_SETTINGS =
  "AUTH_USER_SWITCH_ACCOUNT_SETTINGS";
export const AUTH_USER_WS_SUBSCRIPTION_READY =
  "AUTH_USER_WS_SUBSCRIPTION_READY";
export const AUTH_MAIN_APPBAR_HEIGHT = "AUTH_MAIN_APPBAR_HEIGHT";

export const USER_PANEL_NOTIFICATION_COUNT = "USER_PANEL_NOTIFICATION_COUNT";
export const USER_PANEL_CHAT_ONLINE = "USER_PANEL_CHAT_ONLINE";
export const USER_PANEL_CHAT_ONLINE_INPUT = "USER_PANEL_CHAT_ONLINE_INPUT";
export const USER_PANEL_FULLSCREEN_TOGGLE = "USER_PANEL_FULLSCREEN_TOGGLE";
export const USER_PANEL_PROFILE_PIC_MENU_ANCHOR_EL =
  "USER_PANEL_PROFILE_PIC_MENU_ANCHOR_EL";
export const USER_PANEL_SETTINGS_MENU_ANCHOR_EL =
  "USER_PANEL_SETTINGS_MENU_ANCHOR_EL";

export const CHAT_BOX_RECENT_SEARCH_INPUT_TEXT =
  "CHAT_BOX_RECENT_SEARCH_INPUT_TEXT";
export const CHAT_BOX_RECENT_SEARCH_TEXT = "CHAT_BOX_RECENT_SEARCH_TEXT";
export const CHAT_BOX_RECENT_SEARCH_CHAT_IDS =
  "CHAT_BOX_RECENT_SEARCH_CHAT_IDS";
export const CHAT_BOX_RECENT_CHAT_LIST_DATA = "CHAT_BOX_RECENT_CHAT_LIST_DATA";
export const SMS_CHAT_LIST_DATA = "SMS_CHAT_LIST_DATA";
export const CHAT_BOX_SELECTED_CHATS_ON_FLOATING =
  "CHAT_BOX_SELECTED_CHAT_ON_FLOATING";
export const CHAT_BOX_MESSAGE_DATA = "CHAT_BOX_MESSAGE_DATA";
export const CHAT_BOX_LABELS_POPOVER_ANCHOR_EL =
  "CHAT_BOX_LABELS_POPOVER_ANCHOR_EL";
export const CHAT_BOX_CUSTOMER_FORM_DATA = "CHAT_BOX_CUSTOMER_FORM_DATA";
export const CHAT_BOX_MASSAGE_TEXT_INPUT = "CHAT_BOX_MASSAGE_TEXT_INPUT";
export const CHAT_BOX_WINDOW_SIZE = "CHAT_BOX_WINDOW_SIZE";
export const CHAT_BOX_RECENT_CHAT_LIST_SEARCH_FILTER_DATA =
  "CHAT_BOX_RECENT_CHAT_LIST_SEARCH_FILTER_DATA";
export const CHAT_BOX_FACEBOOK_IDS_WITH_PROFILE_DETAILS =
  "CHAT_BOX_FACEBOOK_IDS_WITH_PROFILE_DETAILS";
export const CHAT_BOX_SUBSCRIPTION_STATUS = "CHAT_BOX_SUBSCRIPTION_STATUS";
export const CHAT_BOX_MESSAGE_TEXTBOX_HEIGHT =
  "CHAT_BOX_MESSAGE_TEXTBOX_HEIGHT";
export const CHAT_BOX_SEARCH_TEXT = "CHAT_BOX_SEARCH_TEXT";
export const CHAT_BOX_CONTAINER_CHAT_SEARCH_TOGGLE =
  "CHAT_BOX_CONTAINER_CHAT_SEARCH_TOGGLE";
export const CHAT_BOX_CONTAINER_CHAT_SEARCH_COUNT =
  "CHAT_BOX_CONTAINER_CHAT_SEARCH_COUNT";
export const CHAT_BOX_CONTAINER_CHAT_SEARCH_UP_BUTTON_TOGGLE =
  "CHAT_BOX_CONTAINER_CHAT_SEARCH_UP_BUTTON_TOGGLE";
export const CHAT_BOX_CONTAINER_CHAT_SEARCH_DOWN_BUTTON_TOGGLE =
  "CHAT_BOX_CONTAINER_CHAT_SEARCH_DOWN_BUTTON_TOGGLE";
export const CHAT_BOX_PENDING_CHAT_COUNT = "CHAT_BOX_PENDING_CHAT_COUNT";
export const CHAT_BOX_PENDING_CHAT_COUNT_DETAIL_CONTAINER_ANCHER_EL =
  "CHAT_BOX_PENDING_CHAT_COUNT_DETAIL_CONTAINER_ANCHER_EL";
export const CHAT_BOX_PENDING_CHAT_COUNT_DETAILS =
  "CHAT_BOX_PENDING_CHAT_COUNT_DETAILS";
export const CHAT_BOX_MARK_NOT_TO_ADD_IN_CHAT_CIRCLE_FOR_LABEL =
  "CHAT_BOX_MARK_NOT_TO_ADD_IN_CHAT_CIRCLE_FOR_LABEL";
export const CHAT_BOX_TYPING_MESSAGE_DETAILS =
  "CHAT_BOX_TYPING_MESSAGE_DETAILS";
export const CHAT_BOX_RECENT_CHAT_LIST_DATA_TOTAL_COUNT =
  "CHAT_BOX_RECENT_CHAT_LIST_DATA_TOTAL_COUNT";
export const CHAT_BOX_RECENT_CHAT_LIST_SHOW_ALL_LIST_TOGGLE =
  "CHAT_BOX_RECENT_CHAT_LIST_SHOW_ALL_LIST_TOGGLE";
export const CHAT_BOX_RECENT_CHAT_LIST_SHOW_ALL_LIST_BY_MANAGER_TOGGLE =
  "CHAT_BOX_RECENT_CHAT_LIST_SHOW_ALL_LIST_BY_MANAGER_TOGGLE";
export const CHAT_BOX_RECENT_CHAT_LIST_SHOW_ALL_LIST_BY_MANAGER_BUTTON_TOGGLE =
  "CHAT_BOX_RECENT_CHAT_LIST_SHOW_ALL_LIST_BY_MANAGER_BUTTON_TOGGLE";
export const CHAT_BOX_NOTIFICATION_CUSTOMERID = "CHAT_BOX_NOTIFICATION_CUSTOMERID";

export const LABEL_LIST_DATA = "LABEL_LIST_DATA";
export const LABEL_LIST_TEXT_INPUT = "LABEL_LIST_TEXT_INPUT";

export const FOLLOW_UP_DIALOG_TOGGLE = "FOLLOW_UP_DIALOG_TOGGLE";
export const FOLLOW_UP_DIALOG_DATE_TIME = "FOLLOW_UP_DIALOG_DATE_TIME";

export const NOTIFICATION_MENU_ANCHOR_EL = "NOTIFICATION_MENU_ANCHOR_EL";
export const NOTIFICATION_MENU_DATA = "NOTIFICATION_MENU_DATA";
export const NOTIFICATION_MENU_SHOW_ALERT = "NOTIFICATION_MENU_SHOW_ALERT";

export const LOGIN_AS_MODAL_TOGGLE = "LOGIN_AS_MODAL_TOGGLE";

export const USERS_LIST_SEARCH_INPUT_TEXT = "USERS_LIST_SEARCH_INPUT_TEXT";
export const USERS_LIST_SEARCH_TEXT = "USERS_LIST_SEARCH_TEXT";
export const USERS_LIST_DATA = "USERS_LIST_DATA";
export const USERS_LIST_DATAS = "USERS_LIST_DATAS";

export const USERS_LIST_SUBSCRIPTION_DATA = "USERS_LIST_SUBSCRIPTION_DATA";
export const USERS_LIST_SELECTED_USER = "USERS_LIST_SELECTED_USER";
export const USERS_LIST_CONTEXT_MENU_POS_AND_OBJECT_DETAILS =
  "USERS_LIST_CONTEXT_MENU_POS_AND_OBJECT_DETAILS";

export const MANAGERS_LIST_DATA = "MANAGERS_LIST_DATA";
export const MANAGERS_LIST_SELECTED_MANAGER = "MANAGERS_LIST_SELECTED_MANAGER";

export const AGENTS_LIST_DATA = "AGENTS_LIST_DATA";

export const ALL_USERS_DATA = "ALL_USERS_DATA";
export const ALL_DESIGNATION_DATA = "ALL_DESIGNATION_DATA";
export const ALL_PAGES_DATA = "ALL_PAGES_DATA";
export const NOTIFICATION_LIST_DATA = "NOTIFICATION_LIST_DATA";
export const PROPS_OBJECT = "PROPS_OBJECT";


export const SEARCH_LIST_DATA = "SEARCH_LIST_DATA";
