import React, { Fragment, useEffect, useRef, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import {
  Container,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { Typography, Avatar } from "@mui/material";

import { AutoSizer, List } from "react-virtualized";
import {
  setChatBoxRecentChatListData,
  setChatBoxRecentSearchText,
  setChatBoxRecentSearchChatIds,
  setChatBoxRecentSearchInputText,
  setChatBoxRecentChatListDataTotalCount,
  setChatBoxSelectedChatsOnFloating,
  setChatBoxNotificationCustomerId,
} from "../../store/actions/ChatBoxActions";
import { setPropsObjectData } from "../../store/actions/PropsObjectActions";
import {
  setUsersListSelectedUser,
  setUsersListData,
  setUsersListSearchInputText,
  setUsersListSearchText,
} from "../../store/actions/UsersListActions";

import { setAllUsersData } from "../../store/actions/AdmindataActions";
import includes from "./includes";
import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useSnackbar } from "notistack";
import FacebookAvatar from "./FacebookAvatar";
import FacebookTypography from "./FacebookTypography";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import ReactTimeAgo from "react-timeago";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import ChatListTypingMessageStatus from "./ChatListTypingMessageStatus";
import Tabs from "../../otherComponents/react-responsive-tabs";
import "../../otherComponents/react-responsive-tabs/styles.css";

import messengerIcon from "../../assets/chatWindow/messenger.svg";

const useStyles = makeStyles((theme) => ({
  textFieldInputRoot: {
    width: "100%",
    background: "#eeeeee",
    border: "0px solid #d0d0d0",
    paddingLeft: "35px",
  },
  textFieldInput: {
    height: 41,

    padding: "0px 4px",
    borderBottom: 0,
  },
  pageIcon: {
    position: "absolute",
    left: 1,
    background: "#737272",
  },
  userList: {
    marginTop: 5,
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarWidth: "thin",
    scrollbarColor: "#ccc transparent",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  },
  customerIcon: {
    marginLeft: 8,
    marginTop: "10px",
  },
  listItemPri: {
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "14px",
  },
  listItemPrimaryText: {
    fontWeight: 500,
    flex: 1,
    color: "#272525",
    fontSize: 12,
    wordBreak: "break-all",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "break-spaces",
    maxHeight: 24,
  },
  listItemButton: {
    height: 75,
    overflow: "hidden!important",
    borderBottom: "1px solid #DFE2E5",
    paddingRight: 4,
    width: "96%",
    marginLeft: "2%",
    "&:hover": {
      background: "#E6D9EC;",
    },
  },
  loadingCircularProgress: {
    margin: "auto",
    display: "block",
    marginTop: 20,
    fontWeight: "400",
    color: "#4d1277",
  },
  selectedListItem: {
    // borderRadius: "10px",
    background: "#E6D9EC;",
    // border: "1px solid #d7d7d7",
    boxShadow: "0px 2px 1px 1px rgba(0,0,0,0.02)",
  },
  listItemContainer: {
    display: "flex",
    flexDirection: "column",
  },
  timeStamp: {
    marginTop: "-85px",
    textAlign: "right",
    fontSize: 10,
    // color: "#7e7e7e",
    color: "#777777",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontFamily: "Poppins",
  },
  listItemAvatarAndTextContainer: {
    display: "flex",
    marginTop: "-25px",
  },
  listItemSecondaryText: {
    whiteSpace: "nowrap",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 400,
    color: "#777777",
  },
  listItemMarkUnread: {
    background: "red",
  },
  listItemInnerContainer: {
    paddingLeft: 3,
    paddingRight: 12,
    paddingTop: 8,
    paddingBottom: 8,
  },

  listItem: {
    padding: 0,
    height: "80px",
  },
  listItemPrimaryTextMarkUnread: {
    fontWeight: "bold",
  },
  listItemSecondaryTextMarkUnread: {
    fontWeight: "bold",
    color: "#4D1277",
  },
  markNotToAddInChatCircleContainer: {
    width: "55%",
    height: 5,
  },
  markNotToAddInChatCircleSpan: {
    // height: "20px",
    textAlign: "right",
    fontSize: 10,
    // color: "#7e7e7e",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: "red",
    right: 0,
    marginRight: -140,
    // marginLeft: "280px",
    marginTop: "10px",
    fontFamily: "Poppins",
  },
  salesDonewppSpan: {
    width: "calc(100% - 5px)",
    background: "#47c843",
    height: 5,
    display: "block",
  },
  salesDoneppSpan: {
    width: "calc(100% - 5px)",
    background: "#3c9939",
    height: 5,
    display: "block",
  },
  listItemBottomContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  listNoRows: {
    padding: 10,
  },
}));

const ChatList = (props) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [filterData, setfilterData] = useState([]);

  const [showSearchList, setShowSearchList] = useState("");

  const [onResquestFetching] = useState(false);

  const onResquestFetching_ = useRef(null);
  onResquestFetching_.current = onResquestFetching;

  const [hasNextPage] = useState(true);
  const [, setShowCircle] = useState(true);
  const hasNextPage_ = useRef(null);
  hasNextPage_.current = hasNextPage;

  const [showBar, setShowBar] = useState(false);

  const chatBoxRecentChatListFetchNumber = 20;
  const [agentId, setAgentId] = useState();

  let userIdForRecentsChatQuery = new includes().getUserIdForRecentsChatQuery(
    props.authUserId,
    props.authPanelType,
    props.usersListSelectedUser,
    props.chatBoxRecentChatListShowAllListToggle
  );
  if (props.userauthid) {
    userIdForRecentsChatQuery = props.userauthid;
  }

  var managerIdForRecentsChatQuery =
    new includes().getManagerIdForRecentsChatQuery(
      props.authUserId,
      props.authPanelType,
      props.managersListSelectedManager,
      props.chatBoxRecentChatListShowAllListByManagerToggle,
      props.chatBoxRecentChatListShowAllListToggle
    );

  const ChatLastDetailsByIdWithPaginationQuery = gql`
    query ChatLastDetailsByIdWithPagination(
      $userID: ID
      $first: Int
      $after: String
      $managerId: ID
      $cursor: String
    ) {
      chatlastdetailsbyidwithpagination(
        userID: $userID
        first: $first
        after: $after
        managerId: $managerId
        cursor: $cursor
      ) {
        chatDetails {
          id
          customerId
          pageId
          messageId
          messagetext
          messagetimestamp
          customerName
          customerPicture
          messagetype
          agentId
          alternateagentId
          read
          labels
          marknottoaddinchatcircle
          file
          chatorder
        }
        totalCount
        hasNextPage
        managerId
        userID
      }
    }
  `;

  const [
    chatLastDetailsByIdWithPagination,
    {
      data: chatLastDetailsByIdWithPaginationQueryResult,
      loading: chatLastDetailsByIdWithPaginationQueryLoading,
      error: chatLastDetailsByIdWithPaginationQueryError,
    },
  ] = useLazyQuery(ChatLastDetailsByIdWithPaginationQuery, {
    fetchPolicy: "network-only",
  });
  const chatlastdetailsbyCustomerNameQuery = gql`
    query chatlastdetailsbyCustomerName($searchtext: String!, $agentId: ID!) {
      chatlastdetailsbyCustomerName(
        searchtext: $searchtext
        agentId: $agentId
      ) {
        chatDetails {
          id
          customerId
          pageId
          messageId
          messagetext
          messagetimestamp
          customerName
          messagetype
          agentId
          alternateagentId
          read
          labels
          marknottoaddinchatcircle
          file
          chatorder
        }
      }
    }
  `;
  const [
    chatlastdetailsbyCustomerName,
    { data: chatlastdetailsbyCustomerNameResult },
  ] = useLazyQuery(chatlastdetailsbyCustomerNameQuery, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (props.chatBoxSubscriptionStatus) {
      props.setChatBoxRecentChatListData([]);

      props.setChatBoxRecentChatListDataTotalCount(null);
      if (props.chatBoxRecentChatListShowAllListToggle) {
        props.setUsersListSelectedUser(null);
        var findPreviousSelectedItem = _.find(
          props.usersListData,
          (itm) => itm.selected == true
        );

        if (findPreviousSelectedItem) {
          findPreviousSelectedItem.selected = false;
          props.setUsersListData(_.cloneDeep(props.usersListData));
        }
      }
      if (userIdForRecentsChatQuery != "") {
        function findPos(obj) {
          var curtop = 0;
          if (obj.offsetParent) {
            do {
              curtop += obj.offsetTop;
            } while ((obj = obj.offsetParent));
            return [curtop];
          }
        }

        window.scroll(0, findPos(document.getElementById("scrolltome")));
        chatLastDetailsByIdWithPagination({
          variables: {
            userID: userIdForRecentsChatQuery,
            // after: endCursor,
            first: chatBoxRecentChatListFetchNumber,
            managerId: managerIdForRecentsChatQuery,
          },
        });
      }
    }
  }, [
    props.chatBoxSubscriptionStatus,
    userIdForRecentsChatQuery,
    props.chatBoxRecentChatListShowAllListToggle,
  ]);
  const fetchMoreData = () => {
    if (
      !chatLastDetailsByIdWithPaginationQueryLoading &&
      chatLastDetailsByIdWithPaginationQueryResult
        .chatlastdetailsbyidwithpagination.hasNextPage &&
      !chatlastdetailsbyCustomerNameResult?.chatlastdetailsbyCustomerName
        ?.chatDetails
    ) {
      setShowCircle(true);
      chatLastDetailsByIdWithPagination({
        variables: {
          userID: userIdForRecentsChatQuery,
          first: chatBoxRecentChatListFetchNumber,
          managerId: managerIdForRecentsChatQuery,
          cursor:
            props.chatBoxRecentChatListData[
              props.chatBoxRecentChatListData.length - 1
            ].chatorder,
        },
      });
    } else {
      setShowCircle(false);
    }
  };

  const parseNameFromURL = (url) => {
    let myArray = url.split("?")[0];
    let name = [];

    for (let i = myArray.length; i >= 0; i--) {
      if (myArray[i] == "/") break;
      name.push(myArray[i]);
    }
    let subfinal = name.reverse();
    let final = subfinal.join("");
    return final;
  };
  const formatChatDetails = (chatDetails) => {
    var chatBoxRecentChatListData = [];
    chatDetails.map((item) => {
      var messageText =
        item.messagetype == "followuplabel"
          ? JSON.parse(item.messagetext)
          : item.messagetext;

      messageText =
        item.messagetype == "followuplabel"
          ? `${messageText[0]} at ${moment
              .unix(messageText[1] / 1000)
              .format("yyyy-MM-DD hh:mm A")}`
          : messageText;

      messageText =
        item.messagetype == "label"
          ? messageText.includes("!-!-!-")
            ? messageText.split("!-!-!-")[0]
            : messageText
          : messageText;
      chatBoxRecentChatListData.push({
        agentId: item.agentId,
        pageId: item.pageId,
        customerId: item.customerId,
        pageName: "",
        customername: item.customerName,
        customerPicture: item.customerPicture,
        lastMessage: messageText ? messageText : parseNameFromURL(item.file),
        lastMessageTimeStamp: item.messagetimestamp,
        selected: false,
        messageId: item.messageId,
        read: item.read,
        labels: JSON.parse(item.labels),
        loading: false,
        marknottoaddinchatcircle: item.marknottoaddinchatcircle,
        chatorder: item.chatorder,
      });
    });
    return chatBoxRecentChatListData;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  var dataArray =
    filterData != null ? filterData : props.chatBoxRecentChatListData;
  useEffect(() => {
    if (
      chatLastDetailsByIdWithPaginationQueryResult &&
      chatLastDetailsByIdWithPaginationQueryResult.chatlastdetailsbyidwithpagination
    ) {
      if (
        chatLastDetailsByIdWithPaginationQueryResult
          .chatlastdetailsbyidwithpagination.userID ==
          userIdForRecentsChatQuery &&
        chatLastDetailsByIdWithPaginationQueryResult
          .chatlastdetailsbyidwithpagination.managerId ==
          managerIdForRecentsChatQuery
      ) {
        props.setChatBoxRecentChatListDataTotalCount(
          chatLastDetailsByIdWithPaginationQueryResult
            .chatlastdetailsbyidwithpagination.chatDetails.length
        );
        let chatDetailsss =
          chatLastDetailsByIdWithPaginationQueryResult
            .chatlastdetailsbyidwithpagination.chatDetails;
        if (chatDetailsss && chatDetailsss.length) {
          setAgentId(
            chatLastDetailsByIdWithPaginationQueryResult
              .chatlastdetailsbyidwithpagination?.chatDetails[0].agentId
          );
        } else {
        }

        if (
          chatLastDetailsByIdWithPaginationQueryResult
            .chatlastdetailsbyidwithpagination.chatDetails.length > 0
        ) {
          var chatBoxRecentChatListData = null;
          if (props.chatBoxRecentChatListData.length > 0) {
            chatBoxRecentChatListData = [
              ...props.chatBoxRecentChatListData,
              ...formatChatDetails(
                chatLastDetailsByIdWithPaginationQueryResult
                  .chatlastdetailsbyidwithpagination.chatDetails
              ),
            ];
          } else
            chatBoxRecentChatListData = formatChatDetails(
              chatLastDetailsByIdWithPaginationQueryResult
                .chatlastdetailsbyidwithpagination.chatDetails
            );
          props.setChatBoxRecentChatListData(
            _.cloneDeep(chatBoxRecentChatListData)
          );

          if (
            chatLastDetailsByIdWithPaginationQueryResult
              .chatlastdetailsbyidwithpagination.hasNextPage
          ) {
          }
        }
      }
    }
  }, [chatLastDetailsByIdWithPaginationQueryResult]);

  useEffect(() => {
    if (
      chatlastdetailsbyCustomerNameResult &&
      chatlastdetailsbyCustomerNameResult.chatlastdetailsbyCustomerName
    ) {
      let chatBoxRecentChatListData = [];

      if (
        chatlastdetailsbyCustomerNameResult.chatlastdetailsbyCustomerName.chatDetails.find(
          (curr) => curr.customerId == props.chatBoxNotificationCustomerId
        )
      ) {
        chatBoxRecentChatListData = formatChatDetails([
          chatlastdetailsbyCustomerNameResult.chatlastdetailsbyCustomerName.chatDetails.find(
            (curr) => curr.customerId == props.chatBoxNotificationCustomerId
          ),
        ]);
        setfilterData(_.cloneDeep(chatBoxRecentChatListData));
      } else {
        chatBoxRecentChatListData = formatChatDetails(
          chatlastdetailsbyCustomerNameResult.chatlastdetailsbyCustomerName
            .chatDetails
        );
        setfilterData(_.cloneDeep(chatBoxRecentChatListData));
      }
    }
  }, [chatlastdetailsbyCustomerNameResult]);

  useEffect(() => {
    if (chatLastDetailsByIdWithPaginationQueryError) {
      chatLastDetailsByIdWithPaginationQueryError.graphQLErrors.map(
        ({ message }, i) => {
          enqueueSnackbar(message, { variant: "error" });
        }
      );
      if (userIdForRecentsChatQuery != "") {
      }
    }
  }, [chatLastDetailsByIdWithPaginationQueryError]);

  useEffect(() => {
    if (props.chatBoxRecentSearchInputText?.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        chatlastdetailsbyCustomerName({
          variables: {
            searchtext: props.chatBoxRecentSearchInputText,
            agentId: agentId,
          },
        });

        // Send Axios request here
      }, 500);
      setShowSearchList(true);
      return () => clearTimeout(delayDebounceFn);
    } else if (props.chatBoxRecentSearchInputText?.length == 0) {
      props.setChatBoxNotificationCustomerId("");
      setfilterData([]);
      setShowSearchList(false);
    }
  }, [props.chatBoxRecentSearchInputText]);

  var dataArray = showSearchList ? filterData : props.chatBoxRecentChatListData;

  function getLabels(labelsData) {
    if (!labelsData) {
      return [];
    }

    return labelsData.map((label, index) => ({
      title: label.includes("!-!-!-") ? label.split("!-!-!-")[0] : label,
      getContent: () => "",
      /* Optional parameters */
      key: index,
      color: label.includes("!-!-!-") ? label.split("!-!-!-")[1] : "8",
    }));
  }

  const isRowLoaded = ({ index }) => {
    return !!dataArray[index];
  };
  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  const getTopBarName = () => {
    let name = undefined;
    if (userIdForRecentsChatQuery) {
      if (props.managersListData && props.managersListData.length) {
        name = props.managersListData.find(
          (curr) => curr.id == userIdForRecentsChatQuery
        )?.name;
      }
      if (
        name == undefined &&
        props.usersListData &&
        props.usersListData.length
      ) {
        name = props.usersListData.find(
          (curr) => curr.id == userIdForRecentsChatQuery
        )?.name;
      }
    }

    return name;
  };
  var value = 0;
  const rndInt = randomIntFromInterval(1, 5);
  const chatListRef = useRef(null);

  useEffect(() => {
    if (props.activeUserDetail.id == undefined) setShowBar(true);
    else if (props.activeUserDetail.id != props.authUserId) {
      setShowBar(true);
    }
    if (props.ifMyChatsTabPressed) setShowBar(false);
  }, [props.activeUserDetail.id, props.authUserId, props.ifMyChatsTabPressed]);

  return (
    <>
      <div disableGutters={true} style={{ height: "calc(100vh - 230px)" }}>
        {!new includes().showRecentListIsLoadingAccordingToPanelType(
          props.chatBoxSubscriptionStatus,
          props.authPanelType,
          props.usersListSelectedUser,
          props.chatBoxRecentChatListDataTotalCount,
          props.chatBoxRecentChatListShowAllListToggle
        ) ? (
          <div
            style={{
              height: "calc(100vh - 235px)",
              overflowY: "hidden",
              marginTop: 10,
            }}
          >
            {
              <div
                style={{
                  width: "100%",
                  height: "30px",
                  marginTop: 10,
                  background: "#3e3e3e",
                  display: !showBar ? "none" : null,
                  marginBottom: 10,
                }}
              >
                <div
                  style={{
                    width: "95%",
                    margin: "0 auto",
                    display: "flex",
                    alignItems: "center",
                    height: "30px",
                  }}
                >
                  <>
                    <KeyboardArrowLeftIcon
                      sx={{ color: "#FFFFFF", cursor: "pointer" }}
                      onClick={() => {
                        props.backBtnHandler(userIdForRecentsChatQuery);
                      }}
                    />

                    <span
                      style={{
                        fontFamily: "Poppins",
                        color: "#FFFFFF",
                        marginLeft: "10px",
                        fontSize: "12px",
                        fontWeight: 500,
                        cursor: "pointer",
                      }}
                    >
                      {getTopBarName()}
                    </span>
                  </>
                </div>
              </div>
            }

            <div id="scrolltome"></div>

            {dataArray?.length > 0 ? (
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    ref={chatListRef}
                    // className={props.className}
                    className={classes.userList}
                    rowHeight={80}
                    rowCount={dataArray.length}
                    height={height}
                    width={width}
                    rowRenderer={({ index, isScrolling, key, style }) => {
                      var item = dataArray[index];
                      if (
                        index == dataArray.length - 1 &&
                        isScrolling == false
                      ) {
                        fetchMoreData();
                      }

                      if (isRowLoaded({ index })) {
                        var item = dataArray[index];
                        return (
                          <>
                            <ListItem
                              key={item.customerId}
                              style={style}
                              classes={{
                                root: clsx(classes.listItem, {
                                  [classes.selectedListItem]:
                                    item && item.selected,
                                }),
                              }}
                              onClick={() => {
                                props.onItemClick(item);
                              }}
                              button
                              disableRipple
                              className={classes.listItemButton}
                            >
                              <Container
                                disableGutters={true}
                                className={clsx(
                                  classes.listItemContainer,
                                  classes.listItemInnerContainer
                                )}
                              >
                                <Container
                                  disableGutters={true}
                                  className={
                                    classes.listItemAvatarAndTextContainer
                                  }
                                >
                                  <ListItemAvatar>
                                    <div style={{ position: "relative" }}>
                                      <Avatar
                                        // key={src}
                                        className={classes.customerIcon}
                                        src={""}
                                      ></Avatar>
                                      {/* {item.customerPicture ? (
                                        <Avatar
                                          // key={src}
                                          className={classes.customerIcon}
                                          src={item.customerPicture}
                                        ></Avatar>
                                      ) : (
                                        <FacebookAvatar
                                          key={item.customerId}
                                          className={classes.customerIcon}
                                          type="customer"
                                          item={item}
                                        ></FacebookAvatar>
                                      )} */}
                                      <img
                                        style={{
                                          width: "13px",
                                          position: "absolute",
                                          bottom: "0px",
                                          right: "5px",
                                        }}
                                        src={messengerIcon}
                                        alt="messenger"
                                      />
                                    </div>
                                  </ListItemAvatar>
                                  <ListItemText
                                    classes={{
                                      primary: classes.listItemPrimaryText,
                                      secondary: clsx(
                                        classes.listItemSecondaryText,
                                        {
                                          [classes.listItemSecondaryTextMarkUnread]:
                                            !item.read,
                                        }
                                      ),
                                    }}
                                    primary={
                                      item.customername &&
                                      item.customerName !== "" ? (
                                        <Typography
                                          sx={{ fontWeight: 500, fontSize: 14 }}
                                          className={clsx(classes.listItemPri, {
                                            [classes.listItemPrimaryTextMarkUnread]:
                                              !item.read,
                                          })}
                                        >
                                          {item.customername}
                                        </Typography>
                                      ) : (
                                        <Typography
                                          sx={{ fontWeight: 500, fontSize: 14 }}
                                          className={clsx(classes.listItemPri, {
                                            [classes.listItemPrimaryTextMarkUnread]:
                                              !item.read,
                                          })}
                                        >
                                          Customer Name
                                          {/* Jack Ross */}
                                        </Typography>
                                        // <FacebookTypography
                                        //   className={clsx(classes.listItemPri, {
                                        //     [classes.listItemPrimaryTextMarkUnread]:
                                        //       !item.read,
                                        //   })}
                                        //   key={item.customerId}
                                        //   pageNameChange={(name) => {
                                        //     var findItem = _.find(
                                        //       props.chatBoxRecentChatListData,
                                        //       (list) =>
                                        //         list.pageId == item.pageId &&
                                        //         list.customerId == item.customerId
                                        //     );
                                        //     if (findItem) {
                                        //       if (findItem.pageName != name) {
                                        //         findItem.pageName = name;

                                        //         props.setChatBoxRecentChatListData(
                                        //           _.cloneDeep(
                                        //             props.chatBoxRecentChatListData
                                        //           )
                                        //         );
                                        //       }
                                        //     }
                                        //   }}
                                        //   customerNameChange={(name) => {

                                        //     var findItem = _.find(
                                        //       props.chatBoxRecentChatListData,
                                        //       (list) =>
                                        //         list.pageId == item.pageId &&
                                        //         list.customerId == item.customerId
                                        //     );

                                        //     if (findItem) {
                                        //       if (findItem.customerName != name) {
                                        //         findItem.customerName = name;
                                        //         props.setChatBoxRecentChatListData(
                                        //           _.cloneDeep(
                                        //             props.chatBoxRecentChatListData
                                        //           )
                                        //         );
                                        //       }
                                        //     }
                                        //   }}
                                        //   item={item}
                                        // />
                                      )
                                    }
                                    secondary={
                                      item?.lastMessage?.length > 30
                                        ? `${item?.lastMessage?.slice(
                                            0,
                                            30
                                          )}...`
                                        : item?.lastMessage
                                    }
                                  />
                                </Container>

                                <div
                                  style={{
                                    flexGrow: 1,
                                    position: "absolute",
                                    top: "45px",
                                    left: "50px",
                                    width: "190px",
                                  }}
                                  className="basic__tabs"
                                >
                                  <Tabs
                                    colorNumber={rndInt}
                                    allowRemove={true}
                                    key={item.labels}
                                    showMore={true}
                                    showMoreLabel={"more"}
                                    showInkBar={false}
                                    transform={false}
                                    unmountOnExit={true}
                                    selectedTabKey={0}
                                    onChildrenClick={(key, text) => {
                                      props.setChatBoxRecentSearchText("");
                                      props.setChatBoxRecentSearchChatIds([]);
                                      props.setChatBoxRecentSearchInputText(
                                        "l:" + text
                                      );
                                    }}
                                    items={getLabels(item.labels)}
                                  />
                                </div>
                                <div
                                  className={classes.listItemBottomContainer}
                                >
                                  {!item.typingMessageDetails && (
                                    <div
                                      className={
                                        classes.markNotToAddInChatCircleContainer
                                      }
                                    >
                                      <div
                                        className={
                                          classes.markNotToAddInChatCircleSpan
                                        }
                                      >
                                        {item.marknottoaddinchatcircle === 1 ? (
                                          <Typography
                                            sx={{
                                              fontSize: "9px",
                                              color: "red",
                                            }}
                                          >
                                            {" "}
                                            closed
                                          </Typography>
                                        ) : (
                                          <Typography
                                            sx={{
                                              fontSize: "9px",
                                              color: "green",
                                            }}
                                          >
                                            {" "}
                                            open
                                          </Typography>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {item.typingMessageDetails ? (
                                    <ChatListTypingMessageStatus
                                      pageId={item.pageId}
                                      customerId={item.customerId}
                                      typingMessageDetails={
                                        item.typingMessageDetails
                                      }
                                    ></ChatListTypingMessageStatus>
                                  ) : null}
                                  <ReactTimeAgo
                                    className={classes.timeStamp}
                                    date={moment.unix(
                                      item.lastMessageTimeStamp / 1000
                                    )}
                                    locale="en-US"
                                  />
                                </div>
                              </Container>
                            </ListItem>
                          </>
                        );
                      }
                    }}
                  ></List>
                )}
              </AutoSizer>
            ) : (
              <>
                {chatLastDetailsByIdWithPaginationQueryLoading ? (
                  <>
                    <CircularProgress
                      className={classes.loadingCircularProgress}
                      size={24}
                    />
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                        color: "gray",
                        marginTop: "10%",
                      }}
                    >
                      No Chats Found
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.ChatBoxReducer,
    ...state.AuthReducer,
    ...state.UsersListReducer,
    ...state.ManagersListReducer,
    ...state.AdminDataReducer,
    ...state.PropsObjectDataReducer,
  };
};
export default connect(mapStateToProps, {
  setUsersListSearchText,
  setUsersListSearchInputText,
  setChatBoxRecentChatListData,
  setChatBoxRecentSearchText,
  setChatBoxRecentSearchChatIds,
  setChatBoxRecentSearchInputText,
  setChatBoxRecentChatListDataTotalCount,
  setUsersListSelectedUser,
  setUsersListData,
  setAllUsersData,
  setPropsObjectData,
  setChatBoxSelectedChatsOnFloating,
  setChatBoxNotificationCustomerId,
})(ChatList);
