import { makeStyles } from 'tss-react/mui'
// import { grey } from '@mui/material/colors'

const useStyles = makeStyles()((theme) => ({
  search: {
    position: 'relative',
    borderRadius: 3,
    minHeight: 'unset',
    border: '0.5px solid #E8E8E8',
    // backgroundColor: grey[100],
    '&:hover': {
      backgroundColor: theme.custom?.background,
    },
    // marginRight: 2,
    // marginTop: 10,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      // marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: 10,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  placeholder: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    fontWeight: 400,
  },
}))
export default useStyles
