import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    maxWidth: "100%",
    [theme.breakpoints.down("lg")]: {
      // display: "inline-block",
      fontSize: "12px",
    },
  },
  textfb: {
    marginLeft: 10,
    fontSize: "14px",
    wordWrap: "break-word",
    fontWeight: 500,
    whiteSpace: "nowrap",
    overflowWrap: "break-word",
    wrap: true,
    maxWidth: 100,
    [theme.breakpoints.down("md")]: {
      // display: "inline-block",
      fontSize: "12px",
    },
  },
  text: {
    fontSize: "14px",
    wordWrap: "break-word",
    fontWeight: 500,
    whiteSpace: "nowrap",
    overflowWrap: "break-word",
    wrap: true,
    maxWidth: 100,
    [theme.breakpoints.down("md")]: {
      // display: "inline-block",
      fontSize: "12px",
    },
  },
  //   bottomChatsTabTexts: {
  //     display: "inline",
  //     fontWeight: "500",
  //     color: "black",
  //     marginLeft: 5,
  //     width: 150,
  //     whiteSpace: "nowrap",
  //     overflow: "hidden",
  //     fontStyle: "bold",
  //     fontFamily: "Poppins",
  //     fontSize: "17px",
  // },
  nameandpage: {
    fontSize: "12px",
    fontWeight: 400,
  },
  datetimepicker: {
    border: "1px solid lightgray",
  },

  buttonhere: {
    display: "flex",
    justifyContent: "center",
  },
  dialogCustomizedWidth: {
    width: "30% !important",
  },
  setup: {
    zIndex: 1000,
  },
  textSendMessage: {
    height: "21px",
    width: "45px",
    fontSize: "12px",
    fontWeight: 400,
    [theme.breakpoints.down("lg")]: {
      // display: "inline-block",
      fontSize: "10px",
    },
  },
}));
export default useStyles;
