export default function codeToEmoji(stringVal) {
    var words = stringVal.split(" ");
    var alpha = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
    ];
    // var numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
    // function stringslice(text, count) {
    //   return text.slice(0, count) + (text.length > count ? "..." : "");
    // }
    const ar1 = [
      "o/",
      "</3",
      "<3",
      "8-D",
      "8D",
      ":-D",
      "=-3",
      "=-D",
      "=3",
      "=D",
      "B^D",
      "X-D",
      "XD",
      "x-D",
      "xD",
      ":')",
      ":'-)",
      ":-))",
      "8)",
      ":)",
      ":-)",
      ":3",
      ":D",
      ":]",
      ":^)",
      ":c)",
      ":o)",
      ":}",
      ":っ)",
      "=)",
      "=]",
      "0:)",
      "0:-)",
      "0:-3",
      "0:3",
      "0;^)",
      "O:-)",
      "3:)",
      "3:-)",
      "}:)",
      "}:-)",
      "*)",
      "*-)",
      ":-,",
      ";)",
      ";-)",
      ";-]",
      ";D",
      ";]",
      ";^)",
      ":-|",
      ":|",
      ":(",
      ":-(",
      ":-<",
      ":-[",
      ":-c",
      ":<",
      ":[",
      ":c",
      ":{",
      ":っC",
      "%)",
      "%-)",
      ":-P",
      ":-b",
      ":-p",
      ":-Þ",
      ":-þ",
      ":P",
      ":b",
      ":p",
      ":Þ",
      ":þ",
      ";(",
      "=p",
      "X-P",
      "XP",
      "d:",
      "x-p",
      "xp",
      ":-||",
      ":@",
      ":-.",
      ":-/",
      ":/",
      ":L",
      ":S",
      ":\\",
      "=/",
      "=L",
      "=\\",
      ":'(",
      ":'-(",
      "^5",
      "^<_<",
      "o/\\o",
      "|-O",
      "|;-)",
      ":###..",
      ":-###..",
      "D-':",
      "D8",
      "D:",
      "D:<",
      "D;",
      "D=",
      "DX",
      "v.v",
      "8-0",
      ":-O",
      ":-o",
      ":O",
      ":o",
      "O-O",
      "O_O",
      "O_o",
      "o-o",
      "o_O",
      "o_o",
      ":$",
      "#-)",
      ":#",
      ":&",
      ":-#",
      ":-&",
      ":-X",
      ":X",
      ":-J",
      ":*",
      ":^*",
      "ಠ_ಠ",
      "*\\0/*",
      "\\o/",
      ":>",
      ">.<",
      ">:(",
      ">:)",
      ">:-)",
      ">:/",
      ">:O",
      ">:P",
      ">:[",
      ">:\\",
      ">;)",
      ">_>^",
    ];
    var emojis = [
      "👋",
      "💔",
      "💗",
      "😁",
      "😁",
      "😁",
      "😁",
      "😁",
      "😁",
      "😁",
      "😁",
      "😁",
      "😁",
      "😁",
      "😁",
      "😂",
      "😂",
      "😃",
      "😄",
      "😄",
      "😄",
      "😄",
      "😄",
      "😄",
      "😄",
      "😄",
      "😄",
      "😄",
      "😄",
      "😄",
      "😄",
      "😇",
      "😇",
      "😇",
      "😇",
      "😇",
      "😇",
      "😈",
      "😈",
      "😈",
      "😈",
      "😉",
      "😉",
      "😉",
      "😉",
      "😉",
      "😉",
      "😉",
      "😉",
      "😉",
      "😐",
      "😐",
      "😒",
      "😒",
      "😒",
      "😒",
      "😒",
      "😒",
      "😒",
      "😒",
      "😒",
      "😒",
      "😖",
      "😖",
      "😜",
      "😜",
      "😜",
      "😜",
      "😜",
      "😜",
      "😜",
      "😜",
      "😜",
      "😜",
      "😜",
      "😜",
      "😜",
      "😜",
      "😜",
      "😜",
      "😜",
      "😠",
      "😠",
      "😡",
      "😡",
      "😡",
      "😡",
      "😡",
      "😡",
      "😡",
      "😡",
      "😡",
      "😢",
      "😢",
      "😤",
      "😤",
      "😤",
      "😫",
      "😫",
      "😰",
      "😰",
      "😱",
      "😱",
      "😱",
      "😱",
      "😱",
      "😱",
      "😱",
      "😱",
      "😲",
      "😲",
      "😲",
      "😲",
      "😲",
      "😲",
      "😲",
      "😲",
      "😲",
      "😲",
      "😲",
      "😳",
      "😵",
      "😶",
      "😶",
      "😶",
      "😶",
      "😶",
      "😶",
      "😼",
      "😽",
      "😽",
      "🙅",
      "🙆",
      "🙆",
      "😄",
      "😡",
      "😠",
      "😈",
      "😈",
      "😈",
      "😲",
      "😜",
      "😒",
      "😡",
      "😈",
      "😤",
    ];
    for (let i = 0; i < words.length; i++) {
      if (alpha.includes(words[i][0]) || alpha.includes(words[i][0])) {
      } else {
        if (ar1.indexOf(words[i]) != -1) {
          words[i] = emojis[ar1.indexOf(words[i])];
        }
      }
    }
    var newString = words.join(" ");
    return newString;
  }