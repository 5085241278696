import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { setAdminPanelDrawerToggle } from "../../store/actions/AdminpanelActions";

import ChatBox from "../chatBox";
import Setting from "../setting";
import AddUsers from "../adminPanel/addUsers";
import MainLayout from "../layout";
import Report from "../Report/Report";
import Dashboard from "../dashboard/Dashboard";

const MainContentContainer = (props) => {
  return (
    <MainLayout>
      <Switch>
        <Route exact path="/dashboard">
          <Dashboard />
        </Route>
        <Route exact path="/reports">
          <Report />
        </Route>
        <Route exact path="/users">
          <AddUsers />
        </Route>

        {/* <Route
          exact
          strict
          path={["/settings", "/settings/facebook", "/settings/website", "/settings/chatpage", "/setting/cannedResponse", "/setting/label"]}
          render={(props) => (
            <Setting
              {...props}
            />
          )}
        >
        </Route> */}

        <Redirect exact from="/settings" to={`settings/label`} />
        <Redirect exact from="/chat" to={`chats`} />
        <Route
          exact
          path={["/settings", "/settings/:pageName"]}
          component={(props) => <Setting {...props} />}
        ></Route>

        <Route
          path={["/chats", "/admin", "/"]}
          render={(props) => <ChatBox {...props} isManager={true} />}
        />
      </Switch>
    </MainLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.AdminPanelReducer,
    ...state.AuthReducer,
  };
};
export default connect(mapStateToProps, {
  setAdminPanelDrawerToggle,
})(MainContentContainer);
