import * as React from 'react';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import useStyles from './Styles';

interface Props {
  values: string[]
  selectChange: any
  value: string
}
const SelectSmall: React.FC<Props> = ({ values, selectChange, value }) => {


  const { classes } = useStyles();
  const handleChange = (event: SelectChangeEvent) => {
    selectChange(event.target.value);
  };
  return (
    <FormControl size="small" className={classes.formControl}>
      {/* <InputLabel id="demo-select-small" className={classes.inputLabel}>{age}</InputLabel> */}
      <Select
        value={value}
        // label="Time"
        onChange={handleChange}
        className={classes.select}
      // className={classes.form}
      >
        {values.map((val, index) => {
          return (
            <MenuItem key={index} className={classes.menuItem} value={val}>{val}</MenuItem>
          )
        }
        )}

      </Select>
    </FormControl>
  );
}

export default SelectSmall
