import React, { useState, useEffect } from 'react'
//
import { gql, useMutation } from '@apollo/client'
import { useHistory } from "react-router-dom"
//
import { useSnackbar } from 'notistack'
//
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
//
import Box from '@mui/material/Box/Box'
import useStyles from './Styles'
// import Logo from '../../../../assets/images/logo.svg'
import AuthButton from "../shared/atom/button/AuthButton";
import Heading from "../shared/atom/heading/Heading";

import SignUpFooter from '../shared/atom/signUpFooter/SignUpFooter'
import SignUpTop from "../shared/atom/signUpTop/SignUpTop";
import { Text } from "../shared/atom/text/Text";
import KuikTextField from "../shared/atom/kuikTextField/TextField";

const ForgetPasswordQuery = gql`
  mutation RequestResetToken($email: String!) {
    requestresettoken(email: $email) {
      success
      error
    }
  }
`

const ForgotPasswordRightComponent: React.FC = () => {
  //
  const [email, setEmail] = useState('')
  //
  const { enqueueSnackbar } = useSnackbar()
  //
  const history = useHistory()
  //
  const [
    requestResetToken,
    {
      loading: requestResetTokenQueryLoading,
      error: requestResetTokenQueryError,
      data: requestResetTokenQueryResult,
    },
  ] = useMutation(ForgetPasswordQuery)
  ///////////////////////////
  useEffect(() => {
    if (requestResetTokenQueryError) {
      requestResetTokenQueryError.graphQLErrors.map(({ message }) => {
        enqueueSnackbar(message, { variant: 'error' })
      })
    }
  }, [requestResetTokenQueryError])

  useEffect(() => {
    if (
      requestResetTokenQueryResult &&
      requestResetTokenQueryResult.requestresettoken
    ) {
      if (requestResetTokenQueryResult.requestresettoken.error) {
        enqueueSnackbar(requestResetTokenQueryResult.requestresettoken.error, {
          variant: 'error',
        })
      } else {
        history.push(`/checkyouremail/${email}`)
      }
    }
  }, [requestResetTokenQueryResult])

  // interface for the form values
  interface FormValues {
    email: string
  }

  // validation schema using Yup
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid eail admdress')
      .required('Email is required'),
  })

  const handleSubmit = async (
    values: FormValues,
    // { setSubmitting }: FormikHelpers<FormValues>
    { setSubmitting }: any
  ) => {
    // handle form submission
    setSubmitting(false)
    // e.preventDefault()
    try {
      await requestResetToken({
        variables: {
          email: values.email,
        },
      })
      setEmail(values.email)
    } catch (e) { }
  }

  /////////////////////////////////
  const { classes } = useStyles()
  return (
    <>
      <Box className={classes.root}>
        <Box style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80%",
        }}>
          <SignUpTop />
          <Heading heading="Forgot Password" />
          <Text text="No worries, we’ll send you reset instructions" />

          <Formik
            initialValues={{ email: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik: any) => (
              <Box
                component="form"
                onSubmit={formik.handleSubmit}
                className={classes.form}
              >
                <KuikTextField
                  id="email"
                  label="Email Address"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <AuthButton text="Send instructions" loading={requestResetTokenQueryLoading} />
              </Box>
            )}
          </Formik>
          <SignUpFooter route="/login" text="Sign in" />
        </Box>
      </Box>
    </>
  )
}

export default ForgotPasswordRightComponent