import { makeStyles } from 'tss-react/mui'

const useStyle = makeStyles()(()=>({
    cardContent : {
        padding:'0px'  
    },
    headerText:{
        fontWeight : '500',
        fontSize : '16px',
        LineHeight :'24px',
        padding : '0px'
    },
    subHeading:{
        fontWeight : '400',
        fontSize : '14px',
        LineHeight :'21px',
        color: '#838383'
    },
    text:{
        fontSize: '12px',
        lineHeight: '16px',
        color:'#838383'
    },
    labelInput : {
        height:'32px',
        width:"200px",
        borderRadius: '3px'
    },
    addButton :{
        height: '32px',
        textTransform: 'capitalize',
        borderRadius: '3px',
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: '500',
        textAlign: 'center'
    },
    inputBox :{
        display: 'flex',
        alignItems: 'center',
        width: '22.5%',
        marginTop: '20px'
    },
    tableStyle:{
        width:'65%'
    },
    tableHeadings:{
        fontWeight : '400',
        fontSize : '12px',
        LineHeight : '18px',
        color: '#838383',
        height:'35px',
        padding: '0px 0px 0px 10px',
        marginRight: '20px',
        borderBottom: '1px solid #DFE2E5',
    },
    tbody:{
        height:'50px',
        padding: '0px 0px 0px 10px',
        borderBottom: '1px solid #DFE2E5'
    },
    tableText:{
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
        color: '#272525'
    },
    deleteIconStyle:{
        color:'#838383',
        cursor: 'pointer'
    },
    labelRemoveBox:{

    },
    labelCancelButton:{
        textTransform:'capitalize',
        height:"30px",
        fontSize: '11px',
        fontWeight: '500',
        lineHeight: '18px',
        background: '#F5F5F5',
        color:'#777777',
        '&:hover':{
            background: '#F5F5F5',
            color:'#777777',
        },
        marginRight:'5px'
    },
    labelRemoveButton:{
        textTransform:'capitalize',
        height:"30px",
        fontSize: '11px',
        fontWeight: '500',
        lineHeight: '18px',
        marginLeft:'5px'
    },
    tableRowSelectedStyle:{
    
    },
    loadingCircularProgress: {
    margin: "auto",
    display: "block",
    marginTop: 20,
    color: "#55A530",
    },
}))


export default useStyle