import * as React from 'react'
import { Typography, ListItem, Box } from '@mui/material'
//
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
//
// import EditModal from '../EditModal/EditModal'
//
import useStyle from './Styles'
//
import { Dispatch, SetStateAction } from 'react';

interface Props {
  label: string
  text: string
  keyIndex: number
  setting: any
  editResponse:any
}

const Response: React.FC<Props> = ({ label, text, keyIndex, setting, editResponse }) => {
  //
  const [hovered, setHovered] = React.useState(false)
  const [index, setIndex] = React.useState(keyIndex)

  React.useEffect(() => {
    setIndex(keyIndex)
  }, [keyIndex])

  const handleMouseEnter = () => {
    setHovered(true)
  }

  const handleMouseLeave = () => {
    setHovered(false)
  }

  const deleteItem = () => {
    setting(keyIndex)
  }

  const [openModal, setOpenModal] = React.useState<boolean>(false)
  const closeModal = () => {
    setOpenModal(false)
  }
  const OpenModal = () => {
    setOpenModal(true)
  }

  const { classes } = useStyle()
  return (
    <>
      {' '}
      {/* <EditModal check={openModal} closeModal={closeModal} text={text} label={label} keyIndex={index} setUpdatedata={setUpdatedata} /> */}
      <ListItem
        className={
          !hovered ? classes.listStyle : `${classes.listStyle} ${classes.hover}`
        }
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* <ListItem className={classes.listStyle}> */}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              width: '100%',
              fontWeight: '400',
              fontFamily: 'poppins',
              fontSize: '14px',
              lineHeight: '21px',
              color: '#272525',
            }}
            variant="subtitle1"
          >
            {text}
          </Typography>

          <Typography
            sx={{
              width: '100%',
              fontFamily: 'poppins',
              fontWeight: '400',
              fontSize: '12px',
              lineHeight: '18px',
              color: '#838383',
              //
              marginTop: '8px',
            }}
            variant="subtitle1"
          >
            {label}
          </Typography>
        </Box>
        {/* <Box className={`${classes.iconBox} ${classes.hover}`}> */}
        <Box
          className={
            hovered ? classes.iconBox : `${classes.iconBox} ${classes.hover2}`
          }
        >
          <EditIcon
            // onClick={OpenModal}
            onClick={()=>editResponse(index)}
            style={{ height: '18px', width: '18px', cursor: 'pointer' }}
          />
          <DeleteIcon
            style={{ height: '18px', width: '18px', cursor: 'pointer' }}
            onClick={deleteItem}
          />
        </Box>
      </ListItem>
    </>
  )
}

export default Response
