import React, { Fragment, useEffect, useState } from "react";

import {
  Container,
  CircularProgress,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Badge,
} from "@material-ui/core";
import { List, AutoSizer } from "react-virtualized";
import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import {
  setUsersListSearchInputText,
  setUsersListSearchText,
  setUsersListData,
  setUsersListSubscriptionData,
  setUsersListContextMenuPosAndObjectDetails,
  setUsersListSelectedUser,
} from "../../store/actions/UsersListActions";

import { setManagersListData } from "../../store/actions/ManagersListActions";

import { setAllUsersData } from "../../store/actions/AdmindataActions";
import { setChatBoxRecentChatListShowAllListToggle } from "../../store/actions/ChatBoxActions";
import includes from "./includes";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import _ from "lodash";
import { connect } from "react-redux";
import "../../otherComponents/react-responsive-tabs/styles.css";
import { useSnackbar } from "notistack";
import ErrorIcon from "@mui/icons-material/Error";
import LensIcon from "@mui/icons-material/Lens";
import iconRight from "../../assets/chatWindow/Icons-chevron-up.svg";
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import { styled, } from '@mui/material/styles';
import { Divider, Typography } from "@mui/material";

import SearchIcon from '@mui/icons-material/Search';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    paddingLeft: `3em`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: 2,
  // backgroundColor: grey[100],
  '&:hover': {
    backgroundColor: theme.custom?.background
  },
  marginTop: 3,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    // marginLeft: theme.spacing(3),  
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(() => ({
  padding: 10,
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));


const useStyles = makeStyles((theme) => ({
  textFieldInputRoot: {
    width: "100%",
    background: "#eeeeee",
    border: "0px solid #d0d0d0",
    paddingLeft: "35px",
  },
  textFieldInput: {
    height: 41,

    padding: "0px 4px",
    borderBottom: 0,
  },
  listItemPrimaryText: {
    fontSize: "14 !important",
    textTransform: "capitalize",
    fontFamily: 'Poppins',
    fontWeight: "500",
    flex: 1,
    wordBreak: "break-all",
    textOverflow: "ellipsis",
    overflow: "hidden",
    // whiteSpace: "break-spaces",
    whiteSpace: 'nowrap',
    // maxHeight: 24,
    width: '100%',
    marginTop: 4,
  },
  listItemButton: {
    background: "#fff",
    height: "60px",
    overflow: "hidden!important",
    borderBottom: "1px solid #DFE2E5",
    width: "96% !important",
    marginLeft: "2%",
    paddingRight: 4,
    "&:hover": {
      // borderRadius: "10px",
      background: "#E6D9EC",
      border: "1px solid #d7d7d7",
      boxShadow: "0px 2px 1px 1px rgba(0,0,0,0.02)",
    },
  },
  loadingCircularProgress: {
    margin: "auto",
    display: "block",
    marginTop: 20,
    fontWeight: "400", color: "#4d1277"
  },
  selectedListItem: {
    background: "#eaeaea",
    borderLeft: "7px solid #9ac2ff",
  },
  listItemContainer: {
    display: "flex",
    flexDirection: "column",
    cursor: "context-menu",
  },

  listItemAvatarAndTextContainer: {
    display: "flex",
  },
  listItemSecondaryText: {
    whiteSpace: "nowrap",
    fontSize: 10,
    marginTop: 1,
  },

  listItemInnerContainer: {
    paddingLeft: 16,
    paddingRight: 4,
    paddingTop: 8,
    paddingBottom: 8,
  },
  listItem: {
    padding: 0,
    height: '95px'
  },
  listItemBottomContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  listNoRows: {
    padding: 10,
  },
  userList: {
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarWidth: 'thin',
    scrollbarColor: '#ccc transparent',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      borderRadius: '3px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
  },

  disconnectedIcon: {
    marginRight: 3,
    color: "#f50057",
  },
  primaryTextContainer: {
    display: "flex",
  },
  spans: {
    // width: "90px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: 14
  },
  onlineStatusIcon: {
    marginRight: 3,
    color: "green",
    fontFamily: "Poppins",
  },
  offlineStatusIcon: {
    marginRight: 3,
    color: "red",
    fontFamily: "Poppins",
  },
  userAvatar: {
    marginTop: 7,
  },
  userAvatarChatsCountBadge: {
    background: "#55a530",
    marginTop: 14,
    transform: " scale(0.88) translate(-15%, -45%)",
    fontSize: "10.5px",
    color: "white",
  },
}));

const ManagerList = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const [filterData, setfilterData] = useState(null);
  const [managerUsers, setManagerUsers] = useState([]);
  const [isappend, setIsAppend] = useState(false);
  const userListItemHandleClick = (event, userId) => {
    event.preventDefault();

    props.setUsersListContextMenuPosAndObjectDetails({
      anchorEl: event.currentTarget,
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      userId: userId,
    });
  };


  const GetSubscriptionDataQuery = gql`
    query GetSubscriptionDataall {
      getsubscriptiondataall {
        id
        data
      }
    }
  `;

  let [getSubscriptionDataall, { data: getSubscriptionDataQueryResult }] =
    useLazyQuery(GetSubscriptionDataQuery, {
      fetchPolicy: "network-only",
    });
  useEffect(() => {
    if (
      getSubscriptionDataQueryResult &&
      getSubscriptionDataQueryResult.getsubscriptiondataall
    ) {
      props.setUsersListSubscriptionData(
        JSON.parse(getSubscriptionDataQueryResult.getsubscriptiondataall.data)
      );
    }
  }, [getSubscriptionDataQueryResult]);

  useEffect(() => {
    if (true) {
      getSubscriptionDataall();
    }
  }, []);

  const handleGetManagers = gql`
  query getManagers(
    $mainSuperAdminId:ID!
    $cursor:Int
    $limit:Int

  ) {
    getManagers(
      mainSuperAdminId: $mainSuperAdminId
      cursor:$cursor
      limit:$limit
    ) {
      users{
       id
        name
        username
        email
        status
        number
        agentlimitchatassign
        isUserLoggedIn
        pages
        number
        designation {
              id
             name
             paneltype
        }
      }
      hasNextPage
    }
    }`;

  let [
    getManagers,
    {
      loading: getManagersQueryLoading,
      error: getManagersQueryError,
      data: getManagersQueryResult,
    },
  ] = useLazyQuery(handleGetManagers, {
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (getManagersQueryResult && getManagersQueryResult.getManagers.users) {
      let managersListData = [];

      getManagersQueryResult.getManagers.users
        .map((user) => {
          const findCurrentSubUserData = _.find(
            props.usersListSubscriptionData,
            (dataSub) => dataSub.agentId == user.id
          );

          managersListData.push({
            id: user.id,
            name: user.name,
            picture: user.picture,
            isOnline: findCurrentSubUserData
              ? findCurrentSubUserData.isOnline
              : false,
            loggedIn: findCurrentSubUserData
              ? findCurrentSubUserData.loggedIn
              : false,
            isConnected: findCurrentSubUserData
              ? findCurrentSubUserData.isConnected
              : false,
            chatsAssignedCount: findCurrentSubUserData
              ? findCurrentSubUserData.chats.length
              : 0,
            panelType: user.designation.paneltype,
          });
        });
      managersListData = _.sortBy(managersListData, [
        (item) => item.isOnline && item.isConnected,
        (item) => item.loggedIn,
      ]);
      let finalUsersListData = []

      if (isappend) {
        finalUsersListData = [...props.managersListData, ...managersListData];
      }
      else {
        finalUsersListData = [...managersListData];
      }

      //  props.setUsersListData(_.cloneDeep(_.reverse(usersListData)));
      props.setManagersListData(_.cloneDeep(_.reverse(finalUsersListData)));


    }
  }, [getManagersQueryResult, props.usersListSubscriptionData])


  useEffect(() => {

    getManagers({
      variables: {
        cursor: 0,
        mainSuperAdminId: JSON.parse(localStorage.getItem("ActiveUserdetail"))
          .mainSuperAdminId,
        limit: 20
      },
    });
    setIsAppend(false);
  }, []);


  var searchUsersTextFieldTimeOut = null;
  useEffect(() => {
    if (searchUsersTextFieldTimeOut) searchUsersTextFieldTimeOut.clear();

    searchUsersTextFieldTimeOut = setTimeout(() => {
      props.setUsersListSearchText(props.usersListSearchInputText);
    }, 500);
  }, [props.usersListSearchInputText]);

  useEffect(() => {
    if (props.usersListSearchText.length > 0) {
      setfilterData(
        _.filter(props.managersListData, (itm) => {
          var searchValue_ = props.usersListSearchText.toLowerCase();
          if (!itm.name) {
            return [];
          } else {
            return itm.name.toLowerCase().indexOf(searchValue_) != -1;
          }
        })
      );
    } else {
      setfilterData(null);
    }
  }, [props.usersListSearchText, props.managersListData]);

  var dataArray = filterData != null ? filterData : props.managersListData;
  const fetchMoreData = () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs

    if (
      getManagersQueryResult && getManagersQueryResult.getManagers.users.hasNextPage
    ) {
      // setShowCircle(true);
      getManagers({
        variables: {
          limit: 20,
          mainSuperAdminId: JSON.parse(localStorage.getItem("ActiveUserdetail"))
            .mainSuperAdminId,
          cursor: props.managersListData[props.managersListData.length - 1].id

        },
      });
      // setShowCircle(true);
    } else {
      // setShowCircle(false);
    }
    // setShowCircle(false);

  };
  return (
    <>
      <Box sx={{
        height: '30px',
        width: '98%',
        paddingLeft: 1,
        // marginTop: 20,
      }}>
        <Box sx={{
          position: 'relative',
          borderRadius: 1,
          minHeight: 'unset',
          // border: '0.5px solid #E8E8E8',
          border: '0.5px solid #E8E8E8',
          // backgroundColor: grey[100],
          '&:hover': {
            // backgroundColor: "blue",
          },
          height: '40px',
          // marginRight: 2,
          // marginTop: 10,
          marginLeft: 0,
          width: '100%',

        }}>
          {/* <Box sx={{
            padding: 10,
            // height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

          }}>
            <SearchIcon />
          </Box> */}<Search>
            <SearchIconWrapper>
              <SearchIcon sx={{ color: "#838383" }} />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder={'search'
              }
              value={props.usersListSearchInputText}
              onInput={(e) => {
                props.setUsersListSearchInputText(e.target.value);
              }}
            // placeholder={<Typography className={classes.placeholder}>Search</Typography>}
            // inputProps={{ 'aria-label': 'search' }}
            />
          </Search>

        </Box>
      </Box>

      {getManagersQueryLoading ? (
        <CircularProgress
          className={classes.loadingCircularProgress}
          size={24}
        />
      ) : (

        <div disableGutters={true} style={{
          height: "calc(100vh - 229px)",
          marginTop: 15
        }}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                overscanRowsCount={5}
                rowHeight={({ index }) => {
                  return 60;
                }}
                className={classes.userList}
                rowRenderer={({ index, isScrolling, key, style }) => {
                  var item = dataArray[index];
                  console.log("item:",item)
                  if (index == dataArray.length - 1 && isScrolling == false) {
                    fetchMoreData()
                  }
                  return (
                    <>
                      <ListItem
                        style={style}
                        classes={{
                          root: clsx(classes.listItem, {
                            [classes.selectedListItem]: item.selected,
                          }),
                        }}
                        onClick={() => {
                          props.setChatBoxRecentChatListShowAllListToggle(false);

                          new includes().bindItemToRecentChatByUser(
                            item,
                            props.usersListData,
                            props.setUsersListData,
                            props.setUsersListSelectedUser
                          );
                          props.onItemClick && props.onItemClick(item);
                        }}
                        button disableRipple
                        className={classes.listItemButton}
                      >
                        <Container
                          onContextMenu={(event) => {
                            userListItemHandleClick(event, item.id);
                          }}
                          disableGutters={true}
                          className={clsx(
                            classes.listItemContainer,
                            classes.listItemInnerContainer
                          )}
                        >
                          <Container
                            disableGutters={true}
                            className={classes.listItemAvatarAndTextContainer}
                          >
                            <ListItemAvatar>
                              <Badge
                                style={{ width: "15px" }}
                                badgeContent={item.chatsAssignedCount}
                                classes={{
                                  badge: classes.userAvatarChatsCountBadge,
                                }}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                <Avatar
                                  className={classes.userAvatar}
                                  key={item.picture}
                                  src={item.picture}
                                ></Avatar>
                                <Tooltip
                                  arrow={true}
                                  placement={"top"}
                                  title={item.isOnline ? "Online" : "Offline"}
                                >
                                  <LensIcon
                                    style={{
                                      width: "12px",
                                      marginTop: "30px",
                                      marginLeft: "-12px",
                                      zIndex: "100",
                                    }}
                                    className={clsx({
                                      [classes.onlineStatusIcon]: item.isOnline,
                                      [classes.offlineStatusIcon]: !item.isOnline,
                                    })}
                                  />
                                </Tooltip>
                              </Badge>
                            </ListItemAvatar>

                            <ListItemText
                              classes={{
                                primary: classes.listItemPrimaryText,
                                secondary: clsx(classes.listItemSecondaryText),
                              }}
                              primary={
                                <div className={classes.primaryTextContainer}>
                                  {item.isOnline && !item.isConnected && (
                                    <Tooltip
                                      arrow={true}
                                      placement={"top"}
                                      title={"Not connected"}
                                    >
                                      <ErrorIcon
                                        className={classes.disconnectedIcon}
                                      />
                                    </Tooltip>
                                  )}

                                  <span className={classes.spans}>
                                    {item.name}
                                  </span>
                                </div>
                              }
                              secondary={
                                <div
                                  className={clsx({
                                    [classes.onlineStatusIcon]: item.isOnline,
                                    [classes.offlineStatusIcon]: !item.isOnline,
                                  })}
                                >
                                  {item.loggedIn
                                    ? <Typography variant="subtitle2" sx={{
                                      fontSize: '10px',
                                      color: "green",

                                    }}>User is logged in</Typography>
                                    : <Typography variant="subtitle2" sx={{
                                      fontSize: '10px',
                                      color: "red",

                                    }}>User is logged out</Typography>

                                  }
                                </div>
                              }
                            />
                          </Container>
                        </Container>
                        <div style={{ marginRight: "20px" }}>
                          <img
                            src={iconRight}
                            style={{
                              width: "15px",
                              transform: "rotate(90deg)",
                            }} alt=""
                          />
                        </div>
                        <Divider />
                      </ListItem>


                    </>



                  );
                }}
                rowCount={dataArray.length}
                height={height}
                width={width}
              ></List>
            )}
          </AutoSizer>
        </div>

      )}



    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.UsersListReducer,
    ...state.AuthReducer,
    ...state.ManagersListReducer,
    ...state.AdminDataReducer,
  };
};
export default connect(mapStateToProps, {
  setManagersListData,
  setUsersListSearchInputText,
  setUsersListSearchText,
  setUsersListData,
  setUsersListSubscriptionData,
  setUsersListContextMenuPosAndObjectDetails,
  setUsersListSelectedUser,
  setChatBoxRecentChatListShowAllListToggle,
  setAllUsersData,
})(ManagerList);
