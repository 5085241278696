import React, { useEffect } from "react";
//
import { gql, useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
//
import Typography from "@mui/material/Typography";
//
import { useSnackbar } from "notistack";
//
import useStyles from "./Styles";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button";
import SignUpTop from "../shared/atom/signUpTop/SignUpTop";
import Heading from "../shared/atom/heading/Heading";
import { Text, EmailText } from "../shared/atom/text/Text";
import KuikTextField from "../shared/atom/kuikTextField/TextField";
import AuthButton from "../shared/atom/button/AuthButton";
import SignUpFooter from "../shared/atom/signUpFooter/SignUpFooter";

const verifyCodeMutation = gql`
  mutation verifyCode($email: String!, $randomCode: String!) {
    verifyCode(email: $email, randomCode: $randomCode) {
      success
      error
    }
  }
`;
const EmailConfirmRightComponent: React.FC = () => {
  //
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  console.log("location in emailconfirmation",location)
  //
  const { initialMinute = 0, initialSeconds = 0 } = {
    initialMinute: 0,
    initialSeconds: 20,
  };
  const [minutes, setMinutes] = React.useState<number>(initialMinute);
  const [seconds, setSeconds] = React.useState(initialSeconds);
  const [isResendBtnDisable, setIsResendBtnDisable] = React.useState(true);
  const [isDisableConfirm, setIsDisableConfirm] = React.useState(true);
  const [code, setCode] = React.useState<string>();

  const verifyCodeMutation = gql`
    mutation verifyCode($email: String!, $randomCode: String!) {
      verifyCode(email: $email, randomCode: $randomCode) {
        success
        error
      }
    }
  `;
  const [
    verifyCode,
    {
      loading: verifyCodeMutationLoading,
      error: verifyCodeMutationError,
      data: verifyCodeMutationResult,
    },
  ] = useMutation(verifyCodeMutation);

  useEffect(() => {
    if (verifyCodeMutationError) {
      verifyCodeMutationError.graphQLErrors.map(({ message }, i) => {
        enqueueSnackbar(message, { variant: "error" });
      });
    }
  }, [verifyCodeMutationError]);

  useEffect(() => {
    console.log("confirmation result: ",verifyCodeMutationResult)
    if (verifyCodeMutationResult && verifyCodeMutationResult.verifyCode) {
      if (
        verifyCodeMutationResult.verifyCode &&
        verifyCodeMutationResult.verifyCode.error
      ) {
        enqueueSnackbar(verifyCodeMutationResult.verifyCode.error, {
          variant: "error",
        });
      }
      if (verifyCodeMutationResult.verifyCode.success) {
        enqueueSnackbar("Email confirmed successfully.", {
          variant: "success",
        });

        // history.push({
        //   pathname: "/",
        // });
        history.push("/chats");
      }
    }
  }, [verifyCodeMutationResult]);

  const resendCodeMutation = gql`
    mutation resendCode($email: String!) {
      resendCode(email: $email) {
        success
        error
      }
    }
  `;
  const [
    resendCode,
    {
      loading: resendCodeMutationLoading,
      error: resendCodeMutationError,
      data: resendCodeMutationResult,
    },
  ] = useMutation(resendCodeMutation);

  useEffect(() => {
    if (resendCodeMutationResult && resendCodeMutationResult.resendCode) {
      if (resendCodeMutationResult.resendCode.success) {
        enqueueSnackbar("code sent successfully", { variant: "success" });
      }
    }
  }, [resendCodeMutationResult]);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          setIsResendBtnDisable(false);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });
  useEffect(() => {
    if (code) {
      if (code.length < 6) {
        setIsDisableConfirm(true);
      } else {
        setIsDisableConfirm(false);
      }
    }
  }, [code]);

  const resendCodeHandler = async () => {
    setMinutes(0);
    setSeconds(59);
    setIsResendBtnDisable(true);
    await resendCode({
      variables: {
        email: location.state.email,
      },
    });
  };

  //
  const { classes } = useStyles();
  // interface for the form values
  interface FormValues {
    code: string;
  }

  // validation schema using Yup
  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .min(6, "Code must be at least 6 numbers")
      .required("Code is required"),
  });

  const handleSubmit = async (
    values: FormValues,
    // { setSubmitting }: FormikHelpers<FormValues>
    { setSubmitting }: any
  ) => {
    // handle form submission
    setSubmitting(false);
    //
    setCode(values.code);
    console.log("confirmation code",values.code)
    console.log("confirmation email",location.state.email)
    //
    try {
      await verifyCode({
        variables: {
          email: location.state.email,
          randomCode: values.code,
        },
      });
    } catch (e) {
      console.log("error in submitting", e);
    }
  };
  return (
    <>
      <Box className={classes.root}>
        <SignUpTop />
        <Heading heading="Confirm Your Email" />
        <Typography className={classes.confirmText}>
          An email with a verification code has been sent to
        </Typography>
        {/* <EmailText text={`${location.state.email}`} /> */}
        <EmailText
          text={`${location.state ? location.state.email : "No email address"}`}
        />
        <Formik
          initialValues={{ code: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik: any) => (
            <Box
              component="form"
              onSubmit={formik.handleSubmit}
              className={classes.form}
            >
              <KuikTextField
                id="number"
                label="Enter code"
                name="code"
                value={formik.values.code}
                onChange={formik.handleChange}
                helperText={formik.touched.code && formik.errors.code}
              />
              <AuthButton
                text="Confirm Email"
                loading={verifyCodeMutationLoading}
              />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography className={classes.confirmText}>
                  Didn't receive the email?
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Typography className={classes.confirmText}>
                    You can resend the code in
                  </Typography>
                  {/* <Typography className={classes.confirmCode}>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</Typography> */}
                  {seconds !== 0 ? (
                    <Typography className={classes.confirmCode}>
                      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Formik>

        <Button
          disabled={seconds !== 0 ? true : false}
          sx={{
            fontWeight: "500",
            cursor: "pointer",
            marginTop: "5px",
            //
            backgroundColor: seconds === 0 ? "grey" : "white",
            padding: "1px 7px",
            borderRadius: "3px",
            color: "black",
            textTransform: "capitalize",
            boxShadow: "none",
            "&: hover": {
              backgroundColor: "grey",
            },
          }}
          onClick={() => resendCodeHandler()}
        >
          Resend Email
        </Button>

        <SignUpFooter route="/login" text="Sign in" />
      </Box>
    </>
  );
};

export default EmailConfirmRightComponent;
