import * as React from "react";

import {
  OutlinedInput,
  Box,
  Modal,
  Typography,
  TextareaAutosize,
  Button,
} from "@mui/material";
import { FormEvent } from "react";
//
import useStyles from "./Styles";
//
import FormHelperText from "@mui/material/FormHelperText";
import { useFormik } from "formik";
import * as Yup from "yup";
//
const style = {
  // position: 'absolute' as 'absolute',
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //
  width: "400px",
  // height: "350px",
  height: "auto",
  maxHeight: "385px",
  //
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)",
  borderRadius: "3px",
};

interface Props {
  check: boolean;
  closeModal: () => void;
  setdata: (data: { text: string; shortcut: string }) => void;
}

const KeepMountedModal: React.FC<Props> = ({ check, closeModal, setdata }) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    //closing Modal
    setOpen(false);
    //closing the dropdown
    closeModal();
    // setOpen(false)
  };
  //
  React.useEffect(() => {
    setOpen(check);
  }, [check]);
  //

  const [message, setMessage] = React.useState("");
  const [message2, setMessage2] = React.useState("");

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    //
    setdata({ text: message, shortcut: message2 });
    //
    setMessage("");
    setMessage2("");
    handleClose();
  };
  //

  const fieldStyle = {
    width: "100%",
    height: "30px",
    border: "0.0px solid #E8E8E8",
    borderRadius: "3px",
    background: "#FFFFFF",
    //
    marginTop: "5px",
    marginBottom: "0px",
  };

  const { classes } = useStyles();

  /////////////////////////////////////////////

  const [initialFormikValues, setInitialFormikValues] = React.useState({
    text: "",
    shortcut: "",
  });

  const validationSchema = Yup.object().shape({
    text: Yup.string().required("text is required"),
    shortcut: Yup.string().required("shortcut is required"),
  });

  interface FormValuesProps {
    text: string;
    shortcut: string;
  }

  const updatedHandleSubmit = async (
    values: FormValuesProps,
    formikHelpers: any
  ) => {
    formikHelpers.setSubmitting(false);
    try {
      //
      setdata({ text: values.text, shortcut: values.shortcut });
      formikHelpers.resetForm();
      handleClose();
      //
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues: initialFormikValues,
    validationSchema: validationSchema,
    onSubmit: updatedHandleSubmit,
  });

  /////////////////////////////////////////////

  return (
    <div>
      <span style={{ display: "none" }}>Add new</span>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        onClick={(event) => {
          event.stopPropagation();
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          {/* <form onSubmit={handleSubmit} className={classes.boxForm}> */}
          <Box component="form" onSubmit={formik.handleSubmit} className={classes.boxForm}>
            <Typography
              sx={{
                width: "100%",
                height: "24px",
                fontFamily: "poppins",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#272525",
              }}
              variant="body1"
            >
              Create Canned Response
            </Typography>

            <Typography
              sx={{
                width: "100%",
                height: "36px",
                fontFamily: "poppins",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "18px",
                color: "#838383",
                //
                marginTop: "5px",
              }}
              variant="body1"
            >
              Add a text shortcut to quickly insert this reply. Shortcuts must
              be at least three characters.
            </Typography>

            <Typography
              sx={{
                width: "100%",
                height: "18px",
                fontFamily: "poppins",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "18px",
                color: "#272525",
                //
                marginTop: "10px",
              }}
              variant="body1"
            >
              Responses text
            </Typography>

            <TextareaAutosize
              className={classes.root}
              // value={message}
              // onChange={(event) => setMessage(event?.target.value)}
              //
              name="text"
              value={formik.values.text}
              onChange={formik.handleChange}
              onError={formik.touched.text && Boolean(formik.errors.text)}
              //
              placeholder="Canned responses message"
              style={{
                width: "100%",
                height: "100px",
                resize: "none",
                marginTop: "5px",
                background: "#FFFFFF",
                border: "0.5px solid #E8E8E8",
                borderRadius: "3px",
                padding: "5px",
                //
                fontFamily: "poppins",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "18px",
                color: "#272525",
              }}
            />
            {formik.touched.text && formik.errors.text && (
              <FormHelperText
                style={{
                  fontWeight: "400",
                  fontFamily: "poppins",
                  fontSize: "12px",
                  lineHeight: "18px",
                }}
                error
              >
                {formik.errors.text}
              </FormHelperText>
            )}

            <Typography
              sx={{
                width: "100%",
                height: "18px",
                fontFamily: "poppins",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "18px",
                color: "#272525",
                //
                marginTop: "10px",
              }}
              variant="body1"
            >
              Shortcut
            </Typography>

            <OutlinedInput
              className={classes.root}
              style={fieldStyle}
              // value={message2}
              // onChange={(event) => setMessage2(event.target.value)}
              //
              name="shortcut"
              value={formik.values.shortcut}
              onChange={formik.handleChange}
              onError={
                formik.touched.shortcut && Boolean(formik.errors.shortcut)
              }
              //
              placeholder="Add shortcut"
              inputProps={{
                style: {
                  width: "100%",
                  height: "30px",
                  fontFamily: "poppins",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#272525",
                  //
                  paddingLeft: "5px",
                },
              }}
            />

            {formik.touched.shortcut && formik.errors.shortcut && (
              <FormHelperText
                style={{
                  fontWeight: "400",
                  fontFamily: "poppins",
                  fontSize: "12px",
                  lineHeight: "18px",
                }}
                error
              >
                {formik.errors.shortcut}
              </FormHelperText>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "25px",
              }}
            >
              <Button
                onClick={handleClose}
                variant="contained"
                color="primary"
                sx={{
                  width: "66px",
                  height: "32px",
                  background: "#F5F5F5",
                  borderRadius: "3px",
                  fontFamily: "poppins",
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#777777",
                  marginRight: "5px",
                  "&:hover": {
                    color: "#777777",
                    backgroundColor: "#F5F5F5",
                  },
                  //
                  textTransform: "capitalize",
                  boxShadow: "none",
                }}
                //
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  width: "65px",
                  height: "32px",
                  backgroundColor: "#4D1277",
                  borderRadius: "3px",
                  fontFamily: "poppins",
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#FFFFFF",
                  //
                  textTransform: "capitalize",
                  boxShadow: "none",
                }}
              >
                Save
              </Button>
            </div>
          </Box>
          {/* </form> */}
        </Box>
      </Modal>
    </div>
  );
};

export default KeepMountedModal;
