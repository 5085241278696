import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Avatar, CardHeader, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Typography from '@mui/material/Typography';
import profile from '../../../../../../assets/img/profile.jpg';
import useStyle from './Style';
import {Link} from 'react-router-dom';
import SnackBar from '../../../../snackBar/SnackBar';
//
interface Props{
  username:any
  getTableRow: any
  getProfile: any
  getDisconnect: any
}
const InternalPage: React.FC<Props>  = ({username,getTableRow, getProfile, getDisconnect}) => {
  const { classes } = useStyle();
  const [pageStatus, setPageStatus] = React.useState<boolean>(false);
  const pageConnectivityHandle = () => {
    setPageStatus(!pageStatus);
  }

  return (
    <Card sx={{ minWidth: 275 }} style={{height:'calc(100vh - 112px)', borderRadius:'10px', boxShadow: '0px 5px 10px rgba(127, 63, 152, 0.1)', overflowY: 'auto'}}>
      <CardHeader title="Accept chats from Facebook Messenger" />
      <CardContent className={classes.cardContent}>
         <Box className={classes.profileSectionBox}>
            <Typography sx={{display:'inline-block'}} component={'p'} className={classes.text}>
              Connected to Facebook as:&nbsp;&nbsp;
            </Typography>
              <Avatar src={getProfile ? getProfile : profile} className={classes.profileSection} />
            <Typography sx={{display:'inline-block'}} component={'p'} className={classes.text}>
            {/* &nbsp;&nbsp;Wayne Scott&nbsp;&nbsp; */}
            &nbsp;&nbsp;{username}&nbsp;&nbsp;
            </Typography>
            <Link className={classes.disconnectLinl} onClick={()=>getDisconnect(false)}>Disconnect</Link>
         </Box>
         <Box>
            <Table className={classes.tableStyle}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} className={classes.pageName}>
                    PAGE NAME
                  </TableCell>
                  <TableCell>                    
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  getTableRow
                }
              </TableBody>
            </Table>
         </Box>
         {
          pageStatus ? 
          <SnackBar text='Successfully connected Page!' coolor='green' snackbarStatus={pageStatus} />
          :
          <SnackBar text='Page disconnected!' coolor='#E34D59' snackbarStatus={pageStatus} />
        }
      </CardContent>
    </Card>
  )
}

export default InternalPage
