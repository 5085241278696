import { makeStyles } from 'tss-react/mui'
const useStyles = makeStyles()(() => ({

    icon: {
        // marginLeft: 1.5,
        height: '21px',
        width: '21px',
        marginBottom: 5,
      },
}))
export default useStyles
