
import { Box, IconButton, Menu, } from '@mui/material'
import React, { useState, } from 'react';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import useStyles from './Styles'

interface Props {
    onEmojiClick?: any;
}

const EmojiModal: React.FC<Props> = ({ onEmojiClick }) => {
    const { classes } = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleanchorE1Open = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleanchorE1Open} className={classes.icon} >
                <EmojiEmotionsOutlinedIcon color="info" />
            </IconButton>
            <Menu anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}

                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >

                <Box sx={{ height: 300, width: 300 }}>

                    <Picker searchPosition='none' data={data} onEmojiSelect={onEmojiClick} />
                </Box>
            </Menu>
        </>
    )
}

export default EmojiModal