import { makeStyles } from 'tss-react/mui'
const useStyles = makeStyles()(() => ({
    outgoingMessage: {
        position: "relative",
        // borderRadius: "20px",
        borderRadius: "5px 5px 0px 5px",
        display: "inline-block",
        color: "#fff !important",
        // padding: "10px 25px",
        margin: "5px 17px 8px 5px !important",
        marginLeft: "100px",
        marginRight: "35px",
        whiteSpace: "pre-wrap",
        // fontSize: "15.5px",
        fontFamily: "Poppins",
        maxWidth: "355px",
        // borderRadius: 1,
        fontSize: 14,
        // whiteSpace: 'normal',
        padding: 10,
        // color: '#FFFFFF',
        backgroundColor: '#4D1277',
      },
      outgoingMessageContainer: {
        // display: "flex",
        justifyContent: "flex-end",
        marginRight: "1%",
        display: 'flex',
        // maxWidth: '50%',
        // padding: 0.5,
        borderRadius: '1px 1px 0px 1px',
        // alignSelf: 'flex-end',
        // backgroundColor: orange[500],
        color: 'black',
      },
      errorMessage: {
        color: "#f50057",
        marginTop: "auto",
      },
      messageTimeStamp: {
        display: "block",
        width: "95%",
        margin: "20px auto 20px auto",
        textAlign: "center",
      },
      messageTimeStampText: {
        color: "#A0A0A0",
        display: "block",
        fontFamily: "Poppins",
        fontSize: 10,
        fontWeight: 400
      },
      customerIcon2: {
        marginLeft: "10px",
        width: "10px !important",
        height: "10px !important",
        marginBottom: "-5%",
      },
      seenMessageIcon: {
        width: "14px !important",
        // width: "100%",
        // height: "100%",
    
        // left: -40,
        // border: "1px solid red",
        height: "14px !important",
        // marginLeft: "36%",
        // marginBottom: "-120%",
        // marginTop: "-133%",
        // fontSize: 10,
    
      },
      seenMessageIconContainer: {
        border: "1px solid gray",
        alignSelf: "flex-end",
        borderRadius: "50%",
        display: "flex",
        width: "14px !important",
        marginBottom: "11px",
        marginLeft: "-13px",
      },
      chatMessageProgress: {
        width: "19px!important",
        height: "19px!important",
        marginTop: "auto",
        marginBottom: "auto",
        color: "#4D1277",
      },
    
      messageTextBox: {
        margin: "0",
        padding: "0 6px",
        outline: "none",
        font: "inherit",
        maxHeight: 100,
        flex: 1,
        minHeight: 40,
        paddingTop: 8,
        // paddingLeft: 8,
        paddingRight: "50px",
        paddingLeft: "15px",
        overflow: "auto!important",
        borderRadius: "20px",
        border: "0px solid lightgrey",
        width: "100%",
        resize: "none",
        marginBottom: "-6px",
        fontFamily: "Poppins",
      },
      imageoutgoing: {
        background: "none",
        // borderRadius: "10%",
        margin: "5px 15px 5px 5px !important",
      },
      unreadMessage: {
        background: "red",
      },
      readMessage: {
        background: "red",
      },
      deliveredMessageIconContainer: {
        border: "1px solid gray",
        alignSelf: "flex-end",
        borderRadius: "50%",
        display: "flex",
        marginBottom: "11px",
        marginLeft: "-13px",
      },
      deliveredMessageIcon: {
        fontSize: 10,
      },
}))
export default useStyles
