import React, { useEffect, useRef } from "react";
import _ from "lodash";
import {
  Card,
  Box,
  Avatar,
  CardHeader,
  CardContent,
  List,
  ListItem,
  Button,
  Typography,
  TableRow,
  TableCell,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import MoodIcon from "@mui/icons-material/EmojiEmotions";
import HelpIcon from "@mui/icons-material/Help";
import profile from "../../../../../assets/img/Messenger.svg";
import useStyle from "./Style";
import expressConfig from "../../../../../config/express.json";

import avatar from "../../../../../assets/setting/avatar.png";

import disconnectIcon from "../../../../../assets/setting/page-disconnected-02.svg";
import removeIcon from "../../../../../assets/setting/close-02.svg";

import axios from "axios";
import { useSnackbar } from "notistack";
import { Facebook } from "../../../../../auth/Facebook";
import { CircularProgress } from "@material-ui/core";
import InternalPage from "./internalPage/InternalPage";
// import { color } from "console-log-colors";
import Modal from "../../../Modal/Modal";
import { FacebookLoginResponse, FacebookRespose } from "./types/fbTypes";
import {
  useAddPagesMutation,
  useConnectPageMutation,
  useDeletePageMutation,
  useDisConnectPageMutation,
  useGetPagesQuery,
} from "./fbQueries/fbQueries";
//
import CloseIcon from "@mui/icons-material/Close";
//
declare global {
  interface Window {
    FB: any;
  }
}

const SettingFacebook: React.FC = () => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [isDelete, setIsDelete] = React.useState<boolean>(false);
  const activeUsers = localStorage.getItem("ActiveUserdetail");
  const activeUserData = activeUsers && JSON.parse(activeUsers);

  const { enqueueSnackbar } = useSnackbar();
  const env = process.env.NODE_ENV || "development";
  const config = expressConfig[env];
  const verticalRef: any = useRef(null);
  const [paraText, setParaText] = React.useState("");
  const [btnText, setBtnText] = React.useState("");
  const [imgSrc, setImgSrc] = React.useState<string>("");
  const [pagesData, setPagesData] = React.useState<any[]>([]);

  const [actionPageData, setActionPageData] = React.useState();
  const [loginData, setLoginData] = React.useState<any>({});
  const [connectToFbOn, setConnectToFbOn] = React.useState(false);
  const [loadingWindow, setLoadingWindow] = React.useState(true);

  const [
    getPages,
    { loading: getPagesQueryLoading, data: getPagesQueryResult },
  ] = useGetPagesQuery();

  useEffect(() => {
    const pages: any = [];
    if (getPagesQueryResult && getPagesQueryResult.getAllPages) {
      getPagesQueryResult.getAllPages.forEach((curr: any, index: number) =>
        pages.push({
          order: index,
          ...curr,
        })
      );
      setPagesData(pages);
      setConnectToFbOn(true);
    }
  }, [getPagesQueryResult]);

  const [
    disconnectPage,
    {
      loading: disConnectPageMutationLoading,
      error: disConnectPageMutationError,
      data: disConnectPageMutationResult,
    },
  ] = useDisConnectPageMutation();

  useEffect(() => {
    if (disConnectPageMutationResult && disConnectPageMutationResult) {
      const updatedPagesData =
        pagesData && pagesData.length
          ? pagesData.map((element) => {
            if (element.pageId == actionPageData)
              return {
                ...element,
                isSelected: false,
              };
            else return element;
          })
          : [];

      setPagesData(updatedPagesData);
    }
  }, [disConnectPageMutationResult]);

  useEffect(() => {
    if (disConnectPageMutationError) {
      disConnectPageMutationError.graphQLErrors.map(({ message }, i) => {
        enqueueSnackbar(message, { variant: "error" });
      });
    }
  }, [disConnectPageMutationError]);

  const [
    connectPage,
    {
      loading: connectPageMutationLoading,
      error: connectPageMutationError,
      data: connectPageMutationResult,
    },
  ] = useConnectPageMutation();

  useEffect(() => {
    if (connectPageMutationResult) {
      //
      console.log("actionPageData", actionPageData);
      const updatedPagesData =
        pagesData && pagesData.length
          ? pagesData.map((element) => {
            if (element.pageId == actionPageData) {
              console.log("got true", element.pageId);
              return {
                ...element,
                isSelected: true,
              };
            } else return element;
          })
          : [];
      setPagesData(updatedPagesData);
      //
      // const updatedPagesData =
      //   pagesData && pagesData.length
      //     ? pagesData.map((element) => {
      //         if (element.pageId == actionPageData)
      //           return {
      //             ...element,
      //             isSelected: true,
      //           };
      //         else return element;
      //       })
      //     : [];

      // setPagesData(updatedPagesData);
    }
  }, [connectPageMutationResult]);

  useEffect(() => {
    console.log("pagesData has been changed", pagesData);
  }, [pagesData]);

  useEffect(() => {
    if (connectPageMutationError) {
      connectPageMutationError.graphQLErrors.map(({ message }, i) => {
        enqueueSnackbar(message, { variant: "error" });
      });
    }
  }, [connectPageMutationError]);

  const [
    addPages,
    {
      loading: addPagesMutationLoading,
      error: addPagesMutationError,
      data: addPagesMutationResult,
    },
  ] = useAddPagesMutation();

  useEffect(() => {
    if (addPagesMutationError) {
      addPagesMutationError.graphQLErrors.map(({ message }, i) => {
        enqueueSnackbar(message, { variant: "error" });
      });
    }
  }, [addPagesMutationError]);

  useEffect(() => {
    if (addPagesMutationResult && addPagesMutationResult.addpages) {
      if (addPagesMutationResult.addpages.success) {
      } else {
        enqueueSnackbar(addPagesMutationResult.addpages.error, {
          variant: "error",
        });
      }
    }
  }, [addPagesMutationResult]);
  useEffect(() => Facebook.fbInt(), []);

  const [
    deletepage,
    {
      loading: deletepageMutationLoading,
      error: deletepageMutationError,
      data: deletepageMutationResult,
    },
  ] = useDeletePageMutation();

  useEffect(() => {
    if (deletepageMutationResult && deletepageMutationResult) {
      const pagesDataWithoutCurrData: any =
        pagesData &&
        pagesData.length &&
        pagesData.filter((curr: any) => curr.pageId !== actionPageData);
      setPagesData(pagesDataWithoutCurrData);
      // verticalRef.current.alterModalShow();
      setIsDelete(false);
      enqueueSnackbar("page has been removed", { variant: "success" });
    }
  }, [deletepageMutationResult]);

  useEffect(() => {
    if (deletepageMutationError) {
      deletepageMutationError.graphQLErrors.map(({ message }, i) => {
        enqueueSnackbar(message, { variant: "error" });
      });
    }
  }, [deletepageMutationError]);

  useEffect(() => {
    getPages({
      variables: {
        mainSuperAdminId: activeUserData.mainSuperAdminId,
      },
    });
  }, []);

  const colorConnected = {
    color: "#22AA61",
    backgroundColor: "#ecf7f3",
    borderColor: "#22AA61",
    borderRadius: "3px",
    //
    width: "116px",
    height: "32px",
    //
    textTransform: "none",
    padding: "0px",
    //
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
  };
  const colorDisconnected = {
    color: "#E34D59",
    backgroundColor: "#fff2f3",
    borderColor: "#E34D59",
    borderRadius: "3px",
    //
    width: "131px",
    height: "32px",
    //
    textTransform: "none",
    padding: "0px",
    //
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
  };
  const { classes } = useStyle();
  const disconnectedHandler = (data: any) => {
    setModalOpen(true);
    setParaText("Do you really want to disconnect this page?");
    setBtnText("Disconnect Page");
    setImgSrc(disconnectIcon);
    setActionPageData(data.pageId);
  };

  const closeHandler = (data: any) => {
    setImgSrc(removeIcon);
    setParaText("Do you really want to remove this page?");
    setBtnText("Remove");
    // verticalRef.current.alterModalShow();
    setActionPageData(data.pageId);
    //
    setIsDelete(true);
    //
    setModalOpen(true);
    setParaText("Do you really want to remove this page?");
    setBtnText("Remove Page");
  };

  const connectedHandler = (data: any) => {
    console.log("connected page...", data);
    setActionPageData(data.pageId);
    connectPage({
      variables: {
        pageId: data.pageId,
      },
    });
  };

  const disConnectApiCallHandler = () => {
    disconnectPage({
      variables: {
        pageId: actionPageData,
      },
    });
  };
  const removeApiCallHandler = () => {
    deletepage({
      variables: {
        pageId: actionPageData,
      },
    });
  };

  useEffect(() => {
    setLoadingWindow(true);
  }, []);

  useEffect(() => {
    console.log("loginData has been changed", loginData);
  }, [loginData]);

  const pages =
    pagesData &&
    pagesData.length &&
    pagesData.map((curr: any) => (
      <TableRow key={curr.id}>
        <TableCell sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            src={curr.picture ? curr.picture : avatar}
            variant="square"
            style={{ height: "35px", width: "35px", borderRadius: "3px" }}
          />
          <Typography
            component={"p"}
            sx={{ display: "inline-block" }}
            className={classes.pageName}
          >
            {curr.name}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Button
            variant="outlined"
            onClick={
              curr.isSelected
                ? () => disconnectedHandler(curr)
                : () => connectedHandler(curr)
            }
            sx={curr.isSelected ? colorDisconnected : colorConnected}
          >
            {curr.isSelected ? "Disconnect Page" : "Connect Page"}
          </Button>
        </TableCell>
        <TableCell>
          <CloseIcon
            onClick={() => closeHandler(curr)}
            sx={{ height: "18px", width: "18px", cursor: "pointer" }}
          />
        </TableCell>
      </TableRow>
    ));

  const connectToFB = () => {
    console.log("connecting to facebook")
    if (typeof window.FB !== "undefined") {
      window.FB.login((responseLogin: FacebookLoginResponse) => {
        if (responseLogin.authResponse) {
          window.FB.api("/me", function (responses: FacebookRespose) {
            window.FB.api(
              `/${responses.id}/picture?redirect=false`,
              "GET",
              function (response: any) {
                // Insert your code here
                setLoginData({
                  name: responses.name,
                  picture: response.data.url,
                });
                setLoadingWindow(false);
              }
            );
          });
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      });
    }
  };

  // const connectToFB = () => {
  //   window.FB.login((responseLogin: FacebookLoginResponse) => {
  //     if (responseLogin.authResponse) {
  //       window.FB.api("/me", function (responses: FacebookRespose) {
  //         window.FB.api(
  //           `/${responses.id}/picture?redirect=false`,
  //           "GET",
  //           function (response: any) {
  //             // Insert your code here
  //             setLoginData({
  //               name: responses.name,
  //               picture: response.data.url,
  //             });
  //             setLoadingWindow(false);
  //           }
  //         );
  //       });
  //     } else {
  //       console.log("User cancelled login or did not fully authorize.");
  //     }
  //   });
  // };

  const connectToFacebookHandler = () => {
    window.FB.login(
      (responseLogin: FacebookLoginResponse) => {
        var longAccessToken = null;
        if (responseLogin.status === "connected") {
          axios
            .get(
              `https://graph.facebook.com/oauth/access_token?  
                  grant_type=fb_exchange_token&          
                  client_id=${config.facebook_app_id}&
                  client_secret=${config.facebook_app_secret}&
                  fb_exchange_token=${responseLogin.authResponse.accessToken}`
            )
            .then((response) => {
              longAccessToken = response.data.access_token;

              if (longAccessToken) {
                window.FB.api(
                  `/${responseLogin.authResponse.userID}/accounts?fields=name,picture,access_token&access_token=${longAccessToken}`,
                  (responseAccount: FacebookRespose) => {
                    console.log(responseAccount);
                    if (responseAccount && !responseAccount.error) {
                      responseAccount.data.map((curr: FacebookRespose) =>
                        axios
                          .post(
                            `https://graph.facebook.com/${curr.id}/subscribed_apps?subscribed_fields=messages,messaging_postbacks,message_reads,messaging_payments,message_deliveries,message_echoes,messaging_checkout_updates,standby,messaging_handovers,message_reactions,feed,inbox_labels,messaging_feedback&access_token=${curr.access_token}`
                          )
                          .then((impres) => setConnectToFbOn(true))
                      );
                      var filterData: any = responseAccount.data;
                      console.log(filterData, "filterData after");
                      console.log(pagesData, "pagesData ");

                      pagesData?.map((item: any) => {
                        filterData = _.filter(
                          filterData,
                          (responseData) => responseData.id !== item.pageId
                        );
                      });
                      console.log(filterData, "filterData before")

                      if (filterData && filterData.length != 0) {
                        const createPageObjectValue: any = [];
                        filterData.forEach((item: any) =>
                          createPageObjectValue.push({
                            name: item.name,
                            pageId: item.id,
                            accesstoken: item.access_token,
                            picture: item.picture.data.url,
                            mainSuperAdminId: activeUserData.mainSuperAdminId,
                          })
                        );

                        addPages({
                          variables: {
                            objects: createPageObjectValue,
                          },
                        });
                        setPagesData([...createPageObjectValue, ...pagesData]);
                      }
                    } else if (responseAccount && responseAccount.error) {
                    }
                  }
                );
              }
            })
            .catch((error) => {
              console.log("long lived token error ", error);
            });
        }
      },
      {
        scope:
          "pages_manage_metadata,pages_show_list,pages_messaging,pages_read_engagement",
      },
      { auth_type: "reauthenticate" }
    );
  };
  return (
    <Box>
      {true && (
        <Box>
          {!connectToFbOn && !loadingWindow && (
            <Card
              sx={{ minWidth: 275 }}
              style={{
                height: "calc(100vh - 112px)",
                borderRadius: "10px",
                overflowY: "auto",
              }}
            >
              <CardHeader
                title="Accept chats from Facebook Messenger"
                style={{
                  fontFamily: "poppins",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "27px",
                  color: "#272525",
                }}
              />
              <CardContent className={classes.cardContent}>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: "10px" }}>
                    <Avatar
                      src={profile}
                      variant="square"
                      className={classes.avtarStyle}
                    />
                  </Box>
                  <Box>
                    <Typography className={classes.heading}>
                      Connect your Facebook page
                    </Typography>
                    <Typography component="p" className={classes.text}>
                      Handle your Messenger conversations directly in your
                      Kuikwit panel and answer quickly to your customer’s
                      questions. Less distraction with switching platforms, more
                      productivity.
                    </Typography>
                    <Button
                      variant="contained"
                      className={classes.fbButton}
                      onClick={connectToFacebookHandler}
                    >
                      connect to facebook
                    </Button>
                  </Box>
                </Box>
                <Box sx={{ marginTop: "20px" }}>
                  <Box sx={{ display: "flex" }}>
                    <Box>
                      <MoodIcon className={classes.emojies} color="disabled" />
                    </Box>
                    <Box>
                      <Typography classes={classes.heading}>
                        Integration benefits
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <List sx={{ paddingTop: "0px" }}>
                      <ListItem sx={{ padding: "0px" }}>
                        <Box>
                          <DoneIcon className={classes.tickMark} />
                        </Box>
                        <Typography component="p" className={classes.text}>
                          You and your team members can see all Messenger
                          communication in one place.
                        </Typography>
                      </ListItem>
                      <ListItem sx={{ padding: "0px" }}>
                        <Box>
                          <DoneIcon className={classes.tickMark} />
                        </Box>
                        <Typography component="p" className={classes.text}>
                          Don't miss any messages from Messenger - thanks to
                          desktop notifications.
                        </Typography>
                      </ListItem>
                      <ListItem sx={{ padding: "0px" }}>
                        <Box>
                          <DoneIcon className={classes.tickMark} />
                        </Box>
                        <Typography component="p" className={classes.text}>
                          Easy to integrate, no-code required, takes less than 2
                          minutes..
                        </Typography>
                      </ListItem>
                    </List>
                  </Box>
                </Box>
                <Box sx={{ marginTop: "20px" }}>
                  <Box sx={{ display: "flex" }}>
                    <Box>
                      <HelpIcon className={classes.emojies} color="disabled" />
                    </Box>
                    <Box>
                      <Typography classes={classes.heading}>
                        How it works
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    component="p"
                    sx={{ marginTop: "5px" }}
                    className={classes.text}
                  >
                    By integrating Messenger with Kuikwit, you receive all your
                    fan/business page's messages in one inbox. It means you can
                    handle your conversations across channels more efficiently
                    without the risk of losing context. Your data is safe and
                    secure - no third-party apps have access to it.
                  </Typography>
                </Box>
                <Box sx={{ marginTop: "20px" }}>
                  <Box sx={{ display: "flex" }}>
                    <Box>
                      <HelpIcon className={classes.emojies} color="disabled" />
                    </Box>
                    <Box>
                      <Typography classes={classes.heading}>
                        How to connect
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ marginTop: "5px" }}>
                    <Typography component="p" className={classes.text}>
                      Kuikwit integrates with Messenger in just two quick
                      steps:.
                    </Typography>
                    <Typography
                      component={"p"}
                      className={classes.howToConnect}
                    >
                      1. Click the button “Connect Facebook Messenger”.
                    </Typography>
                    <Typography
                      component={"p"}
                      className={classes.howToConnect}
                    >
                      2. Log in to your Facebook account and choose a fan page
                      you would like to integrate with.
                    </Typography>
                    <Typography component="p" className={classes.text}>
                      Next time you receive a message from your fan page, it
                      will land in your chats panel inbox, with other messages
                      from your communication channels.
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          )}
          {connectToFbOn && !loadingWindow && (
            <>
              {getPagesQueryLoading ? (
                <CircularProgress
                  className={classes.loadingCircularProgress}
                  size={24}
                />
              ) : (
                <InternalPage
                  username={loginData.name}
                  getTableRow={pages}
                  getProfile={loginData.picture}
                  getDisconnect={setConnectToFbOn}
                />
              )}
            </>
          )}
          {loadingWindow && (
            <>
              <Card
                sx={{ minWidth: 275 }}
                style={{
                  height: "calc(100vh - 112px)",
                  borderRadius: "10px",
                  overflowY: "auto",
                }}
              >
                <CardHeader
                  title="Accept chats from Facebook Messenger"
                  style={{
                    fontFamily: "poppins",
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "27px",
                    color: "#272525",
                  }}
                />
                <CardContent className={classes.cardContent}>
                  <div
                    style={{
                      width: "100%",
                      height: "calc(100vh - 230px)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <CircularProgress
                      style={{ color: "green", marginBottom: "10px" }}
                    />
                    <button
                      style={{
                        border: "1px solid green",
                        background: "none",
                        fontFamily: "poppins",
                        fontSize: "13px",
                        padding: "5px 15px",
                      }}
                      onClick={connectToFB}
                    >
                      Connect To Facebook
                    </button>
                  </div>
                </CardContent>
              </Card>
            </>
          )}
          <Modal
            btnText={btnText}
            img={imgSrc}
            modalClose={setModalOpen}
            modalOpen={modalOpen}
            removeItem={
              isDelete ? removeApiCallHandler : disConnectApiCallHandler
            }
            text={paraText}
          />
        </Box>
      )}
    </Box>
  );
};

export default SettingFacebook;
