import React from 'react'
import Typography from '@mui/material/Typography'
//
import theme from '../../../style/theme'

interface Props {
  heading: string
}

const Heading: React.FC<Props> = ({ heading }) => {
  return (
    <Typography
      sx={{
        fontWeight: 600,
        // fontSize: '24px',
        [theme.breakpoints.up('md')]: {
          fontSize: '1.5rem',
        },
        // fontSize: "0.875rem",
        [theme.breakpoints.up('sm')]: {
          fontSize: '1.375rem',
        },
        // [theme.breakpoints.up('xl')]: {
        //   fontSize: '1.5rem',
        // },
        lineHeight: '36px',
        textTransform: 'capitalize',
        textAlign: 'center',
      }}
    >
      {heading}
    </Typography>
  )
}

export default Heading
