import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from "react";
//
import {
  OutlinedInput,
  Button,
  Box,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import { FormEvent } from "react";
//
import Modal from "react-bootstrap/Modal";
import useStyles from "./EditModalStyle";
//
import FormHelperText from "@mui/material/FormHelperText";
import { useFormik } from "formik";
import * as Yup from "yup";

const MyVerticallyCenteredModal = (props: any) => {
  const [inputData, setInputData] = useState(props.prop.data);

  const classes = useStyles();

  // const updateCannedResponse = (event: React.FormEvent<HTMLFormElement>) => {
  //   //
  //   event.preventDefault();
  //   //
  //   if (inputData.shortCut.length && inputData.longText.length) {
  //     props.prop.updatedData(inputData);
  //   }
  // };

  useEffect(() => {
    setInputData(props.prop.data)
    formik.setValues({text:props.prop.data?.longText,shortcut:props.prop.data?.shortCut});
    //here data comes of particular cannedResponse, and useEffect notices it
  }, [props.prop.data]);

  /////////////////////////////////
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    //
    width: "400px",
    // height: "350px",
    height: "auto",
    maxHeight: "385px",
    //
    // padding:"10px",
    //
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)",
    borderRadius: "3px",
  };

  const [message, setMessage] = React.useState("");
  const [message2, setMessage2] = React.useState("");
  const [index, setIndex] = React.useState();

  const fieldStyle = {
    width: "100%",
    height: "30px",
    border: "0.0px solid #E8E8E8",
    borderRadius: "3px",
    background: "#FFFFFF",
    //
    // padding: '0px',
    marginTop: "5px",
    marginBottom: "0px",
  };

  /////////////////////////////////////////////

  const [initialFormikValues, setInitialFormikValues] = React.useState({
    text: "",
    shortcut: "",
  });


  const validationSchema = Yup.object().shape({
    text: Yup.string().required("text is required"),
    shortcut: Yup.string().required("shortcut is required"),
  });

  interface FormValuesProps {
    text: string;
    shortcut: string;
  }

  const updatedHandleSubmit = async (
    values: FormValuesProps,
    formikHelpers: any
  ) => {
    formikHelpers.setSubmitting(false);
    try {
      //
      const dataSubmitted = {
        id:props.prop.data.id,
        shortCut:values.shortcut,
        longText:values.text
      }
      props.prop.updatedData(dataSubmitted);
      //
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues: initialFormikValues,
    validationSchema: validationSchema,
    onSubmit: updatedHandleSubmit,
  });

  /////////////////////////////////////////////

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Box sx={style}>
        {/* <form
          onSubmit={updateCannedResponse}
          className={classes.classes.boxForm}
        > */}
        <Box component="form" onSubmit={formik.handleSubmit} className={classes.classes.boxForm}>
          <Typography
            sx={{
              width: "100%",
              fontFamily: "poppins",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px",
              height: "24px",
              color: "#272525",
            }}
            variant="body1"
          >
            Edit this Canned Response
          </Typography>

          <Typography
            sx={{
              width: "100%",
              height: "36px",
              fontFamily: "poppins",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#838383",
              //
              marginTop: "5px",
            }}
            variant="body1"
          >
            Add a text shortcut to quickly insert this reply. Shortcuts must be
            at least three characters.
          </Typography>

          <Typography
            sx={{
              width: "100%",
              height: "18px",
              fontFamily: "poppins",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#272525",
              //
              marginTop: "10px",
            }}
            variant="body1"
          >
            Responses text
          </Typography>

          <TextareaAutosize
            // value={inputData?.longText}
            // onChange={(e)=>setInputData({...inputData,longText:e.target.value})}
            //
            name="text"
            value={formik.values.text}
            onChange={formik.handleChange}
            onError={formik.touched.text && Boolean(formik.errors.text)}
            //
            placeholder="Canned responses message"
            style={{
              width: "100%",
              height: "100px",
              resize: "none",
              marginTop: "5px",
              background: "#FFFFFF",
              border: "0.5px solid #E8E8E8",
              borderRadius: "3px",
              padding: "5px",
              //
              fontFamily: "poppins",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#272525",
            }}
          />

          {formik.touched.text && formik.errors.text && (
            <FormHelperText
              style={{
                fontWeight: "400",
                fontFamily: "poppins",
                fontSize: "12px",
                lineHeight: "18px",
              }}
              error
            >
              {formik.errors.text}
            </FormHelperText>
          )}

          <Typography
            sx={{
              width: "100%",
              height: "18px",
              fontFamily: "poppins",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#272525",
              //
              marginTop: "10px",
            }}
            variant="body1"
          >
            Shortcut
          </Typography>

          <OutlinedInput
            className={classes.classes.root}
            style={fieldStyle}
            // value={inputData?.shortCut}
            // onChange={(e)=>setInputData({...inputData,shortCut:e.target.value})}
            //
            name="shortcut"
            value={formik.values.shortcut}
            onChange={formik.handleChange}
            onError={formik.touched.shortcut && Boolean(formik.errors.shortcut)}
            //
            fullWidth
            placeholder="Add shortcut"
            inputProps={{
              style: {
                width: "100%",
                height: "30px",
                fontFamily: "poppins",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "18px",
                color: "#272525",
                //
                paddingLeft: "5px",
              },
            }}
          />

          {formik.touched.shortcut && formik.errors.shortcut && (
            <FormHelperText
              style={{
                fontWeight: "400",
                fontFamily: "poppins",
                fontSize: "12px",
                lineHeight: "18px",
              }}
              error
            >
              {formik.errors.shortcut}
            </FormHelperText>
          )}

          <Box
            sx={{
              width: "39px",
              height: "18px",
              background: "#F5F5F5",
              borderRradius: "2px",
              //
              padding: "2px 3px",
              marginTop: "2px",
              //
              fontFamily: "poppins",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#838383",
            }}
          >
            #help
          </Box>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "5px",
            }}
          >
            <Button
              // onClick={handleClose}
              onClick={() => props.onHide()}
              variant="contained"
              color="primary"
              sx={{
                width: "66px",
                height: "32px",
                background: "#F5F5F5",
                borderRadius: "3px",
                fontFamily: "poppins",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "18px",
                color: "#777777",
                marginRight: "5px",
                "&:hover": {
                  color: "#777777",
                  backgroundColor: "#F5F5F5",
                },
                //
                textTransform: "capitalize",
                boxShadow: "none",
              }}
              //
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                width: "65px",
                height: "32px",
                backgroundColor: "#4D1277",
                borderRadius: "3px",
                fontFamily: "poppins",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "18px",
                color: "#FFFFFF",
                //
                textTransform: "capitalize",
                boxShadow: "none",
              }}
            >
              Save
            </Button>
          </div>
        {/* </form> */}
        </Box>
      </Box>
    </Modal>
  );
};

interface listDataProp {
  id: number;
  shortCut: string;
  longText: string;
}

interface EditModalProps {
  ref: React.RefObject<any>;
  data: listDataProp | null;
  updatedData: (e: listDataProp) => void;
}

type MyComponentType = {
  alterModalShow: () => void;
};

const EditModal = forwardRef<MyComponentType, EditModalProps>((props, ref) => {
  const [modalShow, setModalShow] = React.useState(false);
  useImperativeHandle(ref, () => ({
    alterModalShow: () => {
      setModalShow(!modalShow);
    },
  }));

  return (
    <>
      <MyVerticallyCenteredModal
        prop={props}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
});

export default EditModal;
