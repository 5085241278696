import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    // marginTop: 120,
    flexDirection: 'column',
    boxSizing: 'border-box',
    // background: 'rgba(127, 63, 152, 0.05)',
    //
    padding: '35px',
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    flexDirection: 'column',
  },
  confirmText:{
    fontFamily:"poppins",
    fontWeight:"400",
    fontSize:"14px",
    lineHeight:"18px",
    color:"#938783",
    marginBottom:"5px"
  },
  confirmCode:{
    fontFamily:"poppins",
    fontWeight:"600",
    fontSize:"15px",
    lineHeight:"18px",
    color:"black",
    marginLeft:"5px"
  }
}))

export default useStyles
