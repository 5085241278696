import React, { useEffect, useState, useMemo } from 'react'
import { CircularProgress, Grid, Typography, Box } from '@mui/material'
import ReportsSummaryComp from '../../componentsfromkuikwit/components/pages/Reports/reportsSummaryComp/ReportsSummaryComp'
import ReportsCustomersReceived from '../../componentsfromkuikwit/components/pages/Reports/reportsCustomersReceived/ReportsCustomersReceived'
import ReportsPagesBreakdown from '../../componentsfromkuikwit/components/pages/Reports/reportsPagesBreakdown/ReportsPagesBreakdown'
import ReportsDataGridComp from '../../componentsfromkuikwit/components/pages/Reports/reportsDataGridComp/ReportsDataGridComp'
import { useSelector } from 'react-redux'
import { useManagersQuery, useUsersByManagersQuery, useCustomerCountAndCountsPerPage, useGetCustomerTabularDatas } from './Queries'


import { ReportsInitialProp, ManagersQueryResultItem, Pagination, TabularData, UsersByManagersQueryResultItem, getCustomerCountAndCountsPerPageResultItem, CustomerData } from './Types';
// import GridSkeleton from '../../componentsfromkuikwit/components/pages/Reports/reportsDataGridComp/GridSkeleton'

const Report: React.FC<ReportsInitialProp> = () => {
  const { authPanelType, authUserId } = useSelector((state: any) => state.AuthReducer)
  // console.log(authUserId, "authUserId")
  const [managersList, setManagersList] = useState<ManagersQueryResultItem[]>([])
  const [loading, setLoading] = useState(true)
  const [customersReceivedCount, setCustomersReceivedCount] = useState<number>(0)
  const [agentsList, setAgentsList] = useState<ManagersQueryResultItem[]>([])
  const [pagesBreakdownData, setPagesBreakdownData] = useState<getCustomerCountAndCountsPerPageResultItem[]>([])
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [selectedNames, setSelectedNames] = useState<string[]>([])
  const [selectedAgentsIds, setSelectedAgentsIds] = useState<string[]>([])
  const [selectedManagersIds, setSelectedManagersIds] = useState<string[]>([])
  const [tabularData, setTabularData] = useState<CustomerData[]>([])
  const [StartEnd, setStartEnd] = useState({
    start: '',
    end: ''
  })
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    hasNextPage: false,
    currentPage: 1,
    lastCursorValue: '0',
    firstCursorValue: []
  });


  document.title = "Reports";
  useEffect(() => {
    setSelectedIds([...selectedAgentsIds, ...selectedManagersIds])

    const agentsNames = agentsList.filter(item => selectedAgentsIds.includes(item.id)).map(item => item.name)
    const managersNames = managersList.filter(item => selectedManagersIds.includes(item.id)).map(item => item.name)
    setSelectedNames([...agentsNames, ...managersNames])

  }, [selectedAgentsIds, selectedManagersIds])

  // fetching and storing managers list of a particular superadminid functionality

  const [
    getManagers,
    {
      loading: getManagersLoading,
      error: getManagersError,
      data: getManagersResult,
    },
  ] = useManagersQuery();

  const getManagerApiCallingHandle = async () => {
    const activeUserDetail = localStorage.getItem("ActiveUserdetail");
    const mainSuperAdminId = activeUserDetail
      ? JSON.parse(activeUserDetail).mainSuperAdminId
      : null;

    getManagers({
      variables: {
        managersOnly: true,
        mainSuperAdminId,

      },
    });
  };

  useEffect(() => {
    if (getManagersResult && getManagersResult.users) {
      const listOfManagers = getManagersResult.users;
      const listOfManagersId = listOfManagers.map((item: ManagersQueryResultItem) => item.id)
      setManagersList(listOfManagers)
      getUsersByManagers({
        variables: {
          managerId: listOfManagersId
        }
      })
    }
  }, [getManagersResult])



  useEffect(() => {
    getManagerApiCallingHandle()
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const timezoneOffset = -7 * 60; // Offset in minutes (US time zone, example -7:00)

    currentDate.setMinutes(currentDate.getMinutes() + currentDate.getTimezoneOffset() + timezoneOffset);

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');

    const end = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    const start = `${year}-${month}-${day} ${'00:00:00'}`;


    getCustomerCountAndCountsPerPagehandle({ start, end })


  }, [authUserId]);
  // fetching and storing agents list of all the managers found of a particular superadminid  functionality

  const [
    getUsersByManagers,// to be called after calling getmanagers query to fetch all managers
    {
      loading: getUsersByManagersLoading,
      error: getUsersByManagersError,
      data: getUsersByManagersResult,
    },
  ] = useUsersByManagersQuery();

  useEffect(() => {
    if (getUsersByManagersResult && getUsersByManagersResult.getusersbymanagers) {
      const usersByManagers = getUsersByManagersResult.getusersbymanagers.map((item: UsersByManagersQueryResultItem) => ({ name: item.username, id: `${item.id}` }))
      setAgentsList(usersByManagers)
    }
  }, [getUsersByManagersResult])

  useEffect(() => {
    if (getUsersByManagersError) {
      console.log(getUsersByManagersError, "getUsersByManagersError")
    }
  }, [getUsersByManagersError])

  // fetching getCustomerCountAndCountsPerPage functionality


  const [
    getCustomerCountAndCountsPerPage,
    {
      loading: getCustomerCountAndCountsPerPageLoading,
      error: getCustomerCountAndCountsPerPageError,
      data: getCustomerCountAndCountsPerPageResult,
    },
  ] = useCustomerCountAndCountsPerPage();


  useEffect(() => {
    if (getCustomerCountAndCountsPerPageResult && getCustomerCountAndCountsPerPageResult.getCustomerCountAndCountsPerPage) {
      const data: getCustomerCountAndCountsPerPageResultItem[] = getCustomerCountAndCountsPerPageResult.getCustomerCountAndCountsPerPage.countData
      const count = data.map((item: getCustomerCountAndCountsPerPageResultItem) => item.totalCustomerCount).reduce(function (accumulator, currentValue) {
        return accumulator + currentValue;
      }, 0);
      setCustomersReceivedCount(count)

      const mergedData: any = {};

      for (const item of data) {
        if (mergedData.hasOwnProperty(item.name)) {
          mergedData[item.name].totalCustomerCount += item.totalCustomerCount;
        } else {
          mergedData[item.name] = {
            ...item
          };
        }
      }

      const mergedDataArray: getCustomerCountAndCountsPerPageResultItem[] = Object.values(mergedData);
      setPagesBreakdownData(mergedDataArray)
      setLoading(false)
      tablularDataApiHandle(0, 1, false)

    }
  }, [getCustomerCountAndCountsPerPageResult])

  useEffect(() => {
    if (getCustomerCountAndCountsPerPageError) {
      console.log(getCustomerCountAndCountsPerPageError, "getCustomerCountAndCountsPerPageError")
    }
  }, [getCustomerCountAndCountsPerPageError])

  const getCustomerCountAndCountsPerPagehandle = async (state: any) => {

    const { start, end } = state
    setStartEnd({
      start,
      end
    })


    const id = authPanelType === "AGENT" ? String(authUserId) : selectedIds

    // console.log(start, end, id, "asdasdasdasdasdasd")

    // await getCustomerCountAndCountsPerPage({
    //   variables: {
    //     idArray: id,
    //     start: `${start}`,
    //     end: `${end}`,
    //     interval: 2
    //   }
    // })
  }

  // pagination function of getCustomerTabularDatas

  const tablularDataApiHandle = async (cursorValue: number, newPage: React.SetStateAction<number>, greater: boolean) => {


    setPagination(item => {
      return {
        ...item,
        currentPage: Number(newPage),

      }
    }
    )





    await getCustomerTabularDatas({
      variables: {
        idArray: [String(authUserId)],
        start: String(StartEnd.start),
        end: String(StartEnd.end),
        cursor: Number(cursorValue), limit: 10
      }
    })
  }


  // get tablualr data functionality

  const [
    getCustomerTabularDatas,
    {
      loading: getCustomerTabularDatasLoading,
      error: getCustomerTabularDatasError,
      data: getCustomerTabularDatasResult,
    },
  ] = useGetCustomerTabularDatas();

  useEffect(() => {
    if (getCustomerTabularDatasError) {
      console.log(getCustomerTabularDatasError, "getCustomerTabularDatasError")
    }
  }, [getCustomerTabularDatasError])

  useEffect(() => {
    if (getCustomerTabularDatasResult && getCustomerTabularDatasResult.getCustomerTabularDatas) {
      const data = getCustomerTabularDatasResult.getCustomerTabularDatas.customerdata
      const hasNextPage: boolean = getCustomerTabularDatasResult.getCustomerTabularDatas.hasNextPage
      setTabularData(data)

      setPagination(prev => {
        return {
          ...prev,
          hasNextPage,
          page: (hasNextPage && prev.currentPage >= prev.page) ? prev.page + 1 : prev.page,
          firstCursorValue: [...prev.firstCursorValue, Number(prev.lastCursorValue)],
          lastCursorValue: data.length !== 0 ? data[data.length - 1].id : prev.lastCursorValue,
        }
      })

    }
  }, [getCustomerTabularDatasResult])

  return (
    <Grid container ml={2.5} mr={6} mt={10}
      sx={{
        height: "90%",
        width: "98%",
        marginBottom: 20,
        // border: "1px solid red",
        boxShadow: '0px 5px 10px rgba(127, 63, 152, 0.1)',
        borderRadius: '10px',
      }} >
      {loading === false ?
        (
          <> <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={9} >
                <ReportsSummaryComp authPanelType={authPanelType} managersList={managersList} agentsList={agentsList} getCustomerCountAndCountsPerPagehandle={getCustomerCountAndCountsPerPagehandle}
                  setSelectedAgentsIds={setSelectedAgentsIds} setSelectedManagersIds={setSelectedManagersIds} selectedNames={selectedNames}
                />

              </Grid>
              <Grid item xs={12} md={3} >
                <Grid container spacing={2}>
                  <Grid item xs={12} >
                    <ReportsCustomersReceived text="Customers Received" figure={customersReceivedCount} />
                  </Grid>
                  <Grid item xs={12} >
                    <ReportsPagesBreakdown pagesBreakdownData={pagesBreakdownData} />
                  </Grid>
                </Grid>
              </Grid>

            </Grid>

          </Grid>
            <Grid item xs={12} mt={1} >
              {<ReportsDataGridComp getCustomerTabularDatasLoading={getCustomerTabularDatasLoading} pagination={pagination} tablularDataApiHandle={tablularDataApiHandle} tabularData={tabularData} />}

            </Grid>
            <Grid item xs={12} sx={{ height: 40 }}>

            </Grid>
          </>
        ) : (

          <Box sx={{
            marginTop: 200,
            width: "100%",
            height: "calc(100vh - 100px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            fontWeight: "400", color: "#4d1277"

          }}>

            <CircularProgress size={48} />
            <Typography position='absolute'>This feature will be available soon!</Typography>
          </Box>


        )}


    </Grid>)




}

export default Report