import React,{useState, useEffect} from 'react'
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import TwilioInputUI from './TwilioInputUI'

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import {Avatar, Button, Link, Typography} from '@mui/material';
import useStyle from './Style';
import Twillio from '../../../../../assets/setting/twilio.svg';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TwilioGif from '../../../../../assets/gif/twilio.gif'

interface typesForPhoneNumber{
  phoneNumber: string
  __typename: string
  active : boolean
}

interface resultType{
  accountSID: string
  active: boolean
  authToken: string
  id: string
  phoneNumber: typesForPhoneNumber[]
  __typename: string
}

interface Props{
  setTwilioConfig:React.Dispatch<React.SetStateAction<resultType | null | undefined>>
  setIsAuth: React.Dispatch<React.SetStateAction<boolean>>
}
const TwilioConfiguration : React.FC<Props> = ({
  setIsAuth,
  setTwilioConfig
}) =>{

  const {classes} = useStyle();
  const { enqueueSnackbar } = useSnackbar();
  const addTwilioConfigurationQuery = gql`
  mutation addTwilioConfiguration(
    $accountSID:String!
    $authToken:String!
 
  ){
    addTwilioConfiguration(
    accountSID:$accountSID
    authToken:$authToken
 
  ) {
      result{
        id
        accountSID
        authToken
        phoneNumber{
          phoneNumber
          active
        }
        active
      }
      success
      error
    }
  }
`;
let [
  addTwilioConfiguration,
  {
    loading: addTwilioConfigurationQueryLoading,
    error: addTwilioConfigurationQueryError,
    data: addTwilioConfigurationQueryResult,
  },
] = useMutation(addTwilioConfigurationQuery);

useEffect(() => {
  if (addTwilioConfigurationQueryError) {
    addTwilioConfigurationQueryError.graphQLErrors.map(({ message }, i) => {
      enqueueSnackbar(message, { variant: "error" });
    });
  }
}, [addTwilioConfigurationQueryError]);

useEffect(() => {
  if (addTwilioConfigurationQueryResult && addTwilioConfigurationQueryResult.addTwilioConfiguration) {
    const {success,error,result} = addTwilioConfigurationQueryResult.addTwilioConfiguration;

    if(result) {
      // console.log("result addTwilioConfiguration", result);
      setTwilioConfig(result)
        // props.setTwilioConfig(result)
    }
    if (success) {
      setIsAuth(true);
    } else {
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
  }
}, [addTwilioConfigurationQueryResult]);
  const [accSID,setAccSID] = useState("");
  const [authToken,setAuthToken] = useState("");


  const saveConfiguration = (event: React.FormEvent<HTMLFormElement>)=>{
    event.preventDefault()
    if(accSID !== '' && authToken !== ''){
      addTwilioConfiguration({
        variables: {
          accountSID:accSID,
          authToken:authToken
        },
      });
    }
  }
  return (
    
    <Card sx={{ minWidth: 275 }} style={{height:'calc(100vh - 112px)', borderRadius:'10px', overflowY: 'auto'}}>
      <Box className={classes.headerBox}>
        <Avatar src={Twillio} className={classes.avtarStyle} />
        <Box>
          <Typography component={'p'} className={classes.headerHeading}>
            Add Twilio to Kuikwit
          </Typography>
          <Typography component={'p'} className={classes.headerSubHeading}>
            Provide Account SID and Auth token to connect your Twilio account
          </Typography>
        </Box>
      </Box>
      <Grid container xs={12}>
          <Grid xs={5}>
                <Box component={'form'} onSubmit={saveConfiguration} className={classes.formStyle}>
                    <TwilioInputUI 
                        label="Account SID"
                        onChange={setAccSID}
                        value={accSID}
                    />
                    <TwilioInputUI 
                        label="Auth token"
                        onChange={setAuthToken}
                        value={authToken}
                    />
                    <Button variant='contained' type='submit' className={classes.buttonStyle}>
                    Authorize App
                    </Button>
                <Typography component={'p'} className={classes.text}>Don't have Twilio account?<Link href='https://www.twilio.com/try-twilio' target='_blank'> Click here </Link>to create one.</Typography>
              </Box>
          </Grid>
          <Grid xs={7}>
            <Box>
              <Typography  component={'p'} className={classes.replySection}>
                Reply to SMS in Kuikwit with Twilio
              </Typography>
            </Box>
              <Box sx={{textAlign: 'end'}}>
                <img src={TwilioGif} width="100%"/>
              </Box>
          </Grid>
      </Grid>
    </Card>
  )
}

export default TwilioConfiguration