import { makeStyles } from 'tss-react/mui'

const useStyle = makeStyles()(()=>({
    cardContent : {
        paddingTop:'0px'     
    },
    text:{
        fontWeight:"400",
        fontSize: '12px',
        lineHeight: '18px',
        color:'#777777'
    },
    profileSection:{
        display:'inline-block', 
        verticalAlign: 'middle'
    },
    profileSectionBox:{
        background: '#FBFBFB',
        width: '700px',
        height:'45px',
        marginLeft:'20px'
    },
    disconnectLinl:{
        textDecoration: 'none', 
        fontWeight:"400",
        fontSize: '12px',
        lineHeight: '18px',
        color:'red'
    },
    tableStyle:{
        width:'700px'
    },
    pageFirstCharConnectedColor:{
        color:'#2AD19B',
        background:'#D2F8EB',
    },
    pageFirstCharDisconnectedColor:{
        color:'#D9515D',
        background:'#FADCDE',
    },
    pageFirstChar:{
        display: 'inline-block',
        width: '40px',
        height: '40px',
        fontWeight: '900',
        fontSize: '28px',
        textAlign: 'center',
        marginRight:'20px'
    },
    buttonStyleDisconnected:{
        color:'#E34D59',
        backgroundColor:'#fff2f3', 
        borderColor:'#E34D59', 
        width:'105px', 
        textTransform:'none',
        padding: '2px',
        fontSize:'10px',
    },
    buttonStyleConnected:{
        color:'#22AA61',
        backgroundColor:'#ecf7f3', 
        borderColor:'#22AA61', 
        width:'105px', 
        textTransform:'none',
        padding: '2px',
        fontSize:'10px',
    },
    pageName:{
        color:'#838383',
        fontWeight:'400',
        fontSize:'12px',
        lineHeight:"18px"
    }
}))


export default useStyle