import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import useStyles from './Styles'
import _ from 'lodash'
import LeftCardDashboard from '../../componentsfromkuikwit/components/pages/dashboard/leftCardDashboard/LeftCardDashboard'
// import RightCardDashboard from '../../componentsfromkuikwit/components/pages/dashboard/rightCardDashboard/RightCardDashboard'
import TotalChatsChart from '../../componentsfromkuikwit/components/pages/dashboard/totalChatsChart/TotalChatsChart'
import TotalNoOfPages from '../../componentsfromkuikwit/components/pages/dashboard/chatsByPages/ChatsByPages'
import { gql, useQuery, useLazyQuery, useSubscription } from '@apollo/client';
import { useDispatch, useSelector } from "react-redux";
import { setChatBoxPendingChatCount, setChatBoxPendingChatCountDetails, } from '../../store/actions/ChatBoxActions'
import { useGetDashboardGraphicalDataQuery, useGetAgentsAndActiveCustomersQuery, useGetAgentTotalChatQuery } from './Queries'

const subscriptionTotalChat = gql`
 subscription subscriptiontotalchat($agentId:[ID!]){
       subscriptiontotalchat(agentId:$agentId){
       success,
       result
     }
 }
    
`

const subscriptionDataAdminAgents = gql`
subscription subscriptionDataAdminAgents{
    subscriptiondataadminagents(id:1,paneltype:"SUPERADMIN"){
      success,
      result
    }
  }
  `

const GetPendingChatCountQuery = gql`
query GetPendingChatCount {
  getpendingchatcount {
    success
    error
    result
  }
}
`;

const GetSpecificPagesQuery = gql`
query getSpecificPages($id:[ID!]) {
    getSpecificPages(id:$id) 
   { pagedata{
        id
        name
  }}
}
`;



interface PageCountType {
    pageId: string
    customerId: string
    count: number
}
interface PageCountSummary {
    pageId: string;
    times: number;
}
interface PendingChatPages {
    name?: string
    pageId: string
    times: number
}


const Dashboard: React.FC = () => {
    document.title = "Dashboard";

    const { authPanelType, authUserId } = useSelector((state: any) => state.AuthReducer);
    const { authPagesData, authUserPagesAssigned } = useSelector((state: any) => state.AuthReducer);
    const { chatBoxPendingChatCount } = useSelector((state: any) => state.ChatBoxReducer);

    // take out panel type and idss



    const activeUserDetail = localStorage.getItem("ActiveUserdetail");
    const mainSuperAdminId = activeUserDetail
        ? JSON.parse(activeUserDetail).id
        : null;
    const panelType = activeUserDetail
        ? JSON.parse(activeUserDetail).paneltype
        : null;




    ///



    const dispatch = useDispatch();
    const [pageCountSummaryArray, setPageCountSummaryArray] = useState<PageCountSummary[]>([])
    const [pendingChatPages, setPendingChatPages] = useState<PendingChatPages[]>([]);

    // subscriptionDataAdminAgents

    const { data: subscriptionDataAdminAgentsData, loading: subscriptionDataAdminAgentsLoading, error: subscriptionDataAdminAgentsError } = useSubscription(
        subscriptionDataAdminAgents,
        {
            variables: {
                id: [authUserId],
                paneltype: `${panelType}`
            }
        }
    );

    useEffect(() => {
        console.log(subscriptionDataAdminAgentsError, "subscriptionDataAdminAgentsError")

    }, [subscriptionDataAdminAgentsError])




    ///


    useEffect(() => {
        // console.log(userdata, "userdata")
        if (subscriptionDataAdminAgentsData && subscriptionDataAdminAgentsData.subscriptiondataadminagents.result) {
            const { activeAgents, myActiveCustomers } = subscriptionDataAdminAgentsData.subscriptiondataadminagents.result  
            setActiveAgentsAndCustomer({
                activeAgents,
                activeCustomers: myActiveCustomers
            })
        }

    }, [subscriptionDataAdminAgentsData])



    //////


    const { data: subscriptionTotalChatData, loading: subscriptionTotalChatLoading, error: subscriptionTotalChatError } = useSubscription(
        subscriptionTotalChat,
        {
            variables: {
                agentId: [authUserId]

            }
        }
    );


    // useEffect(() => {
    //     // console.log(userdata, "userdata")
    //     if (subscriptionTotalChatData && subscriptionTotalChatData.getAgentsAndActiveCustomers) {
    //         const { activeAgents, activeCustomers } = subscriptionTotalChatData.getAgentsAndActiveCustomers
    //         setActiveAgentsAndCustomer({
    //             activeAgents,
    //             activeCustomers
    //         })
    //     }

    // }, [subscriptionTotalChatData])

    //
    //hitting query



    useEffect(() => {
        // console.log(userdata, "userdata")
        if (subscriptionTotalChatData && subscriptionTotalChatData.getAgentsAndActiveCustomers) {
            const { activeAgents, activeCustomers } = subscriptionTotalChatData.getAgentsAndActiveCustomers
            setActiveAgentsAndCustomer({
                activeAgents,
                activeCustomers
            })
        }

    }, [subscriptionTotalChatData])
    // useEffect(() => {
    //     // console.log(subscriptionTotalChatLoading, "subscriptionTotalChatLoading")

    // }, [subscriptionTotalChatLoading])




    const [getSpecificPages, { loading: getSpecificPagesLoading, error: getSpecificPagesError, data: getSpecificPagesResult }] = useLazyQuery(GetSpecificPagesQuery, {
        fetchPolicy: "network-only",
    });



    // useEffect(() => {
    //     if (userloading === false) {
    //         const gotdata = JSON.parse(userdata.getsubscriptiondataall.data)

    //         const onlineUsers = [];
    //         let sum = 0
    //         //
    //         for (let index = 0; index < gotdata.length; index++) {
    //             if (gotdata[index].isOnline === true) {
    //                 onlineUsers.push(gotdata[index])
    //                 //for active customers
    //                 sum += gotdata[index].chats.length
    //             }
    //         }
    //         setonlineUsers(onlineUsers)
    //         setactiveAgents(onlineUsers.length)
    //         //
    //         setactiveCustomers(sum)
    //         //
    //     }
    // }, [userloading])

    useEffect(() => {
        if (subscriptionTotalChatData) {
            const count = JSON.parse(subscriptionTotalChatData.subscriptiontotalchat.result)
        }

    }, [subscriptionTotalChatData])


    useEffect(() => {
        console.log(subscriptionTotalChatError, "subscriptionTotalChatError")

    }, [subscriptionTotalChatError])



    const [
        getPendingChatCount,
        {
            loading: getPendingChatCountQueryLoading,
            error: getPendingChatCountQueryError,
            data: getPendingChatCountQueryResult,
        },
    ] = useLazyQuery(GetPendingChatCountQuery, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        getPendingChatCount();
    }, []);

    const PendingChatCountChangedSubscription = gql`
    subscription PendingChatCountChanged {
      pendingchatcountchanged {
        result
      }
    }
  `;

    const {
        data: pendingChatCountChangedSubscriptionResult,
        error: pendingChatCountChangedSubscriptionError,
    } = useSubscription(PendingChatCountChangedSubscription);


    // useEffect(() => {

    //     if (
    //         pendingChatCountChangedSubscriptionResult &&
    //         pendingChatCountChangedSubscriptionResult.pendingchatcountchanged
    //     ) {

    //         var pendingChatCount = JSON.parse(
    //             pendingChatCountChangedSubscriptionResult.pendingchatcountchanged.result
    //         );
    //         console.log(pendingChatCount.length, "pendingChaasdadasdtCountChangedSubscriptionResult")
    //         dispatch(setChatBoxPendingChatCount(pendingChatCount.length))
    //     }
    // }, [pendingChatCountChangedSubscriptionResult]);

    useEffect(() => {
        if (
            getPendingChatCountQueryResult &&
            getPendingChatCountQueryResult.getpendingchatcount
        ) {

            const pendingChatCount: PageCountType[] = JSON.parse(
                getPendingChatCountQueryResult.getpendingchatcount.result
            );
            // console.log(pendingChatCount, "pendingChatCountasdadadsd")
            //

            if (
                authUserPagesAssigned
            ) {
                _.remove(
                    pendingChatCount,
                    (item) => !authUserPagesAssigned.includes(item.pageId)
                );

                var userPagesAdded: any[] = authPagesData;
                _.remove(
                    userPagesAdded,
                    (item) => !authUserPagesAssigned.includes(item.pageId)
                );
                dispatch(setChatBoxPendingChatCountDetails(userPagesAdded))
            } else {
                var userPagesAllAdded = _.map(
                    authPagesData,
                    (item) => item.pageId
                );
                _.remove(
                    pendingChatCount,
                    (item) => !userPagesAllAdded.includes(item.pageId)
                );
                dispatch(setChatBoxPendingChatCountDetails(authPagesData))
            }

            //
            dispatch(setChatBoxPendingChatCount(pendingChatCount.length))

            // Create a map to store the counts
            setPageCountSummaryArray(pendingChatCount.reduce(
                (summaryArray: PageCountSummary[], item: PageCountType) => {
                    const existingItem = summaryArray.find(
                        (summaryItem) => summaryItem.pageId === item.pageId
                    );
                    if (existingItem) {
                        existingItem.times += 1;
                    } else {
                        summaryArray.push({ pageId: item.pageId, times: 1 });
                    }
                    return summaryArray;
                },
                []
            ))

            //getting distinct pages id
            let pageIdArray: any[] = []
            for (let i = 0; i < pendingChatCount.length; i++) {

                pageIdArray.push(pendingChatCount[i].pageId)
            }


            const uniquePageIdArray = pageIdArray.filter((item, index) => {
                return pageIdArray.indexOf(item) === index;
            });

            getSpecificPages({ variables: { id: uniquePageIdArray } });


        }
    }, [getPendingChatCountQueryResult, authPagesData]);

    useEffect(() => {
        if (getSpecificPagesResult?.getSpecificPages) {

            const updatedPageCounts = pageCountSummaryArray.map((pageCount) => {
                const matchingProp = getSpecificPagesResult?.getSpecificPages.find((pageProp: { pagedata: { id: string } }) => pageProp.pagedata.id === pageCount.pageId);
                if (matchingProp) {
                    return { ...pageCount, name: matchingProp.pagedata.name };
                }
                return pageCount;
            });

            setPendingChatPages(updatedPageCounts);
        }
    }, [getSpecificPagesResult])

    useEffect(() => {
        console.log(getSpecificPagesError, "getSpecificPagesError")
    }, [getSpecificPagesError])

    const { classes } = useStyles()


    // active agents and customers data handling

    const [activeAgentsAndCustomer, setActiveAgentsAndCustomer] = useState({
        activeAgents: 0,
        activeCustomers: 0
    })

    const [getAgentsAndActiveCustomers,
        { loading: getAgentsAndActiveCustomersLoading,
            data: getAgentsAndActiveCustomersData,
            error: getAgentsAndActiveCustomersError
        }
    ] = useGetAgentsAndActiveCustomersQuery()



    const bringActiveDetails = async () => {
        if (mainSuperAdminId && panelType) {
            await getAgentsAndActiveCustomers({
                variables: {
                    agentId: mainSuperAdminId,
                    paneltype: `${panelType}`

                }
            })
        }
        if (mainSuperAdminId) {
            await getAgentTotalChat({
                variables: {
                    agentId: mainSuperAdminId,

                }
            })
        }
    }

    useEffect(() => {
        bringActiveDetails();

    }, [mainSuperAdminId, panelType])

    useEffect(() => {
        if (getAgentsAndActiveCustomersData && getAgentsAndActiveCustomersData.getAgentsAndActiveCustomers) {
            const { activeAgents, myActiveCustomers } = getAgentsAndActiveCustomersData.getAgentsAndActiveCustomers
            setActiveAgentsAndCustomer({
                activeAgents,
                activeCustomers: myActiveCustomers

            })
        }
    }, [getAgentsAndActiveCustomersData])

    useEffect(() => {
        console.log(getAgentsAndActiveCustomersError, "getAgentsAndActiveCustomersError")
    }, [getAgentsAndActiveCustomersError])

    // total chats data handling

    const [totalChats, setTotalChats] = useState(0)

    const [getAgentTotalChat,
        { loading: getAgentTotalChatLoading,
            data: getAgentTotalChatData,
            error: getAgentTotalChatError
        }
    ] = useGetAgentTotalChatQuery()

    useEffect(() => {
        if (getAgentTotalChatData && getAgentTotalChatData.getAgentTotalChat) {
            setTotalChats(getAgentTotalChatData.getAgentTotalChat.totalChat)
        }
    }, [getAgentTotalChatData])

    useEffect(() => {
        console.log(getAgentTotalChatError, "getAgentTotalChatError")
    }, [getAgentTotalChatError])




    /// total chats chart data handling


    const [totalChatsSelectValue, setTotalChatsSelectValue] = useState("Today")
    const [totalChatsGraphData, setTotalChatsGraphData] = useState([])

    const [getDashboardGraphicalData,
        { loading: getDashboardGraphicalDataLoading,
            data: getDashboardGraphicalDataQuery,
            error: getDashboardGraphicalDataQueryError
        }
    ] = useGetDashboardGraphicalDataQuery()

    useEffect(() => {
        if (totalChatsSelectValue &&  mainSuperAdminId) {
            getDashboardGraphicalData({
                variables: {
                    idArray: [mainSuperAdminId],
                    dateMode: totalChatsSelectValue

                }
            }
            )
        }
    }, [totalChatsSelectValue, mainSuperAdminId])

    useEffect(() => {
        if (getDashboardGraphicalDataQuery && getDashboardGraphicalDataQuery.getDashboardGraphicalData.data) {
            setTotalChatsGraphData(getDashboardGraphicalDataQuery.getDashboardGraphicalData.data)
        }
    }, [getDashboardGraphicalDataQuery])

    useEffect(() => {
        console.log(getDashboardGraphicalDataQueryError, "getDashboardGraphicalDataQueryError")
    }, [getDashboardGraphicalDataQueryError])




    return (
        <>

            <Grid className={classes.dashboard} mt={8}>
                <Grid
                    pt={2}
                    pl={3}
                    pr={2}
                    container
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {authPanelType !== "AGENT" && <Grid item lg={4} md={6} xs={12}>
                                <LeftCardDashboard text="Active Agents" figure={activeAgentsAndCustomer.activeAgents} />
                            </Grid>}
                            <Grid item lg={authPanelType !== "AGENT" ? 4 : 8} md={6} xs={12}>
                                <LeftCardDashboard text="Active Customers" figure={activeAgentsAndCustomer.activeCustomers} />
                            </Grid>
                            <Grid item lg={4} md={12} xs={12}>
                                <Grid container spacing={2} >
                                    <Grid item xs={12} md={6} lg={12}>
                                        <LeftCardDashboard text="Total Chats" figure={totalChats} />

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <TotalChatsChart selectValue={totalChatsSelectValue} data={totalChatsGraphData} selectChange={setTotalChatsSelectValue} authPanelType={authPanelType} text="Total Chats" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TotalNoOfPages text="Pending Chats" figure={chatBoxPendingChatCount} data={pendingChatPages} noChats=" No Pending Chats" getPendingChatCountQueryLoading={getSpecificPagesLoading} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                            </Grid>
                            <Grid item xs={12} md={4}>
                            </Grid>
                        </Grid>
                    </Grid>


                </Grid>
                <Grid className={classes.bottom}></Grid>
            </Grid>
        </>
    )
}

export default Dashboard