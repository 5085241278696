import React, { useState, FC } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// import useStyles from './Styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SvgIcon from "@mui/material/SvgIcon";
import Box from "@mui/material/Box";
import { Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import Radio from '@mui/material/Radio';

import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import {
    setUserPanelChatOnline,
} from "../../../../../store/actions/UserPanelActions";
import { connect } from 'react-redux';


interface Props {
    // menuItems: string[]
}

const ChatMainCardHeaderSelect: FC<Props> = (props: any) => {
    const [value, setValue] = useState<string>('');
    //   const { classes } = useStyles();
    const handleChange = (event: SelectChangeEvent) => {

        setValue(event.target.value);
        if (props.authUserId != null) {
            changeOnlineStatusMutation({
                variables: {
                    // online: event.target.checked,
                    online: event.target.value === 'Available' ? true : false,
                },
            });
        }
    };


    const controlRadio = (item: string) => ({
        checked: value === item,
        onChange: handleChange,
        value: item,
        name: 'size-radio-button-demo',
        inputProps: { 'aria-label': item },
    });

    const ChangeOnlineStatusMutation = gql`
    mutation ChangeOnlineStatus($online: Boolean!) {
      changeonlinestatus(online: $online) {
        success
        error
        result
      }
    }
  `;

    let [
        changeOnlineStatusMutation,
        {
            loading: changeOnlineStatusMutationLoading,
            error: changeOnlineStatusMutationError,
            data: changeOnlineStatusMutationResult,
        },
    ] = useMutation(ChangeOnlineStatusMutation);

    React.useEffect(() => {
        setValue(Boolean(props.userPanelChatOnline) ? "Available" : "Away")
    }, [value, props.userPanelChatOnline])

    React.useEffect(() => {
        if (
            changeOnlineStatusMutationResult &&
            changeOnlineStatusMutationResult.changeonlinestatus
        ) {
            props.setUserPanelChatOnline(
                JSON.parse(changeOnlineStatusMutationResult.changeonlinestatus.result)
            );
        }
    }, [changeOnlineStatusMutationResult]);
    React.useEffect(() => {
        if (changeOnlineStatusMutationError) {
            props.setUserPanelChatOnline(false);
            changeOnlineStatusMutationError.graphQLErrors.map(({ message }, i) => {
                // enqueueSnackbar(message, { variant: "error" });
            });
        }
    }, [changeOnlineStatusMutationError]);

    return (
        <Select
            sx={{
                "& legend": {
                    width: 0,
                }, height: "30px", width: "130px",
            }}
            value={value}
            label="Time"
            onChange={handleChange}
            // className={classes.select}
            renderValue={(value) => {
                return (
                    <Box sx={{ display: "flex", gap: 1 }}>
                        <Badge color={value === 'Available' ? ("secondary") : ('error')} badgeContent=" " variant="dot" overlap="circular"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <SvgIcon sx={{ fontSize: "20px", }} color="primary">

                                <AccessTimeIcon />

                            </SvgIcon>
                        </Badge>
                        <Typography sx={{ fontWeight: 400, fontSize: "12px", marginTop: 0.25 }}> {value}</Typography>
                    </Box>
                );
            }}

        >
            <MenuItem value={"Available"} sx={{
                width: '98%',
                margin: '0 auto',
                height: '25px',
                borderRadius: '3px',
                backgroundColor: '#FFFFFF',
                color: '#272525',
                fontFamily: 'poppins',
                fontSize: 12,
                fontWeight: '400', display: "flex",
            }}>

                <Radio size="small" {...controlRadio("Available")} sx={{ marginLeft: -2 }} />
                {"Available"}</MenuItem>

            <MenuItem value={"Away"} sx={{
                width: '98%',
                margin: '0 auto',
                height: '25px',
                borderRadius: '3px',
                backgroundColor: '#FFFFFF',
                color: '#272525',
                fontFamily: 'poppins',
                fontSize: 12,
                fontWeight: '400', display: "flex",
            }}>

                <Radio size="small" {...controlRadio("Away")} sx={{ marginLeft: -2 }} />
                {"Away"}</MenuItem>
        </Select>

    );
}

// export default ChatMainCardHeaderSelect


const mapStateToProps = (state: any) => {
    return {
        ...state.UserPanelReducer,
        ...state.AuthReducer,
    };
};
export default connect(mapStateToProps, {
    setUserPanelChatOnline,
})(React.memo(ChatMainCardHeaderSelect));