
const breakpoints = {
    values: {
      // xs: 0,
      // sm: 576,
      // md: 768,
      // lg: 992,
      // xl: 1200,
      // xxl: 1400,
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  };
  
  export default breakpoints;
  