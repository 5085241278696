import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme) => ({
  root: {
    "&:focus": {
      borderColor: "#4D1277 !important",
      
    },
  },
}));
export default useStyles;
