import { useState, useMemo } from 'react'
import useStyles from './Styles';
import { Grid, Card, Typography, Button, Stack } from '@mui/material';
import ReportsSummaryCompSelect from './ReportsSummaryCompSelect';
import ReactApexChart from 'react-apexcharts';
import chartData from './growth';
import ReportsSummaryCompSelectChips from './ReportsSummaryCompSelectChips';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { ManagersQueryResultItem, UsersByManagersQueryResultItem } from '../../../../../components/Report/Types';

interface Props {
  authPanelType: String
  managersList?: ManagersQueryResultItem[]
  agentsList?: ManagersQueryResultItem[]
  setSelectedManagersIds: any
  setSelectedAgentsIds: any
  selectedNames?: string[]
  getCustomerCountAndCountsPerPagehandle: (s: any) => void
}

const ReportsSummaryComp: React.FC<Props> = ({ getCustomerCountAndCountsPerPagehandle, selectedNames, authPanelType, managersList, agentsList, setSelectedAgentsIds, setSelectedManagersIds }) => {

  const [datePicker, setDatePicker] = useState<boolean>(false)
  const { classes } = useStyles();
  const [chartType, setChartType] = useState(true);
  const type = !chartType ? "bar" : "line";
  const reportData = chartData();
  const apexcharts = useMemo(() => <ReactApexChart key={type} {...reportData} type={type} />, [type])

  const handleDatePickerClick = () => {
    setDatePicker(!datePicker)
  }
  const handleDatePickerSubmit = () => {
    getCustomerCountAndCountsPerPagehandle(StartEnd)
    setDatePicker(!datePicker)
  }

  const [StartEnd, setStartEnd] = useState({
    start: '',
    end: ''
  })

  const [adate, setadate] = useState(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }));

  const [state, setState] = useState([
    {
      startDate: new Date(adate),
      endDate: (new Date(adate)),
      key: 'selection'
    }
  ]);

  const setDateRange = (item: any) => {

    let startdate = moment(item.selection.startDate).format("YYYY-MM-DD 10:00:00");
    let enddate = moment(item.selection.endDate).add(1, 'days').format("YYYY-MM-DD 09:59:59");


    setStartEnd({ start: startdate, end: enddate })

    setState([item.selection])

  }


  console.log(state, "staasdasdadte")
  return (
    <>
      <Card className={classes.chart}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item md={8} ml={2}>
                <Typography className={classes.text}>Summary</Typography>
              </Grid>
              <Grid item mt={2} mr={2}>
                <Button sx={{
                  height: '32px', width: "109px",
                  fontSize: "12px", fontWeight: 500, textDecoration: 'capitalize'
                }} onClick={handleDatePickerClick}
                  color="primary" variant='contained'>Select date </Button>

                {datePicker && <div className='myclass' style={{ position: 'absolute', left: '15%', zIndex: '10', top: '25%', boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.1)', display: 'flex', flexDirection: 'column', background: 'white' }}>
                  <DateRangePicker
                    onChange={(item: any) => setDateRange(item)}
                    // showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={state}
                    rangeColors={["#4D1277"]}
                    direction="horizontal"
                    preventSnapRefocus={true}
                    calendarFocus="backwards"
                  />
                  <Button sx={{
                    height: '32px', width: "109px",
                    fontSize: "12px", fontWeight: 500, textDecoration: 'capitalize', marginLeft: 'auto', padding: '10px', marginBottom: '10px', marginRight: '20px',
                  }} onClick={handleDatePickerSubmit}
                    color="primary" variant='contained'>Confirm</Button>
                </div>}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    {authPanelType === "SUPERADMIN" && <ReportsSummaryCompSelect type="Manager" list={managersList} setSelectedManagersIds={setSelectedManagersIds} />}
                    {authPanelType !== "AGENT" && <ReportsSummaryCompSelect type="Agent" list={agentsList} setSelectedAgentsIds={setSelectedAgentsIds} />}


                  </Stack>
                </Grid>

              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={12} >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} ml={2} mt={-2}>
                {authPanelType !== "AGENT" && <ReportsSummaryCompSelectChips selectedNames={selectedNames} />
                }              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {apexcharts}
        </Grid>
      </Card>
    </>
  )
}

export default ReportsSummaryComp