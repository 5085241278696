
import React from 'react'
import useStyle from './Style';
import { FormControl, OutlinedInput } from '@mui/material';
interface Props{
  label:string
  onChange:React.Dispatch<React.SetStateAction<string>>
  value : string
}
const TwilioInputUI : React.FC<Props> = ({label, onChange, value}) => {
  const {classes} = useStyle();
  return (

    <FormControl className={classes.formControl} sx={{marginBottom:'10px'}}>
      <label className={classes.inputLabel} htmlFor={label}>{label}</label>
      <OutlinedInput 
        id="accountSID" 
        className={classes.formInput}  
        name={label} 
        onChange={(e)=> onChange(e.target.value)}
        value={value}
        required={true}
        />
    </FormControl>
  )
}
// TwilioInputUI.defaultProps = {
//   label: "Account SID",
//   onChange: (e)=>console.log(e.target.value),
//   value: "asdf"
// }

export default TwilioInputUI