import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";

const ManagersQuery = gql`
  query Managers($managersOnly: Boolean = true, $mainSuperAdminId: ID!) {
    users(managersOnly: $managersOnly, mainSuperAdminId: $mainSuperAdminId) {
      id
      name
    }
  }
`;

export const useManagersQuery = () => {
  return useLazyQuery(ManagersQuery, {
    fetchPolicy: "network-only",
  });
};

const usersByManagersQuery = gql`
  query getusersbymanagers($managerId: [String!]) {
    getusersbymanagers(managerId: $managerId) {
      managerId
      id
      designationId
      username
    }
  }
`;

export const useUsersByManagersQuery = () => {
  return useLazyQuery(usersByManagersQuery, {
    fetchPolicy: "network-only",
  });
};

const customerCountAndCountsPerPage = gql`
  query getCustomerAndCountsPerPage(
    $idArray: [ID!]
    $start: String
    $end: String
    $interval: Int
  ) {
    getCustomerCountAndCountsPerPage(
      idArray: $idArray
      start: $start
      end: $end
      interval: $interval
    ) {
      countData {
        pageId
        name
        dateRange
        totalCustomerCount
      }
    }
  }
`;
export const useCustomerCountAndCountsPerPage = () => {
  return useLazyQuery(customerCountAndCountsPerPage, {
    fetchPolicy: "network-only",
  });
};

const getCustomerTabularDatas = gql`
  query getCustomerTabularDatas(
    $idArray: [ID!]!
    $start: String!
    $end: String!
    $cursor: Float!
    $limit: Int!
  ) {
    getCustomerTabularDatas(
      idArray: $idArray
      start: $start
      end: $end
      cursor: $cursor
      limit: $limit
    ) {
      customerdata {
        id
        timeStamp
        pageName
        customerName
        label
        customerId
        pageId
      }
      hasNextPage
    }
  }
`;
export const useGetCustomerTabularDatas = () => {
  return useLazyQuery(getCustomerTabularDatas, {
    fetchPolicy: "network-only",
  });
};
