import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import CircularProgress from "@mui/material/CircularProgress"
import CloseIcon from '@mui/icons-material/Close'
//
import SearchBar from '../../SearchBarTeam/SearchBarTeam'
//
import { Avatar, ListItem } from '@mui/material'

import image from '../../../../../assets/img/profile.jpg'
//
import { FormEvent } from 'react'
import { ChangeEvent } from 'react'


////////////////////////////////////////////////////
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { constants } from '../../../../../config/constant';
import useSnackBar from '../../../../../hooks/useSnackBar';
// import Button from '../atoms/buttons/button';
import Back from '../../../../icons/back';
import Close from '../../../../icons/close';
import User from '../../../../icons/user';
import SingleInput from '../../../../input/singleInput';
import Loader from '../../../../loader';
import SquareTag from '../../../../tags/squareTag';
//
import Store from '../../../../../store/index'


const initialState = {
  error: {}
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case "ON_ERROR":
      return { ...state, error: { ...action.payload } };
    default:
      return state;
  }
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  //
  width: '400px',
  // height: '350px',
  height: '360px',
  borderRadius: '3px',
  backgroundColor: '#FFFFFF',
}



interface dataProp {
  designation: {
    __typename: string;
    id: string;
    name: string;
    paneltype: string;
    // ... other properties of the designation object
  };
  email: string;
  id: string;
  isSelected: boolean;
  isUserLoggedIn: null;
  name: string;
  number: string;
  pages: string;
  status: string;
  username: string;
  __typename: string;
}


interface AgentModalProps {
  check: boolean;
  closeModal: () => void;
  handleSelectedUsers: (selectedUsers: dataProp[]) => void;
  loadMoreDataHandle: (i: React.SyntheticEvent) => void;
  openPanelCategory: string;
  getManagersQueryLoading: boolean;
  getAgentsQueryLoading: boolean;
}



const KeepMountedModal: React.FC<AgentModalProps> = ({ check, closeModal, handleSelectedUsers, loadMoreDataHandle, openPanelCategory, getManagersQueryLoading, getAgentsQueryLoading }) => {
  //
  const { getState } = Store;
  const getdata = getState()
  //
  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    //closing Modal
    setOpen(false)
    //turning value to false - for not modal appearing for clicking on the openModal
    closeModal()
    // setOpen(false)
  }
  //
  React.useEffect(() => {
    setOpen(check)
  }, [check])
  //

  const handleSubmitt = (event: FormEvent) => {
    event.preventDefault()
  }
  //
  const handleSearchh = (query: string) => {
    console.log(`Searching for "${query}"...`)
    // perform search logic here
  }

  const [checked, setChecked] = React.useState(false)

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  const data = [1, 2, 3, 4, 5]


  ////////////////////////////////////////////////////////////////////
  const [showError, setShowError] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState('')


  const store = useSelector(store => store)
  const [listData, setListData] = useState<dataProp[]>([])
  const { error } = useSnackBar()
  const [tempListData, setTemListData] = useState<dataProp[]>([])
  const [allSelected, setAllSelected] = useState(false)
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (openPanelCategory === 'managerlist') {
      // const list = []
      const list: dataProp[] = [];


      // store.ManagersListReducer.managersListData.map((item, index) => {
      //   list.push({ ...item, isSelected: false })
      // })

      getdata.ManagersListReducer.managersListData.map((item: dataProp, index: number) => {
        list.push({ ...item, isSelected: false })
      })

      setListData(list)
      setTemListData(list)
    }
    if (openPanelCategory === 'agentlist') {
      // const list = []
      const list: dataProp[] = [];
      // store.AgentsListReducer.setAgentsListData.map((item, index) => {
      //   list.push({ ...item, isSelected: false })
      // })

      getdata.AgentsListReducer.setAgentsListData.map((item: dataProp, index: number) => {
        list.push({ ...item, isSelected: false });
      });

      setListData(list)
      setTemListData(list)
    }
  }, [openPanelCategory, getdata.ManagersListReducer.managersListData])

  const handleSelectAll = (flag: boolean) => {
    const list = [...listData];
    list.forEach(element => {
      element.isSelected = flag
    });
    setListData(list)
    setAllSelected(flag)
  }

  const handleCheckListItem = (data: dataProp, flag: boolean) => {
    const list = [...listData];
    list.forEach(element => {
      if (element.id != null && data.id != null) {
        if (element.id === data.id) {
          element.isSelected = !flag;
        }
      }
    });
    setListData(list)
  }


  const handleSubmit = () => {

    // const tempData = []
    const tempData: dataProp[] = [];

    listData.forEach(element => {
      if (element.isSelected) {
        tempData.push(element)
      }
    });
    if (tempData.length > 0) {
      const isValid = checkProfileValidation({ tempData })
      if (isValid) {
        handleSelectedUsers(tempData)
        // props.onClose()
        handleClose()
      }
    } else {
      setShowError(true)
      setErrorMsg("At least one manager must be selected")
    }
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    let list = [];
    if (e.target.value == '') {
      setListData(tempListData)
    } else {
      list = tempListData.filter(item => item.name.toUpperCase().includes(e.target.value.toUpperCase()));
      setListData(list)
    }
  }



  interface TempData {
    tempData: dataProp[];
  }

  interface MyError {
    listEmpty?: string;
  }


  const checkProfileValidation = (data: TempData) => {
    try {
      // const error = {};
      const error: MyError = {};
      if (data.tempData.length == 0) {
        error.listEmpty = "Please select at least one user"
      }

      dispatch({ type: "ON_ERROR", payload: error });

      return !Object.keys(error).length;
    } catch (error) {
      console.log(error)
    }
  }


  ////////////////////////////////////////////////////////////////////

  React.useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false)
      }, 3000);
    }
  }, [showError])


  return (
    <div>
      <span>
        Choose Managers<span style={{ color: '#E92C2C' }}>*</span>
      </span>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        onClick={(event) => {
          event.stopPropagation()
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '35px',
                //
                marginTop: '10px',
                marginLeft: '20px',
              }}
            >
              <Typography align="center">
                <p
                  style={{
                    fontWeight: '500',
                    fontFamily: 'poppins',
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#272525',
                    //
                    marginBottom: "0px"
                  }}
                >
                  Choose Managers
                </p>
                <p
                  style={{
                    fontFamily: 'poppins',
                    fontWeight: '400',
                    // fontSize: '10px',
                    // lineHeight: '15px',
                    fontSize: "12px",
                    lineHeight: "17px",
                    //
                    color: '#838383',
                    //
                    marginBottom: "0px"
                  }}
                >
                  Select any one
                </p>
              </Typography>
            </Box>

            <span onClick={handleClose} style={{ height: '30px' }}>
              <CloseIcon
                style={{
                  // height: '10.8px',
                  // width: '10.8px',
                  height: '18.8px',
                  width: '18.8px',
                  color: '#B9B9B9',
                  marginTop: '13.61px',
                  marginRight: '13.6px',
                  cursor: 'pointer',
                }}
              />
            </span>
          </Box>
          <Divider
            sx={{ width: '100%', marginTop: '10px', color: '#E8E8E8' }}
          />

          <form onSubmit={handleSubmit}>
            <Box sx={{ padding: '10px 20px 0px 20px' }}>

              <SearchBar onChange={handleSearch} />

              {showError && <Typography variant="body2" style={{
                width: '100%',
                fontFamily: 'poppins',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '18px',
                color: 'red',
                marginTop: '0px',
              }}>
                {errorMsg}
              </Typography>}

              <div style={{ marginTop: showError ? "-8px" : '10px' }}>
                <label>
                  {/* <input
                    type="checkbox"
                    checked={checked}
                    onChange={handleCheckboxChange}
                  /> */}

                  <input type="checkbox" checked={allSelected} onChange={() => handleSelectAll(!allSelected)} />

                  <span
                    style={{
                      fontFamily: 'poppins',
                      fontWeight: '400',
                      // fontSize: '10px',
                      // lineHeight: '15px',
                      fontSize: "12px",
                      lineHeight: "17px",
                      //
                      color: '#838383',
                      position: 'relative',
                      top: '-2px',
                      marginLeft: '5px',
                    }}
                  >
                    Select all
                  </span>
                </label>
              </div>

              <Box
                onScroll={loadMoreDataHandle}
                sx={{
                  marginTop: '10px',
                  // height: '28.5vh',
                  height: '173px',
                  overflowY: 'auto',
                  //
                  scrollbarWidth: 'thin',
                  scrollbarColor: '#ccc transparent',
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#ccc',
                    borderRadius: '3px',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                  },
                  //
                }}
              >


                {!getManagersQueryLoading &&
                  openPanelCategory === 'managerlist' &&
                  listData.length == 0 &&
                  <div className='m-auto text-center'>
                    <p className='font-small'>No record found</p>
                  </div>}

                {listData.length > 0 && listData.map((item_, index) => <ListItem alignItems="center" sx={{ padding: '0px' }}>
                  <input checked={item_.isSelected} onChange={() => handleCheckListItem(item_, item_.isSelected)} type="checkbox" />
                  <Avatar
                    alt="John Doe"
                    // src={image}
                    sx={{
                      width: '30px',
                      height: '30px',
                      marginLeft: '10px',
                      marginRight: '5px',
                      marginTop: '5px',
                      marginBottom: '5px',
                    }}
                  />
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontFamily: 'poppins',
                      fontWeight: '500',
                      fontSize: '12px',
                      lineHeight: '18px',
                      marginTop: 0,
                      color: '#272525',
                    }}
                  >
                    {item_.name}
                  </Typography>
                </ListItem>)}

                {getManagersQueryLoading && openPanelCategory === 'managerlist' && <div className='text-center'>
                  {/* <Loader color={constants.theme.green} /> */}
                  <CircularProgress style={{ marginLeft: "3px", width: "25px", height: "25px", fontWeight: "400", color: "#4d1277" }} thickness={5} />
                </div>}

              </Box>

            </Box>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                // paddingTop: '10px',
                paddingRight: '10px',
                marginTop: "0px"
              }}
            >
              <Button
                variant="contained"
                // color="primary"
                type="submit"
                sx={{
                  width: '63px',
                  height: '28px',
                  backgroundColor: '#4D1277',
                  borderRadius: '3px',
                  fontFamily: 'poppins',
                  fontSize: '12px',
                  fontWright: '500',
                  lineHeight: '18px',
                  color: '#FFFFFF',
                  //
                  textTransform: 'capitalize',
                  boxShadow: 'none',
                }}
              >
                Invite
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  )
}

export default KeepMountedModal
