import { makeStyles } from 'tss-react/mui'
// import theme from '../../style/theme';
//
const useStyles = makeStyles()(() => ({
  boxForm: {
    margin: '10px',
  },

  root: {
    '& .MuiInputBase-input::placeholder': {
      width: '100%',
      height: '30px',
      fontFamily: 'poppins',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#838383',
      //
      display: 'flex',
      alignItems: 'center',
    },
    '& .css-19g784a-MuiInputBase-root-MuiOutlinedInput-root':{
      height:"30px"
    }
  },
}))
export default useStyles
